import * as Yup from 'yup';

export const IncidentReasonSchema = Yup.object().shape({
  reason: Yup.string()
    .trim('La razón no puede contener solo espacios en blanco')
    .min(5, 'La razón debe tener al menos 5 caracteres')
    .required('La razón es requerida'),
});

export const IncidentReason = {
  reason: '',
}