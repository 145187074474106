import React from "react";
import EmptyImage from "../../assets/img/ic_content.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import { Tooltip } from "@mui/material";
import {
  setCurrent,
  setDefaultSort,
  setPage,
  setSortBy,
} from "../../store/slices/traspasosSlice.ts";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const TableTraspasos = () => {
  const dispatch = useDispatch();
  const { query, records, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.traspasos
  );

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";

    dispatch(setSortBy(sort));
    dispatch(setPage(0));

    dispatch(setDefaultSort(sortOrder));
  };

  console.log("traspasos: ", records);
  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr style={{ ...defaultStyle }}>
            <th onClick={() => setSort("id")}>
              Id {sortBy === "id" && getIconSort()}
            </th>
            <th onClick={() => setSort("partner")}>
              Colaborador {sortBy === "partner" && getIconSort()}
            </th>
            {!query.includes("rejected") && (
              <React.Fragment>
                <th onClick={() => setSort("approvalDate")}>
                  Fecha de aprobación
                  {sortBy === "approvalDate" && getIconSort()}
                </th>
                {/* <th >
                  Estatus 
                </th>
                <th >
                  Completado
                </th> */}
              </React.Fragment>
            )}
            <th onClick={() => setSort("newBranch")}>
              Sucursal nueva {sortBy === "newBranch" && getIconSort()}
            </th>
            <th onClick={() => setSort("newPosition")}>
              Posición nueva {sortBy === "newPosition" && getIconSort()}
            </th>
            <th onClick={() => setSort("oldBranch")}>
              Sucursal anterior {sortBy === "oldBranch" && getIconSort()}
            </th>
            <th onClick={() => setSort("oldPosition")}>
              Posición anterior {sortBy === "oldPosition" && getIconSort()}
            </th>
            {!query.includes("rejected") && !query.includes("pending") && (
              <>
                <th onClick={() => setSort("approvedBy")}>
                  Aprobado por {sortBy === "approvedBy" && getIconSort()}
                </th>
                {/* <th onClick={() => setSort("transferCount")}>
                  Número total de traspasos{" "}
                  {sortBy === "transferCount" && getIconSort()}
                </th> */}
              </>
            )}

            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {records?.length > 0 ? (
            records
              .filter((item) => {
                if (query.includes("pending")) return true;
                return true;
              })
              .map((item, index) => {
                const person = item.partner.person;
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                      {toCamelCase(`${person.firstName} ${person.lastName}`)}
                    </td>

                    {!query.includes("rejected") && (
                      <React.Fragment>
                        <td>
                          {item.approvalDate
                            ? moment(item.approvalDate).format("DD/MM/YYYY")
                            : "Sin fecha"}
                        </td>
                        {/* <td>{item.isApproved ? "Aprobado" : "Sin aprobar"}</td>
                        <td>{item.isFinished ? "Completado" : "Pendiente"}</td> */}
                      </React.Fragment>
                    )}

                    <td>{item.newBranch.name}</td>
                    <td>{item.newPosition.name}</td>
                    <td>{item.oldBranch.name}</td>
                    <td>{item.oldPosition.name}</td>
                    {!query.includes("rejected") &&
                      !query.includes("pending") && (
                        <>
                          {item.isApproved && (
                            <td>
                              {item.authorizingUser?.name +
                                " " +
                                item.authorizingUser?.lastName}
                            </td>
                          )}
                          {/* <td>{item?.transferCount}</td> */}
                        </>
                      )}

                    <td>
                      <Tooltip title="Ver detalles" arrow>
                        <button
                          onClick={() => dispatch(setCurrent(item))}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fa-solid fa-eye text-white"></i>
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan={10}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no hay nadie en la lista negra
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableTraspasos;
