import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import FilterVacantes from "./FilterVacantes.tsx";
import PaginationVacantes from "./PaginationVacantes.tsx";
import EmptyImage from "../../assets/img/ic_content.svg";
import {
  setDataToRemove,
  setDefaultSort,
  setPage,
  setPriorityRemove,
  setShowModal,
  setShowModalEdit,
  setShowModalPri,
  setShowModalPriority,
  setSortBy,
} from "../../store/slices/vacantesSlice.ts";
import { Tooltip } from "@mui/material";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import moment from "moment";
import { fixedHeadStyle, fixedStyle } from "../../utils/constanst.js";

const TableVacantes = ({ fetchVacantes }) => {
  const dispatch = useDispatch();
  const { vacantes, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.vacantes
  );

  const sortedVacantes = vacantes?.slice().sort((a, b) => {
    if (a.isPriority === b.isPriority) return 0;
    if (a.isPriority === true) return -1;
    return 1;
  });

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";

    dispatch(setSortBy(sort));
    dispatch(setPage(0));

    dispatch(setDefaultSort(sortOrder));
  };

  const defaultStyle = { cursor: "pointer" };

  return (
    <React.Fragment>
      <div className="input-group w-100">
        <div className="d-flex align-items-center w-100">
          <FilterVacantes />
        </div>
      </div>

      <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead>
            <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
              <th style={{ ...fixedStyle } as React.CSSProperties} className=" text-center" onClick={() => setSort("id")}>
                Id {sortBy === "id" && getIconSort()}
              </th>
              <th
                className=" text-center"
                onClick={() => setSort("businessName")}
              >
                Razón social {sortBy === "businessName" && getIconSort()}
              </th>
              <th className=" text-center" onClick={() => setSort("branch")}>
                Sucursal {sortBy === "branch" && getIconSort()}
              </th>
              <th className=" text-center" onClick={() => setSort("createdAt")}>
                Creada desde {sortBy === "createdAt" && getIconSort()}
              </th>
              <th className=" text-center" onClick={() => setSort("position")}>
                Puestos {sortBy === "position" && getIconSort()}
              </th>
              <th className=" text-end" onClick={() => setSort("isAvailable")}>
                Activo {sortBy === "isAvailable" && getIconSort()}
              </th>
              <th className=" text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedVacantes?.length > 0 ? (
              sortedVacantes.map((item) => {
                return (
                  <tr key={item.id}>
                    <td style={{ ...fixedStyle} as React.CSSProperties} className="text-center">{item.id}</td>
                    <td className=" text-center">
                      {item.isPriority ? (
                        <>
                          <Tooltip title={`Vacante urgente`} arrow>
                            <div>
                              <i
                                className="fa-solid fa-warning me-2"
                                style={{ color: "#FFB22C" }}
                              ></i>
                              {toCamelCase(`${item?.businessName?.name}`)}
                            </div>
                          </Tooltip>
                        </>
                      ) : (
                        item?.businessName?.name
                      )}
                    </td>
                    <td className=" text-center">{item.branch.name}</td>
                    <td className=" text-center">
                      {moment(item.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className=" text-center">{item.position.name}</td>
                    <td className=" text-end">
                      {item.isAvailable ? "Si" : "No"}
                    </td>
                    <td className=" text-end">
                      {
                        <a
                          href="#/"
                          className={
                            item.isPriority
                              ? "btn btn-danger m-2"
                              : "btn btn-gray m-2"
                          }
                          onClick={() => {
                            dispatch(
                              setPriorityRemove({
                                id: item.id,
                                active: item.isPriority,
                                name: "",
                              })
                            );
                            dispatch(setShowModalPriority(true));
                            dispatch(setShowModalPri(true));
                          }}
                        >
                          <span className="d-none d-sm-inline"></span>
                          <i
                            className={
                              item.isPriority ? " fa fa-close" : " fa fa-bell"
                            }
                          ></i>
                        </a>
                      }
                      {
                        <a
                          href="#/"
                          className={
                            item.isAvailable
                              ? "btn btn-orange m-2"
                              : "btn btn-green m-2"
                          }
                          onClick={() => {
                            dispatch(
                              setDataToRemove({
                                id: item.id,
                                active: item.isAvailable,
                                name: "",
                              })
                            );
                            dispatch(setShowModalEdit(true));
                            dispatch(setShowModal(true));
                          }}
                        >
                          <span className="d-none d-sm-inline"></span>
                          <i
                            className={
                              item.isAvailable ? " fa fa-close" : " fa fa-check"
                            }
                          ></i>
                        </a>
                      }
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="py-4">
                    <div className="d-flex">
                      <img
                        src={EmptyImage}
                        alt=""
                        className="mx-auto w-250px"
                      />
                    </div>
                    <h5 className="text-secondary text-center fs-20px">
                      Aún no existen vacantes
                    </h5>
                    <h5 className="text-center text-secondary fw-400 fs-15px">
                      Prueba añadiendo una en
                    </h5>
                    <h5 className="text-center text-secondary fw-400">
                      el botón de 'Agregar vacante'
                    </h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <PaginationVacantes />
    </React.Fragment>
  );
};

export default TableVacantes;
