import React, { useEffect, useState } from "react";
import { Partner } from "../../models/Partner";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import { getIncapacitiesByPartner } from "../../services/incapacities.service.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

const IncapacitiesPartner = ({
  partner,
  reloadPage,
}: {
  partner: Partner;
  reloadPage: any;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [incapacities, setIncapacities] = useState([]);

  useEffect(() => {
    const fetchIncapacities = async () => {
      setLoading(true);
      try {
        const data = await getIncapacitiesByPartner(partner.id);
        setIncapacities(data);
      } catch (error) {
        console.error("Error al cargar incapacidades", error);
      }
      setLoading(false);
    };

    if (partner?.id) {
      fetchIncapacities();
    }
  }, [partner]);

  if (loading) {
    return <div>Cargando incapacidades...</div>;
  }

  if (!incapacities || incapacities.length === 0) {
    return <div>No se encontraron incapacidades para este socio.</div>;
  }

  return (
    <div className="tab-pane fade" id="incapacidades">
      <div className="row g-4">
        <div className="col-12">
          <button
            className="btn btn-success p-2"
            onClick={() => navigate(`/mi-expediente/nuevo/${partner.id}`)}
          >
            Agregar nueva incapacidad
          </button>
        </div>

        {incapacities.map((incapacity: any) => (
          <div className="col-md-4 col-sm-6" key={incapacity.id}>
            <div
              className="card mb-4 rounded-4 shadow-sm"
              style={{
                border: "1px solid #e0e0e0",
                backgroundColor: "#fafafa",
                transition: "transform 0.3s ease",
                height: "100%",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
              }}
            >
              <a onClick={() => navigate(`incapacidad/${incapacity.id}`)}>
                <div className="card-body">
                  <div className="mt-2 mb-3">
                    <span
                      className={`badge ${
                        incapacity.approved ? "bg-success" : "bg-warning"
                      }`}
                    >
                      {incapacity.approved ? "Aprobado" : "Pendiente"}
                    </span>
                  </div>

                  <h5 className="card-title fs-4">
                    {incapacity.title.substring(0, 30) +
                      (incapacity.title.length > 30 ? "..." : "")}
                  </h5>
                  <p className="card-text text-muted fs-6">
                    {incapacity.description.substring(0, 80) +
                      (incapacity.description.length > 80 ? "..." : "")}
                  </p>

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-text">
                      <i
                        className="fa-solid fa-paperclip"
                        style={{ color: "#f71d1d" }}
                      ></i>{" "}
                      {incapacity.proofFiles && incapacity.proofFiles.length > 0
                        ? `${incapacity.proofFiles.length} archivos`
                        : "Aún no tiene documentos adjuntados"}
                    </p>
                    <div className="text-muted" style={{ fontSize: 14 }}>
                      Creado el:{" "}
                      {format(
                        parseISO(incapacity.createdAt),
                        "dd 'de' MMMM yyyy",
                        {
                          locale: es,
                        }
                      )}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncapacitiesPartner;
