import httpClient from "./httpClient";

const prefix = "/candidates";

export async function updateSalary(id: number, data: {}) {
  return (await httpClient.put(`${prefix}/update-salary/${id}`, data)).data;
}

export async function getMissingIMSSSalary() {
  return (await httpClient.get(`${prefix}/missing-IMSS-salary`)).data;
}

export async function getStatsChilitos(){
  return (await httpClient.get(`${prefix}/stats`)).data;
}

export async function getChilitosByBranch(id: number) {
  return (await httpClient.get(`${prefix}/documents-branch/${id}`)).data;
}

export async function getShiftManager() {
  return (await httpClient.get(`${prefix}/get-shift-manager`)).data;
}