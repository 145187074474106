import React, { useEffect, useMemo, useState } from "react";
import {
  Scanner,
  IDetectedBarcode,
  useDevices,
} from "@yudiel/react-qr-scanner";
import { notification } from "../../utils/Notifications.tsx";
import moment from "moment";
import {
  registerAssistance,
  authorizeAssistance,
} from "../../services/assistanceCheck.service.ts";
import { findConfigurationByKey } from "../../services/configuration.service.ts";
import {
  fetchPartner,
  getMessagePartner,
} from "../../services/partner.service.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { findByName } from "../../services/branch.service.ts";
import { isValidForSweetAlert } from "../../validation/schemas/MessagePartnerSchema.ts";
import Swal from "sweetalert2";
import Branch from "../../models/Branch.ts";
import { useWebSocket } from "../../hooks/useWebSocket.tsx";
import Loader from "../../components/Loader.tsx";
import { useDevicesHook } from "../../hooks/useDevices.tsx";

const QrScanner = () => {
  const [scannerEnabled, setScannerEnabled] = useState(true);
  const [actualDate, setActualDate] = useState(new Date());
  const [branch, setBranch] = useState<Branch>();
  const [tempUser, setTempUser] = useState({
    nombre: "",
    checkAt: "",
    status: "",
  });
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  // const { devices, defaultDeviceId } = useDevicesHook();
  const devices = useDevices();
  const [refreshKey, setRefreshKey] = useState(0);
  const [show, setShow] = useState(true);

  const { isConnected, triggerUpdate } = useWebSocket();
  const { user } = useSelector((state: RootState) => state.auth);
  const [configuration, setConfiguration] = useState<any>(null);

  const fetchConfiguration = async () => {
    try {
      const config = await findConfigurationByKey("assistance");
      setConfiguration(config);
      console.log("config", config);
      const branch = await findByName(user.branch);
      console.log("branch", branch);
      setBranch(branch);
    } catch (error) {
      console.error("Error al obtener la configuración:", error);
    }
  };

  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (!scannerEnabled || detectedCodes.length === 0) return;

    setScannerEnabled(false);

    try {
      const rawValue = detectedCodes[0].rawValue;
      if (!rawValue) {
        throw new Error("No data found in QR code");
      }

      const object = JSON.parse(rawValue);
      console.log(object);
      const { dataQr, buildId, userId } = object;

      const authorizationResponse = await authorizeAssistance(
        dataQr,
        buildId,
        userId
      );

      if (authorizationResponse.status === "Authorized") {
        const { decryptedDataQr } = authorizationResponse.details;
        const { user, timestamp, device, ip, municipio, location } =
          decryptedDataQr;
        const time = new Date(timestamp);
        // 14 segundo
        const expirationTime = new Date(time.getTime() + 14000);
        // const expirationTime = new Date(time.getTime() + 1400000);

        if (expirationTime < new Date()) {
          notification("error", "El código QR ha expirado", "danger");
          return;
        }

        const partner = await fetchPartner(user.id);
        const message = await getMessagePartner(partner.id);

        console.log("Message", message);
        if (isValidForSweetAlert(message)) {
          Swal.fire({
            title: message.title,
            text: message.message,
            icon: message.icon as any,
            showCancelButton: false,
            showConfirmButton: false,
            timer: message.duration * 1000,
            timerProgressBar: true,
          });
        }

        const assistanceObject = {
          branchId: branch?.id,
          checkDate: moment(actualDate).format("YYYY-MM-DD"),
          checkTime: moment(actualDate).format("HH:mm:ss"),
          details: {
            partner: user.id,
            deviceId: device.deviceId,
            deviceModel: device.deviceModel,
            deviceName: device.deviceName,
            ip: ip,
            location: municipio,
            coords: JSON.stringify(location),
          },
        };

        const savedAssistance = await registerAssistance(
          user.id,
          assistanceObject
        );

        console.log("savedAssistance", savedAssistance);

        setTempUser({
          nombre: user.name,
          checkAt: moment(actualDate).format("HH:mm:ss"), 
          // status: savedAssistance[0].status ,
          status: savedAssistance.lenth > 0 ? savedAssistance[0].status : savedAssistance.status
        });

        triggerUpdate({ branchId: Number(branch?.id) });

        notification(
          "Registrado",
          "Registro gurdado correctamente",
          "success"
        );
      } else {
        notification(
          "error",
          "No autorizado: el dispositivo no coincide con el registrado",
          "danger"
        );
      }
    } catch (error) {
      console.error("Error al manejar el escaneo:", error);
      notification("error", "Error al procesar el código QR", "danger");
    } finally {
      setTimeout(() => {
        setScannerEnabled(true);
        setTempUser({
          nombre: "",
          checkAt: "",
          status: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActualDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchConfiguration();
  }, []);

  useEffect(() => {
    console.log(deviceId);
  }, [deviceId]);

  if (!configuration)
    return (
      <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            No se ha establecido una configuración de hora de llegada máxima y
            mínima de entrada.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa estos campos desde el menú configuración para
            poder registrar la asistencia de los trabajadores.
          </p>
        </div>
      </div>
    );

  if (!isConnected) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="w-100 px-4 mt-1">
      <div className="row flex-column flex-md-row justify-content-md-between w-100">
        <div
          className={`col-md-${
            Boolean(branch?.typeBanner === "text" && branch?.message) ||
            Boolean(branch?.typeBanner === "file" && branch?.imageUrl)
              ? 6
              : 12
          } ps-2 ${
            !(
              Boolean(branch?.typeBanner === "text" && branch?.message) ||
              Boolean(branch?.typeBanner === "file" && branch?.imageUrl)
            ) && "d-flex justify-content-center"
          }`}
        >
          <div className="bg-white py-3 pt-4 pb-2 rounded-3 shadow mb-4 mb-md-0">
            <div className="mx-auto w-75 text-center mb-3">
              <p
                className={`fs-20px ${
                  scannerEnabled ? "text-success" : "text-secondary"
                }`}
              >
                {scannerEnabled ? "Escanea tu QR" : "Escaneado"}
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <select
                  // key={refreshKey}
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                    // setRefreshKey(prevKey => prevKey + 1)
                    setShow(false);
                  }}
                >
                  <option value={undefined}>
                    {devices.length === 0
                      ? "Cargando..."
                      : "Selecciona un dispositivo"}
                  </option>
                  {devices.map((device, index) => (
                    <option key={index} value={device.deviceId}>
                      {device.label}
                    </option>
                  ))}
                </select>
                <button onClick={() => setRefreshKey((prevKey) => prevKey + 1)}>
                  <i className="fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
              {scannerEnabled ? (
                <>
                  <Scanner
                    key={refreshKey}
                    onScan={handleScan}
                    styles={{ container: { width: "100%", borderRadius: 10 } }}
                    // paused={show}
                    constraints={{
                      // deviceId: deviceId,
                      facingMode: "user",

                      // deviceId: { exact: deviceId },
                    }}
                    // components={{
                    //   onOff: true,
                    //   audio: true,
                    //   finder: true,
                    //   zoom: true,
                    //   torch: true,
                    //   // tracker: true,
                    // }}
                    // constraints={{
                    //   facingMode: "environment",
                    //   deviceId: deviceId,
                    // }}
                    onError={(error) => {
                      console.log(`onError: ${error}'`);
                    }}
                  />
                </>
              ) : (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin fa-2x"></i>
                </div>
              )}
            </div>

            <div className="text-center">
              <p className="display-6 mb-2">
                {moment(actualDate).format("dddd, DD MMMM YYYY")}
              </p>
              <p className="display-1 fw-bold">
                {actualDate.toLocaleTimeString()}
              </p>
            </div>

            {tempUser.nombre && tempUser.checkAt && (
              <div
                className={`mx-auto w-75 mt-4 p-3 rounded-4 shadow text-center ${
                  tempUser.status === "Asistencia" ||
                  tempUser.status === "Salida registrada"
                    ? "bg-success text-white"
                    : tempUser.status === "Retardo"
                    ? "bg-danger text-white"
                    : "bg-secondary text-white"
                }`}
              >
                <h3 className="display-6">{tempUser.nombre}</h3>
                <p className="h4">
                  {tempUser.status === "Salida registrada"
                    ? "Salida"
                    : "Entrada"}{" "}
                  registrada a las: {actualDate.toLocaleTimeString()}
                </p>
              </div>
            )}
          </div>
        </div>

        {branch?.typeBanner === "text" && branch?.message && (
          <div className="col-md-6 ps-2">
            <div className="bg-light p-4 rounded-3 shadow text-center h-100">
              <h1 className="display-5 fw-semibold">Aviso del día</h1>
              <div dangerouslySetInnerHTML={{ __html: branch.message }} />
            </div>
          </div>
        )}

        {branch?.typeBanner === "file" && branch?.imageUrl && (
          <div className="col-md-6 ps-2">
            <div className="bg-light p-4 rounded-3 shadow text-center h-100">
              <h1 className="display-5 fw-semibold">Aviso del día</h1>
              <img
                src={branch.imageUrl}
                alt="Aviso del día"
                className="img-fluid rounded-3 mt-4"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QrScanner;
