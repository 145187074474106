import { Template } from "../others/IEditTemplate";

export const DefaultNotificationUser = {
  idUser: -99,
  rule: -99,
  idBranch: -99,
  idUserType: -99,
  idRule: -99,
};

export interface EditNotificationUser {
  idUser: number;
  idBranch: number;
  idRule: number;
  idUserType: number;
  rule: string;
}

export interface INotificationUser {
  id: number;
  idUserType: number;
  idUser: number;
  idBranch: number;
  idRule: number;
  createdAt: string;
  updateAt: string;
  rule?: string;
}

export const NotificationUserInitial: INotificationUser = {
  id: -1,
  idUserType: -1,
  rule: "",
  idRule: -1,
  idUser: -1,
  idBranch: -1,
  createdAt: "",
  updateAt: "",
};

export const NotificationUserFill = (data, typeRule) => {

  console.log(typeRule.find((rule) => rule.name === data?.rule)?.id)

  return {
    id: data?.id,
    idUserType: data?.userName?.rol?.id,
    rule: data?.rule,
    idRule: typeRule.find((rule) => rule.name === data?.rule)?.id || -1, 
    idUser: data?.userName?.id,
    idBranch: data?.branchName?.id,
    createdAt: data?.createdAt,
    updateAt: data?.updateAt,
  };
};

