import React, { useEffect } from "react";
import { useFormik } from "formik";
import { IDeleteUser } from "../../interfaces/others/IDeleteUser";
import { notification } from "../../utils/Notifications.tsx";
import { cancelProgramingPartner, unsubscribePartner, unsubscribeProgrammingPartner } from "../../services/partner.service.ts";
import { DeletePartnerSchema } from "../../validation/schemas/DeletePartnerSchema.ts";
import CustomInput from "../formik/CustomInput.tsx"
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";

const DeletePartner: React.FC<IDeleteUser> = ({ name, id, programed, closeFn }) => {

  const formik = useFormik({
    validationSchema: DeletePartnerSchema,
    initialValues: {
      reasonUnsubscribe: "",
      temporalUnsubscribe: false,
      banDate: null,
    },
    onSubmit: async (values) => {
      try {
        if (!values.temporalUnsubscribe) {
          const { reasonUnsubscribe } = values;
          await unsubscribePartner(id, { reasonUnsubscribe });
        } else {
          await unsubscribeProgrammingPartner(id, { ...values });
        }
        notification("", `Usuario ${name} dado de baja correctamente`, "info");
      } catch (error) {
        console.error(error);
        notification(
          "Error :(",
          `Ocurrio un error al tratar de dar de baja a ${toCamelCase(name)}`,
          "danger"
        );
      } finally {
        closeFn();
      }
    },
  });

  const handleCancel = async () => {
    try {
      await cancelProgramingPartner(id);
      notification("Éxito", `Baja de ${toCamelCase(name)} cancelada`, "success");
      closeFn();
    } catch (error) {
      console.error(error);
      notification(
        "Error :(",
        `Ocurrio un error al tratar de cancelar la baja de ${toCamelCase(name)}`,
        "danger"
      );
    }
  }

  useEffect(() => {
    if (programed) {
      console.log("programed", programed)
      formik.setFieldValue("temporalUnsubscribe", !!programed)
      formik.setFieldValue("banDate", programed.banDate)
      formik.setFieldValue("reasonUnsubscribe", programed.reasonUnsubscribe)
    }
  }, [])

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas dar de baja a {toCamelCase(name)}?
      </p>
      <CustomInput
        formik={formik}
        field="reasonUnsubscribe"
        label="Razón de la baja"
        placeholder="Escribe la razón de la baja"
        sm={8}
        smLabel={4}
        disabled={programed}
      />
      <CustomCheckboxField
        field="temporalUnsubscribe"
        formik={formik}
        label="Baja programada"
        mdLabel={4}
        disable={programed}
      />
      {
        formik.values.temporalUnsubscribe && (
          <CustomInput
            type="date"
            formik={formik}
            field="banDate"
            label="Fecha de baja"
            sm={8}
            smLabel={4}
            disabled={programed}
            minDate={new Date().toISOString().split("T")[0]}
          />
        )
      }

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        {
          programed ?
            <button className="btn btn-azul me-2" onClick={() => handleCancel()}>
              Cancelar baja programada
            </button>
            :
            <button
              className="btn btn-danger"
              onClick={() => formik.handleSubmit()}
            >
              Dar de baja
            </button>
        }
      </div>
    </React.Fragment>
  );
};

export default DeletePartner;
