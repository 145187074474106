import React from "react";
import { useParams } from "react-router-dom";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";

import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { PartnersInBranch } from "../../models/PartnersInBranch.ts";
import { getChilitosByBranch } from "../../services/candidate.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import TableDocuments from "../../components/reports/documentsBranch/TableDocuments.tsx";
import { utils, writeFile } from "xlsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { formatDate, isValidImageUrl } from "../../utils/index.ts";

const DocumentsBranch = () => {
  useAppSettings();
  const { id } = useParams();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [records, setRecods] = React.useState<PartnersInBranch>();

  const fetchData = async () => {
    try {
      const response = await getChilitosByBranch(Number(id));
      setRecods(response);
    } catch (error) {
      notification("", "Error al obtener los registros", "danger");
    } finally {
      setLoading(false);
    }
  };

  const exportFile =async () => {
    // Crear una hoja de trabajo vacía
    const ws = utils.aoa_to_sheet([]);

    // Añadir encabezados personalizados
    const headers = [
      "Nombre",
      "Puesto",
      "Fecha de entrada",
      "¿Tiene credenciales?",
      "Documentos pendientes",
    ];
    utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    // Iterar sobre los datos y añadir filas personalizadas
    let rowIndex = 2; // Empezar en la fila 2 (después de los encabezados)

    for(const record of  records?.partners || []){
      const row = [
        toCamelCase(
          `${record.partner.person.firstName} ${record.partner.person.lastName} ${record.partner.person.secondLastName}`
        ),
        record.partner.candidate.vacancy.position.name,
        formatDate(String(record.recruitmentDate)),
        record.hasCredentials ? "Sí" : "No",
        record.documents.missingDocuments.missingDocumentsInmediate.length +
          record.documents.missingDocuments.missingDocumentsNoInmediate.length 
      ];
      utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
      rowIndex++;
    }
    // records?.partners.forEach((record) => {
    //   const { ine, perfil, solicitud, domicilio } = record.documents;
    

    //   const row = [
    //     toCamelCase(
    //       `${record.partner.person.firstName} ${record.partner.person.lastName} ${record.partner.person.secondLastName}`
    //     ),
    //     record.partner.candidate.vacancy.position.name,
    //     formatDate(String(record.recruitmentDate)),
    //     record.hasCredentials ? "Sí" : "No",
    //     record.documents.missingDocuments.missingDocumentsInmediate.length +
    //       record.documents.missingDocuments.missingDocumentsNoInmediate.length 
    //       // + Number(ineTotal) +
    //       // Number(perfilTotal) +
    //       // Number(solicitudTotal) +
    //       // Number(domicilioTotal),
    //   ];
    //   utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
    //   rowIndex++;
    // });
    ws["!cols"] = [
      { wch: 35 }, // Nombre
      { wch: 35 }, // Puesto
      { wch: 15 }, // Fecha de entrada
      { wch: 16 }, // tiene credenciales
      { wch: 15 }, // documentos pendientes
    ];

    // Crear el libro de trabajo y añadir la hoja de trabajo
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");

    // Exportar a XLSX
    writeFile(wb, "Plantilla de lideres.xlsx");
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Reportes"
        basePath="/reportes"
        title="Documentos de cada chilito por sucursal"
      />
      <div className="border-0 m-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 20 }}
                  >
                    <div className="alert alert-success m-0" role="alert">
                      {records?.branch.name}
                    </div>
                    <div className="alert alert-primary m-0" role="alert">
                      {records?.partners.length} Chilitos
                    </div>
                    <div className="alert alert-info m-0" role="alert">
                      {
                        records?.partners.filter(
                          (chilito) => chilito.hasCredentials
                        ).length
                      }
                      &nbsp;Credenciales generadas
                    </div>
                    <div className="alert alert-primary m-0" role="alert">
                      {Number(records?.partners.length) -
                        Number(
                          records?.partners.filter(
                            (chilito) => chilito.hasCredentials
                          ).length
                        )}
                      &nbsp;Credenciales faltantes
                    </div>
                  </div>
                  <button
                    className="btn btn-verde d-block btn-sm fs-14px"
                    onClick={() => exportFile()}
                  >
                    Exportar&nbsp;&nbsp;
                    <i className="fa-solid fa-file-arrow-down" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <TableDocuments data={records?.partners || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentsBranch;
