import React from "react";
import IFormikProps from "../../../interfaces/others/IFormikProps";
import { PanelBody } from "../../../components/panel/panel";
import CustomSelectField from "../../../components/formik/CustomSelectField.tsx";
import { readBranches } from "../../../services/branch.service.ts";
import { readAllUser } from "../../../services/user.service.ts";
import { listAllRoles } from "../../../services/role.service.js";

const NotificationUserForm = ({ formik }: IFormikProps) => {
  const [typeUsers, setTypeUsers] = React.useState([]);
  interface User {
    id: number;
    name: string;
    rol: {
      id: number;
      name: string;
    };
    branch?: string;
  }

  const [users, setUsers] = React.useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);
  const [branches, setBranches] = React.useState([]);
  const [warningMessage, setWarningMessage] = React.useState("");

  const typeRule = [
    { id: 1, name: "Inasistencias" },
    { id: 2, name: "Posiciones abiertas" },
    { id: 3, name: "Cumpleaños" },
  ];

  const getDependencies = async () => {
    try {
      const users = await readAllUser();
      const branches = await readBranches();
      const roles = await listAllRoles();
      setUsers(users);
      setTypeUsers(roles);
      console.log(roles);
      setFilteredUsers(users);
      setBranches(branches);
      console.log(branches);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  React.useEffect(() => {
    const userTypeId = formik.values.idUserType;

    const isLeader = userTypeId === 2;

    if (isLeader) {
      const user = filteredUsers.find((user) => user.rol.id === userTypeId);
      if (!user || !user.branch) {
        setWarningMessage("No tiene sucursal asignada.");
      } else {
        setWarningMessage("");
      }
    } else {
      setWarningMessage("");
    }

    if (userTypeId) {
      const filtered = users.filter((user) => user.rol.id === userTypeId);
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [formik.values.idUserType, users]);

  return (
    <React.Fragment>
      <div>
        <PanelBody>
          <CustomSelectField
            formik={formik}
            field="idUserType"
            list={typeUsers}
            label="Tipo de usuario"
            sm={5}
          />

          <CustomSelectField
            formik={formik}
            field="idUser"
            list={filteredUsers}
            label="Usuario"
            sm={5}
          />

          <CustomSelectField
            formik={formik}
            field="idBranch"
            list={branches}
            label="Sucursal"
            sm={5}
            disabled={formik.values.idUserType === 2}
          />

          {warningMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {warningMessage}
            </div>
          )}

          <CustomSelectField
            formik={formik}
            field="idRule"
            list={typeRule}
            label="Regla"
            sm={5}
          />
        </PanelBody>
      </div>
    </React.Fragment>
  );
};

export default NotificationUserForm;
