import AWS from 'aws-sdk';

const apiUrl = process.env.REACT_APP_API_URL;

export class CatalogosService {

  static desactivateCatalogos = async (catalog, id) => {
    const url = apiUrl + `/${catalog}/desactivate/${id}`;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "PATCH",
      headers,
    });
    const data = await resp.json();
    return data;
  }

  static getCatalogos = async (catalog) => {
    const url = apiUrl + "/"+catalog;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });
    const data = await resp.json();
    return data;
  }

  static saveCatalogos = async (catalog, formData) => {
    const url = apiUrl + "/"+catalog;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(formData),
    });

    const data = await resp.json();
    return data;
  }

  static updateCatalogos = async (catalog, formData, id) => {
    const url = apiUrl + "/"+catalog+"/"+id;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify(formData),
    });

    const data = await resp.json();
    return data;
  }

  static getBusinessNames = async () => {
    const url = apiUrl + "/business-names";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getPositions = async () => {
    const url = apiUrl + "/catalog/positions";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getAvailablePositions = async () => {
    const url = apiUrl + "/positions/available";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getSchoolings = async () => {
    const url = apiUrl + "/catalog/schoolings";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getShifts = async () => {
    const url = apiUrl + "/catalog/shifts";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getPerfilUrl = async (curp) => { 
    return ;
  };

  static getIneUrl = async (curp) => {
    return ;
  };

  static getDomicilioUrl = async (curp) => {
    return ;
  };

  static getSolicitudUrl = async (curp) => {
   return ;
  };

  static getBranches = async () => {
    const url = apiUrl + "/catalog/branches";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getBranchesByPosition = async (positionId) => {
    const url = apiUrl + "/branches/position/" + positionId;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getSizes = async () => {
    const url = apiUrl + "/catalog/sizes";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };

  static getApplicationsStatuses = async () => {
    const url = apiUrl + "/catalog/application_statuses";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  }

  static getInterviewStatuses = async () => {
    const url = apiUrl + "/catalog/interview_statuses";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  }

  static getPeriodicities = async () => {
    const url = apiUrl + "/catalog/periodicities";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  }

  static getReasons = async () => {
    const url = apiUrl + "/catalog/reasons";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  }

  static getAvailabilitiesByPosition = async (id) => {
    const url = apiUrl + "/availabilities/position/" + id;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const resp = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await resp.json();
    return data;
  };
  static putCAtalog = async (id, urlEdit, formData) => {
    const url = apiUrl + urlEdit;
    let headers = new Headers();

    console.log("URL", url);
    console.log("FORMDATA", formData);

    headers.append("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(formData),
    });

    const data = await resp.json();
    return data;

  }

  static postCatalog = async (urlPost, formData) => {
    const url = apiUrl + urlPost;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(formData),
    });

    const data = await resp.json();
    return data;

  }

  static deleteCatalog = async (id, urlDelete) => {
    const url = apiUrl + urlDelete;
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "DELETE",
      headers,
    });

    const data = await resp.json();
    return data;

  }

}