import React, { useEffect, useState } from "react";
import CustomTextEditor from "../../../CustomTextEditor.tsx";
import { useFormik } from "formik";
import {
  readBranch,
  updateMessage,
} from "../../../../services/branch.service.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import CustomFileInput from "../../../formik/CustomFileInput.tsx";

const EditBranchMessage = ({ branchId, closeFn }) => {
  const [branch, setBranch] = useState(null);
  const [editorType, setEditorType] = useState("text");
  const [loading, setLoading] = useState(false);

  const fetchDependencies = async () => {
    setLoading(true);
    const branch = await readBranch(branchId);
    console.log("##BRANCHEEEEES", branch);
    setBranch(branch);
    formik.setValues({
      message: branch.message || " ",
      banner: branch.imageUrl,
      typeBanner: branch.typeBanner || "text",
      imageFileType: branch.imageFileType || "",
    });
    setEditorType(branch.typeBanner || "text");
    setLoading(false);
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const formik = useFormik({
    initialValues: {
      message: branch?.message || "",
      banner: branch?.imageUrl || null,
      typeBanner: branch?.typeBanner || "text",
      imageFileType: branch?.imageFileType || "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("Valores a enviar:", values);
        if (!values.message || values.message === "null") {
          values.message = "";
        }
        if (!values.message && editorType === "file") {
          values.message = branch.message;
        }
        await updateMessage(branchId, values);
        notification("Éxito", "Mensaje actualizado", "success");
        setLoading(false);
        closeFn();
      } catch (error) {
        console.error(error);
        notification(
          "Error",
          "Ocurrió un error al actualizar el mensaje",
          "danger"
        );
        setLoading(false);
      }
    },
  });

  const handleEditorTypeChange = (e) => {
    const selectedType = e.target.value;
    setEditorType(selectedType);
    formik.setFieldValue("typeBanner", selectedType);
  };

  console.log("Valores actuales", formik.values);

  return (
    <div className="d-flex flex-column">
      <div className="mb-3">
        <label htmlFor="editorType" className="form-label">
          Selecciona el tipo de banner
        </label>
        <select
          id="editorType"
          className="form-select"
          value={editorType}
          onChange={(e) => {
            const selectedType = e.target.value;
            setEditorType(selectedType);
            formik.setFieldValue("typeBanner", selectedType);
          }}
        >
          <option value="text">Editor de texto</option>
          <option value="file">Subir banner</option>
        </select>
      </div>

      {editorType === "text" && (
        <CustomTextEditor
          label="Mensaje de la sucursal"
          formik={formik}
          field="message"
          required
        />
      )}

      {editorType === "file" && (
        <CustomFileInput
          formik={formik}
          field="banner"
          label="Banner"
          showLabel={false}
          accept="image/*"
          sm={12}
        />
      )}

      {branch?.updatedBy && (
        <p className="text-muted mt-3">
          Última actualización por:{" "}
          {branch.updatedBy?.name + " " + branch.updatedBy?.lastName}
        </p>
      )}

      <button
        className="btn btn-primary ms-auto mt-4"
        type="submit"
        disabled={loading}
        onClick={() => formik.handleSubmit()}
      >
        {
          loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            "Guardar"
          )
        }
      </button>
    </div>
  );
};

export default EditBranchMessage;
