import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { PartnerElement } from "../../../models/PartnersInBranch";
import RowPartnetDocuments from "./RowPartnetDocuments.tsx";

const TableDocuments = ({ data }: { data: PartnerElement[] }) => {
  const [records, setRecords] = React.useState<PartnerElement[]>(data);
  const [column, setColumn] = React.useState<string>("name");
  const [order, setOrder] = React.useState<string>("desc");

  React.useEffect(() => {
    setRecords(data);
    setSort("name");
  }, [data]);

  const getIconSort = () => {
    return (
      <>
        <i className={`fa-solid fa-sort-${order === "asc" ? "up" : "down"}`} />
        &nbsp;
      </>
    );
  };

  const setSort = (sort: string) => {
    const sortOrder =
      column !== sort ? "asc" : order === "asc" ? "desc" : "asc";

    setColumn(sort);

    setOrder(sortOrder);

    // Order by name
    if (sort === "name") {
      setRecords(
        data.sort((a, b) =>
          sortOrder === "asc"
            ? a.partner.person.firstName.localeCompare(
                b.partner.person.firstName
              )
            : b.partner.person.firstName.localeCompare(
                a.partner.person.firstName
              )
        )
      );
    }

    // Order by position
    if (sort === "position") {
      setRecords(
        data.sort((a, b) =>
          sortOrder === "asc"
            ? a.partner.candidate.vacancy.position.name.localeCompare(
                b.partner.candidate.vacancy.position.name
              )
            : b.partner.candidate.vacancy.position.name.localeCompare(
                a.partner.candidate.vacancy.position.name
              )
        )
      );
    }

    // Order by recruitmentDate type date
    if (sort === "recruitmentDate") {
      setRecords(
        data.sort((a, b) =>
          sortOrder === "asc"
            ?new Date( a.recruitmentDate).getTime() -new Date( b.recruitmentDate).getTime()
            : new Date(b.recruitmentDate).getTime() - new Date(a.recruitmentDate).getTime()
        )
      );
    }

    // Order by credentials type boolean (true, false) no is number
    if (sort === "credentials") {
      setRecords(
        data.sort((a, b) =>
          sortOrder === "asc"
            ? a.hasCredentials === b.hasCredentials
              ? 0
              : a.hasCredentials
              ? -1
              : 1
            : b.hasCredentials === a.hasCredentials
            ? 0
            : b.hasCredentials
            ? -1
            : 1
        )
      );
    }

    // Order by documentsPending type number
    if (sort === "documentsPending") {
      setRecords(
        data.sort((a, b) =>
          sortOrder === "asc"
            ? Number(
                a.documents.missingDocuments.missingDocumentsInmediate.length +
                  a.documents.missingDocuments.missingDocumentsNoInmediate
                    .length
              ) -
              Number(
                b.documents.missingDocuments.missingDocumentsInmediate.length +
                  b.documents.missingDocuments.missingDocumentsNoInmediate
                    .length
              )
            : Number(
                b.documents.missingDocuments.missingDocumentsInmediate.length +
                  b.documents.missingDocuments.missingDocumentsNoInmediate
                    .length
              ) -
              Number(
                a.documents.missingDocuments.missingDocumentsInmediate.length +
                  a.documents.missingDocuments.missingDocumentsNoInmediate
                    .length
              )
        )
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              onClick={() => setSort("name")}
              style={{ cursor: "pointer" }}
            >
              {column === "name" && getIconSort()}
              Nombre
            </TableCell>
            <TableCell
              onClick={() => setSort("position")}
              style={{ cursor: "pointer" }}
            >
              {column === "position" && getIconSort()}
              Puesto
            </TableCell>
            <TableCell
              onClick={() => setSort("recruitmentDate")}
              style={{ cursor: "pointer" }}
            >
              {column === "recruitmentDate" && getIconSort()}
              Fecha de entrada
            </TableCell>
            <TableCell
              onClick={() => setSort("credentials")}
              align="center"
              style={{ cursor: "pointer" }}
            >
              {column === "credentials" && getIconSort()}
              Credenciales
            </TableCell>
            <TableCell
              onClick={() => setSort("documentsPending")}
              align="center"
              style={{ cursor: "pointer" }}
            >
              {column === "documentsPending" && getIconSort()}
              Documentos pendientes
            </TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row, _) => (
            <RowPartnetDocuments key={_} data={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDocuments;
