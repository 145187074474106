import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { CalendarService } from "../../services/calendar.service";
import { fetchAllAplications } from "../../services/application.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import CalendarRow from "./CalendarRow.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

function CalendarAgenda() {
  const plugins = [dayGridPlugin, timeGridPlugin, bootstrapPlugin];
  const headerToolbar = {
    left: "dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    right: "prev,next today",
  };
  const buttonText = {
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
  };
  const views = {
    timeGrid: {
      eventLimit: 6,
    },
  };

  const [events, setEvents] = useState();

  const fetchEvents = async (start, end) => {
    const events = [];
    try {
      const data = await fetchAllAplications(start, end);
      //   return;
      data?.forEach((element) => {
        const date = new Date(
          element.applicationDate + " " + element.applicationTime
        );
        element.date = new Intl.DateTimeFormat("es-MX", {
          dateStyle: "full",
          timeStyle: "medium",
        }).format(date);

        if (true) {
          console.log(element);
          let evento = {
            title: toCamelCase(
              element.person.firstName +
              " " +
              element.person.lastName +
              " " +
              element.person.secondLastName),
            start: element.applicationDate + "T" + element.applicationTime,
            // branch: element.vacancy.branch.name,
            color: getColor(element.applicationStatus.name),
          };
          events.push(evento);
        }

        setEvents(events);
      });
    } catch (error) {
      console.log(error);
      notification("Error ❌", "Error al cargar las citas", "danger");
    }
  };

  const getColor = (status) => {
    switch (status) {
      case "Solicitado":
        return "#28a745";
      case "Entrevistado":
        return "#007bff";
      case "Aceptado":
        return "#ffc107";
      case "Pre-Colaborador":
        return "rgb(255 235 0)";
      case "Colaborador":
        return "#17a2b8";
      case "Rechazado":
        return "rgb(255 67 67)";
      default:
        return "rgb(255 67 67)";
    }
  };

  const getCalendarEvents = () => {
    let events = [];
    CalendarService.getApplications().then((data) => {
      let rows = data.rows;

      rows?.forEach((element) => {
        const date = new Date(
          element.application_date + " " + element.application_time
        );
        element.date = new Intl.DateTimeFormat("es-MX", {
          dateStyle: "full",
          timeStyle: "medium",
        }).format(date);

        if (element.id_application_status == "1") {
          let evento = {
            title:
              element.first_name +
              " " +
              element.last_name +
              " " +
              element.second_last_name,
            start: element.application_date + "T" + element.application_time,
          };
          events.push(evento);
        }

        setEvents(events);
      });
    });
  };

  const handleDateRangeData = (date) => {
    const start = date.startStr;
    const end = date.endStr;
    fetchEvents(start, end);
  };

  return (
    <div> <div className='m-4' style={{marginTop: 0, marginBottom: 0}}>
    <h5>Simbología:</h5>
    <ul className="list-inline">
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: '#28a745', border: '1px solid #0002' }}>Solicitado</span>
      </li>
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: '#007bff', border: '1px solid #0002' }}>Entrevistado</span>
      </li>
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: '#ffc107', border: '1px solid #0002' }}>Aceptado</span>
      </li>
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: 'rgb(255 235 0)', border: '1px solid #0002' }}>Pre-Colaborador</span>
      </li>
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: '#17a2b8', border: '1px solid #0002' }}>Colaborador</span>
      </li>
      <li className="list-inline-item">
        <span className="badge text-black" style={{ backgroundColor: 'rgb(255 67 67)', border: '1px solid #0002' }}>Rechazado</span>
      </li>
    </ul>
  </div>
      <div className="row m-4">
        
        <div className="col-lg">
          
          <FullCalendar
            datesSet={(arg) => {
              handleDateRangeData(arg);
            }}
            plugins={plugins}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            headerToolbar={headerToolbar}
            buttonText={buttonText}
            events={events}
            views={views}
            defaultTimedEventDuration="01:00:00"
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            weekends="true"
            // eventContent={(eventInfo) => (
            //   <CalendarRow item={eventInfo.event} />
            // )} 
          />
        </div>
      </div>
    </div>
  );
}

export default CalendarAgenda;
