import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { setShowModal } from "../../../../store/slices/incidentSlice.ts";

import PartnersAutocomplete from "../../../formik/PartnersAutocomplete.tsx";
import SelectHour from "../../../formik/SelectHour.tsx";
import IncidentSchema from "../../../../validation/schemas/IncidentSchema.ts";
import { RootState } from "../../../../store/index.ts";
import { Incident } from "../../../../models/IncidentRecord.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import { saveIncident } from "../../../../services/branchSchedule.service.ts";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";

const ModalIncident = () => {
  const dispatch = useDispatch();
  const { currentRecord, refreshFunction } = useSelector((state: RootState) => state.incident);
  const [initialValues, setInitialValues] = React.useState({
    id: -99,
    originalPartnerId: -99,
    partnerReplacement: -99,
    entryTime: new Date(),
    departureTime: new Date(),
    observations: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: IncidentSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        let { id, departureTime, entryTime, ...data } = values;

        // Get the hour and minute of the entry time
        const entryHour =
          entryTime.getHours() < 10
            ? `0${entryTime.getHours()}`
            : entryTime.getHours();
        const entryMinute =
          entryTime.getMinutes() < 10
            ? `0${entryTime.getMinutes()}`
            : entryTime.getMinutes();
        const entryTimeString = `${entryHour}:${entryMinute}`;

        // Get the hour and minute of the departure time
        const departureHour = departureTime.getHours();
        const departureMinute = departureTime.getMinutes();
        const departureTimeString = `${departureHour}:${departureMinute}`;

        const response = await saveIncident(id, {
          ...data,
          entryTime: entryTimeString,
          departureTime: departureTimeString,
        });
        notification("Exito", response.message, "success");
      } catch (error) {
        notification("Error", error.message ?? "Ocurrio un error", "danger");
      } finally {
        dispatch(setShowModal(false));
        await refreshFunction();
      }
    },
  });

  React.useEffect(() => {
    if (currentRecord) {
      const record: Incident = currentRecord;
      const [startHour, startMinute] = record.startTime
        ?.split(":")
        ?.map((str) => parseInt(str.trim(), 10));

      const [endHour, endMinute] = record.endTime
        .split(" ")[2]
        ?.split(":")
        ?.map((str) => parseInt(str.trim(), 10));

      const entryTime = new Date();
      entryTime.setHours(startHour, startMinute, 0, 0);

      const departureTime = new Date();
      departureTime.setHours(endHour, endMinute, 0, 0);

      setInitialValues({
        id: record.id,
        originalPartnerId: -99,
        partnerReplacement: -99,
        entryTime: entryTime,
        departureTime: departureTime,
        observations: "",
      });
    }
  }, [currentRecord]);

  return (
    <React.Fragment>
      <PartnersAutocomplete field="partnerReplacement" formik={formik} />
      <SelectHour field="entryTime" formik={formik} label="Hora de entrada" />
      <SelectHour
        field="departureTime"
        formik={formik}
        label="Hola de salida"
      />
      <div className="mt-2" />
      <CustomTextArea
        field="observations"
        formik={formik}
        label="Observaciones"
        placeholder="Escribe alguna observación"
        smLabel={4}
        sm={8}
      />

      <div className="d-flex justify-content-end mt-3">
        <button
          className="btn btn-secondary me-2"
          onClick={() => dispatch(setShowModal(false))}
        >
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalIncident;
