import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer.tsx";
import EmptyImage from "../../assets/img/ic_content.svg";
import { setPage } from "../../store/slices/notificationsUserSlice.ts";
import DeleteNotificationUser from "./components/DeleteNotificationUser.tsx";

const TableNotificationsUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [id, setId] = React.useState<number>(0);
  const [name, setName] = React.useState<string>("");
  const [ruleName, setRuleName] = React.useState<string>("");

  const { total, page, limit, notificationsUser } = useSelector(
    (state: RootState) => state.notificationUser
  );

  console.log("notificationsUser", notificationsUser);

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage));
  };

  return (
    <React.Fragment>
      <div className="table-responsive mb-3">
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th>No.</th>
              <th>Tipo de Usuario</th>
              <th>Usuario</th>
              <th>Regla de notificación</th>
              <th>Sucursal</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {notificationsUser?.notificationsUser?.length > 0 ? (
              notificationsUser.notificationsUser.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.userName.rol.name}</td>
                  <td>
                    {item.userName.name} {item.userName.lastName}
                  </td>
                  <td>{item.rule}</td>
                  <td>{item.branchName.name}</td>
                  <td>
                    <a
                      href="#/"
                      className="btn btn-naranja"
                      onClick={() => navigate(`editar/${item.id}`)}
                    >
                      <span className="d-none d-sm-inline"></span>
                      <i className="fa fa-pencil"></i>
                    </a>
                    {Number(item.id) !== 1 && (
                      <a
                        href="#/"
                        className="btn btn-rojo m-2"
                        onClick={() => {
                          setId(Number(item.id));
                          setName(item.userName.name);
                          setRuleName(item.rule);
                          setShowModal(true);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa fa-circle-xmark"></i>
                      </a>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className="py-4">
                    <div className="d-flex">
                      <img
                        src={EmptyImage}
                        alt=""
                        className="mx-auto w-250px"
                      />
                    </div>
                    <h5 className="text-secondary text-center fs-20px">
                      Aún no existen usuarios
                    </h5>
                    <h5 className="text-center text-secondary fw-400 fs-15px">
                      Prueba añadiendo uno en
                    </h5>
                    <h5 className="text-center text-secondary fw-400">
                      el botón de 'Nuevo usuario'
                    </h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
          Mostrando{" "}
          {page * limit + notificationsUser?.notificationsUser?.length} de{" "}
          {total} registros
        </div>
        <ul className="pagination mb-0 justify-content-center">
          <li className={`page-item ${page === 0 && "disabled"} `}>
            <a
              href="#/"
              className="page-link"
              onClick={() => handlePageChange(page - 1)}
            >
              Anterior
            </a>
          </li>

          <li
            className={`page-item ${
              total <=
                page * limit + notificationsUser?.notificationsUser?.length &&
              "disabled"
            } `}
          >
            <a
              href="#/"
              className="page-link"
              onClick={() => handlePageChange(page + 1)}
            >
              Siguiente
            </a>
          </li>
        </ul>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => {
          setShowModal(false);
          setId(-1);
          setName("");
          setRuleName("");
        }}
        title={`Eliminar a ${name}`}
        children={
          <DeleteNotificationUser
            userName={name}
            rule={ruleName}
            id={id}
            closeFn={() => {
              setShowModal(false);
              setId(-1);
              setName("");
              setRuleName("");
            }}
          />
        }
      />
    </React.Fragment>
  );
};

export default TableNotificationsUsers;
