import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { readPartnersByBranch } from '../../../services/partner.service.ts';
import moment from 'moment';
import { findByExactDateAndBranch, readHoursByBranch, readPositionsByBranchAndShift } from '../../../services/branchSchedule.service.ts';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import { generateTimeIntervals } from '../../../utils/constanst.js';
import Title from '../../Title.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index.ts';
import { toCamelCase } from '../../../hooks/toCameCase.tsx';

const ResumenHorarios = () => {
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  useAppSettings();
  const [selectedDate, setSelectedDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const [schedules, setSchedules] = React.useState<any[]>([]);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [hours, setHours] = React.useState<any[]>([]);
  const [branchHours, setBranchHours] = React.useState<any[]>([]);
  const [hoursRange, setHoursRange] = React.useState<any>();

  const fetchDependencies = async () => {

    const schedules = await findByExactDateAndBranch(selectedDate, Number(id));
    const branchHours = await readHoursByBranch(Number(id));
    setBranchHours(branchHours);



    if (!branchHours) return;

    const hoursArray = await generateTimeIntervals(branchHours?.matutinoStart, branchHours?.vespertinoEnd);

    const positions = await readPositionsByBranchAndShift(Number(id), 'Todos');
    const positionsArray = positions.map((position) => {
      const totalWorkersRequired = position.numberOfWorkers;

      const workers: any = [];

      for (let i = 0; i < totalWorkersRequired; i++) {
        workers.push({
          id: i,
          positionId: position.position.id,
          positionName: position.position.name + ' ' + (i + 1),
          originalPositionName: position.position.name,
          shift: position.shift,
        });
      }

      return workers
    });

    const sortedByNames = positionsArray.flat().sort((a, b) => {
      return a.positionName.localeCompare(b.positionName);
    });

    const sortOrder = ['Matutino', 'Intermedio', 'Vespertino'];

    const sorted = sortedByNames.sort((a, b) => {
      return sortOrder.indexOf(a.shift) - sortOrder.indexOf(b.shift);
    });

    setPositions(sorted);

    const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];
    const schedulesSortedByShift = schedules.sort((a, b) => {
      return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
    });

    const schedulesGroupedByShift = groupHoursByShift(hoursArray, branchHours);
    setHoursRange(schedulesGroupedByShift);

    setHours(hoursArray);

    setSchedules(schedulesSortedByShift);
  }

  useEffect(() => {
    fetchDependencies();
  }, [])

  const calculateColorRange = (scheduleShift: string, hour: string) => {
    const branchHoursMap = {
      Matutino: {
        start: branchHours.matutinoStart,
        end: branchHours.matutinoEnd,
      },
      Intermedio: {
        start: branchHours.intermedioStart,
        end: branchHours.intermedioEnd,
      },
      Vespertino: {
        start: branchHours.vespertinoStart,
        end: branchHours.vespertinoEnd,
      },
    };

    const { start, end } = branchHoursMap[scheduleShift];

    if (end.substring(0, 5) === '00:00') {
      return '#F6FB7A';
    }

    if (isHourInRange(start.substring(0, 5), end.substring(0, 5), hour)) {
      return '#F6FB7A';
    } else {
      return '#75869444';
    }
  };

  const isHourInRange = (startHour: string, endHour: string, hour: string): boolean => {
    const format = 'HH:mm';
    const start = moment(startHour, format);
    const end = moment(endHour, format);
    const checkHour = moment(hour, format);

    if (start.isAfter(end)) {
      return (
        checkHour.isBetween(start, moment('23:59', format), undefined, '(]') ||
        checkHour.isBetween(moment('00:00', format), end, undefined, '[)')
      );
    }

    return checkHour.isBetween(start, end, undefined, '(]');
  };

  type TimeSlot = { key: number; display: string };
  type ShiftTimes = {
    matutinoStart: string;
    matutinoEnd: string;
    intermedioStart: string;
    intermedioEnd: string;
    vespertinoStart: string;
    vespertinoEnd: string
  };

  type ShiftResult = {
    matutino: TimeSlot[];
    intermedio: TimeSlot[];
    vespertino: TimeSlot[];
  };

  const groupHoursByShift = (timeSlots: TimeSlot[], shifts: ShiftTimes): ShiftResult => {
    const format = 'HH:mm:ss';

    const result: ShiftResult = {
      matutino: [],
      intermedio: [],
      vespertino: [],
    };

    const isHourInShift = (startHour: string, endHour: string, shiftStart: string, shiftEnd: string) => {
      const start = moment(startHour, format);
      const end = moment(endHour, format);
      const shiftStartTime = moment(shiftStart, format);
      const shiftEndTime = moment(shiftEnd, format);

      if (shiftStartTime.isAfter(shiftEndTime)) {
        return (
          start.isBetween(shiftStartTime, moment('23:59:59', format), undefined, '()') ||
          start.isBetween(moment('00:00:00', format), shiftEndTime, undefined, '()') ||
          end.isBetween(shiftStartTime, moment('23:59:59', format), undefined, '()') ||
          end.isBetween(moment('00:00:00', format), shiftEndTime, undefined, '()')
        );
      }

      return (
        start.isBetween(shiftStartTime, shiftEndTime, undefined, '[)') ||
        end.isBetween(shiftStartTime, shiftEndTime, undefined, '[)')
      );
    };

    timeSlots.forEach((slot) => {
      const [startHour, endHour] = slot?.display.split(' - ').map(time => `${time}:00`);

      if (isHourInShift(startHour, endHour, shifts.matutinoStart, shifts.matutinoEnd)) {
        result.matutino.push(slot);
      }

      if (isHourInShift(startHour, endHour, shifts.intermedioStart, shifts.intermedioEnd)) {
        result.intermedio.push(slot);
      }

      if (isHourInShift(startHour, endHour, shifts.vespertinoStart, shifts.vespertinoEnd)) {
        result.vespertino.push(slot);
      }
    });

    return result;
  };

  const getIntermedioIndex = () => {
    let intermedioIndex = 0;

    if (
      hoursRange.matutino[hoursRange.matutino.length - 1]?.display.split(' - ')[1] ===
      hoursRange.intermedio[0]?.display.split(' - ')[0]
    ) {
      intermedioIndex = hoursRange.matutino.findIndex((hour) => hour?.display.split(' - ')[1] === hoursRange.intermedio[0]?.display.split(' - ')[0]) + 1;
    } else {
      intermedioIndex = hoursRange.matutino.indexOf(hoursRange.intermedio[0]);
    }
    return intermedioIndex;
  }

  const getVespertinoIndex = () => {
    let vespertinoIndex = 0;

    const areTimeSlotsContinuous = (endTime: string, startTime: string) => {
      const format = 'HH:mm';
      const end = moment(endTime, format);
      const start = moment(startTime, format);

      return end.isSame(start);
    };

    if (
      areTimeSlotsContinuous(
        hoursRange.matutino[hoursRange.matutino.length - 1]?.display.split(' - ')[1],
        hoursRange.intermedio[0]?.display.split(' - ')[0]
      )
    ) {
      vespertinoIndex = hoursRange.matutino.length + hoursRange.intermedio.length;
    } else {
      const intermedioIndex = getIntermedioIndex();
      const vespertinoStartIndex = hoursRange.intermedio.findIndex((hour) =>
        areTimeSlotsContinuous(
          hour?.display.split(' - ')[1],
          hoursRange.vespertino[0]?.display.split(' - ')[0]
        )
      );

      vespertinoIndex = intermedioIndex + vespertinoStartIndex + 1;
    }

    return vespertinoIndex;
  };


  if (schedules.length === 0) return (
    <Layout>
      <Title baseTitle="Resumen de horario" basePath="" title="Resumen de horarios" />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4 p-4">
          <h5 className='text-center mb-2'>Buscar por día</h5>
          <div className="d-flex flex-row w-100 justify-content-center">
            <input
              type='date'
              className="form-control w-25 me-2"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={fetchDependencies}
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            La sucursal no cuenta con un horario establecido.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa el horario de la sucursal para poder ver los horarios de los trabajadores.
          </p>
        </div>
      </div>
    </Layout>
  )

  return (
    <Layout>
      <Title baseTitle="Resumen de horario" basePath="" title="Resumen de horarios" />

      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4 p-4">
          <h5 className='text-center mb-2'>Buscar por día</h5>
          <div className="d-flex flex-row w-100 justify-content-center">
            <input
              type='date'
              className="form-control w-25 me-2"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={fetchDependencies}
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <div>
              <h5>Simbología:</h5>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#0F0', border: '1px solid #0002' }}>Puesto cubierto</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#F6FB7A', border: '1px solid #0002' }}>Puesto no cubierto</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#75869444', border: '1px solid #0002' }}>Sin puesto requerido</span>
                </li>
              </ul>
            </div>
            <div className="table-sticky mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th className="border text-center" colSpan={hoursRange.matutino.length}>
                      Matutino
                    </th>
                  </tr>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th className="border text-center" colSpan={getIntermedioIndex()}></th>
                    <th className="border text-center" colSpan={hoursRange.intermedio.length}>
                      Intermedio
                    </th>
                  </tr>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th className="border text-center" colSpan={getVespertinoIndex()}></th>
                    <th className="border text-center" colSpan={hoursRange.vespertino.length}>
                      Vespertino
                    </th>
                  </tr>
                  <tr className="sticky-thead">
                    <th className="border-end text-center"></th>
                    {hours.map((hour, index) => (
                      <th className="border text-center" key={index}>
                        {hour?.display}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {positions.map((position, index) => (
                    <tr key={index}>
                      <td className="sticky-column border-end bg-white">{position.positionName}</td>
                      {hours.map((hour, index) => {
                        console.log(hour);
                        const posibleSchedule = schedules.find(
                          (schedule) =>
                            schedule.positionName === position.positionName &&
                            schedule.shift === position.shift &&
                            (isHourInRange(
                              schedule.startTime.split(' - ')[0],
                              schedule.endTime.split(' - ')[1],
                              hour?.display.split(' - ')[1]
                            )
                              || schedule.endTime.split(' - ')[1] === '00:00' && hour?.display.split(' - ')[1] === '00:00'
                            )
                        );
                        if (posibleSchedule) {
                          return (
                            <td
                              className="border-black border-2"
                              style={{ backgroundColor: '#0F0' }}
                            >
                              <span>{toCamelCase(`${posibleSchedule.partner.person.firstName} ${posibleSchedule.partner.person.lastName}`)}</span>
                            </td>
                          );
                        } else {
                          if (hoursRange[position.shift.toLowerCase()].includes(hour)) {
                            return (
                              <td
                                className="border-black border-2"
                                style={{ backgroundColor: calculateColorRange(position.shift, hour?.display.split(' - ')[1]) }}
                              >
                                <span>Sin asignar</span>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                className="border"
                                style={{ backgroundColor: '#75869444' }}
                              >
                                <span>No aplica</span>
                              </td>
                            );
                          }
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResumenHorarios