import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";

import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import ModalContainer from "../../../ModalContainer.tsx";
import TableIncidencias from "./TableIncidencias.tsx";
import ModalIncident from "./ModalIncident.tsx";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import {
  countIncidentsByBranch,
  readIncidentsByBranch,
} from "../../../../services/branchSchedule.service.ts";
import {
  AssistanceCheck,
  Incident,
} from "../../../../models/IncidentRecord.ts";

import { RootState } from "../../../../store/index.ts";
import {
  setDate,
  setLoading,
  setRefreshFunction,
  setSelectButton,
  setShowModal,
} from "../../../../store/slices/incidentSlice.ts";
import ModifyAssitence from "./ModifyAssitence.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import "moment/locale/es";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Badge } from "@mui/material";
import { getIconNumber } from "../../../../utils/index.ts";
import { readIncidents } from "../../../../services/assistanceCheck.service.ts";
import { Partner } from "../../../../models/Partner.ts";

const Incidencias = () => {
  useAppSettings();

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  let id;

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const dispatch = useDispatch();
  const [currentMonth, setCurrentMonth] = React.useState(dayjs());
  const [calendar, setCalendar] = React.useState<
    {
      date: string;
      records: number;
      Incidencias: number;
      "Sin entrada": number;
      "Sin salida": number;
      Faltas: number;
      Remplazos: number;
    }[]
  >([]);
  const { showModal, title, loading, selectButton, date, showClock } =
    useSelector((state: RootState) => state.incident);

  const [incidents, setIncidents] = React.useState<
    { key: string; records: Incident[] }[]
  >([]);
  const [withoutSchedule, setWithoutSchedule] = React.useState<{
    partners: Partner[];
    records: AssistanceCheck[];
  }>({ partners: [], records: [] });
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [fetch, setFetch] = React.useState(false);

  const fetchDependencies = async () => {
    dispatch(setLoading(true));
    try {
      const response = await readIncidents(
        Number(id),
        date?.format("YYYY-MM-DD"),
        selectButton
      );

      if (selectButton === "Remplazos") {
        setIncidents([{ key: "Remplazos", records: response }]);
        return;
      }

      const {
        attandanceAfter,
        departureBefore,
        withoutCheckOut,
        withoutRegisters,
        withoutSchedule,
      } = response;

      const totalIncidents =
        attandanceAfter.length +
        departureBefore.length +
        withoutCheckOut.length +
        withoutRegisters.length +
        // withoutSchedule.partners.length;

        setIncidents([
          { key: "Llego despues", records: attandanceAfter },
          { key: "Salio antes", records: departureBefore },
          { key: "Sin salida", records: withoutCheckOut },
          { key: "Sin registros", records: withoutRegisters },
        ]);
      setWithoutSchedule(withoutSchedule);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const countIncidentsByMonth = async () => {
    dispatch(setLoading(true));
    try {
      const records = await countIncidentsByBranch(
        Number(id),
        currentMonth?.format("YYYY-MM-DD")
      );
      setCalendar(records);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchDependencies();
    dispatch(
      setRefreshFunction(async () => {
        await fetchDependencies();
        await countIncidentsByMonth();
      })
    );
  }, [selectButton, fetch]);

  React.useEffect(() => {
    countIncidentsByMonth();
  }, [currentMonth]);

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const {
      highlightedDays = [],
      day,
      outsideCurrentMonth,
      showDaysOutsideCurrentMonth,
      ...other
    } = props;

    // 2024-10-25
    // Buscar day en el calendar

    const dayString = day.format("YYYY-MM-DD");
    const dayInCalendar = calendar.find((c) => c.date === dayString);

    if (outsideCurrentMonth) {
      return (
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      );
    }

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={getIconNumber(dayInCalendar?.records ?? 0)}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const CustomButton = ({ title }) => {
    const record =
      title === "Faltas" || title === "Remplazos" || title === "Completados"
        ? null
        : calendar.find((c) => c.date === date?.format("YYYY-MM-DD"));

    return (
      <button
        className={`btn btn-${
          selectButton === title ? "azul" : "secondary"
        } mb-2 mx-2`}
        onClick={() => dispatch(setSelectButton(title))}
      >
        {title}
        {/* {JSON.stringify(
          calendar.find((c) => c.date === date?.format("YYYY-MM-DD"))
        )} */}
        {/* &nbsp;&nbsp; ({ record? record[title] : 0}) */}
        {record && (
          <>
            &nbsp;&nbsp; ({record[title === "Incidencias" ? "records" : title]})
          </>
        )}
      </button>
    );
  };

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Sucursales"
          basePath="/sucursales"
          activeTitle={`Incidencias del día`}
          title={`Incidencias del día`}
        />
        <div className="card border-0 m-4 rounded">
          <div className="tab-content p-3">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CustomButton title="Incidencias" />
              <CustomButton title="Remplazos" />

              <button
                className={`btn btn-verde mb-2`}
                onClick={() => setShowCalendar(true)}
              >
                {date?.format("DD/MM/YYYY")}&nbsp;&nbsp;
                <i className="fa-regular fa-calendar-check" />
              </button>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DemoContainer
                    components={["MobileDatePicker"]}
                    sx={{
                      pt: 0,
                      mx: 1,
                      cursor: "pointer",
                      width: 0,
                      height: 0,
                    }}
                  >
                    <DemoItem>
                      <MobileDatePicker
                        open={showCalendar}
                        sx={{ cursor: "pointer" }}
                        className="form-control mb-5px fs-13px"
                        value={dayjs(date)}
                        defaultValue={dayjs(date)}
                        onClose={() => {
                          setShowCalendar(false);
                          setFetch(!fetch);
                        }}
                        onChange={(e: any) => {
                          dispatch(setDate(e));
                        }}
                        slots={{
                          day: ServerDay,
                        }}
                        onMonthChange={(e) => {
                          if (currentMonth.month() !== e.month()) {
                            // Validar cuando cambie de mes
                            setCurrentMonth(e);
                          }
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            <TableIncidencias
              records={incidents}
              withoutSchedule={withoutSchedule}
            />
          </div>
        </div>
      </Layout>
      <ModalContainer
        open={showModal}
        title={title}
        closeFn={() => dispatch(setShowModal(false))}
        size="lg"
        children={
          <React.Fragment>
            {title === "Cambiar chilito" ? (
              <ModalIncident />
            ) : title === "Modificar registro" ? (
              <ModifyAssitence />
            ) : (
              <>a</>
            )}
          </React.Fragment>
        }
        {...(showClock && {
          size: "xl",
        })}
      />
    </React.Fragment>
  );
};

export default Incidencias;
