import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  assignBadgeToPartner,
  createBadge,
  deleteBadge,
  readBadgeById,
  readBadges,
  updateBadge,
} from "../../services/badges.service.ts";
import {
  setBadgesList,
  setFetch,
  setFetchRemove,
} from "../../store/slices/badgesSlice.ts";
import { AssingBadgeProps } from "../../interfaces/others/IDeleteBadge.ts";
import {
  DefaultBadge,
  EditBadgeProperties,
  FillBadge,
} from "../../interfaces/formik/IBadges.ts";
import { RootState } from "../../store/index.ts";
import { useFormik } from "formik";
import {
  AssignBadgeSchema,
  BadgeSchema,
} from "../../validation/schemas/BadgeSchema.ts";
import CustomInput from "../../components/formik/CustomInput.tsx";
import CustomSelectField from "../../components/formik/CustomSelectField.tsx";
import CustomTextArea from "../../components/formik/CustomTextArea.tsx";

const AssignBadgeToPartner: React.FC<AssingBadgeProps> = ({
  partner,
  badgesOfPartner,
  closeFn,
}) => {
  const dispatch = useDispatch();
  const { badgesList } = useSelector((state: RootState) => state.badges);
  const [initialValues, setInitialValues] = React.useState<EditBadgeProperties>(
    {} as EditBadgeProperties
  );
  const [loading, setLoading] = React.useState(false);

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const badgesForList = await readBadges();
      dispatch(setBadgesList(badgesForList));
      console.log("BADGES RECIBIDOS DE BACK", badgesForList);

      const badgesFiltered = badgesForList.filter(
        (badge) =>
          !badgesOfPartner.some((partnerBadge) => partnerBadge.id === badge.id)
      );

      setBadgesListNew(badgesFiltered);
    } catch (error) {
      notification("error", "Error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };

  const [badgesListNew, setBadgesListNew] = React.useState<any>([]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: AssignBadgeSchema,
    initialValues: {
      badgeId: -99,
      partnerName: partner.person.firstName + " " + partner.person.lastName,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await assignBadgeToPartner(values.badgeId, partner.id);
        notification("", "Insignia asignada correctamente", "success");
      } catch (error) {
        if (error.statusCode === 409) {
          const message = error.message;
          const badgeNameMatch = message.match(/"([^"]+)"/);
          const badgeName = badgeNameMatch
            ? badgeNameMatch[1].toUpperCase()
            : "LA INSIGNIA REQUERIDA";
          notification(
            "error",
            `No puedes asignar esta insignia, debe completar ${badgeName} primero.`,
            "warning"
          );
        } else {
          notification("error", "Error al guardar la insignia", "danger");
        }
      } finally {
        setLoading(false);
        dispatch(setFetchRemove(true));
        closeFn();
      }
    },
  });

  React.useEffect(() => {
    loadDependencies();
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          formik={formik}
          field="partnerName"
          label="Nombre de colaborador"
          placeholder="Colaborador"
          sm={8}
          disabled={true}
          smLabel={4}
          required
        />

        <CustomSelectField
          formik={formik}
          field="badgeId"
          list={badgesListNew}
          label="Nueva insignia"
          sm={8}
          disabled={loading}
          smLabel={4}
        />

        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
            Cancelar
          </button>
          <button className="btn btn-success" disabled={loading} type="submit">
            Asignar insignia
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AssignBadgeToPartner;
