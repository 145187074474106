export interface ColorScheduleProperties {
  id: number;
  name: string;
  value: Value;
}

export interface Value {
  parcial: string;
  retardo: string;
  noAplica: string;
  sinCubrir: string;
  asistencia: string;
  sinAsistencia: string;
  porLlegar: string;
}

export class ColorSchedule {
  id: number;
  name: string;
  value: Value;

  constructor(id: number, name: string, value: Value) {
    this.id = id;
    this.name = name;
    this.value = value;
  }
}

export const defaultFilters = {
  resumen: false,
  realTime: false,
  assistence: false,
  late: false,
  withoutAssistence: false,
  toArrive: false
};
