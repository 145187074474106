import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  createBadge,
  deleteBadge,
  readBadgeById,
  readBadges,
  updateBadge,
} from "../../services/badges.service.ts";
import { setBadgesList, setFetch } from "../../store/slices/badgesSlice.ts";
import { EditBadgeProps } from "../../interfaces/others/IDeleteBadge.ts";
import {
  DefaultBadge,
  EditBadgeProperties,
  FillBadge,
} from "../../interfaces/formik/IBadges.ts";
import { RootState } from "../../store/index.ts";
import { useFormik } from "formik";
import { BadgeSchema } from "../../validation/schemas/BadgeSchema.ts";
import CustomInput from "../../components/formik/CustomInput.tsx";
import CustomSelectField from "../../components/formik/CustomSelectField.tsx";
import CustomTextArea from "../../components/formik/CustomTextArea.tsx";

const EditBadge: React.FC<EditBadgeProps> = ({ name, id, closeFn }) => {
  const dispatch = useDispatch();
  const { badgesList } = useSelector((state: RootState) => state.badges);
  const [initialValues, setInitialValues] = React.useState<EditBadgeProperties>(
    {} as EditBadgeProperties
  );
  const [loading, setLoading] = React.useState(false);

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const badgesForlist = await readBadges();
      dispatch(setBadgesList(badgesForlist));
      
      const template = await readBadgeById(Number(id));
      const fillObject = FillBadge(template);

      if (!fillObject) throw new Error("Error al cargar los datos");
      setInitialValues(fillObject);
    } catch (error) {
      notification("error", "Error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: BadgeSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("values", values);
      try {
        if (id) {
          await updateBadge(Number(id), {
            title: values.title,
            description: values.description,
            parentId: values.parentBadgeId,
          });
          notification(
            "success",
            "Insignia actualizada correctamente",
            "success"
          );
        } else {
          await createBadge({
            title: values.title,
            description: values.description,
            parentId: values.parentBadgeId === -99 ? undefined : values.parentBadgeId,
          });
          notification("success", "Insignia creada correctamente", "success");
        }
      } catch (error) {
        notification("error", "Error al guardar la insignia", "danger");
      } finally {
        setLoading(false);
        dispatch(setFetch(true));
        closeFn();
      }
    },
  });

  React.useEffect(() => {
    if (id) {
      loadDependencies();
    } else {
      setInitialValues(DefaultBadge as EditBadgeProperties);
    }
  }, [id]);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          formik={formik}
          field="title"
          label="Nombre de la insignia"
          placeholder="Insignia"
          sm={8}
          disabled={loading}
          smLabel={4}
          required
        />
        <CustomTextArea
          formik={formik}
          field="description"
          label="Descripción"
          placeholder="Descripción"
          sm={8}
          disabled={loading}
          smLabel={4}
          required
        />
        <CustomSelectField
          formik={formik}
          field="parentBadgeId"
          list={badgesList}
          label="Desbloquear cuando se obtenga"
          sm={8}
          disabled={loading}
          smLabel={4}
        />

        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
            Cancelar
          </button>
          <button className="btn btn-success" disabled={loading} type="submit">
            {id ? "Actualizar insignia" : "Crear insignia"}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EditBadge;
