import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";

moment.locale("es");

const TableInasistencias = () => {
  const { records } = useSelector((state: RootState) => state.inasistencias);

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Colaborador</th>
            <th>Sucursal asignada</th>
            <th>Posición</th>
            <th>Turno</th>
            <th>Rango de Fechas</th>
            <th>Día de la Semana</th>
            <th>Fecha Exacta</th>
            <th>Hora de Entrada</th>
            <th>Hora de Salida</th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 ? (
            records.map((item, index) => {
              const {
                id,
                partner,
                positionName,
                shift,
                dateRange,
                dayOfWeek,
                exactDate,
                startTime,
                endTime,
                assistanceCheck,
                assistanceCheckout,
              } = item;

              // Asignar valores predeterminados para partner en caso de que falten datos.
              const partnerName = partner.person
                ? `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
                : "Desconocido";

              return (
                <tr key={index}>
                  <td>{id}</td>
                  <td>{partnerName}</td>
                  <td>{partner.candidate.branch.name}</td>
                  <td>{positionName}</td>
                  <td>{shift}</td>
                  <td>{dateRange}</td>
                  <td>{dayOfWeek}</td>
                  <td>{moment(exactDate).format("DD/MM/YYYY")}</td>
                  <td>{assistanceCheck ? assistanceCheck.checkTime : startTime}</td>
                  <td>{assistanceCheckout ? assistanceCheckout.checkTime : endTime}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">Aún no existen datos</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableInasistencias;
