import React from "react";
import Layout from "../../components/Layout.tsx";
import NavBar from "../puestos/components/NavBar.tsx";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Title from "../../components/Title.tsx";
import NotificationsUsersComponent from "./components/NotificationsUsersComponent.tsx";

type Props = {};

const NotificationsUsers = (props: Props) => {
  useAppSettings();
  return (
    <Layout>
      <Title
        baseTitle="Notificaciones"
        basePath="/notificaciones"
        title="Notificationes para usuarios"
      />
      <NotificationsUsersComponent />
    </Layout>
  );
};

export default NotificationsUsers;
