import React from 'react'
import { useParams } from 'react-router-dom';
import { fetchPartners } from '../../../services/partner.service.ts';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import Title from '../../Title.tsx';
import TableColaboradores from './componentes/TableColaboradores.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { setColaboradores } from '../../../store/slices/colaboradoresBranchSlice.ts';
import { RootState } from '../../../store/index.ts';
import { resetState, setLoading, setTotal } from '../../../store/slices/colaboradoresSlice.ts';
import { notification } from '../../../utils/Notifications.tsx';
import FiltrosColaboradores from '../../colaboradores/FiltrosColaboradores.tsx';
import PaginationColaboradores from '../../../pages/colaboradores/PaginationColaboradores.tsx';
const PartnersByBranch = () => {
  useAppSettings();
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const dispatch = useDispatch();

  const {
    menuSelectedByBranch,
    page,
    limit,
    param,
    loading,
    fetch,
    query,
    sortBy,
    defaultSort,
  } = useSelector((state: RootState) => state.colaboradores);

  const fetchColaboradores = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query;
      if (queryToSend.length === 0) {
        queryToSend = `tab=${menuSelectedByBranch}`;
      }

      let filter = `?sort=${sortBy}&order=${defaultSort}&offset=${offset}&limit=${limit}&branch=${id}&${queryToSend}`;
      const response = await fetchPartners(filter);

      dispatch(setColaboradores(response.results));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", "Error al cargar los colaboradores", "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchColaboradores();
  }, [page, limit, menuSelectedByBranch, param, fetch, query, sortBy, defaultSort]);

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Layout loading={loading}>
      <Title baseTitle="Sucursales" basePath="/sucursales" activeTitle='Colaboradores' title="Colaboradores" />
      <div className="card border-0 m-4">
        <div className="tab-content p-3">
          <FiltrosColaboradores branch />
          <TableColaboradores />
          <PaginationColaboradores />
        </div>
      </div>
    </Layout>
  )
}

export default PartnersByBranch