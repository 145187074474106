import React from 'react'
import EmptyImage from '../../../../assets/img/ic_content.svg'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import ModalContainer from '../../../ModalContainer.tsx'
import ModalEditAssistance from './ModalEditAssistance.tsx'
import ModalDetailsAssistance from './ModalDetailsAssistance.tsx'

const getColor = (status) => {
  switch (status) {
    case 'Asistencia':
      return 'bg-success text-white fw-600'
    case 'Retardo':
      return 'bg-danger text-white fw-600'
    case 'Salida registrada':
      return 'text-info'
    default:
      return 'bg-secondary text-white fw-600'
  }
}

const TableChecks = ({ checkList, refetch }) => {
  const [currentRecord, setCurrentRecord] = React.useState<any>(null)
  const [detailMode, setDetailMode] = React.useState(false)

  return (
    <div className="table-responsive mb-3 p-4">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th style={{ width: '23%' }}>Fecha</th>
            <th style={{ width: '22%' }}>Hora de chequeo</th>
            <th style={{ width: '20%' }}>Estatus</th>
          </tr>
        </thead>
        <tbody>
          {checkList && checkList.length > 0
            ? (
              checkList.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '10%' }}>{moment(item.checkDate).format('dddd, D [de] MMMM [de] YYYY')}</td>
                  <td style={{ width: '10%' }}>{item.checkTime}</td>
                  <td style={{ width: '10%' }} className={getColor(item?.status ?? 'Sin registro')}>{item?.status ?? 'Sin registro'}</td>
                  <td style={{ width: '10%' }}>
                    <Tooltip
                      title={`Detalles`}
                      arrow
                    >
                      <a
                        href='#/'
                        className="btn btn-secondary m-2"
                        onClick={() => { setCurrentRecord(item); setDetailMode(true) }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa fa-eye"></i>
                      </a>
                    </Tooltip>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='py-4'>
                    <div className="d-flex">
                      <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                    </div>
                    <h5 className='text-secondary text-center fs-20px'>No existen registros aún</h5>
                  </div>
                </td>
              </tr>
            )}
        </tbody>
      </table>
      <ModalContainer
        open={detailMode}
        title='Detalles del registro'
        closeFn={() => { setCurrentRecord(null); setDetailMode(false); }}
      >
        <ModalDetailsAssistance
          record={currentRecord}
          closeFn={async () => {
            setCurrentRecord(null);
            setDetailMode(false);
          }}
        />
      </ModalContainer>
    </div>
  )
}

export default TableChecks