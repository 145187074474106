import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Title from "../../components/Title.tsx";
import { Panel, PanelHeader } from "../../components/panel/panel.jsx";
import Button from "../../components/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import PositionForm from "../../components/positions/PositionForm.tsx";
import { PositionSchema } from "../../validation/schemas/PositionSchema.ts";
import { DefaultPosition } from "../../interfaces/formik/IPosition.ts";
import DocumentationPosition from "../../components/positions/DocumentationPosition.tsx";
import { fetchDocumentTypes } from "../../services/documentTypes.service.ts";
import { savePosition } from "../../services/position.service.ts";
import { createTemplate } from "../../services/template.service.ts";
import Layout from "../../components/Layout.tsx";
import ResponsabilitiesPosition from "../../components/positions/ResponsabilitiesPosition.tsx";
import { notification } from "../../utils/Notifications.tsx";
import BaseContract from "../../components/positions/BaseContract.tsx";

const NewPosition = () => {
  useAppSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState<[]>([]);

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const documentTypes = await fetchDocumentTypes();
      setDocuments(documentTypes);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadDependencies();
  }, []);

  const formik = useFormik({
    validationSchema: PositionSchema,
    initialValues: DefaultPosition,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const position = await savePosition({
          name: values.positionName,
          highRisk: values.highRisk,
          idBusinessName: values.idBusinessName,
          funtions: values.funtions,
          idDepartment: values.idDepartment,
          minSalary: values.minSalary ? parseFloat(values.minSalary) : 0,
          maxSalary: values.maxSalary ? parseFloat(values.maxSalary) : 0,
        });
        console.log("position", position);

        // Crear plantilla con el id del puesto
        const idInmediate: number[] = values.documentTypesInmediate.map(
          (doc: any) => doc.idDoc
        );

        const templateInmediate = await createTemplate({
          positionId: position.id,
          inmediate: true,
          documentTypes: idInmediate,
        });

        const idNoInmediate: number[] = values.documentTypesNoInmediate.map(
          (doc: any) => doc.idDoc
        );

        const templateNoInmediate = await createTemplate({
          positionId: position.id,
          inmediate: false,
          documentTypes: idNoInmediate,
        });

        notification("Éxito", "Puesto creado correctamente", "success");
      } catch (error) {
        console.log("error", error);
        notification("Error", "Error al crear el puesto", "danger");
      } finally {
        navigate("/puestos");
        //window.location.href = "/puestos";
        setLoading(false);
      }
    },
  });

  const combinedArray = [
    ...formik.values.documentTypesInmediate,
    ...formik.values.documentTypesNoInmediate,
  ];

  const uniqueArray = documents.filter((item: any) => {
    return !combinedArray.some((doc: any) => doc.idDoc === item.id);
  });

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Puestos"
        basePath="/puestos"
        activeTitle="Nuevo puesto"
        title="Nuevo puesto"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales del puesto
          </PanelHeader>
          <PositionForm formik={formik} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Documentacion inmediata
          </PanelHeader>
          <DocumentationPosition
            formik={formik}
            inmediate={true}
            list={uniqueArray}
            originalField="documentTypesInmediate"
            originalList={documents}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Documentacion no inmediata
          </PanelHeader>
          <DocumentationPosition
            formik={formik}
            inmediate={true}
            list={uniqueArray}
            originalField="documentTypesNoInmediate"
            originalList={documents}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Contrato base
          </PanelHeader>
          <BaseContract
            formik={formik}
            originalField="funtions"
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Responsabilidades
          </PanelHeader>
          <ResponsabilitiesPosition
            formik={formik}
            originalField="funtions"
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/puestos")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewPosition;
