import React, { useEffect } from 'react';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import { useParams } from 'react-router-dom';
import { readHoursByBranch, readPositionsByBranch, readSchedulesByBranchAndWeek, readSchedulesByBranchShiftWeekDay } from '../../../services/branchSchedule.service.ts';
import Title from '../../Title.tsx';
import moment from 'moment';
import { fetchPartners } from '../../../services/partner.service.ts';
import { toCamelCase } from '../../../hooks/toCameCase.tsx';
import { weekDays, weeks } from '../../../utils/constanst.js';

type Props = {};

const CoberturaPuestos = (props: Props) => {
  useAppSettings();
  const { id } = useParams();

  const [template, setTemplate] = React.useState<any>([]);
  const [weekSchedules, setWeekSchedules] = React.useState<any>([]);
  const [branchHours, setBranchHours] = React.useState<any>(null);
  const [branchPositions, setBranchPositions] = React.useState<any>(null);

  function groupByShifts(data) {
    return data.reduce((acc, current) => {
      const shift = current.shift;

      if (!acc[shift]) {
        acc[shift] = [];
      }

      acc[shift].push(current);
      return acc;
    }, {});
  }

  function transformShiftsData(data) {
    const result = {};

    for (const [shiftName, positions] of Object.entries(data)) {
      const totalPositions = positions.reduce((total, position) => {
        return total + parseInt(position.numberOfWorkers, 10);
      }, 0);

      result[shiftName] = {
        positions: positions,
        totalPositions: totalPositions,
      };
    }

    return result;
  }

  function groupByShift(data) {
    return data.reduce((acc, item) => {
      const shiftName = item.candidate.shift.name;

      if (!acc[shiftName]) {
        acc[shiftName] = [];
      }

      acc[shiftName]?.push(item);

      return acc;
    }, {});
  }

  const getTotalPositionsOcupied = (partners, template) => {
    let counter = 0;
    if (Array.isArray(partners)) {
      template.forEach((position) => {
        const total = partners
          .filter(partner => position.numberOfWorkers > 0)
          .filter(
            (partner) => partner.candidate.vacancy.position.id === position.position.id
          ).length;
        counter += total;
      });
    }
    return counter;
  };

  const fetchDependencies = async () => {
    const template = await readPositionsByBranch(Number(id));
    const groupedData = groupByShifts(template);
    const transformedData = transformShiftsData(groupedData);

    const order = ['Matutino', 'Intermedio', 'Vespertino'];

    const orderedData = Object.keys(transformedData)
      .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      .reduce((acc, key) => {
        acc[key] = transformedData[key];
        return acc;
      }, {} as Record<string, any>);

    setTemplate(orderedData);

    const branchHours = await readHoursByBranch(Number(id));
    setBranchHours(branchHours);

    const branchPositions = await fetchPartners(`?branch=${id}&tab=activos&schedules=true&limit=1000`);
    const groupedBranchPositions = groupByShift(branchPositions.results);
    setBranchPositions(groupedBranchPositions);

    const weekSchedules = await readSchedulesByBranchAndWeek(Number(id), weeks[1]?.value);

    setWeekSchedules(weekSchedules);
  };

  useEffect(() => {
    fetchDependencies();
  }, [id]);

  const renderShiftRows = (shiftName) => {
    const shiftTemplate = template[shiftName];
    const shiftBranchPositions = Array.isArray(branchPositions?.[shiftName])
      ? branchPositions[shiftName]
      : [];

    const totalPositions = shiftTemplate ? shiftTemplate.totalPositions : 0;
    const contractedPositions = getTotalPositionsOcupied(shiftBranchPositions, shiftTemplate.positions);
    let coveragePercentage = totalPositions > 0
      ? (getTotalPositionsOcupied(shiftBranchPositions, shiftTemplate.positions) / totalPositions) * 100
      : 0;

    if (!isFinite(coveragePercentage) || isNaN(coveragePercentage) || coveragePercentage < 0 || coveragePercentage > 100) {
      coveragePercentage = 0;
    }

    return (
      <>
        <tr key={shiftName} className='table-primary sticky-thead' style={{ zIndex: 101, top: 35 }}>
          <td className='sticky-column'>{shiftName}</td>
          <td className='text-center'>Titular</td>
          <td className='text-center'>Horario</td>
          <td className='text-center'>{totalPositions}</td>
          <td className='text-center'>{contractedPositions}</td>
          <td className='text-center'>{Math.max(0, totalPositions - contractedPositions)}</td>
          <td className='text-center'>{coveragePercentage.toFixed(2)}%</td>
          <td>Lunes</td>
          <td>Martes</td>
          <td>Miércoles</td>
          <td>Jueves</td>
          <td>Viernes</td>
          <td>Sábado</td>
          <td>Domingo</td>
        </tr>
        {shiftTemplate?.positions
          .filter(position => position.numberOfWorkers > 0)
          .map((position) => {
            const filteredPartners = shiftBranchPositions?.filter(
              (record) => record.candidate.vacancy.position.id === position.position.id
            ) || [];
            const totalPartners = filteredPartners.length;
            const coveragePercentage = (totalPartners / position.numberOfWorkers) * 100;

            return (
              <tr
                key={position.id + Math.random()} // Aseguramos que cada colaborador tenga una clave única
                className={
                  coveragePercentage > 100
                    ? 'table-morado' // Cambiamos el color a morado si la cobertura es mayor a 100%
                    : coveragePercentage < 100
                      ? 'table-danger'
                      : coveragePercentage === 100
                        ? 'table-success'
                        : 'table-warning'
                }
              >
                <td className='sticky-column bg-white'>{position.position.name}</td>
                <td className='text-center'>
                  {totalPartners > 0
                    ? filteredPartners.map((partner) => <div>{toCamelCase(partner.person.firstName)}</div>)
                    : 'Sin titular'}
                </td>
                <td className='text-center'>
                  {moment(branchHours?.[`${shiftName.toLowerCase()}Start`], 'HH:mm:ss').format('h:mm A') + ' - ' + moment(branchHours?.[`${shiftName.toLowerCase()}End`], 'HH:mm:ss').format('h:mm A')}
                </td>
                <td className='text-center'>{position.numberOfWorkers}</td>
                <td className='text-center'>{totalPartners}</td>
                <td className='text-center'>{Math.max(0, position.numberOfWorkers - totalPartners)}</td>
                <td className='text-center'>{(coveragePercentage || 0).toFixed(2)}%</td>
                {
                  Array.from({ length: 7 }).map((_, i) => (
                    <td className='text-center'>
                      {
                        weekSchedules.filter((schedule) => {
                          return schedule.position.id === position.position.id
                            && schedule.shift === shiftName
                            && schedule.dayOfWeek === weekDays[i].name;
                        }).length > 0
                          ? weekSchedules.filter((schedule) => {
                            return schedule.position.id === position.position.id
                              && schedule.shift === shiftName
                              && schedule.dayOfWeek === weekDays[i].name;
                          }).map((schedule) => {
                            return schedule.partner?.person?.firstName
                              ? `${schedule.partner.person.firstName} ${schedule.partner.person.lastName}`
                              : '-';
                          }).join(', ')
                          : '-'
                      }
                    </td>
                  ))
                }
              </tr>
            );
          })}
        <tr key={shiftName} className='table-secondary'>
          <td></td>
        </tr>
      </>
    );
  };

  const generalCoverage = () => {
    let totalPositions = 0;
    let contractedPositions = 0;

    // Cálculo de posiciones totales y contratadas a nivel general
    Object.keys(template).forEach((shiftName) => {
      const shiftTemplate = template[shiftName];
      totalPositions += shiftTemplate.totalPositions;

      const shiftBranchPositions = Array.isArray(branchPositions?.[shiftName])
        ? branchPositions[shiftName]
        : [];

      contractedPositions += getTotalPositionsOcupied(shiftBranchPositions, shiftTemplate.positions);
    });

    const coveragePercentage = totalPositions > 0
      ? (contractedPositions / totalPositions) * 100
      : 0;

    return (
      <>
        <tr key='general-coverage-header' className='table-primary'>
          <td>Total Cobertura</td>
          <td>Horario</td>
          <td className='text-center'>Total Posiciones</td>
          <td className='text-center'>Total Contratados</td>
          <td className='text-center'>Vacantes</td>
          <td className='text-center'>Cobertura</td>
          <td colSpan={8}></td> {/* Espacio adicional */}
        </tr>
        <tr key='general-coverage'>
          <td>Total</td>
          <td>General</td>
          <td className='text-center'>{totalPositions}</td>
          <td className='text-center'>{contractedPositions}</td>
          <td className='text-center'>{Math.max(0, totalPositions - contractedPositions)}</td>
          <td className='text-center'>{coveragePercentage.toFixed(2)}%</td>
          {Array.from({ length: 8 }).map((_, i) => (
            <td className='text-center' key={`general-coverage-${i}`}>-</td>
          ))}
        </tr>
      </>
    );
  };

  return (
    <Layout>
      <Title baseTitle='Surcursales' basePath='/sucursales' activeTitle='Cobertura de puesto' title='Cobertura de puestos' />
      <div className='tab-pane fade active show' id='Puestos'>
        <div className='card border-0 m-4'>
          <div className='tab-content px-3'>
            <div className='py-3'>
              <h5>Simbología:</h5>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#d45ffb6a', border: '1px solid #0002' }}>Exceso de cobertura</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#cceeee', border: '1px solid #0002' }}>Cobertura completa</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#fdebd1', border: '1px solid #0002' }}>Cobertura parcial</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#ffdedd', border: '1px solid #0002' }}>Sin cobertura</span>
                </li>
              </ul>
            </div>

            <div className='table-sticky mb-3'>
              <table className='table table-auto-width align-middle mb-0'>
                <thead className='sticky-thead bg-white' style={{ zIndex: 102 }}>
                  <tr>
                    <th className='text-center'></th>
                    <th className='text-center'>Titular</th>
                    <th className='text-center'>Horario</th>
                    <th className='text-center'>Colaboradores autorizados</th>
                    <th className='text-center'>Colaboradores contratados</th>
                    <th className='text-center'>Vacantes</th>
                    <th className='text-center'>Cobertura</th>
                    <th className='text-center' colSpan={7}>Horario real</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(template).map((shiftName) => renderShiftRows(shiftName))}
                  {generalCoverage()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoberturaPuestos;