import React from "react";
import { IFormikFieldProps } from "../../interfaces/formik/IFormikField";
import { getCurrencyFormat } from "../../utils/index.ts";

const InputEditProfile = ({
  sm = 5,
  smLabel = 3,
  min = 0,
  max = 1000000,
  hidden = false,
  required = true,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  type = "text",
  label = <></>,
  unclickable = false,
  tooltip = null,
  onChangeFile = (e: any) => {},
  money = false,
  button = null,
  ...props
}: IFormikFieldProps & {
  onChangeFile?: (e: any) => void;
  label: any;
  unclickable?: boolean;
  money?: boolean;
  tooltip?: any;
  button?: React.ReactNode;
}) => {
  const error =
    props.formik.touched[props.field] &&
    Boolean(props.formik.errors[props.field]);
  const unclickableObject = { pointerEvents: "none", cursor: "not-allowed" };

  return (
    <React.Fragment>
      <tr>
        <td className={`field`} style={{ color: `${error ? "red" : "black"}` }}>
          {label} :{" "}
        </td>
        <td className="d-flex flex-row align-items-center ">
          <div className={`col-md-${sm}`} style={unclickable ? {cursor: 'not-allowed'} : {}}>
            <input
              type={type}
              className={`form-control mb-5px fs-13px ${
                error ? "is-invalid" : ""
              }`}
              id={props.field}
              name={props.field}
              placeholder={props.placeholder}
              onChange={(event: any) => {
                if (type === "file") {
                  const file = event.target.files[0] as any;
                  onChangeFile(file);
                }

                if (type === "number") {
                  const parsed = parseInt(event.target.value);
                  const finalParsed = isNaN(parsed) ? 0 : parsed;

                  if (finalParsed < min) {
                    event.target.value = "0";
                  } else if (finalParsed > max) {
                    event.target.value = String(max);
                  }
                }

                if (money) {
                  // console.log(event.target.value)
                  // event.target.value = getCurrencyFormat(event.target.value);
                }

                props.formik.handleChange(event);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.formik.handleSubmit();
                }
              }}
              value={props.formik.values[props.field]}
              readOnly={readonly}
              disabled={props.disabled}
              style={unclickable ? {pointerEvents: 'none'} : {}}
            />
            <div
              className="invalid-feedback"
              style={{
                marginTop: "-1%",
                display: "flex",
                marginLeft: "0.25em",
              }}
            >
              {props.formik.touched[props.field] &&
                props.formik.errors[props.field] && (
                  <>{props.formik.errors[props.field]}</>
                )}
            </div>
          </div>
          {tooltip && (
            <div className="d-flex justify-content-center align-items-center">
              {tooltip}
            </div>
          )}
          {button && (
            <div className="d-flex justify-content-center align-items-center ms-2">
              {button}
            </div>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default InputEditProfile;