import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetch, setShowModal } from "../../store/slices/puestosSlice.ts";
import { RootState } from "../../store";
import { deleteRecordPuestos } from "../../services/businessName.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { desactivatePosition } from "../../services/vacancy.service.ts";

const DeleteRecordPuestos = ({ closeFn }: { closeFn: () => void }) => {
  const { currentRecord, fetch } = useSelector(
    (state: RootState) => state.puestos
  );
  const dispatch = useDispatch();

  const removeRecord = async () => {
    try {
      const response = await desactivatePosition(currentRecord.id);
      console.log(response);
      dispatch(setFetch(!fetch));
      closeFn();
      notification("Desactivado", "Puesto desactivado", "success");
    } catch (error) {
      console.log(error);
      notification("Error", error.message, "danger");
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas desactivar a {currentRecord.name}?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => removeRecord()}>
          Desactivar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteRecordPuestos;
