import React, { useEffect, useContext } from "react";
import { AppSettings } from "../../config/app-settings.js";
import Title from "../../components/Title.tsx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader.tsx";
import {
  resetState,
  setDataToRemove,
  setLoading,
  setPriorityRemove,
  setShowModal,
  setShowModalPri,
  setShowModalPriority,
  setTotal,
  setVacantes,
} from "../../store/slices/vacantesSlice.ts";
import { notification } from "../../utils/Notifications.tsx";
import { paginateVacancies } from "../../services/vacancy.service.ts";
import { RootState } from "../../store/index.ts";
import TableVacantes from "../../components/vacantes/TableVacantes.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import EditVacante from "../../components/vacantes/EditVacante.jsx";
import NewVacancy from "../../components/vacantes/NewVacancy.tsx";
import EditVacantePriority from "../../components/vacantes/EditVacantePriority.jsx";

function Vacancies() {
  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-0");
    context.handleSetAppTopMenu(true);

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
      context.handleSetAppTopMenu(false);
    };
  }, []);

  const dispatch = useDispatch();
  const {
    menuSelected,
    page,
    limit,
    param,
    loading,
    fetch,
    query,
    showModal,
    showModalPri,
    showModalPriority,
    showModalEdit,
    dataToRemove,
    priorityRemove,
    sortBy,
    defaultSort,
  } = useSelector((state: RootState) => state.vacantes);

  useEffect(() => {
    fetchVacantes();
  }, [page, limit, menuSelected, param, fetch, query, sortBy, defaultSort]);

  const fetchVacantes = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend =
        query +
        `&sort=${sortBy}&order=${defaultSort}&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "type=all";
      }
      const response = await paginateVacancies(queryToSend);
      dispatch(setVacantes(response.data));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    dispatch(resetState());
  }, []);
  return (
    <>
      <Title baseTitle="Vacantes" basePath="/vacantes" title="Vacantes" />
      <div className="card border-0 m-4">
        <div className="tab-pane fade show active" id="sucursal">
          <div className="card-body">
            <TableVacantes fetchVacantes={fetchVacantes} />
          </div>
        </div>
      </div>
      <div className="h-75px"></div>
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          zIndex: 1,
        }}
      />

      <ModalContainer
        open={showModalPri}
        closeFn={() => {
          dispatch(setShowModalPri(false));
          dispatch(setShowModalPriority(false));
          dispatch(setPriorityRemove(null));
        }}
        title={`Cambiar prioridad de la vacante`}
        children={
          showModalPriority && (
            <EditVacantePriority
              id={priorityRemove}
              closeFn={() => {
                dispatch(setShowModalPri(false));
                dispatch(
                  setPriorityRemove({
                    id: null,
                    name: "",
                    active: false,
                  })
                );
                fetchVacantes();
                
              }}
            />
          )
        }
      />

      <ModalContainer
        open={showModal}
        closeFn={() => {
          dispatch(setShowModal(false));
          dispatch(
            setDataToRemove({
              id: null,
              name: "",
              active: false,
            })
          );
        }}
        title={
          showModalEdit
            ? `${
                !dataToRemove.active ? "Activar vacante" : "Desactivar vacante"
              }`
            : `Agregar vacante`
        }
        children={
          showModalEdit ? (
            <EditVacante
              id={dataToRemove}
              closeFn={() => {
                dispatch(setShowModal(false));
                dispatch(
                  setDataToRemove({
                    id: null,
                    name: "",
                    active: false,
                  })
                );
                fetchVacantes();
              }}
            />
          ) : (
            <NewVacancy
              closeFn={() => {
                dispatch(setShowModal(false));
                dispatch(
                  setDataToRemove({
                    id: null,
                    name: "",
                    active: false,
                  })
                );
                fetchVacantes();
              }}
            />
          )
        }
      />

      <Loader isLoading={loading} />
    </>
  );
}

export default Vacancies;
