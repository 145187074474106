import React from "react";

import Layout from "../components/Layout.tsx";
import Title from "../components/Title.tsx";
import { useAppSettings } from "../hooks/useAppSettings.tsx";
import { Link } from "react-router-dom";

const Reports = () => {
  useAppSettings();
  return (
    <Layout loading={false}>
      <Title baseTitle="Reportes" basePath="/reportes" title="Reportes" />

      <div className="border-0 m-4">
        <div className="row">
          <div className="col-md-4">
            <div style={{ textDecoration: "none", cursor: "not-allowed" }}>
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-top"
                    src="../assets/img/reports/signedContratcs.png"
                    alt="Contratos firmados"
                  />
                  <h5 className="card-title mt-2">Contratos firmados</h5>
                  <p className="card-text">
                    Ver que porcentaje de contratos han sido firmados por
                    sucursal.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <Link to="sucursales" style={{ textDecoration: "none" }}>
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-top"
                    src="../assets/img/reports/branchReport.png"
                    alt="Sucursales y plantilla"
                  />
                  <h5 className="card-title mt-2">Sucursales y plantilla</h5>
                  <p className="card-text">
                    Ver la relación entre los chilitos, la plantilla y las
                    sucursales.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-4">
            <Link to="plantilla-lideres" style={{ textDecoration: "none" }}>
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-top"
                    src="../assets/img/reports/templateLeaders.png"
                    alt="Plantilla líderes"
                  />
                  <h5 className="card-title mt-2">Plantilla líderes</h5>
                  <p className="card-text">
                    Ver los líderes, líderes de servicio y auxiliares de cada
                    sucursal.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-4">
            <Link to="estadisticas-chilito" style={{ textDecoration: "none" }}>
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-top"
                    src="../assets/img/reports/branchReport.png"
                    alt="Sucursales y plantilla"
                  />
                  <h5 className="card-title mt-2">Chilitos en cada sucursal</h5>
                  <p className="card-text">
                    Ver el historico de cada chilitos en cada sucursal.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
