import React from 'react';

function useLastNegativeOne(checkTimeId: number, checkoutId: number) {
  const [lastNegativeOne, setLastNegativeOne] = React.useState<string | null>(null);

  const previousValues = React.useRef({ checkTimeId, checkoutId });

  React.useEffect(() => {
    if (checkTimeId === -1 && previousValues.current.checkTimeId !== -1) {
      setLastNegativeOne('checkTimeId');
    }

    if (checkoutId === -1 && previousValues.current.checkoutId !== -1) {
      setLastNegativeOne('checkoutId');
    }

    previousValues.current = { checkTimeId, checkoutId };
  }, [checkTimeId, checkoutId]);

  const updateLastNegativeOne = (field: string) => {
    setLastNegativeOne(field);
  };

  return { lastNegativeOne, updateLastNegativeOne };
}

export default useLastNegativeOne;