import React, { useEffect, useState } from "react";
import {
  findConfigurationByKey,
  updateConfig,
} from "../../services/configuration.service.ts";
import { ColorScheduleProperties } from "../../models/ColorSchedule.ts";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";
import { notification } from "../../utils/Notifications.tsx";
import { formatKey, hexToRgbAndHsv } from "../../utils/index.ts";

const ColorScheduleForm = ({
  closeFn,
  setLoading,
}: {
  setLoading: (_: boolean) => void;
  closeFn: () => void;
}) => {
  const [current, setCurrent] = useState<string>("asistencia");
  const [colors, setColors] = useState<ColorScheduleProperties>();
  const [color, setColor] = useColor("#ffffff");

  const fetchDependencies = async () => {
    try {
      const response = await findConfigurationByKey("scheduleColor");
      setColors(response);

      setColor({
        hex: response?.value[current],
        hsv: hexToRgbAndHsv(response?.value[current]).hsv,
        rgb: hexToRgbAndHsv(response?.value[current]).rgb,
      });
    } catch (error) {
      notification("", "Error al cargar los colores", "danger");
    } finally {
      setLoading(false);
    }
  };

  const saveConfiguration = async () => {
    setLoading(true);
    try {
      await updateConfig("scheduleColor", colors?.value);
      notification("", "Colores guardados correctamente", "success");
    } catch (error) {
      notification("", "Error al guardar los colores", "danger");
    } finally {
      setLoading(false);
      closeFn();
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDependencies();
  }, []);

  useEffect(() => {
    if (colors) {
      const currentColor = colors.value[current];

      setColor({
        hex: currentColor,
        hsv: hexToRgbAndHsv(currentColor).hsv,
        rgb: hexToRgbAndHsv(currentColor).rgb,
      });
    }
  }, [current]);

  return (
    <React.Fragment>
      <div className="row fs-13px col-md-12">
        <div className="col-md-8">
          {colors &&
            Object.keys(colors.value).map((key: string, index: number) => {
              const value = colors.value[key];

              return (
                <ColorSchedule
                  key={index}
                  color={value}
                  label={key}
                  setCurrent={(e) => setCurrent(e)}
                />
              );
            })}
        </div>

        <div className="col-md-4">
          <ColorPicker
            color={color}
            onChange={(e) => setColor(e)}
            hideInput={["rgb", "hsv"]}
            onChangeComplete={(e) => {
              if (!colors) return;

              const newColors = { ...colors.value, [current]: e.hex };
              setColors({ ...colors, value: newColors });
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cerrar
        </button>
        <button
          className="btn btn-azul me-2"
          onClick={() => saveConfiguration()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ColorScheduleForm;

const ColorSchedule = ({
  label,
  setCurrent,
  color,
}: {
  label: string;
  setCurrent: (e: string) => void;
  color: string;
}) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <label className="col-form-label col-md-5 text-end me-3">
        {formatKey(label)}
      </label>
      <div
        className="col-form-label col-md-7 text-end"
        onClick={() => setCurrent(label)}
        style={{
          width: "25px",
          height: "25px",
          backgroundColor: color,
          cursor: "pointer",
        }}
      />
    </div>
  );
};
