import React from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../../components/Layout.tsx";
import Title from "../../../components/Title.tsx";
import { Panel, PanelHeader } from "../../../components/panel/panel";
import { useFormik } from "formik";
import { NotificationUserSchema } from "../../../validation/schemas/NotificationUserSchema.ts";
import { DefaultNotificationUser } from "../../../interfaces/formik/INotificationUser.ts";
import { saveNotificationUser } from "../../../services/notificationUser.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import Button from "../../../components/Button.tsx";
import { useNavigate } from "react-router-dom";
import NotificationUserForm from "./NotificationUserForm.tsx";

const NewNotification = () => {
  useAppSettings();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  const typeRule = [
    { id: 1, name: "Inasistencias" },
    { id: 2, name: "Posiciones abiertas" },
    { id: 3, name: "Cumpleaños" },
  ];

  const formik = useFormik({
    validationSchema: NotificationUserSchema,
    initialValues: DefaultNotificationUser,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const rule = typeRule.find((rule) => rule.id === values.idRule);
        const notificationUser = await saveNotificationUser({
          idUser: values.idUser,
          idBranch: values.idBranch,
          rule: rule ? rule.name : "",
        });
        console.log("notificationUser", notificationUser);
        notification(
          "Éxito",
          "Regla de notificación creada correctamente",
          "success"
        );
        navigate("/notificaciones");
      } catch (error) {
        notification(
          "Error",
          "Error al crear la regla de notificación",
          "danger"
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Layout loading={loading}>
        <Title
          baseTitle="Notificaciones"
          basePath="/notificaciones"
          activeTitle="Nueva regla de notificación"
          title="Nueva regla de notificación"
        />

        <div className="card border-0 m-4 rounded">
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Datos generales de la regla de notificación
            </PanelHeader>
            <NotificationUserForm formik={formik} />
          </Panel>
        </div>

        <div className="card border-0 m-4 rounded p-3">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <Button
                onClick={() => navigate("/notificaciones")}
                title="Cancelar"
                variant="secondary"
                type="button"
              />
            </div>
            <div className="col-md-3">
              <Button
                onClick={() => formik.handleSubmit()}
                title="Guardar"
                variant="azul"
                type="submit"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NewNotification;
