import React from "react";
import { useParams } from "react-router-dom";
import ChipStatus from "./ChipStatus.tsx";
import { getCurrencyFormat } from "../../utils/index.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import EmptyImage from "../../assets/img/ic_content.svg";

const ApplicationListChangeBranch = ({ applications, size = "lg" }) => {
  const { curp } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="col-xl-8" id="bsSpyContent">
      <div id="Aplications" className="mb-4 pb-3">
        {size === "lg" && (
          <React.Fragment>
            <h4 className="d-flex align-items-center mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                data-icon="solar:user-bold-duotone"
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1 iconify--solar"
              >
                <circle cx={12} cy={6} r={4} fill="currentColor" />
                <path
                  fill="currentColor"
                  d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
                  opacity=".5"
                />
              </svg>
              Aplicaciones
            </h4>
            <p>
              Aquí puedes encontrar todas las postulaciones que se han realizado
              con la CURP {curp}.
            </p>
          </React.Fragment>
        )}
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            {applications
              ?.filter(
                (application) =>
                  application.newBranch.id !== application.oldBranch.id
              )
              .map((application, index) => {
                const date = parseISO(application.requestDate);
                const formattedDateTime = format(date, "dd 'de' MMMM yyyy", {
                  locale: es,
                });

                return (
                  <div
                    className="list-group-item d-flex align-items-center"
                    key={index}
                  >
                    <div className="flex-fill">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span style={{ fontWeight: "bold", fontSize: 15 }}>
                              Nueva Sucursal: {application.newBranch.name}
                            </span>
                            <div
                              className="mt-2 "
                              style={{ fontSize: 14, color: "#6c757d" }}
                            >
                              Sucursal Anterior: {application.oldBranch.name}
                            </div>
                            <div style={{ fontSize: 14, color: "#6c757d" }}>
                              Puesto: {application.newPosition.name}
                            </div>
                            <div style={{ fontSize: 14, color: "#6c757d" }}>
                              Solicitado por: {application.requesterUser.name}{" "}
                              {application.requesterUser.lastName}
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <ChipStatus
                              status={
                                application.isApproved
                                  ? "Cambio de Sucursal"
                                  : "Pendiente"
                              }
                            />
                            <div
                              className="mt-2 mb-1"
                              style={{ fontSize: 14, color: "#6c757d" }}
                            >
                              Fecha de solicitud: {formattedDateTime}
                            </div>
                            {application.approvalDate && (
                              <div style={{ fontSize: 14, color: "#6c757d" }}>
                                Fecha de aprobación:
                                {format(
                                  parseISO(application.approvalDate),
                                  "dd 'de' MMMM yyyy",
                                  { locale: es }
                                )}
                              </div>
                            )}
                            {application.rejectionDate && (
                              <div style={{ fontSize: 14, color: "#6c757d" }}>
                                Fecha de rechazo:
                                {format(
                                  parseISO(application.rejectionDate),
                                  "dd 'de' MMMM yyyy",
                                  { locale: es }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {applications?.length === 0 && (
              <div className="py-4">
                <div className="d-flex">
                  <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                </div>
                <h5 className="text-secondary text-center fs-20px">
                  No existen registros aún
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationListChangeBranch;
