import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultIncapacity,
  EditIncapacityProperties,
  FillIncapacity,
} from "../../interfaces/formik/Incapacity.ts";
import { notification } from "../../utils/Notifications.tsx";
import {
  approveIncapacity,
  createIncapacity,
  getIncapacityById,
} from "../../services/incapacities.service.ts";
import { useFormik } from "formik";
import {
  CreateIncapacityShema,
  IncapacityShema,
} from "../../validation/schemas/IncapacityShema.ts";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { Panel, PanelHeader } from "../../components/panel/panel.jsx";
import IncapacityFormEdit from "../../components/incapacities/IncapacityFormEdit.tsx";
import Button from "../../components/Button.tsx";
import IncapacityFormEditFiles from "../../components/incapacities/IncapacityFormEditFiles.tsx";
import { RootState } from "../../store/index.ts";
import { useSelector } from "react-redux";
import IncapacityFormEditFilesCreate from "../../components/incapacities/IncapacityFormEditFilesCreate.tsx";
import IncapacityFormEditCreate from "../../components/incapacities/IncapacityFormEditCreate.tsx";
import Loader from "../../components/Loader.tsx";

const NewIncapacity = () => {
  const { id } = useParams();
  useAppSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const isCreate = true;
  const isPartner = false;

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: CreateIncapacityShema,
    initialValues: {
      ...DefaultIncapacity,
      proofFiles: [],
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("values", values);
        const incapacity = await createIncapacity(
          id,
          {
            title: values.title,
            description: values.description,
            daysOff: values.daysOff || 0,
            startDate: values.startDate || 0,
            endDate: values.endDate || 0,
            approved: values.approved,
          },
          values.proofFiles
        );

        if (!incapacity) throw new Error("Error al aprobar la incapacidad");

        notification("Éxito", "Incapacidad aprobada correctamente", "success");
      } catch (error) {
        console.log("error", error);
        notification("Error", "Error al crear el puesto", "danger");
      } finally {
        setLoading(false);
        navigate(-1);
      }
    },
  });

  return (
    <Layout>
      <Title
        baseTitle="Aprovar incapacidad"
        basePath={`/colaboradores/aprobar-incapacidad/${id}`}
        title="Información del colaborador"
      />

      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la incapacidad para aprobar
          </PanelHeader>
          <IncapacityFormEditCreate
            formik={formik}
            isPartner={isPartner}
            isCreate={isCreate}
          />
        </Panel>
      </div>

      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Archivos adjuntos de la incapacidad
          </PanelHeader>
          <IncapacityFormEditFilesCreate formik={formik} reloadFiles={null} />
        </Panel>
      </div>

      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate(-1)}
              title="Cancelar"
              variant="secondary"
              type="button"
              disabled={loading}
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title={!isPartner ? "Guardar datos de incapacidad" : "Guardar"}
              variant="azul"
              disabled={loading}
              type="submit"
            />
          </div>
          
        </div>
      </div>

      <Loader isLoading={loading} />
    </Layout>
  );
};

export default NewIncapacity;
