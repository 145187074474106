import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMenuListaNegraSelected,
  MenuSelectedListaNegraEnum,
} from "../../types/BlackListSliceTypes.ts";
import { BlackListExcel } from "../../models/ImportBlackList.ts";

const initialState = {
  menuSelected: MenuSelectedListaNegraEnum.todos,
  total: 0,
  listaNegraList: [],
  page: 0,
  limit: 50,
  loading: true,
  param: "",
  fetch: false,
  showModal: false,
  currentRecord: {
    id: -1,
    curp: "",
  },
  query: "",
  deleteModal: false,
  showModalImportExcel: false,
  currentFile: null,
  currentBlackList: {} as BlackListExcel,
  showModalEditCurrentBlackList: false,
  sortBy: "id",
  defaultSort: "asc",
};

const listaNegraSlice = createSlice({
  name: "listaNegra",
  initialState,
  reducers: {
    setMenu: (state, action: ActionMenuListaNegraSelected) => {
      state.menuSelected = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setListaNegra: (state, action: PayloadAction<[]>) => {
      state.listaNegraList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setCurrentRecord: (
      state,
      action: PayloadAction<{ id: number; curp: string }>
    ) => {
      state.currentRecord = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.deleteModal = action.payload;
    },

    setShowModalImportExcel: (state, action: PayloadAction<boolean>) => {
      state.showModalImportExcel = action.payload;
    },
    setCurrentFile: (state, action: PayloadAction<any>) => {
      state.currentFile = action.payload;
    },
    setCurrentBlackList: (state, action: PayloadAction<BlackListExcel>) => {
      state.currentBlackList = action.payload;
    },
    setShowModalEditCurrentBlackList: (state, action: PayloadAction<boolean>) => {
      state.showModalEditCurrentBlackList = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
  },
});

export const {
  setMenu,
  setPage,
  setLimit,
  setListaNegra,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setShowModal,
  setCurrentRecord,
  setQuery,
  resetState,
  setDeleteModal,
  setShowModalImportExcel,
  setCurrentFile,
  setCurrentBlackList,
  setShowModalEditCurrentBlackList,
  setSortBy,
  setDefaultSort,
} = listaNegraSlice.actions;

export default listaNegraSlice.reducer;
