import httpClient from "./httpClient";

const prefix = "/applications";

export async function readApplicationsByCurp(curp: string) {
  return (await httpClient.get(`${prefix}/read-by-curp/${curp}`)).data;
}

export async function getApplicationsByBranch(branch: string) {
  return (await httpClient.get(`${prefix}/read-by-branch/${branch}`)).data;
}

export async function getChangePositionByPartner(id: number) {
  return (await httpClient.get(`/position-change/partner/${id}`)).data;
}

export async function upgradeCollaborator(id: number) {
  return (await httpClient.patch(`${prefix}/upgrade-to-collaborator/${id}`))
    .data;
}

export async function validateCurp(curp: string) {
  return (await httpClient.get(`${prefix}/validate-curp/${curp}`)).data;
}

export async function getByApplicationHours(id: string, data: any) {
  return (await httpClient.post(`${prefix}/hours/${id}`, data)).data;
}

export async function createApplicationForm(createApplicationDto: any) {
  return (
    await httpClient.post(`${prefix}/application-form`, createApplicationDto)
  ).data;
}

// export async function getApplications() {
//   return (await httpClient.get(`${prefix}`)).data;
// }

export async function getApplications(params: string) {
  return (await httpClient.get(`${prefix}${params}`)).data;
}

export async function getApplicationsFilter(params: any) {
  return (await httpClient.get(`${prefix}?` + params)).data;
}

export async function updateApplication(id: string, body: any, phase: string) {
  return (await httpClient.put(`${prefix}/update-phase/${id}/${phase}`, body))
    .data;
}

export async function approbePhase(id: string) {
  return (await httpClient.put(`${prefix}/aprove-phase/${id}`)).data;
}

export async function rejectPhase(id: string, data: any) {
  return (await httpClient.put(`${prefix}/reject-phase/${id}`, data)).data;
}

export async function updateApplicationForm(id: string, body: any) {
  return (await httpClient.put(`${prefix}/update-application/${id}`, body))
    .data;
}

export async function getCandidateByApplication(id: string) {
  return (await httpClient.get(`${prefix}/get-candidate-by-application/${id}`))
    .data;
}

export async function deleteReject(id: number) {
  return (await httpClient.patch(`${prefix}/delete-reject/${id}`)).data;
}

export async function fetchApplication(id: number) {
  return (await httpClient.get(`${prefix}/get/${id}`)).data;
}

export async function fetchPartnerById(id: number) {
  const response = await httpClient.get(`${prefix}/get-partner/${id}`);
  return response.data;
}

export async function fetchAllAplications(start: string, end: string) {
  return (
    await httpClient.get(
      `${prefix}/fetch-applications?start=${start}&end=${end}`
    )
  ).data;
}

export async function fetchPartnerByUser(id: number) {
  return (await httpClient.get(`${prefix}/get-partner-by-user/${id}`)).data;
}

export async function fetchApplicationByDate(date: string, query?: string) {
  return (
    await httpClient.post(`${prefix}/fetch-applications-by-date?${query}`, { date })
  ).data;
}

export async function updateApplicationStatus(id: number, status: number) {
  return (
    await httpClient.patch(`${prefix}/update-application-status/${id}`, {
      status,
    })
  ).data;
}

export async function importPartners(data: any) {
  return (await httpClient.post(`${prefix}/import-partners`, data)).data;
}

export async function updateImcompletePartner(id: number, data: any) {
  return (
    await httpClient.patch(`${prefix}/complete-partner/${id}`, data)
  ).data;
}
