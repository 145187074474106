import React, { useEffect, useState } from 'react';
import { useAppSettings } from '../../hooks/useAppSettings.tsx';
import { findDifferentBranch, findMissingChecks, reactivatePartner } from '../../services/assistanceCheck.service.ts';
import Layout from '../../components/Layout.tsx';
import Title from '../../components/Title.tsx';
import TableEntradas from '../../components/entradas/TableEntradas.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setLoading, setRecords, setTotal } from '../../store/slices/entradasSlice.ts';
import { notification } from '../../utils/Notifications.tsx';
import PagintationEntradas from '../../components/entradas/PagintationEntradas.tsx';
import FiltersEntradas from '../../components/entradas/FiltersEntradas.tsx';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import EmptyImage from '../../assets/img/ic_content.svg';
import { Tooltip } from '@mui/material';
import { setDataToRemove, setModalDelete } from '../../store/slices/colaboradoresSlice.ts';
import ModalContainer from '../../components/ModalContainer.tsx';
import DeletePartner from '../../components/talento-humano/DeletePartner.tsx';
import { toCamelCase } from '../../hooks/toCameCase.tsx';
import EntradasComponent from '../../components/entradas/EntradasComponent.tsx';

const Entradas: React.FC = () => {
  useAppSettings();
  const dispatch = useDispatch();
  const [missingChecks, setMissingChecks] = useState<any[]>([]);
  const [showReactivateModal, setShowReactivateModal] = useState<any>(null);

  const { dataToRemove, showDeleteModal } = useSelector((state: RootState) => state.colaboradores);

  const fetchData = async () => {
    const missingChecks = await findMissingChecks();
    setMissingChecks(missingChecks);
  };

  useEffect(() => {
    fetchData();
  }, []);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Title baseTitle="Entradas" basePath="/registro-de-entradas" title="Entradas" />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Sucursal diferente" {...a11yProps(0)} />
                <Tab label="Días sin checar" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <EntradasComponent />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-3">
                  <thead>
                    <tr>
                      <th style={{ width: '23%' }}>Colaborador</th>
                      <th style={{ width: '23%' }}>Asistencias no registradas</th>
                      <th style={{ width: '23%' }}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {missingChecks.length === 0 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <img src={EmptyImage} alt="No hay registros" />
                          <h6 className="mt-2">No hay registros</h6>
                        </td>
                      </tr>
                    ) : (
                      missingChecks.map((record) => (
                        <tr key={record.id}>
                          <td>{toCamelCase(record.partner.person.firstName)}</td>
                          <td>
                            {record.missingAssistances.map((missinAassistance) => (
                              <div key={missinAassistance.exactDate}>
                                {`${moment(missinAassistance.exactDate).format("dddd, D [de] MMMM [de] YYYY")} - ${missinAassistance.startTime.split(' - ')[0]} - ${missinAassistance.positionName}`}
                              </div>
                            ))}
                          </td>
                          <td>
                            <Tooltip title="Dar de baja" arrow>
                              <button
                                onClick={() => {
                                  dispatch(setModalDelete(true));
                                  dispatch(
                                    setDataToRemove({
                                      id: record.partner.id,
                                      name: `${record.partner.person.firstName} ${record.partner.person.lastName} ${record.partner.person.secondLastName}`,
                                    })
                                  );
                                }}
                                className="btn btn-danger me-2"
                              >
                                <i className="fas fa-user-minus"></i>
                              </button>
                            </Tooltip>
                            <Tooltip title="Reactivar" arrow>
                              <button
                                onClick={() => {
                                  setShowReactivateModal(record.partner);
                                }}
                                className="btn btn-success"
                              >
                                <i className="fas fa-user-check"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      )))
                    }
                  </tbody>
                </table>
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </div>

      <ModalContainer
        open={showDeleteModal}
        closeFn={() => {
          dispatch(setModalDelete(false));
          dispatch(setDataToRemove({ id: -1, name: "" }));
        }}
        title={`Dar de baja a ${toCamelCase(dataToRemove?.name)}`}
      >
        <DeletePartner
          name={dataToRemove?.name}
          id={dataToRemove?.id}
          closeFn={() => {
            dispatch(setModalDelete(false));
            dispatch(setDataToRemove({ id: -1, name: "" }));
          }}
        />
      </ModalContainer>

      <ModalContainer
        open={!!showReactivateModal}
        closeFn={() => {
          setShowReactivateModal(null);
        }}
        title={`Reactivar a ${showReactivateModal?.person?.firstName}`}
      >
        <div>
          <h6>¿Estás seguro que deseas reactivar a {showReactivateModal?.person?.firstName}?</h6>
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                setShowReactivateModal(null);
              }}
              className="btn btn-secondary me-2"
            >
              Cancelar
            </button>
            <button
              onClick={async () => {
                setShowReactivateModal(null);
                await reactivatePartner(showReactivateModal?.id);
                notification("Reactivado", "Colaborador reactivado", "success");
              }}
              className="btn btn-success"
            >
              Reactivar
            </button>
          </div>
        </div>
      </ModalContainer>
    </Layout>
  );
};

export default Entradas;
