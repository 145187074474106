import React from "react";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { IModalEditSalary } from "../../interfaces/others/IModalEditTimeAppoinment.ts";
import {
  ISalaryHistoryPut,
  SalaryHistoryFill,
  SalaryHistoryInitial,
} from "../../interfaces/formik/ISalaryHistoryPut.ts";
import { EditSalaryHistorySchema } from "../../validation/schemas/EditSalaryHistorySchema.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { updateSalaryForHistory } from "../../services/partner.service.ts";

const UpdateSalary: React.FC<IModalEditSalary> = ({
  dataSalary,
  setLoading,
  closeFn,
  refreshFn,
}) => {
  console.log("dataSalary", dataSalary);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] =
    React.useState<ISalaryHistoryPut>(SalaryHistoryInitial);

  React.useEffect(() => {
    const fetchSalary = async () => {
      try {
        const filledValues = SalaryHistoryFill(dataSalary);
        setInitialValues(filledValues);
      } catch (error) {
        notification(
          "error",
          "Error al obtener el tiempo de la cita",
          "danger"
        );
      }
    };
    fetchSalary();
  }, [dataSalary]);

  const formik = useFormik({
    validationSchema: EditSalaryHistorySchema,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await updateSalaryForHistory(values.partnerInfo, {
          newSalary: Number(values.newSalary),
          reason: values.updateReason,
          userId: user?.id,
        });
        console.log("response", response);

        notification("success", "Salario actualizado correctamente", "success");
      } catch (error) {
        notification("error", "Error al actualizar el salario", "danger");
      } finally {
        setLoading(false);
        refreshFn();
        closeFn();
      }
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          formik={formik}
          field="currentSalaryString"
          label="Salario Actual"
          placeholder="Salario Actual"
          sm={8}
          smLabel={4}
          disabled
          required={false}
        />
        <CustomInput
          formik={formik}
          field="minSalaryString"
          label="Salario Mínimo"
          placeholder="Salario Mínimo del Puesto"
          sm={8}
          smLabel={4}
          disabled
          required={false}
        />
        <CustomInput
          formik={formik}
          field="maxSalaryString"
          label="Salario Máximo"
          placeholder="Salario Máximo del Puesto"
          sm={8}
          smLabel={4}
          disabled
          required={false}
        />
        <CustomInput
          formik={formik}
          field="newSalary"
          label="Nuevo Salario"
          placeholder="Nuevo Salario"
          sm={8}
          smLabel={4}
        />

        {formik.values.newSalary !== null &&
          formik.values.newSalary <= formik.values.minSalary && (
            <div className="alert alert-warning text-center" role="alert">
              El nuevo salario es MENOR al salario mínimo del puesto
            </div>
          )}

        {formik.values.newSalary !== null &&
          formik.values.newSalary >= formik.values.maxSalary && (
            <div className="alert alert-warning text-center" role="alert">
              El nuevo salario es MAYOR al salario máximo del puesto
            </div>
          )}

        {formik.values.newSalary !== null &&
          (formik.values.newSalary <= formik.values.minSalary ||
            formik.values.newSalary >= formik.values.maxSalary) && (
            <>
              <CustomInput
                formik={formik}
                field="updateReason"
                label="Razón "
                placeholder="Razón de la actualización"
                type="text"
                sm={8}
                smLabel={4}
              />
             
            </>
          )}

        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
            Cancelar
          </button>
          <button className="btn btn-success" type="submit">
            Actualizar salario
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UpdateSalary;