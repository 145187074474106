import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import "moment/locale/es";
import {
  setCurrentRecord,
  setDefaultSort,
  setPage,
  setShowDeleteModal,
  setShowReactiveModal,
  setShowSoftDeleteModal,
  setSortBy,
} from "../../store/slices/puestosSlice.ts";
import ModalContainer from "../ModalContainer.tsx";
import DeleteRecordPuestos from "./DeleteRecordPuestos.tsx";
import NewRecordPuestos from "./NewRecordPuestos.tsx";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/img/ic_content.svg";
import { getCurrencyFormat } from "../../utils/index.ts";
import { fixedHeadStyle, fixedStyle } from "../../utils/constanst.js";

moment.locale("es");

const TablePuestos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editRecord, setEditRecord] = useState(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { puestos, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.puestos
  );

  const handleCloseEditModal = () => {
    setEditRecord(null);
    setShowEditModal(false);
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead style={{ ...defaultStyle }}>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
            <th style={{ ...fixedStyle } as React.CSSProperties} onClick={() => setSort("id")}>
              ID {sortBy === "id" && getIconSort()}
            </th>
            <th onClick={() => setSort("name")}>
              Puesto {sortBy === "name" && getIconSort()}
            </th>
            <th onClick={() => setSort("status")}>
              Estatus {sortBy === "status" && getIconSort()}
            </th>
            <th onClick={() => setSort("businessName")}>
              Razón social {sortBy === "businessName" && getIconSort()}
            </th>
            <th onClick={() => setSort("minSalary")}>
              Salario Mínimo {sortBy === "minSalary" && getIconSort()}
            </th>
            <th onClick={() => setSort("maxSalary")}>
              Salario Máximo {sortBy === "maxSalary" && getIconSort()}
            </th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {puestos && puestos.length > 0 ? (
            puestos.map((item, index) => (
              <tr key={index}>
                <td style={{ ...fixedStyle} as React.CSSProperties} >{item.id}</td>
                <td>{item.name}</td>
                <td>{item.status ? "Activo" : "Desactivado"}</td>
                <td>{item.businessName?.name}</td>
                <td>{getCurrencyFormat(item.minSalary) || "No asignado"}</td>
                <td>{getCurrencyFormat(item.maxSalary) || "No asignado"}</td>
                <td>
                  {item.status ? (
                    <a
                      className="btn btn-dark m-2"
                      onClick={() => {
                        dispatch(
                          setCurrentRecord({ id: item.id, name: item.name })
                        );
                        dispatch(setShowDeleteModal(true));
                      }}
                    >
                      <span className="d-none d-sm-inline"></span>
                      <i className="fa fa-circle-xmark"></i>
                    </a>
                  ) : (
                    <a
                      className="btn btn-success m-2"
                      onClick={() => {
                        dispatch(
                          setCurrentRecord({ id: item.id, name: item.name })
                        );
                        dispatch(setShowReactiveModal(true));
                      }}
                    >
                      <span className="d-none d-sm-inline"></span>
                      <i className="fa fa-circle-check"></i>
                    </a>
                  )}

                  <a
                    className="btn btn-primary m-2"
                    onClick={() => {
                      navigate(`/puestos/editar/${item.id}`);
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </a>
                  <a
                    className="btn btn-danger m-2"
                    onClick={() => {
                      dispatch(
                        setCurrentRecord({ id: item.id, name: item.name })
                      );
                      dispatch(setShowSoftDeleteModal(true));
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no existen puestos
                  </h5>
                  <h5 className="text-center text-secondary fw-400 fs-15px">
                    Prueba añadiendo uno en
                  </h5>
                  <h5 className="text-center text-secondary fw-400">
                    el botón de 'Agregar puestos'
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showEditModal && (
        <ModalContainer
          open={showEditModal}
          title={
            editRecord ? "Actualizar Razón Social" : "Agregar Razón Social"
          }
          closeFn={handleCloseEditModal}
          children={
            <NewRecordPuestos
              initialValues={editRecord}
              onclose={handleCloseEditModal}
            />
          }
        />
      )}
    </div>
  );
};

export default TablePuestos;
