import * as Yup from "yup";

export const BadgeSchema = Yup.object().shape({
  title: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
});

export const AssignBadgeSchema = Yup.object().shape({
  badgeId: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
});
