import React from "react";

import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import Divider from "./Divider.tsx";
import { LeaderTemplatePosition } from "../../../models/LeaderTemplate.ts";

const RowPartners = ({ data }: { data: LeaderTemplatePosition[] }) => {
  return (
    <React.Fragment>
      {data.map((record, _) => (
        <React.Fragment>
          <div className="row">
            <div className="col-md-4 d-flex align-items-center">
              <p className="card-text">
                <strong>{record.name}:</strong>
              </p>
            </div>
            <div className="col-md-8">
              {record.partners.length === 0 && (
                <div className="col">
                  <p className="card-text">
                    Sin {record.name.toLowerCase()} asignado
                  </p>
                </div>
              )}
              {record.partners.map(({ candidate, ...partner }, index) => {
                const { person } = partner;

                return (
                  <React.Fragment key={partner.id}>
                    <div className="col">
                      <p className="card-text">
                        {toCamelCase(
                          `${person.firstName} ${person.lastName} ${person.secondLastName}`
                        )}{" "}
                        - {person.phone}
                      </p>
                    </div>
                    {record.partners.length === index + 1 ? null : <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {data.length === _ + 1 ? null : <Divider />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default RowPartners;
