import React from "react";
import { useParams } from "react-router-dom";
import { getCurrencyFormat } from "../../utils/index.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import EmptyImage from "../../assets/img/ic_content.svg";

const ListNotAprovedIncapacities = ({ incapacities, size = "lg" }) => {
  const { curp } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="col-xl-12 -none" id="bsSpyContent">
      <div id="Applications" className="mb-4 pb-3">
        {size === "lg" && (
          <>
            <h4 className="d-flex align-items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                data-icon="solar:user-bold-duotone"
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1 iconify--solar"
              >
                <circle cx={12} cy={6} r={4} fill="currentColor" />
                <path
                  fill="currentColor"
                  d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
                  opacity=".5"
                />
              </svg>{" "}
              Aplicaciones
            </h4>
            <p>
              Aquí puedes encontrar todas las postulaciones realizadas con la
              CURP {curp}.
            </p>
          </>
        )}
        <div className="card">
          <div className="list-group list-group-flush">
            {incapacities?.map((incapacity) => (
              <div
                key={incapacity?.id}
                className="list-group-item p-3 mb-3 rounded-3 shadow-sm  -light d-flex flex-column flex-md-row align-items-md-start"
              >
                <div className="d-flex align-items-center me-md-3 mb-3 mb-md-0">
                  {incapacity?.partner.urlImage ? (
                    <img
                      src={incapacity?.partner.urlImage}
                      alt="Profile"
                      className="rounded-circle  -secondary"
                      style={{ width: 70, height: 70, objectFit: "cover" }}
                    />
                  ) : (
                    <div
                      className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center"
                      style={{ width: 70, height: 70 }}
                    >
                      <i className="fas fa-user fa-lg"></i>
                    </div>
                  )}
                </div>
                <div className="flex-fill">
                  <h5 className="fw-bold mb-1">{incapacity?.title}</h5>
                  <p className="text-muted mb-2">{incapacity?.description}</p>
                  <p className="mb-1">
                    <span className="fw-bold">Solicitante: </span>
                    {incapacity?.partner?.person?.firstName}{" "}
                    {incapacity?.partner?.person?.lastName}{" "}
                    {incapacity?.partner?.person?.secondLastName}
                  </p>
                  <p className="mb-1">
                    <span className="fw-bold">Fecha de solicitud: </span>
                    {format(
                      parseISO(incapacity?.createdAt),
                      "dd 'de' MMMM 'de' yyyy",
                      {
                        locale: es,
                      }
                    )}
                  </p>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="badge text-white bg-warning text-dark mb-2 py-2 px-3 rounded-pill">
                    Pendiente
                  </span>
                  <a
                    href={`/colaboradores/aprobar-incapacidad/${incapacity?.id}`}
                    className="btn btn-primary mt-auto"
                  >
                    Ver incapacidad
                  </a>
                </div>
              </div>
            ))}

            {incapacities?.length === 0 && (
              <div className="py-4 text-center">
                <img src={EmptyImage} alt="" className="mx-auto w-250px mb-3" />
                <h5 className="text-secondary">
                  No se han presentado incapacidades aún
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListNotAprovedIncapacities;
