import React from "react";

import { useAppSettings } from "../../hooks/useAppSettings.tsx";

import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { getBranchesStats } from "../../services/partner.service.ts";
import { ReportPositionsBranch } from "../../models/ReportPositionsBranch.ts";
import TableUnfilled from "../../components/reports/unfilledPositions/TableUnfilled.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import {
  setLoading,
  setRecords,
  setShowModal,
} from "../../store/slices/ReportsSlice.ts";
import ModalDetailsPosition from "../../components/reports/unfilledPositions/ModalDetailsPosition.tsx";

const ReporteSolicitudes = () => {
  useAppSettings();
  const dispatch = useDispatch();
  const { loading, showModal, modalType } = useSelector(
    (state: RootState) => state.report
  );

  const fetchRecords = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getBranchesStats();
      dispatch(setRecords(response));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchRecords();
  }, []);

  function formatString(str) {
    let result = str.replace(/-/g, " ");
    result = result.toLowerCase();
    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
  }

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Reportes"
          basePath="/reportes"
          activeTitle="Sucursales"
          title="Reporte de sucursales"
        />
        <div className="card border-0 m-4 rounded">
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Reporte de sucursales
            </PanelHeader>
            <PanelBody>
              <div className="table-responsive mb-3">
                <TableUnfilled />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </Layout>

      <ModalContainer
        open={showModal}
        title={formatString(modalType)}
        closeFn={() => dispatch(setShowModal({ showModal: false }))}
        children={<ModalDetailsPosition />}
        size="lg"
      />
    </React.Fragment>
  );
};

export default ReporteSolicitudes;
