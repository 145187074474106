import httpClient, { httpFormDataClient } from "./httpClient";

const prefix = "/incapacities";

export async function createIncapacity(partnerId, incapacityData, proofFiles) {
  const formData = new FormData();

  if (proofFiles && proofFiles.length > 0) {
    proofFiles.forEach((file) => {
      formData.append("proofFiles", file);
    });
  }

  Object.keys(incapacityData).forEach((key) => {
    formData.append(key, incapacityData[key]);
  });

  return (await httpFormDataClient.post(`${prefix}/${partnerId}`, formData))
    .data;
}

export async function deleteProofFile(id) {
  return (await httpClient.delete(`${prefix}/proof-files/${id}`)).data;
}

export async function updateIncapacity(id, updateData) {
  return (await httpClient.put(`${prefix}/${id}`, updateData)).data;
}

export async function uploadFilesToIncapacity(id, proofFiles) {
  const formData = new FormData();

  if (proofFiles && proofFiles.length > 0) {
    proofFiles.forEach((file) => {
      formData.append("proofFiles", file);
    });
  }

  return (await httpFormDataClient.post(`${prefix}/${id}/files`, formData))
    .data;
}

export async function approveIncapacity(id, approveData) {
  return (await httpClient.patch(`${prefix}/${id}/approve`, approveData)).data;
}

export async function getIncapacitiesByPartner(partnerId) {
  return (await httpClient.get(`${prefix}/partner/${partnerId}`)).data;
}

export async function getIncapacityById(id) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}

export async function getAllIncapacities() {
  return (await httpClient.get(`${prefix}`)).data;
}
