import httpClient from "./httpClient";

const prefix = "/notifications-users";

export async function saveNotificationUser(body: any) {
  return (await httpClient.post(`${prefix}`, body)).data;
}

export async function updateNotificationUser(id: number, body: any) {
  return (await httpClient.put(`${prefix}/${id}`, body)).data;
}

export async function deleteNotificationUser(id: number) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function readNotificationUser(id: number) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}

export async function paginateNotificationsUser(query: string) {
  return (await httpClient.get(`${prefix}/paginate?${query}`)).data;
}