import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import moment from 'moment';
import { findByExactDateAndBranch, readPositionsByBranchAndShift } from '../../../services/branchSchedule.service.ts';
import { shifts } from '../../../utils/constanst.js';
import Layout from '../../Layout.tsx';
import Title from '../../Title.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index.ts';
import { useWebSocket } from '../../../hooks/useWebSocket.tsx';

const groupPositionsByUniqueName = (positions) => {
  const groupedPositions = {};

  positions.forEach((position) => {
    const positionName = position.position.name;

    if (!groupedPositions[positionName]) {
      groupedPositions[positionName] = [];
    }

    const totalWorkersRequired = position.numberOfWorkers;
    for (let i = 0; i < totalWorkersRequired; i++) {
      const workerKey = `${positionName} ${i + 1}`;
      const workerIndex = groupedPositions[positionName].findIndex(
        (worker) => worker.positionName === workerKey
      );

      if (workerIndex === -1) {
        groupedPositions[positionName].push({
          positionName: workerKey,
          shifts: [position.shift],
        });
      } else {
        groupedPositions[positionName][workerIndex].shifts.push(position.shift);
      }
    }
  });

  return Object.values(groupedPositions).flat();
};

const ResumenHorariosAsistencia = () => {
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  useAppSettings();
  const [schedules, setSchedules] = useState<any[]>([]);
  const [positions, setPositions] = useState<any[]>([]);

  const fetchDependencies = useCallback(async () => {
    const today = moment().format('YYYY-MM-DD');

    try {
      const fetchedSchedules = await findByExactDateAndBranch(today, Number(id));
      setSchedules(fetchedSchedules);

      const fetchedPositions = await readPositionsByBranchAndShift(Number(id), 'Todos');
      const uniquePositionArray = groupPositionsByUniqueName(fetchedPositions);
      setPositions(uniquePositionArray);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, [id]);

  useEffect(() => {
    fetchDependencies();
  }, [fetchDependencies]);


  const getColorByPartners = (partners) => {

    if (partners.length === 0) {
      return '#e2e2e2';
    }

    let missingCounter = 0;

    partners.forEach(partner => {
      if (partner?.assistanceCheck?.status === null || partner?.assistanceCheck?.status === '' || partner?.assistanceCheck?.status === 'Fuera de horario' || !partner?.assistanceCheck?.status) {
        missingCounter++;
      }
    });

    if (missingCounter === partners.length) {
      return 'red';
    }

    if (partners.length === 1) {
      return partners[0]?.assistanceCheck?.status === 'Asistencia' ? '#0f0' : partners[0]?.assistanceCheck?.status === 'Retardo' ? 'orange' : '#f00';
    }

    return missingCounter > 0 ? 'yellow' : '#0f0';
  };

  const { isConnected, assistancesObserver } = useWebSocket();

  useEffect(() => {
    if (isConnected) {
      const unsubscribe = assistancesObserver((data) => {
        const { branchId } = data;

        if (branchId === Number(id)) {
          fetchDependencies();
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [isConnected]);

  return (
    <Layout loading={!isConnected}>
      <Title baseTitle="Cobertura" basePath=""  title="Cobertura en tiempo real" />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <div>
              <h5>Simbología:</h5>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#0F0', border: '1px solid #0002' }}>Asistencia completa</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: 'orange', border: '1px solid #0002' }}>Asistencia con retardo</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: 'yellow', border: '1px solid #0002' }}>Asistencia parcial</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#F00', border: '1px solid #0002' }}>Sin asistencia</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-black" style={{ backgroundColor: '#e2e2e2', border: '1px solid #0002' }}>Puestos sin cubrir</span>
                </li>
                <li className="list-inline-item">
                  <span className="badge text-white" style={{ backgroundColor: '#000', border: '1px solid #0002' }}>No aplica</span>
                </li>
              </ul>
            </div>
            <div className="table-sticky mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0">
                <thead className='sticky-thead'>
                  <tr>
                    <th style={{ width: '20%' }} className="border-end">Puesto</th>
                    <th style={{ width: '20%' }} className="border-end">Matutino</th>
                    <th style={{ width: '20%' }} className="border-end">Intermedio</th>
                    <th style={{ width: '20%' }} className="border-end">Vespertino</th>
                  </tr>
                </thead>
                <tbody>
                  {positions.map((position, index) => (
                    <tr key={index}>
                      <td style={{ width: '20%' }} className="border-end">{position.positionName}</td>
                      {shifts.filter(shift => shift.name !== 'Todos').map((shift, idx) => {
                        const partners = schedules.filter(
                          schedule =>
                            schedule.positionName === position.positionName &&
                            schedule.shift === shift.name
                        );
                        return (
                          <td key={idx} className="border-end" style={{ width: '20%', backgroundColor: !position.shifts.includes(shift.name) ? 'black' : getColorByPartners(partners) }}>
                            {partners.map((partner, idx) => (
                              <div key={idx}>{`${partner.partner.person.firstName} ${partner.partner.person.lastName}`}</div>
                            ))}
                            {
                              !position.shifts.includes(shift.name) && <div>No aplica</div>
                            }
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResumenHorariosAsistencia;
