import React from "react";

type Status =
  | "Solicitado"
  | "Entrevistado"
  | "Aceptado"
  | "Pre-Colaborador"
  | "Colaborador"
  | "Rechazado"
  | "Cambio de puesto"
  | "Cambio de Sucursal"
  | "Sin salario IMSS asignado"
  | "Cambio de Sucursal"
  | "Cambio de salario";

interface ChipStatusProps {
  status: Status;
  position?: string;
}

const ChipStatus: React.FC<ChipStatusProps> = ({ status, position }) => {
  let badgeColor = "";

  switch (status) {
    case "Solicitado":
      badgeColor = "#6c757d"; // primary
      break;
    case "Entrevistado":
      badgeColor = "#6c757d"; // secondary
      break;
    case "Aceptado":
      badgeColor = "#28a745"; // success
      break;
    case "Pre-Colaborador":
      badgeColor = "#17a2b8"; // info
      break;
    case "Colaborador":
      badgeColor = "#343a40"; // dark
      break;
    case "Rechazado":
      badgeColor = "#dc3545"; // danger
      break;
    case "Cambio de Sucursal":
      badgeColor = "#0f766e"; // warning
      break;
    case "Cambio de puesto":
      badgeColor = "rgb(254 137 137)"; // warning
      break;
    case "Cambio de salario":
      badgeColor = "#86198f"; // warning
      break;
    case "Sin salario IMSS asignado":
      badgeColor = "rgb(254 137 137)"; // warning
      break;
    default:
      badgeColor = "rgb(254 137 137)"; // light
  }

  return (
    <div
      style={{
        backgroundColor: badgeColor,
        color: "white",
        padding: "3px 7px",
        borderRadius: "10px",
        display: "inline-block",
      }}
    >
      {status} {position && ` para ${position}`}
    </div>
  );
};

export default ChipStatus;
