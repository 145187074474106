import * as Yup from "yup";

export const EditSalaryHistorySchema = Yup.object().shape({
  currentSalary: Yup.number().required("Campo requerido"),
  newSalary: Yup.number()
    .typeError("El salario debe ser un número decimal")
    .required("Campo requerido"),
  updateReason: Yup.string().test(
    "is-required-if-out-of-range",
    "Campo requerido si el salario está fuera del rango",
    function (value) {
      const { newSalary, minSalary, maxSalary } = this.parent;
      if (newSalary <= minSalary || newSalary >= maxSalary) {
        return !!value;
      }
      return true;
    }
  ),
});