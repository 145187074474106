import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../../../../store/slices/chequeoSlice.ts";
import { toCamelCase } from "../../../../hooks/toCameCase.tsx";
import { readBranches } from "../../../../services/branch.service.ts";
import Branch from "../../../../models/Branch.ts";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { readPositions } from "../../../../services/position.service.ts";
import { utils, writeFile } from "xlsx";
import { RootState } from "../../../../store/index.ts";
import { formatDate } from "../../../../utils/index.ts";

dayjs.locale("es");

const FilterChequeo = ({ id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    isReplacement: "",
    dateStart: "",
    dateEnd: "",
    checkBranch: "",
    shift: "",
    search: "",
    position: "",
  };
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [positions, setPositions] = React.useState([]);
  const { records, query, page, limit } = useSelector(
    (state: RootState) => state.chequeo
  );
  const fetchDependencies = async () => {
    const branches = await readBranches();
    const positions = await readPositions();
    setBranches(branches);
    setPositions(positions);
  };

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue }));

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, search: newValue };

        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== "all" && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");

        dispatch(setQuery(queryString));

        if (!newValue) {
          setShowPills(selectedFilters(filters).length > 0);
        } else {
          setShowPills(true);
        }

        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const updatedFilters = { ...filters, ...params };
    const selected = selectedFilters(updatedFilters);

    if (selected.length > 5) {
      alert(`No puedes seleccionar más de 5 filtros.`);
      return;
    }

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(
            filter.value
          )}`
      )
      .join("&");

    setFilters(updatedFilters);
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "search":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "isReplacement":
            keyName = "Reemplazo";
            valueName = value === "true" ? "Si" : "No";
            break;
          case "checkBranch":
            keyName = "Sucursal de chequeo";
            valueName = branches.find(
              (branch) => branch.id === Number(value)
            )?.name;
            break;
          case "shift":
            keyName = "Turno";
            valueName = value;
            break;
          case "dateStart":
            keyName = "Fecha inicio";
            valueName = value;
            break;
          case "dateEnd":
            keyName = "Fecha fin";
            valueName = value;
            break;
          case "position":
            keyName = "Posición";
            valueName = positions.find(
              (position) => position.id === Number(value)
            )?.name;
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (filters.dateStart) {
      selected = selected.filter((f) => f.key !== "dateStart");
      selected.push({
        key: "dateStart",
        value: filters.dateStart,
        valueName: filters.dateStart,
        keyName: "Fecha inicio",
      });
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const selected = selectedFilters(updatedFilters);

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(
            filter.value
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
    setShowPills(selected.length > 0);
  };

  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");

    if (!filters.dateStart) {
      const updatedFilters = { ...filters, dateStart: today };
      setFilters(updatedFilters);
      updateFilters(updatedFilters);
    }

    setShowPills(true);
  }, []);

  const calcularMinutosExtra = (
    horaSalidaHorario: string,
    horaSalidaRegistro: string
  ) => {
    if (!horaSalidaHorario || !horaSalidaRegistro) return "";
    const start = dayjs(horaSalidaHorario, "HH:mm");
    const end = dayjs(horaSalidaRegistro, "HH:mm:ss");
    return end.isAfter(start) ? end.diff(start, "minutes") : 0;
  };

  const formatQueryStringForExcel = (filters) => {
    let formattedQuery = [];

    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let label = "";

        switch (key) {
          case "isReplacement":
            label = `Reemplazo: ${
              value === "true" ? "Sí" : value === "false" ? "No" : "Todos"
            }`;
            break;
          case "shift":
            label = `Turno: ${value === "all" ? "Todos" : value}`;
            break;
          case "checkBranch":
            label = `Sucursal de chequeo: ${
              branches.find((branch) => branch.id === Number(value))?.name ||
              "Todos"
            }`;
            break;
          case "dateStart":
            label = `Fecha inicio: ${dayjs(value).format(
              "DD [de] MMMM [de] YYYY"
            )}`;
            break;
          case "dateEnd":
            label = `Fecha fin: ${dayjs(value).format(
              "DD [de] MMMM [de] YYYY"
            )}`;
            break;
          case "search":
            label = `Búsqueda: ${value}`;
            break;
          case "position":
            label = `Posición: ${
              positions.find((position) => position.id === Number(value))
                ?.name || "Todos"
            }`;
            break;
          default:
            label = `${key}: ${value}`;
        }

        formattedQuery.push(label);
      }
    }

    return formattedQuery.join(", ");
  };

  const exportToExcel = () => {
    // Formatear la consulta a un formato legible
    const formattedQueryString = formatQueryStringForExcel(filters);

    const ws = utils.aoa_to_sheet([]);
    const headers = [
      "Id",
      "Colaborador",
      "Posición",
      "Día de la semana",
      "Turno",
      "Hora entrada horario",
      "Hora salida horario",
      "Hora entrada registrada",
      "Hora salida registrada",
      "Minutos extra",
      "Estatus de asistencia",
      "Es reemplazo",
    ];
    utils.sheet_add_aoa(ws, [headers], { origin: "A4" });

    const filtersData = [["Consulta:", formattedQueryString], []];
    utils.sheet_add_aoa(ws, filtersData, { origin: "A2" });

    if (!records || records.length === 0) {
      alert("No hay datos para exportar.");
      return;
    }

    let rowIndex = 6;
    records.forEach((item) => {
      const person = item.partner?.person || {};
      const horaEntradaHorario = item.startTime?.split(" - ")[0] || "";
      const horaSalidaHorario = item.endTime?.split(" - ")[1] || "";
      const horaEntradaRegistrada = item.assistanceCheck
        ? dayjs(item.assistanceCheck.checkTime, "HH:mm:ss").format("HH:mm")
        : "";
      const horaSalidaRegistrada = item.assistanceCheckout
        ? dayjs(item.assistanceCheckout.checkTime, "HH:mm:ss").format("HH:mm")
        : "";
      const minutosExtra = calcularMinutosExtra(
        horaSalidaHorario,
        horaSalidaRegistrada
      );

      const row = [
        item.id,
        `${person.firstName || ""} ${person.lastName || ""} ${
          person.secondLastName || ""
        }`,
        item.positionName || "",
        // item.dayOfWeek || "",
        formatDate(item?.exactDate) || "",
        item.shift || "",
        horaEntradaHorario,
        horaSalidaHorario,
        horaEntradaRegistrada,
        horaSalidaRegistrada,
        minutosExtra !== "" ? `${minutosExtra} min` : "",
        item.assistanceStatus || "",
        item.isReplacement ? "Sí" : "No",
      ];
      utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
      rowIndex++;
    });

    ws["!cols"] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 18 },
      { wch: 15 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Registros");
    writeFile(wb, `Chequeo_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`);
  };

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Reemplazo:</label>
          <select
            className="form-select mt-1"
            id="isReplacement"
            name="isReplacement"
            onChange={(e) =>
              setFilters({ ...filters, isReplacement: e.target.value })
            }
            value={filters.isReplacement}
          >
            <option value={"all"}>Todos</option>
            <option value={"true"}>Si</option>
            <option value={"false"}>No</option>
          </select>
          <label>Turno:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, shift: e.target.value })}
            value={filters.shift}
          >
            <option value={"all"}>Todos</option>
            <option value={"Matutino"}>Matutino</option>
            <option value={"Intermedio"}>Intermedio</option>
            <option value={"Vespertino"}>Vespertino</option>
          </select>
          {!id && (
            <>
              <label>Sucursal de chequeo:</label>
              <select
                className="form-select mt-1"
                id="status"
                name="status"
                onChange={(e) =>
                  setFilters({ ...filters, checkBranch: e.target.value })
                }
                value={filters.checkBranch}
              >
                <option value={"all"}>Todos</option>
                {branches.map((branch: any) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </>
          )}
          {/* <label>Posición:</label>
          <select
            className="form-select mt-1"
            id="position"
            name="position"
            onChange={(e) =>
              setFilters({ ...filters, position: e.target.value })
            }
            value={filters.position}
          >
            <option value={"all"}>Todos</option>
            {positions.map((position: any) => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
          </select> */}

          <label>Fecha inicio:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => {
              const updatedDate = e.target.value;
              setFilters({ ...filters, dateStart: updatedDate });
              updateFilters({ dateStart: updatedDate });
            }}
            value={filters.dateStart}
          />

          <label>Fecha fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) =>
              setFilters({ ...filters, dateEnd: e.target.value })
            }
            value={filters.dateEnd}
          />
          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    partnerBranch: "all",
                    dateStart: "",
                    dateEnd: "",
                    checkBranch: "all",
                    shift: "all",
                    search: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={(e) => handleSearchChange(e)}
              value={filters.search}
            />
            <button className="btn btn-success ms-2" onClick={exportToExcel}>
              <i className="fa-solid fa-file-arrow-down me-2" />
              Exportar a Excel
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters).map((filter: any) => (
            <div
              key={filter.key}
              className="badge bg-primary text-white fs-6 me-2 position-relative pe-4"
            >
              {toCamelCase(
                filter.keyName === "status" ? "Estatus" : filter.keyName
              )}
              &nbsp;:&nbsp;
              {["false", "true"].indexOf(filter.valueName) > -1
                ? filter.valueName === "true"
                  ? "Activos"
                  : "Inactivos"
                : filter.key === "dateStart" || filter.key === "dateEnd"
                ? dayjs(filter.valueName).format("DD-MMMM-YYYY")
                : filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute end-0 top-50 translate-middle"
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFilter(filter.key);
                }}
              ></button>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FilterChequeo;