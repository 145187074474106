import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch } from "react-redux";
import { IDeleteBadge } from "../../interfaces/others/IDeleteBadge.ts";
import { deleteBadge } from "../../services/badges.service.ts";
import { setFetch } from "../../store/slices/badgesSlice.ts";

const DeleteBadge: React.FC<IDeleteBadge> = ({ name, id, closeFn }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await deleteBadge(id);
      notification("", `Insignia ${name} eliminado correctamente`, "success");
    } catch (error) {
      notification(
        "Atención",
        `Ocurrio un error al tratar de eliminar la insignia ${name}, esta insignia puede estar relacionada con otras insignias`,
        "warning"
      );
    } finally {
      dispatch(setFetch(true));
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas eliminar la insignia {name}?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => handleDelete()}>
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteBadge;
