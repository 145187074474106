import React, { useEffect, useState } from "react";
import { getIn, setIn } from "formik";
import { autocompletePartners } from "../../services/partner.service.ts";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { PartnerAutocomplete } from "../../models/PartnerAutocomplete.ts";

const PartnersAutocomplete = ({ ...props }) => {
  const [value, setValue] = useState<PartnerAutocomplete | string>("");

  const [options, setOptions] = useState<PartnerAutocomplete[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);

  const error =
    getIn(props.formik.touched, props.field) &&
    getIn(props.formik.errors, props.field);

  let timeoutId;

  const handleChange = async (event) => {
    setLoading(true);
    const value = event.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(async () => {
      try {
        const data = await autocompletePartners(value);
        setOptions(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleSelect = (data: PartnerAutocomplete) => {
    setValue(data);
    setShowOptions(false);

    const updatedValues = setIn(props.formik.values, props.field, data?.id);
    props.formik.setValues(updatedValues);
  };

  useEffect(() => {
    firstFetch();
  }, []);

  const firstFetch = async () => {
    const data = await autocompletePartners("");
    setOptions(data);
  };

  return (
    <div className="row fs-13px col-md-12">
      <label
        className="form-label col-form-label col-md-4 text-end"
        style={{ color: error ? "red" : "inherit" }}
      >
        Chilito reemplazo&nbsp;
        <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
          *
        </span>
      </label>
      <div className="row fs-13px col-md-8" style={{ position: "relative" }}>
        <input
          type="text"
          className={`form-control ${error ? "is-invalid" : ""}`}
          placeholder="Busca a un chilito"
          id="product-key-input"
          onChange={handleChange}
          onFocus={() => setShowOptions(true)}
          onBlur={() => setShowOptions(false)}
          {...(!showOptions &&
            typeof value !== "string" && {
              value: toCamelCase(
                `${value.person.firstName} ${value.person.lastName} ${value.person.secondLastName}`
              ),
            })}
            autoComplete="off"
        />
        {options.length > 0 && showOptions && (
          <ul
            className="list-group mt-2"
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "white",
              width: "100%",
              maxHeight: "200px",
              overflowY: "auto",
              border: "1px solid #ccc",
              top: "calc(100% + -8px)",
            }}
          >
            {options.map((data, index) => (
              <li
                key={index}
                className="list-group-item list-group-item-action"
                onMouseDown={() => handleSelect(data)}
              >
                {toCamelCase(
                  `${data.person.firstName} ${data.person.lastName} ${data.person.secondLastName}`
                )}
              </li>
            ))}
          </ul>
        )}
        {options.length === 0 && showOptions && (
          <ul className="list-group mt-2">
            <li className="list-group-item list-group-item-action">
              No hay resultados
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default PartnersAutocomplete;
