import axios from "axios";
import moment from "moment";
import { PositionArray, PositionsCompact } from "../models/PositionArray";
import { LayoutItem } from "../models/Layout";
import { CompactLayoutRecord } from "../models/CompactLayoutRecord";
import { ScheduleRecord } from "../models/ScheduleRecord";
import { ColorSchedule } from "../models/ColorSchedule";
import { v4 as uuid } from "uuid";
import { toCamelCase } from "../hooks/toCameCase.tsx";
import { ScheduleTimeConfiguration } from "../models/ScheduleTimeConfiguration.ts";
import dayjs from "dayjs";

const isNumString = (str) => !isNaN(Number(str));

function parseObject(obj) {
  return Object.keys(obj).reduce((result, key) => {
    const val = obj[key];
    result[key] = isNumString(val) ? val : deepParseJson(val);
    return result;
  }, {});
}

export function deepParseJson(jsonString) {
  if (typeof jsonString === "string") {
    if (isNumString(jsonString)) {
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    return jsonString.map(deepParseJson);
  } else if (typeof jsonString === "object" && jsonString !== null) {
    return parseObject(jsonString);
  } else {
    return jsonString;
  }
}

export const getCurrencyFormat = (value: string | number) => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(typeof value === "string" ? parseInt(value) : value);
};

export const valiteCURP = (curp: string) => {
  curp = curp?.toUpperCase().trim();

  const regex = /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/;
  return regex.test(curp);
};

export const formatDate = (date: string = new Date().toDateString()) => {
  return moment(date).format("dddd, D [de] MMMM [de] YYYY");
};

export interface IDefaultArray {
  id: number;
  name: string;
}

export const excelDateToJSDate = (serial: number) => {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);
  return new Date(dateInfo);
};

export const sortByName = (array: any[]) => {
  return array.sort((a, b) => a.name.localeCompare(b.name));
};

export const sortByTitle = (array: any[]) => {
  // Crear una copia del array antes de ordenarlo
  return [...array].sort((a, b) => a.title.localeCompare(b.title));
};

export const sortByPosition = (array: any[]) => {
  return array.sort((a, b) => a?.position?.name.localeCompare(b.name));
};

export function groupAndSortPositions(positions) {
  const groupedPositions = {};

  // Agrupar posiciones por nombre
  positions.forEach((position) => {
    const positionName = position.position.name;
    if (!groupedPositions[positionName]) {
      groupedPositions[positionName] = {
        position: position.position,
        shifts: [],
      };
    }
    groupedPositions[positionName].shifts.push({
      shift: position.shift,
      numberOfWorkers: position.numberOfWorkers,
      requieredWorkers: position.requieredWorkers,
      partners: position.partners,
    });
  });

  const result = Object.values(groupedPositions);

  return result;
}
export async function isValidImageUrl(url) {}

export const mergePositions = (positions: PositionArray[]): any[] => {
  const merged: { [key: string]: any } = {};

  positions.forEach((position) => {
    if (position.positionName) {
      const key = `${position.positionName}-${position.id}`;
      if (!merged[key]) {
        merged[key] = { ...position, shifts: [] };
      }
      merged[key].shifts.push(position.shift);
    }
  });

  return Object.values(merged).map((position) => ({
    positionName: position.positionName,
    shifts: position.shifts,
    compact: true,
    positionOriginalId_: position.positionOriginalId_,
    ...position,
  }));
};

export const compactLayout = (
  layout: LayoutItem[],
  positionsMerged: PositionsCompact[]
): CompactLayoutRecord[] => {
  const newLayout: LayoutItem[] = [];

  const skipPositions: number[] = [];
  positionsMerged.forEach((position, index) => {
    const { shifts, positionOriginalId_ } = position;

    shifts.forEach((shift) => {
      const indexPosition = lastChar(position.positionName);

      const firstCoincidence = findFirstCoincidence(
        layout,
        positionOriginalId_,
        shift,
        skipPositions,
        indexPosition
      );

      if (!firstCoincidence) {
        return;
      }
      skipPositions.push(firstCoincidence?.y);

      const elements = findItemsInRow(layout, firstCoincidence.y);

      elements.forEach((element) => {
        newLayout.push({
          ...element,
          y: index,
        });
      });
    });
  });

  return newLayout;
};

export const getShiftColors = (shifts) => {
  const colors = {
    Matutino: "#FFD700",
    Vespertino: "#FF6347",
    Intermedio: "#4682B4",
  };

  return shifts.map((shift) => colors[shift]);
};

const findFirstCoincidence = (
  layout: LayoutItem[],
  positionId: number,
  shift: string,
  ommitY: number[],
  originalIndex?: string
) => {
  return layout.find(
    (item) =>
      item.positionOriginalId_ === positionId &&
      item.shift === shift &&
      lastChar(item.position) === originalIndex &&
      !ommitY.includes(item.y)
  );
};

const findItemsInRow = (layout: LayoutItem[], y: number) => {
  return layout.filter((item) => item.y === y);
};

const lastChar = (str: string) => str.charAt(str.length - 1);

// export const mergePositions = (positions: PositionArray[]): any[] => {
export const transformScheduleToLayout = (
  records: ScheduleRecord[],
  color: ColorSchedule,
  assistance: ScheduleTimeConfiguration
) => {
  const layout: LayoutItem[] = [];

  records.map((record, index) => {
    const transfer: LayoutItem = {
      i: uuid(),
      minH: 1,
      maxH: 1,
      minW: 1,
      show: true,
      pending: false,
      // name: toCamelCase(record.position),
      name: toCamelCase(record.partner.person.firstName),
      position: record.positionName,

      originalId: record.id,
      shift: record.shift,
      x: Number(record.positionX),
      y: Number(record.positionY),
      w: Number(record.positionW),
      h: 1,
      positionId: record.position.id,
      positionOriginalId_: record.position.id,
      assistanceStatus: record.assistanceStatus,

      startTime: record.startTime,
    };

    // Si aun no es hora de entrada marcarlo como por llegar
    // Verificar si es retardo con la configuración de horarios

    const { startTime } = record;
    const [startHour, startMinute] = startTime
      ?.split(":")
      ?.map((str) => parseInt(str.trim(), 10));
    const maxTime = assistance.value.late.afterMinutes;

    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    let isOnTime = false;
    if (
      currentHour < startHour ||
      (currentHour === startHour && currentMinute <= startMinute + maxTime)
    ) {
      isOnTime = true;
    }

    // if (currentHour <= startHour) {
    //   isOnTime = startMinute + maxTime >= currentMinute;

    //   if (currentHour < startHour) isOnTime = true;
    // }

    const status = record.assistanceStatus;

    if (status === "Asistencia") {
      transfer.color = color.value.asistencia;
    } else if (status === "Retardo") {
      transfer.color = color.value.retardo;
    } else if (!status) {
      transfer.color = color.value.sinAsistencia;
    }

    if (isOnTime && !status) {
      transfer.color = color.value.porLlegar;
      transfer.assistanceStatus = "Por llegar";
    }

    layout.push(transfer);
  });

  return layout;
};

export const updateColor = (
  layout: LayoutItem[],
  color: ColorSchedule,
  assistance: ScheduleTimeConfiguration
): LayoutItem[] => {
  const newLayout: LayoutItem[] = [];

  try {
    layout.forEach((record) => {
      // if( !record.startTime) return
      const newItem = { ...record };
      if (!newItem.startTime) return;

      const { startTime } = record;

      const [startHour, startMinute] = startTime
        ?.split(":")
        ?.map((str) => parseInt(str.trim(), 10));

      const maxTime = assistance.value.late.afterMinutes;

      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      let isOnTime = false;
      if (currentHour <= startHour) {
        isOnTime = startMinute + maxTime >= currentMinute;
      }

      const status = record.assistanceStatus;

      if (status === "Asistencia") {
        newItem.color = color.value.asistencia;
      } else if (status === "Retardo") {
        newItem.color = color.value.retardo;
      } else if (!status) {
        newItem.color = color.value.sinAsistencia;
      }

      if (isOnTime && !status) {
        newItem.color = color.value.porLlegar;
        newItem.assistanceStatus = "Por llegar";
      }
      newLayout.push(newItem);
    });
  } catch (error) {
    console.log(error);
  }
  return newLayout;
};

function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return { r, g, b, a: 1 };
}

function rgbToHsv(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s * 100, v: v * 100, a: 1 };
}

export function hexToRgbAndHsv(hex) {
  const rgb = hexToRgb(hex);
  const hsv = rgbToHsv(rgb.r, rgb.g, rgb.b);
  return { rgb, hsv };
}

export function formatKey(key: string): string {
  return key
    .replace(/([A-Z])/g, " $1") // Inserta un espacio antes de cada letra mayúscula
    .replace(/^./, (str) => str.toUpperCase()); // Convierte la primera letra a mayúsculas
}

export const isDayjs = (date) => {
  return dayjs.isDayjs(date);
};

export function getStartTime(data: string) {
  const [startHour, startMinute] = data
    ?.split(":")
    ?.map((str) => parseInt(str.trim(), 10));

  const [endHour, endMinute] = data
    ?.split(" ")[2]
    ?.split(":")
    ?.map((str) => parseInt(str.trim(), 10));

  const startHourValue = startHour < 10 ? `0${startHour}` : startHour;
  const startMinuteValue = startMinute < 10 ? `0${startMinute}` : startMinute;

  const endHourValue = endHour < 10 ? `0${endHour}` : endHour;
  const endMinuteValue = endMinute < 10 ? `0${endMinute}` : endMinute;

  return {
    startHour: startHourValue,
    startMinute: startMinuteValue,
    startTime: `${startHourValue}:${startMinuteValue}`,
    endHour: endHourValue,
    endMinute: endMinuteValue,
    endTime: `${endHourValue}:${endMinuteValue}`,
  };
}

export function getRequiredWorkedTime(hora1, hora2) {
  if (typeof hora1 !== "string" || typeof hora2 !== "string") {
    throw new Error("Ambas entradas deben ser cadenas en formato 24 horas");
  }

  const [hours1, minutes1] = hora1.split(":").map(Number);
  const [hours2, minutes2] = hora2.split(":").map(Number);

  if (isNaN(hours1) || isNaN(minutes1) || isNaN(hours2) || isNaN(minutes2)) {
    throw new Error("Las cadenas en formato 24 horas no son válidas");
  }

  const fecha1 = dayjs().hour(hours1).minute(minutes1).second(0);
  const fecha2 = dayjs().hour(hours2).minute(minutes2).second(0);

  return fecha1.diff(fecha2, "minute");
}

export function differenceDayjs(fecha1, fecha2) {
  return fecha2?.diff(fecha1, "minute");
}

export function differenceDayjsAndTime(hora24, fecha) {
  if (typeof hora24 !== "string" || !dayjs.isDayjs(fecha)) {
    return 0;
  }

  const [hours, minutes] = hora24.split(":").map(Number);
  if (isNaN(hours) || isNaN(minutes)) {
    return 0;
  }

  const fechaHora = fecha.hour(hours).minute(minutes).second(0);
  return fecha.diff(fechaHora, "minute");
}

export function getStartTimeRecord(data: string) {
  const [startHour, startMinute] = data
    ?.split(":")
    ?.map((str) => parseInt(str.trim(), 10));

  const startHourValue = startHour < 10 ? `0${startHour}` : startHour;
  const startMinuteValue = startMinute < 10 ? `0${startMinute}` : startMinute;

  return {
    startHour: startHourValue,
    startMinute: startMinuteValue,
    startTime: `${startHourValue}:${startMinuteValue}`,
  };
}

export function convertToDayjs(hora) {
  const formato = "HH:mm";
  return dayjs(hora, formato);
}

export const AssistanceStatus = [
  { id: 1, name: "Asistencia" },
  { id: 2, name: "Retardo" },
  { id: 3, name: "Falta" },
  // { id: 4, name: "Permiso" },
  // { id: 5, name: "Incapacidad" },
  // { id: 6, name: "Vacaciones" },
  // { id: 7, name: "Día Festivo" },
  // { id: 8, name: "Por llegar" },
];

export const getIconNumber = (no: number) => {

  if(no > 10) {
    return "🚨";
  }


  switch (no) {
    case 0: 
      return "";
    case 1:
      return "1️⃣";
    case 2:
      return "2️⃣";
    case 3:
      return "3️⃣";
    case 4:
      return "4️⃣";
    case 5:
      return "5️⃣";
    case 6:
      return "6️⃣";
    case 7:
      return "7️⃣";
    case 8:
      return "8️⃣";
    case 9:
      return "9️⃣";
    case 10:
      return "🔟"
    default:
      return "";
  }

  
};
