import React from "react";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { LeaderTemplate } from "../../models/LeaderTemplate.ts";
import { notification } from "../../utils/Notifications.tsx";
import { fetchPartners } from "../../services/partner.service.ts";
import RowPartners from "../../components/reports/templateLeaders/RowPartners.tsx";
import { utils, writeFile } from "xlsx";
import { configuration, getPartners } from "./Configuration.ts";
import { readBranches } from "../../services/branch.service.ts";
import { readPositions } from "../../services/position.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import PrintReport from "./PrintReport.tsx";
import { useReactToPrint } from "react-to-print";
import { getShiftManager } from "../../services/candidate.service.ts";
import { Partner } from "../../models/Partner.ts";
import { Divider } from "@mui/material";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const TemplateLeaders = () => {
  useAppSettings();
  const [loading, setLoading] = React.useState(true);
  const [records, setRecords] = React.useState<LeaderTemplate[]>([]);
  const [shiftManagers, setShiftManagers] = React.useState<Partner[]>([]);
  const componentRef = React.useRef() as React.MutableRefObject<any>;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
         @media all {
          .page-break {
            display: none;
          }
        }
        @media print {
          html, body {
            height: initial !important;
            overflow: initial !important;
          }
          .page-break {
            display: block;
            page-break-before: auto;
          }
          .print-content {
          }
        }
        @page {
            size: letter;
            margin: 7mm;
          }
      `,
  });

  const fetchData = async () => {
    try {
      const branches = await readBranches();
      const positions = await readPositions();
      const shifts = await getShifts();
      const info: any[] = [];
      for (const branch of configuration) {
        const branchId = branches.find((b) => b.name === branch.branch)?.id;
        if (!branchId) continue;
        const element: any = {
          branch: branch,
          positions: [],
        };

        for (const position of branch.positions) {
          const positionId = positions.find(
            (p) => p.name === position.name
          )?.id;
          const shiftId = shifts.find((s) => s.name === position?.shift)?.id;
          if (!positionId) continue;

          const query = `?tab=activos&branch=${branchId}&puesto=${positionId}${
            shiftId ? `&shift=${shiftId}` : ""
          }`;

          const partners = await fetchPartners(query);

          element.positions.push({
            name: `${position.name}${
              position?.shift ? ` ${position.shift}` : ""
            }`,
            shift: position?.shift,
            partners: partners.results,
          });
        }
        info.push(element);
      }
      setRecords(info);

      const response: Partner[] = await getShiftManager();

      const arraySort = response.sort(
        (a, b) => a.candidate.branch.id - b.candidate.branch.id
      );

      setShiftManagers(arraySort);
    } catch (error) {
      notification("", error?.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const exportFile = React.useCallback(() => {
    // Crear una hoja de trabajo vacía
    const ws = utils.aoa_to_sheet([]);

    // Añadir encabezados personalizados
    const headers = ["Sucursal", "Puesto", "Turno", "Colaborador", "Teléfono"];
    utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
    const columnMerge: any[] = [];

    // Iterar sobre los datos y añadir filas personalizadas
    let rowIndex = 2; // Empezar en la fila 2 (después de los encabezados)
    records.forEach((record) => {
      const branch = record.branch.branch;
      const inicio = rowIndex;

      record.positions.forEach((position) => {
        const partners = getPartners(position.partners);

        if (partners.length === 1) {
          const chilito = partners[0];
          const row = [
            branch,
            position.name,
            position.shift,
            chilito.name,
            chilito.phone,
          ];
          utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
          rowIndex++;
        } else if (partners.length > 1) {
          partners.map((partner, _) => {
            const row = [
              _ === 0 ? branch : "",
              _ === 0 ? position.name : "",
              _ === 0 ? position.shift : "",
              partner.name,
              partner.phone,
            ];
            utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
            rowIndex++;
          });
        } else {
          const row = [
            branch,
            position.name,
            position.shift,
            "Sin chilito",
            "",
          ];
          utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
          rowIndex++;
        }
      });

      // Añadir una fila en blanco entre cada sucursal
      utils.sheet_add_aoa(ws, [[]], { origin: `A${rowIndex}` });
      const fin = rowIndex;

      columnMerge.push({ s: { r: inicio - 1, c: 0 }, e: { r: fin - 2, c: 0 } });

      rowIndex++;
    });

    shiftManagers.forEach((record, index) => {
      const row = [
        record.candidate.branch.name,
        record.candidate.vacancy.position.name,
        record.candidate.shift.name,
        `${record.person.firstName} ${record.person.lastName} ${record.person.secondLastName}`,
        record.person.phone,
      ];
      utils.sheet_add_aoa(ws, [row], { origin: `A${rowIndex}` });
      rowIndex++;
    }
    );

    // Congelar la primera fila
    ws["!freeze"] = { xSplit: 0, ySplit: 1, state: "frozen" };
    ws["!cols"] = [
      { wch: 10 }, // Sucursal
      { wch: 35 }, // Puesto
      { wch: 10 }, // Turno
      { wch: 35 }, // Colaborador
      { wch: 15 }, // Teléfono
    ];

    // Merge cells
    ws["!merges"] = columnMerge;

    // Centrar el contenido de la columna fusionada
    columnMerge.forEach((merge) => {
      const cellAddress = `A${merge.s.r + 1}`;
      if (!ws[cellAddress]) ws[cellAddress] = {};
      ws[cellAddress].s = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
    });

    // Crear el libro de trabajo y añadir la hoja de trabajo
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");

    // Exportar a XLSX
    writeFile(wb, "Plantilla de lideres.xlsx");
  }, [records,shiftManagers]);

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Reportes"
          basePath="/reportes"
          activeTitle="Plantilla de líderes"
          title="Plantilla de líderes"
        />
        <div className="border-0 m-4">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 20 }}
                  >
                    <button
                      className="btn btn-azul d-block btn-sm fs-14px"
                      onClick={() => handlePrint()}
                    >
                      Imprimir&nbsp;&nbsp;
                      <i className="fa-solid fa-print" />
                    </button>
                    <button
                      className="btn btn-verde d-block btn-sm fs-14px"
                      onClick={() => exportFile()}
                    >
                      Exportar&nbsp;&nbsp;
                      <i className="fa-solid fa-file-arrow-down" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-0 m-4">
          <div className="row">
            {records.map((record, index) => {
              if (
                record.branch.branch === "Talento Humano" ||
                record.branch.branch === "Guajaqueñito"
              )
                return;
              return (
                <div className={`col-md-6 ${index > 1 && "mt-2"}`} key={index}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{record.branch.branch}</h5>
                      <RowPartners data={record.positions} />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={`col-md-6 `}>
              {records.map((record, index) => {
                if (
                  record.branch.branch === "Talento Humano" ||
                  record.branch.branch === "Guajaqueñito"
                ) {
                  return (
                    <div className="card mt-2">
                      <div className="card-body">
                        <h5 className="card-title">{record.branch.branch}</h5>
                        <RowPartners data={record.positions} />
                      </div>
                    </div>
                  );
                }
                return <></>;
              })}
            </div>
          </div>

          {!loading && (
            <div className="row">
              <div className={`col-md-12 mt-2`}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-2">Jefes de turno</h5>
                    {shiftManagers.map((record, index) => (
                      <React.Fragment>
                        <div className="row  mt-2 mb-2">
                          <div className="col-md-2 d-flex align-items-center">
                            <p className="card-text">
                              <strong>
                                {record.candidate.vacancy.position.name}
                              </strong>
                            </p>
                          </div>
                          <div className="col-md-2 d-flex align-items-center">
                            <p className="card-text">
                              <strong>{record.candidate.branch.name}</strong>
                            </p>
                          </div>
                          <div className="col-md-3">
                            <div className="col">
                              <p className="card-text">
                                {toCamelCase(
                                  `${record.person.firstName} ${record.person.lastName} ${record.person.secondLastName}`
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="col">
                              <p className="card-text">{record.person.phone}</p>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="col">
                              <p className="card-text">
                                {record.candidate.shift.name}
                              </p>
                            </div>
                          </div>
                        </div>
                        {shiftManagers.length === index + 1 ? null : (
                          <Divider />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>

      {/* Print */}
      <div style={{ display: "none" }}>
        <PrintReport
          records={
            <React.Fragment>
              <div style={{ border: "none" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {records.map((record, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "0 0 50%",
                        marginTop: index > 1 ? "16px" : "0",
                        boxSizing: "border-box",
                        padding: "8px",
                      }}
                    >
                      <div
                        style={
                          {
                            // border: "1px solid #ddd",
                            // borderRadius: "4px",
                            // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }
                        }
                      >
                        <div style={{ padding: "16px" }}>
                          <h5 style={{ margin: "0 0 16px 0" }}>
                            {record.branch.branch}
                          </h5>
                          <RowPartners data={record.positions} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h5 style={{ margin: "0 0 16px 0", paddingLeft: '16px' }}>Jefes de turno</h5>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {shiftManagers.map((record, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "1 1 30%", // This ensures 3 items per row
                      
                      }}
                    >
                      <div
                        style={{
                         
                          padding: "16px",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <p style={{ fontWeight: "bold", margin: "0 0 4px" }}>
                          {record.candidate.vacancy.position.name}
                        </p>
                        <p style={{ fontWeight: "bold", margin: "0 0 4px" }}>
                          {record.candidate.branch.name}
                        </p>
                        <p style={{ margin: "0 0 4px" }}>
                          {toCamelCase(
                            `${record.person.firstName} ${record.person.lastName} ${record.person.secondLastName}`
                          )}
                        </p>
                        <p style={{ margin: "0 0 4px" }}>
                          {record.person.phone}
                        </p>
                        <p style={{ margin: "0" }}>
                          {record.candidate.shift.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          }
          ref={componentRef}
          title={"Plantilla de lideres"}
        />
      </div>
    </React.Fragment>
  );
};

export default TemplateLeaders;
