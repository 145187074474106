import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";

const initialState  = {
  modalType: "",
  showModal: false,
  loading: true,
  error: "",
  title: "",
  currentRecord: {},
  refreshFunction: () => Promise<void>,
  selectButton: "Incidencias",
  // date: moment().format("DD/MM/YYYY"),
  date: dayjs(),
  showClock: false
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    setModalType: (state, action) => {
      state.modalType = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setCurrentRecord: (state, action) => {
      state.currentRecord = action.payload;
    },
    setRefreshFunction: (state, action) => {
      state.refreshFunction = action.payload;
    },
    setSelectButton: (state, action) => {
      state.selectButton = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setShowClock: (state, action) => {
      state.showClock = action.payload;
    }
  },
});

export const {
  setModalType,
  setShowModal,
  setLoading,
  setError,
  setTitle,
  setCurrentRecord,
  setRefreshFunction,
  setSelectButton,
  setDate,
  setShowClock
} = incidentSlice.actions;

export default incidentSlice.reducer;
