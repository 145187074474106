import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultIncapacity,
  EditIncapacityProperties,
  FillIncapacity,
} from "../../interfaces/formik/Incapacity.ts";
import { notification } from "../../utils/Notifications.tsx";
import {
  approveIncapacity,
  getIncapacityById,
} from "../../services/incapacities.service.ts";
import { useFormik } from "formik";
import { IncapacityShema } from "../../validation/schemas/IncapacityShema.ts";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { Panel, PanelHeader } from "../../components/panel/panel.jsx";
import IncapacityFormEdit from "../../components/incapacities/IncapacityFormEdit.tsx";
import Button from "../../components/Button.tsx";
import IncapacityFormEditFiles from "../../components/incapacities/IncapacityFormEditFiles.tsx";
import Loader from "../../components/Loader.tsx";

const ApprovedIncapacity = () => {
  const { id } = useParams();
  useAppSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [initialValues, setInitialValues] =
    React.useState<EditIncapacityProperties>({} as EditIncapacityProperties);

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const incapacity = await getIncapacityById(Number(id));
      const fillObject = FillIncapacity(incapacity);

      if (!fillObject) throw new Error("Error al cargar los datos");
      setInitialValues(fillObject);
    } catch (error) {
      notification("error", "Error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };
  const isPartner = false;
  console.log("initialValues", initialValues);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: IncapacityShema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const incapacity = await approveIncapacity(id, {
          title: values.title,
          description: values.description,
          approved: true,
          daysOff: values.daysOff,
          startDate: values.startDate,
          endDate: values.endDate,
        });
        if (!incapacity) throw new Error("Error al aprobar la incapacidad");

        notification("Éxito", "Incapacidad aprobada correctamente", "success");
      } catch (error) {
        console.log("error", error);
        notification("Error", "Error al crear el puesto", "danger");
      } finally {
        setLoading(false);
        navigate(-1);
      }
    },
  });

  React.useEffect(() => {
    loadDependencies();
  }, []);

  return (
    <Layout>
      <Title
        baseTitle="Aprovar incapacidad"
        basePath={`/colaboradores/aprobar-incapacidad/${id}`}
        title="Información del colaborador"
      />

      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la incapacidad para aprobar
          </PanelHeader>
          <IncapacityFormEdit
            formik={formik}
            isPartner={isPartner}
            isCreate={false}
          />
        </Panel>
      </div>

      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Archivos adjuntos de la incapacidad
          </PanelHeader>
          <IncapacityFormEditFiles
            formik={formik}
            reloadFiles={loadDependencies}
          />
        </Panel>
      </div>

      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate(-1)}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          {!formik.values.approved ? (
            <div className="col-md-3">
              <Button
                onClick={() => formik.handleSubmit()}
                title={!isPartner ? "Aprobar" : "Guardar"}
                variant="azul"
                type="submit"
              />
            </div>
          ):(
            <div className="col-md-3">
              <Button
                onClick={() => navigate(-1)}
                title="Esta incapacidad ya ha sido aprobada"
                variant="azul"
                type="button"
                disabled={true}
              />
            </div>
          )}
        </div>
      </div>
      <Loader isLoading={loading} />
    </Layout>
  );
};

export default ApprovedIncapacity;
