import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import PaginationPlantillas from "../../../components/plantilla/PaginationPlantilla.tsx";
import FilterNotificationsUser from "../../../components/plantilla/FilterNotificationsUser.tsx";
import {
  resetState,
  setLoading,
  setNotificationsUser,
  setTotal,
} from "../../../store/slices/notificationsUserSlice.ts";
import { notification } from "../../../utils/Notifications.tsx";
import { paginateNotificationsUser } from "../../../services/notificationUser.service.ts";
import TableNotificationsUsers from "../TableNotificationsUsers.tsx";

type Props = {};

const NotificationsUsersComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { page, limit, param, loading, fetch, query } = useSelector(
    (state: RootState) => state.notificationUser
  );

  const fetchNotificationsUser = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "typeUser=all&branch=all&rule=all";
      }
      const response = await paginateNotificationsUser(queryToSend);
      dispatch(setNotificationsUser(response));
      console.log("response", response);
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  React.useEffect(() => {
    fetchNotificationsUser();
  }, [page, limit, param, fetch, query]);

  return (
    <>
      <div className="tab" id="plantillasTab">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <FilterNotificationsUser />
            <TableNotificationsUsers />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsUsersComponent;
