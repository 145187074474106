import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { RootState } from "../../store/index.ts";
import { read, utils, write } from "xlsx";
import {
  DefaultPartner,
  ImportPartner,
  PartnerExcel,
} from "../../models/ImportPartner.ts";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import {
  IDefaultArray,
  excelDateToJSDate,
  formatDate,
  getCurrencyFormat,
} from "../../utils/index.ts";
import { notification } from "../../utils/Notifications.tsx";
import { readPositions } from "../../services/position.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import { readSizes } from "../../services/size.service.ts";
import { getPeriodicities } from "../../services/periodicty.service.ts";
import ModalContainer from "../../components/ModalContainer.tsx";
import { setShowModalEditCurrentPartner } from "../../store/slices/colaboradoresSlice.ts";
import ImportPartnerForm from "../../components/forms/ImportPartnerForm.tsx";
import { importPartners } from "../../services/application.service.ts";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { getShifts } from "../../services/shift.service.ts";

const ImportPartners = () => {
  useAppSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentFile, showModalEditCurrentPartner } = useSelector(
    (state: RootState) => state.colaboradores
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [records, setRecords] = React.useState<PartnerExcel[]>([]);

  const [branches, setBranches] = React.useState<IDefaultArray[]>([]);
  const [positions, setPositions] = React.useState<IDefaultArray[]>([]);
  const [sizes, setSizes] = React.useState<IDefaultArray[]>([]);
  const [periodicities, setPeriodicities] = React.useState<IDefaultArray[]>([]);
  const [currentPartner, setCurrentPartner] = React.useState<PartnerExcel>(
    {} as PartnerExcel
  );
  const [currentIndex, setCurrentIndex] = React.useState<number>(-1);
  const [validRecords, setValidRecords] = React.useState<number>(0);
  const [shifts, setShifts] = React.useState<IDefaultArray[]>([]);

  // const readFile = () => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const data = e.target?.result;
  //     if (!data) return;

  //     const workbook = read(data, { type: "array" });
  //     const sheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const rows = utils.sheet_to_json(sheet) as ImportPartner[];

  //     const list: PartnerExcel[] = rows.map((row) => {
  //       return new PartnerExcel({
  //         curp: row.CURP,
  //         name: row["Nombre (s)"],
  //         lastName: row["Apellido paterno"],
  //         secondLastName: row["Apellido Materno"],
  //         birthdate: excelDateToJSDate(Number(row["Fecha de nacimiento"])),
  //         phone: row.Teléfono,
  //         address: row.Domicilio,
  //         monthlySalary: row.Salario,
  //         recruitmentDate: excelDateToJSDate(
  //           Number(row["Día de entrada a trabajar"])
  //         ),
  //         position: row.Puesto,
  //         branch: row.Sucursal,
  //         periodicity: row["Periodicidad de pago"],
  //         size: row.Talla,

  //       });
  //     });
  //     setRecords(list);
  //   };

  //   reader.readAsArrayBuffer(currentFile);
  // };

  const loadDependencies = async () => {
    try {
      const positionResponde = await readPositions();
      setPositions(positionResponde);

      const branchResponse = await readBranches();
      setBranches(branchResponse);

      const sizesResponde = await readSizes();
      setSizes(sizesResponde);

      const periodicitiesResponse = await getPeriodicities();
      setPeriodicities(periodicitiesResponse);

      const shiftsResponse = await getShifts();
      setShifts(shiftsResponse);
    } catch (error) {
      notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
    } finally {
      setLoading(false);
    }
  };

  const uploadRecords = async () => {
    setLoading(true);
    try {
      const validRecords = records.filter((record) => record.validate());

      if (validRecords.length === 0) {
        notification("", "No hay registros validos para subir", "danger");
        return;
      }
      const response = await importPartners({ partners: validRecords });

      const translatedResponse = response.map((record) => ({
        " ": record.icon,
        Mensaje: record.message,
        CURP: record.curp,
        Nombre: record.name,
        "Apellido paterno": record.lastName,
        "Apellido materno": record.secondLastName,
        "Fecha de nacimiento": formatDate(record.birthdate.toString()),
        Teléfono: record.phone,
        Domicilio: record.address,
        Salario: getCurrencyFormat(record.monthlySalary),
        "Día de entrada a trabajar": formatDate(
          record.recruitmentDate.toString()
        ),
        Puesto: record.position,
        Sucursal: record.branch,
        "Periodicidad de pago": record.periodicity,
        Talla: record.size,
        Alergias: record.alergias,
        "Tipo de sangre": record.tipoSangre,
        Enfermedad: record.enfermedad,
        Tratamiento: record.tratamiento,
      }));

      const wb = utils.book_new();

      const ws = utils.json_to_sheet(translatedResponse);

      utils.book_append_sheet(wb, ws, "Registros subidos");

      const wbout = write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      saveAs(blob, "registros_subidos.xlsx");

      notification("", "Registros subidos con exito", "success");
    } catch (error) {
      console.log(error);
      notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
    } finally {
      setLoading(false);
      // navigate("/colaboradores");
    }
  };

  React.useEffect(() => {
    loadDependencies();
  }, []);

  // React.useEffect(() => {
  //   if (!currentFile) setError("No se ha seleccionado un archivo");
  //   if (currentFile) {
  //     readFile();
  //   }
  // }, [currentFile]);

  React.useEffect(() => {
    setValidRecords(records.filter((record) => record.validate()).length);
  }, [records]);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Colaboradores"
        basePath="/colaboradores"
        title="Colaboradores"
        activeTitle="Importar colaboradores"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Información a importar
          </PanelHeader>
          <PanelBody>
            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="fa-solid fa-triangle-exclamation fa-lg"></i>{" "}
                {error}
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <div className="alert alert-primary" role="alert">
                  Registros totales: {records.length}
                </div>
                {/* <div className="alert alert-success" role="alert">
                  Registros validos: {validRecords}
                </div>
                <div className="alert alert-warning" role="alert">
                  Registros no validos: {records.length - validRecords}
                </div> */}
              </div>
              <div style={{}}>
                <button
                  type="button"
                  className="btn btn-azul"
                  onClick={() => uploadRecords()}
                  disabled={records.length === 0}
                  style={{
                    cursor: records.length === 0 ? "not-allowed" : "",
                    pointerEvents: "all",
                  }}
                >
                  Subir registros
                </button>
                <button
                  style={{ marginLeft: 10 }}
                  type="button"
                  className="btn btn-azul"
                  onClick={() => {
                    setCurrentPartner(DefaultPartner);
                    dispatch(setShowModalEditCurrentPartner(true));
                  }}
                >
                  Nuevo
                </button>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0 table-bordered  text-nowrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>CURP</th>
                    <th>Nombre (s)</th>
                    <th>Apellido paterno</th>
                    <th>Apellido materno</th>
                    <th>Fecha de nacimiento</th>

                    <th>Teléfono</th>
                    <th>Domicilio</th>
                    {/* <th>Salario</th> */}
                    {/* <th>Día de entrada a trabajar</th> */}
                    <th>Puesto</th>
                    <th>Sucursal</th>
                    {/* <th>Periodicidad de pago</th> */}
                    <th>Talla</th>
                    <th>Turno</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => {
                    const isValid = record.validate();
                    const curpValid = !record.curpValid();
                    const badDataStyle = {
                      backgroundColor: "#e00404",
                      color: "white",
                    };

                    return (
                      <tr key={index}>
                        <td>
                          <i
                            className={`fa-solid fa-${
                              isValid ? "check" : "xmark"
                            } fa-2x`}
                            style={{
                              color: isValid ? "green" : "red",
                            }}
                          />
                        </td>
                        <td style={curpValid ? badDataStyle : {}}>
                          {record.curp}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.name) ? badDataStyle : {}
                          }
                        >
                          {record.name}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.lastName)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.lastName}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.secondLastName)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.secondLastName}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.birthdate)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {formatDate(record.birthdate.toString())}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.phone) ? badDataStyle : {}
                          }
                        >
                          {record.phone}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.address) ? badDataStyle : {}
                          }
                        >
                          {record.address}
                        </td>
                        {/* <td
                          style={
                            record.isInvalid(record.monthlySalary)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {getCurrencyFormat(record.monthlySalary)}
                        </td> */}
                        {/* <td
                          style={
                            record.isInvalid(record.recruitmentDate)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {formatDate(record.recruitmentDate.toString())}
                        </td> */}
                        <td
                          style={
                            !record.validField(record.position, positions)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.position}
                        </td>
                        <td
                          style={
                            !record.validField(record.branch, branches)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.branch}
                        </td>
                        {/* <td
                          style={
                            !record.validField(
                              record.periodicity,
                              periodicities
                            )
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.periodicity}
                        </td> */}
                        <td
                          style={
                            !record.validField(record.size, sizes)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.size}
                        </td>
                        <td
                          style={
                            !record.validField(record.shift, shifts)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.shift}
                        </td>
                        <td>
                          <i
                            className="fa-solid fa-pencil fa-2x amarillo"
                            onClick={() => {
                              dispatch(setShowModalEditCurrentPartner(true));
                              setCurrentPartner(record);
                              setCurrentIndex(index);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </PanelBody>
          <ModalContainer
            open={showModalEditCurrentPartner}
            closeFn={() => {
              dispatch(setShowModalEditCurrentPartner(false));
              setCurrentIndex(-1);
            }}
            title={"Editar registro"}
            children={
              <ImportPartnerForm
                initialValues={currentPartner}
                branches={branches}
                periodicities={periodicities}
                positions={positions}
                sizes={sizes}
                records={records}
                setRecords={setRecords}
                currentIndex={currentIndex}
                shifts={shifts}
              />
            }
            size="lg"
          />
        </Panel>
      </div>
    </Layout>
  );
};

export default ImportPartners;
