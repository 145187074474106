import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Badges } from "../../models/Badges";

const initialState = {
  loading: false,
  badges: [] as Badges[],
  badgesList: [] as Badges[],
  total: 0,
  page: 0,
  limit: 12,
  param: "",
  query: "",
  fetch: false,
  idEdit: 0,
  fetchRemove: false,
  showModal: false,
  name: "",
  showModalBadges: false,
  idToRemove: 0,
  nametoRemove: "",
  showModaltoRemove: false,
  shotModalAssign: false,
};

const badgesSlice = createSlice({
  name: "badges",
  initialState: initialState,
  reducers: {
    setBadges: (state, action: PayloadAction<Badges[]>) => {
      state.badges = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setId: (state, action: PayloadAction<number>) => {
      state.idEdit = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setShowModalBadges: (state, action: PayloadAction<boolean>) => {
      state.showModalBadges = action.payload;
    },
    setBadgesList: (state, action: PayloadAction<Badges[]>) => {
      state.badgesList = action.payload;
    },
    setShowModalRemovePartner: (state, action: PayloadAction<boolean>) => {
      state.showModaltoRemove = action.payload;
    },
    setNametoRemove: (state, action: PayloadAction<string>) => {
      state.nametoRemove = action.payload;
    },
    setIdToRemove: (state, action: PayloadAction<number>) => {
      state.idToRemove = action.payload;
    },
    setFetchRemove: (state, action: PayloadAction<boolean>) => {
      state.fetchRemove = !state.fetchRemove;
    },
    setShowModalAssign: (state, action: PayloadAction<boolean>) => {
      state.shotModalAssign = action.payload;
    },
  },
});

export const {
  setBadges,
  setLoading,
  setTotal,
  setPage,
  setLimit,
  setParam,
  setQuery,
  setFetch,
  resetState,
  setId,
  setShowModal,
  setName,
  setShowModalBadges,
  setBadgesList,
  setShowModalRemovePartner,
  setNametoRemove,
  setIdToRemove,
  setFetchRemove,
  setShowModalAssign,
} = badgesSlice.actions;

export default badgesSlice.reducer;
