import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import ModalContainer from "../ModalContainer.tsx";
import AddDocument from "./AddDocument.tsx";
import AddDocumentEdit from "./AddDocumentEdit.tsx";

const DocumentationPositionEdit = ({
  formik,
  inmediate,
  list,
  originalField,
  originalList
}: IFormikProps & {
  inmediate?: boolean;
  list: Array<{ id: number; name: string }>;
  originalList: Array<{ id: number; name: string }>;
  originalField: string;
}) => {
  const error = formik.touched[originalField] && formik.errors[originalField];
  const [showModal, setShowModal] = React.useState(false);

  const handleDelete = (index: number) => {
    const newValues = [...formik.values[originalField]];
    newValues.splice(index, 1);
    formik.setFieldValue(originalField, newValues);
  };

 

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />
        <div className="col-md-6">
          <button
            type="button"
            className={`btn btn-outline-${error ? "danger" : "secondary"}`}
            onClick={() => setShowModal(true)}
          >
            <i className="fas fa-plus"></i> Agregar documento
          </button>

          <div
            className="invalid-feedback"
            style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
          >
            {formik.touched[originalField] && formik.errors[originalField] && (
              <>{formik.errors[originalField]}</>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />

        <div className="widget-list rounded">
          {originalList &&
            formik.values[originalField]?.sort((a, b) => a - b).map((doc, index) => (
              <div className="widget-list-item" key={index}>
                <div className="widget-list-media icon">
                  <a style={{cursor: 'pointer'}}>
                    <i
                      className="fa fa-trash bg-yellow text-dark"
                      onClick={() => handleDelete(index)}
                    ></i>
                  </a>
                </div>
                <div className="widget-list-content">
                  <h4 className="widget-list-title">
                    {originalList.find((d: any) => d.id === doc)?.name || ""}
                  </h4>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Agregar documento"
        children={
          <AddDocumentEdit
            formik={formik}
            documentList={list}
            closeFn={() => setShowModal(false)}
            originalField={originalField}
          />
        }
      />
    </React.Fragment>
  );
};

export default DocumentationPositionEdit;
