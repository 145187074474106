import { Incident } from "../../models/IncidentRecord.ts";
import {
  AssistanceStatus,
  getRequiredWorkedTime,
  getStartTime,
} from "../../utils/index.ts";

export interface IModifyAssitence {
  partnerId: number;
  checkTimeId: number;
  checkoutId: number;

  // New record (optional)
  fakeTimeEntry: string;
  fakeTimeOut: string;

  reasonToModify: string;
  originalEntryTime: string;
  originalEndTime: string;

  minutesWorked: number;
  requiredWorkedTime: number;

  status: number;
  id: number
}

export const ModifyAssitanceFilled = (incident: Incident): IModifyAssitence => {
  const { startTime } = getStartTime(incident.startTime);
  const { endTime } = getStartTime(incident.endTime);
  const statusId =
    AssistanceStatus.find((_) => _.name === incident?.assistanceStatus)?.id ??
    -99;
  return {
    id: incident.id,
    
    originalEntryTime: startTime,
    originalEndTime: endTime,

    partnerId: incident.partner.id,
    checkTimeId: incident.assistanceCheck?.id ?? -99,
    checkoutId: incident.assistanceCheckout?.id ?? -99,

    fakeTimeEntry: "",
    fakeTimeOut: "",

    // reasonToModify: "",
    reasonToModify: incident.observations ?? "",
    minutesWorked: 0,
    requiredWorkedTime: getRequiredWorkedTime(endTime, startTime),
    status: statusId,
  };
};
