import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { toCamelCase } from "../../../../hooks/toCameCase.tsx";
import { readBranches } from "../../../../services/branch.service.ts";
import Branch from "../../../../models/Branch.ts";
import { setQuery } from "../../../../store/slices/inasistenciasSlice.ts";

const FilterIniasistencias = ({ id }) => {
  const dispatch = useDispatch();

  const initialValues = {
    checkBranch: "",
    dateStart: "",
    dateEnd: "",
    shift: "",
    search: "",
  };

  const [timer, setTimer] = useState<any>(null);
  const [filters, setFilters] = useState(initialValues);
  const [showPills, setShowPills] = useState(false);
  const [branches, setBranches] = useState<Branch[]>([]);

  const maxFilters = 5;

  const fetchDependencies = async () => {
    const branches = await readBranches();
    setBranches(branches);
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        updateFilters({ search: newValue });
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const updatedFilters = { ...filters, ...params };
    const selected = selectedFilters(updatedFilters);

    if (selected.length > maxFilters) {
      alert(`No puedes seleccionar más de ${maxFilters} filtros.`);
      return;
    }

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(filter.value)}`
      )
      .join("&");

    setFilters(updatedFilters);
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let keyName = "";
        switch (key) {
          case "search":
            keyName = "search";
            break;
          case "checkBranch":
            keyName = "checkBranch";
            value = branches.find((branch) => branch.id === Number(value))?.name || value;
            break;
          case "shift":
            keyName = "shift";
            break;
          case "dateStart":
            keyName = "dateStart";
            break;
          case "dateEnd":
            keyName = "dateEnd";
            break;
          default:
            keyName = key;
            break;
        }
        selected.push({ key, value, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const selected = selectedFilters(updatedFilters);

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(
            filter.value
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
    setShowPills(selected.length > 0);
  };

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          {
            !id &&
            <>
              <label>Sucursal de chequeo:</label>
              <select
                className="form-select mt-1"
                name="checkBranch"
                onChange={(e) => setFilters({ ...filters, checkBranch: e.target.value })}
                value={filters.checkBranch}
              >
                <option value={"all"}>Todos</option>
                {branches.map((branch: any) => (
                  <option key={branch.id} value={branch.id}>{branch.name}</option>
                ))}
              </select>
            </>
          }

          <label>Turno:</label>
          <select
            className="form-select mt-1"
            name="shift"
            onChange={(e) => setFilters({ ...filters, shift: e.target.value })}
            value={filters.shift}
          >
            <option value={"all"}>Todos</option>
            <option value={"Matutino"}>Matutino</option>
            <option value={"Intermedio"}>Intermedio</option>
            <option value={"Vespertino"}>Vespertino</option>
          </select>
          <label>Fecha inicio:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setFilters({ ...filters, dateStart: e.target.value })}
            value={filters.dateStart}
          />
          <label>Fecha fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setFilters({ ...filters, dateEnd: e.target.value })}
            value={filters.dateEnd}
          />
          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    checkBranch: "all",
                    dateStart: "",
                    dateEnd: "",
                    shift: "all",
                    search: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={handleSearchChange}
              value={filters.search}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters).map((filter: any) => (
            <div
              key={filter.key}
              className="badge bg-primary text-white fs-6 me-2 position-relative pe-4"
            >
              {toCamelCase(filter.keyName)}
              &nbsp;:&nbsp;
              {filter.value}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute end-0 top-50 translate-middle"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FilterIniasistencias;
