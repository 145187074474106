import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { setPage, setLimit } from "../../store/slices/colaboradoresSlice.ts";

const PaginationColaboradores = () => {
  const dispatch = useDispatch();
  const { page, limit, colaboradores, total } = useSelector(
    (state: RootState) => state.colaboradores
  );

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(event.target.value);
    dispatch(setLimit(newLimit));
    dispatch(setPage(0));
  };

  const startRecord = page * limit + 1;
  const endRecord = page * limit + colaboradores.length;

  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Mostrando {startRecord} - {endRecord} de {total} usuarios
      </div>
      <div className="d-flex align-items-center">
        <span className="me-2">Mostrar:</span>
        <select
          className="form-select me-3"
          value={limit}
          onChange={handleLimitChange}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li className={`page-item ${page === 0 && "disabled"} `}>
          <a
            href="#/"
            className="page-link"
            onClick={() => page > 0 && dispatch(setPage(page - 1))}
          >
            Anterior
          </a>
        </li>

        <li className={`page-item ${endRecord >= total && "disabled"} `}>
          <a
            href="#/"
            className="page-link"
            onClick={() =>
              endRecord < total && dispatch(setPage(page + 1))
            }
          >
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PaginationColaboradores;
