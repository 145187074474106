import React, { useEffect } from "react";
import {
  AssistanceCheck,
  Incident,
} from "../../../../models/IncidentRecord.ts";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import {
  setCurrentRecord,
  setLoading,
  setShowModal,
  setTitle,
} from "../../../../store/slices/incidentSlice.ts";
import PartnerDetailIncident from "./PartnerDetailIncident.tsx";
import { useFormik } from "formik";
import {
  IncidentReason,
  IncidentReasonSchema,
} from "../../../../validation/schemas/IncidentReasonSchema.ts";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";
import PartnerPhoto from "../../../PartnerPhoto.tsx";
import { Partner } from "../../../../models/Partner.ts";
import { useParams } from "react-router-dom";
import { notification } from "../../../../utils/Notifications.tsx";
import { saveSchedule } from "../../../../services/assistanceCheck.service.ts";

const RowNoSchedule = ({
  partner,
  records,
}: {
  partner: Partner;
  records: AssistanceCheck[];
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  let id;

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const dispatch = useDispatch();
  // replacementOf
  const { refreshFunction, date } = useSelector(
    (state: RootState) => state.incident
  );

  const [start] = React.useState(
    records.filter((r) => r.partner.id === partner.id).shift()
  );

  const [end] = React.useState(
    records
      .filter((r) => r.partner.id === partner.id && r.id !== start?.id)
      .pop()
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IncidentReason,
    validationSchema: IncidentReasonSchema,
    onSubmit: async (values) => {
      const obj = {
        partnerId: partner.id,
        startTime: start?.id ?? -99,
        endTime: end?.id ?? -99,
        exactDate: date.format("YYYY-MM-DD"),
        reason: values.reason,
        branchId: Number(id),
      };

      try {
        const schedule = await saveSchedule(obj);
        console.log(schedule);
        notification("", "Incidencia guardada", "success");
      } catch (error) {
        notification(
          "",
          error?.message ?? "Ocurrió un error al actualizar la incidencia",
          "danger"
        );
      } finally {
        await dispatch(refreshFunction());
        dispatch(setLoading(false));
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [partner]);

  return (
    <React.Fragment>
      <tr className="text-center">
        <td>{partner.id}</td>
        <td>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <center>
                <PartnerPhoto curp={partner.person.curp} />
              </center>
              <span className="mt-2">
                {partner.person.firstName} {partner.person.lastName}{" "}
                {partner.person.secondLastName}
              </span>
              <span className="text-secondary">
                {partner.candidate.vacancy.position.name} -{" "}
                {partner.candidate.shift.name}
              </span>
            </div>
          </div>
        </td>
        {/* Cambiar cuando sea remplazo la hora de entrada es diferente aqui
        {selectButton === "Remplazos" && record.replacementOf ? (
          <React.Fragment>
            <td>{record.entryTime ?? record.startTime.split(" - ")[0]}</td>
            <td>{record.departureTime ?? record.endTime.split(" - ")[1]}</td>
          </React.Fragment>
        ) : (
          <React.Fragment>
          <td>{record.startTime.split(" - ")[0]}</td>
          <td>{record.endTime.split(" - ")[1]}</td>
          </React.Fragment>
          )} */}
        {/* Mostrar su registro mas temprano y mas tare entrada y salida */}
        <td>-</td>
        <td>-</td>
        <>
          <td>{start ? start.checkTime : "Sin información"}</td>
          <td>{end ? end.checkTime : "Sin información"}</td>
        </>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p className="w-100 mt-1 fs-13px fw-800 bg">No programado</p>
            <CustomTextArea
              formik={formik}
              field="reason"
              label={""}
              labelRequired={false}
            />
          </div>
        </td>
        <td>
          {["Guardar"].map((status, statusIndex) => {
            const iconClass = getStatusIconClass(status);
            return (
              <Tooltip key={statusIndex} title={status} arrow>
                <button
                  type="button"
                  className="btn p-0"
                  onClick={() => formik.handleSubmit()}
                >
                  <i className={`${iconClass} fs-1 mx-2`}></i>
                </button>
              </Tooltip>
            );
          })}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default RowNoSchedule;

const getStatusIconClass = (status: string) => {
  switch (status) {
    case "Asistencia":
      return "fa-solid fa-circle-check text-success";
    case "Fuera de horario":
      return "fa-solid fa-circle-minus text-secondary";
    case "Retardo":
      return "fa-solid fa-circle-xmark text-danger";
    case "Sin registro":
      return "fa-solid fa-circle-question text-warning";
    case "Editar":
      return "fa-solid fa-pen-to-square text-warning";
    case "Guardar":
      return "fa-solid fa-floppy-disk text-success";
    default:
      return "";
  }
};
