import React from "react";
import FooterChili from "../../components/FooterChili.jsx";
import Loader from "../../components/Loader.tsx";
import Title from "../../components/Title.tsx";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "../../utils/Notifications.tsx";
import { getUser, updateUser } from "../../services/user.service.ts";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import UserForm from "../../components/forms/UserForm.tsx";
import { useFormik } from "formik";
import { UserUpdateSchema } from "../../validation/schemas/UserSchema.ts";
import { IUser, UserFill, UserInitial } from "../../interfaces/formik/IUser.ts";
import Button from "../../components/Button.tsx";
import Layout from "../../components/Layout.tsx";

const EditUser = () => {
  const { id } = useParams();
  const context = useAppSettings();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [initialValues, setInitialValues] = React.useState<IUser>(UserInitial);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserUpdateSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          name: values.name,
          lastName: values.last_name,
          secondLastName: values.second_last_name,
          phone: values.phone,
          status: values.status,
          email: values.email,
          password: values.password,
          idRol: Number(values.id_rol),
          ...(values.id_branch && { idBranch: Number(values.id_branch) }),
        };
        console.log(body);
        await updateUser(Number(id), body);
        notification("Éxito!", "Usuario creado correctamente", "success");
        navigate("/usuarios");
      } catch (error) {
        notification("Error :(", error.message, "danger");
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchUser = async () => {
    if (!id) return navigate("/usuarios");
    try {
      const response = await getUser(Number(id));
      console.log(response);

      setInitialValues(UserFill(response));
    } catch (error) {
      notification("Error :(", error.message, "danger");
      setError("No existe un usuario con ese ID");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Usuarios"
        basePath="/usuarios"
        activeTitle="Editar usuario"
        title="Editar usuario"
      />
      <div className="card border-0 m-4 rounded">
        {error ? (
          <div className="alert alert-danger" role="alert">
            {error}&nbsp;
            <i className="fa-solid fa-triangle-exclamation"></i>
          </div>
        ) : (
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Editar los datos del usuario
            </PanelHeader>
            <PanelBody>
              <UserForm formik={formik} />
            </PanelBody>
          </Panel>
        )}
      </div>

      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/usuarios")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
