import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { useDispatch } from "react-redux";
import { resetState } from "../../store/slices/badgesSlice.ts";
import BadgesComponent from "./BadgesComponent.tsx";

const Badges = () => {
  useAppSettings();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Layout>
      <Title
        baseTitle="Configuración"
        activeTitle="Inisignias"
        basePath="/configuracion"
        title="Insignias"
      />
      <BadgesComponent />
    </Layout>
  );
};

export default Badges;
