import React from "react";
import { useParams } from "react-router-dom";
import ChipStatus from "./ChipStatus.tsx";
import { getCurrencyFormat } from "../../utils/index.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import EmptyImage from "../../assets/img/ic_content.svg";

const ApplicationListReinstatements = ({ applications, size = "lg" }) => {
  const { curp } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);

  const filteredApplications = applications?.candidate?.filter(application => 
    application.application
  );

  return (
    <div className="col-xl-8" id="bsSpyContent">
      <div id="Aplications" className="mb-4 pb-3">
        {size === "lg" && (
          <React.Fragment>
            <h4 className="d-flex align-items-center mb-2">
              {/* SVG del encabezado */}
              Aplicaciones
            </h4>
            <p>
              Aquí puedes encontrar todas las postulaciones que se han realizado
              con la CURP {curp}.
            </p>
          </React.Fragment>
        )}
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            {filteredApplications?.map((application, index) => {
              const dateTimeString = `${application.application.applicationDate} ${application.application.applicationTime}`;
              const date = parseISO(dateTimeString);
              const formattedDateTime = format(
                date,
                "dd 'de' MMMM yyyy, HH:mm 'hrs'",
                { locale: es }
              );

              return (
                <div
                  className="list-group-item d-flex align-items-center"
                  key={index}
                >
                  <div className="flex-fill">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: 15 }}>
                          {application.application.vacancy.position.name}
                        </span>
                        <ChipStatus
                          status={
                            application.application.applicationStatus
                              .name as any
                          }
                        />
                      </div>
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        en {application.application.vacancy.branch.name}
                      </div>
                      {user?.rol === "Administrador" && (
                        <div style={{ fontSize: 14, color: "#6c757d" }}>
                          Expectativa salarial:{" "}
                          {getCurrencyFormat(
                            application.application.monthlySalary
                          )}
                        </div>
                      )}
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        Cita: {formattedDateTime}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {filteredApplications?.length === 0 && (
              <div className="py-4">
                <div className="d-flex">
                  <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                </div>
                <h5 className="text-secondary text-center fs-20px">
                  No existen registros de reincorporación aún
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationListReinstatements;
