import React from "react";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import Loader from "../Loader.tsx";
import SalaryIMSSSchema from "../../validation/schemas/SalaryIMSS.ts";
import { notification } from "../../utils/Notifications.tsx";
import { fetchPartner, updateIMSSSalary } from "../../services/partner.service.ts";

const EditSalary = ({ closeFn, colaboradorId, refetch }) => {
  const [colaborador, setColaborador] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  const formik = useFormik({
    initialValues: { salaryIMSS: null },
    validationSchema: SalaryIMSSSchema,
    onSubmit: async (values) => {
      try {
        await updateIMSSSalary(colaborador.id, values);
        if(refetch) {await refetch();}
        notification("", "Salario IMSS actualizado correctamente", "success");
      } catch (error) {
        console.error(error);
        notification("", "Ocurrió un error al actualizar el salario IMSS", 'danger');
      } finally {
        closeFn();
      }
    },
  });

  const fetchColaborador = async () => {
    try {
      const response = await fetchPartner(colaboradorId);
      setColaborador(response);
      formik.setValues({ salaryIMSS: response.candidate.monthlySalaryIMSS });
    } catch (error) {
      console.error(error);
      notification("error", "Ocurrió un error al cargar el colaborador", 'danger');
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchColaborador();
  }, []);

  if(!colaborador) return <Loader isLoading={loading}/>;

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        <CustomInput
          formik={formik}
          field="salaryIMSS"
          label="Salario IMSS"
          placeholder="Salario IMSS"
          sm={8}
          smLabel={4}
          type="number"
        />
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-azul"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Aceptar
        </button>
      </div>
    </React.Fragment>
  );
};

export default EditSalary;
