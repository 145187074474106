import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  resetState,
  setBadges,
  setBadgesList,
  setLoading,
  setTotal,
} from "../../store/slices/badgesSlice.ts";
import { paginateBadges, readBadges } from "../../services/badges.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import TableBadges from "./TableBadges.tsx";
import FilterPlantillas from "../../components/plantilla/FilterPlantilla.tsx";
import PaginationPlantillas from "../../components/plantilla/PaginationPlantilla.tsx";
import PaginationBadges from "./PaginationBadges.tsx";
import FilterBadges from "./FilterBadges.tsx";

type Props = {};

const BadgesComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { page, limit, param, fetch, query } = useSelector(
    (state: RootState) => state.badges
  ) || { fetch: false };

  const fetchBadges = async () => {
    dispatch(setLoading(true));
    console.log("fetchBadges");
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&limit=${limit}&offset=${offset}`;

      const response = await paginateBadges(queryToSend);
      dispatch(setBadges(response.badges));
      dispatch(setTotal(response.total));
    } catch (error) {
      console.log("error", error);
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const badgesForlist = await readBadges();
      dispatch(setBadgesList(badgesForlist));
    } catch (error) {
      notification("error", "Error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchBadges();
    loadDependencies();
  }, [page, limit, param, fetch, query]);

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <>
      <div className="tab" id="plantillasTab">
        <div className="bg-white  border-0 h-100 m-4">
          <div className="tab-content p-3">
            <FilterBadges />
            <TableBadges reloadPage={fetchBadges} />
            <PaginationBadges />
          </div>
        </div>
      </div>
    </>
  );
};

export default BadgesComponent;
