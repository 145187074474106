import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  selectTab: "",
  records: [],
  loading: true,
  error: "",
  showModal: false,
  modalType: "",
  branchSelected: -1,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setSelectTab: (state, action) => {
      state.selectTab = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setShowModal: (
      state,
      action: PayloadAction<{
        showModal: boolean;
        modalType?: string;
        branchSelected?: number;
      }>
    ) => {
      state.showModal = action.payload.showModal;
      state.modalType = action.payload.modalType || "";
      state.branchSelected = action.payload.branchSelected || -1;
    },
  },
});

export const { setSelectTab, setRecords, setLoading, setError, setShowModal } =
  reportSlice.actions;

export default reportSlice.reducer;
