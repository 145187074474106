import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch } from "react-redux";
import { IDeleteBadgeToPartner } from "../../interfaces/others/IDeleteBadge.ts";
import {
  deleteBadge,
  removeBadgeFromPartner,
} from "../../services/badges.service.ts";
import { setFetch, setFetchRemove } from "../../store/slices/badgesSlice.ts";

const DeleteBadgeToPartner: React.FC<IDeleteBadgeToPartner> = ({
  name,
  id,
  idPartner,
  closeFn,
}) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await removeBadgeFromPartner(id, idPartner);
      notification("", `Insignia ${name} eliminado correctamente`, "success");
    } catch (error) {
      notification(
        "Atención",
        `Ocurrio un error al tratar de eliminar la insignia ${name}`,
        "warning"
      );
    } finally {
      dispatch(setFetchRemove(true));
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas eliminar la insignia {name} del colaborador
        ?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => handleDelete()}>
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteBadgeToPartner;
