import React from "react";

const Divider = ({ color = "#ccc" }: { color?: string }) => {
  return (
    <div className="row">
      <div
        className="col-md-12"
        style={{ borderBottom: `1px solid ${color}`, margin: "10px 0" }}
      />
    </div>
  );
};

export default Divider;
