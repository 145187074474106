import React from "react";
import hljs from 'highlight.js';
import ReactQuill from "react-quill";
// @mui
import { alpha } from "@mui/material/styles";
//

import "react-quill/dist/quill.snow.css";
import { EditorProps } from "./editor/TypesEditor";
import { StyledEditor } from "./editor/StylesEditor.tsx";

// ----------------------------------------------------------------------

export default function Editor({
  id = "minimal-quill",
  error,
  value,
  simple = false,
  helperText,
  sx,
  ...other
}: EditorProps) {
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["link"],
        ["clean"],
        [{ color: [] }],
      ],
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };  

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
    "color",
  ];

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            "& .ql-editor": {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.8),
            },
          }),
          ...sx,
        }}
      >
        <ReactQuill
          modules={modules}
          formats={formats}
          value={value}
          placeholder="Escribe algo aqui..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
