import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { Partner } from "../../models/LeaderTemplate";

const defaultPositions = [
  { name: "Líder de Sucursal SUC" },
  { name: "Líder de Servicio SUC", shift: "Matutino" },
  { name: "Líder de Servicio SUC", shift: "Vespertino" },
  { name: "Auxiliar Administrativo SUC", shift: "Matutino" },
  { name: "Auxiliar Administrativo SUC", shift: "Vespertino" },
];

export const configuration = [
  {
    branch: "Universidad",
    positions: defaultPositions,
  },
  {
    branch: "Macroplaza",
    positions: defaultPositions,
  },
  {
    branch: "Zócalo",
    positions: defaultPositions,
  },
  {
    branch: "Plaza Bella",
    positions: defaultPositions,
  },
  {
    branch: "Xoxocotlan",
    positions: defaultPositions,
  },
  {
    branch: "Reforma",
    positions: defaultPositions,
  },
  {
    branch: "Formación Integral y Cultura",
    positions: [
      { name: "Chief Learning Officer CULTURA" },
      { name: "Jefe de Cultura CULTURA" },
      { name: "Entrenador Supremo CULTURA" },
    ],
  },
  {
    branch: "Operación",
    positions: [
      { name: "Supervisor de Operaciones en Servicio OPERACIÓN" },
      { name: "Supervisor de Operaciones en Cocina OPERACIÓN" },
    ],
  },
  {
    branch: "Administración y Finanzas",
    positions: [
      { name: "Director de Finanzas ADMON" },
      { name: "Director General ADMON" },
    ],
  },
  {
    branch: "Marketing",
    positions: [
      { name: "Jefe de Marketing MKT" },
      { name: "Director Comercial MKT" },
    ],
  },
  {
    branch: "Tecnología",
    positions: [
      { name: "Director de Desarrollo Tecnológico TECNOLOGÍA" },
      { name: "Jefe de Soporte TECNOLOGÍA" },
    ],
  },
  {
    branch: "Call Center",
    positions: [{ name: "Líder de Call Center" }],
  },

  {
    branch: "Auditoria",
    positions: [{ name: "Jefe de Auditoria y Procesos AUDITORIA" }],
  },
  {
    branch: "Comisariato (Cedis)",
    positions: [
      { name: "Director Cadena de Suministros COMISARIATO" },
      { name: "Jefe de Inteligencia Comercial COMISARIATO" },
      { name: "Auxiliar de Distribución COMISARIATO" },
      { name: "Jefe de Distribución COMISARIATO" },
      { name: "Jefe de Almacén COMISARIATO" },
      { name: "Jefe de Compras COMISARIATO" },
      { name: "Jefe de Producción COMISARIATO" },
    ],
  },
  {
    branch: "Talento Humano",
    positions: [
      { name: "Líder Administrativo de Talento Humano TH" },
      { name: "Líder de Reclutamiento TH" },
      { name: "Jefe de Talento Humano TH" },
    ],
  },
  {
    branch: "Guajaqueñito",
    positions: [{ name: "Líder de Sucursal SUC" }],
  },
];

export const getPartners = (
  records: Partner[]
): {
  name: string;
  phone: string;
}[] => {
  const partners = records.map(({ person }) => {
    return {
      name: toCamelCase(
        `${person.firstName} ${person.lastName} ${person.secondLastName}`
      ),
      phone: person.phone,
    };
  });

  return partners;
};
