import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppSettings } from "../hooks/useAppSettings.tsx";
import FooterChili from "../components/FooterChili.jsx";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "./../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Application } from "../models/Application.ts";
import { notification } from "../utils/Notifications.tsx";
import { getApplicationsByBranch } from "../services/application.service.ts";
import { CatalogosService } from "../services/catalogos.service.js";
import ChipStatus from "../components/history/ChipStatus.tsx";
import S3Service from "../services/s3.service.ts";
import moment from "moment";
import "moment/locale/es";
import Layout from "../components/Layout.tsx";
import { Candidate } from "../models/Candidate.ts";
import AdminDashboard from "./dashboard/AdminDashboard.tsx";
import { toCamelCase } from "../hooks/toCameCase.tsx";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ChangePosition } from "../models/ChangePosition.ts";
import { checkAllPending } from "../services/changePosition.service.ts";
import ChangePositionRow from "../components/dashboard/ChangePositionRow.tsx";
moment.locale("es");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const Dashboard = () => {
  const context = useAppSettings();
  const { user } = useSelector((state: RootState) => state.auth);
  const [applications, setApplications] = React.useState<Candidate[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [changePositionPending, setChangePositionPending] = React.useState<
    ChangePosition[]
  >([]);
  const navigate = useNavigate();

  const fetchApplications = async (branch: string) => {
    try {
      const response = (await getApplicationsByBranch(branch)) as Candidate[];
      const applicationTemp: Candidate[] = [];
      for (const item of response) {
        const profileUrl = await S3Service.getFile(
          "PERFIL_" + item?.person?.curp
        );
        item.person.image = profileUrl?.url;

        applicationTemp.push(item);
      }
      setApplications(applicationTemp);

      const aplications = await checkAllPending(user.rol === 'Lider de sucursal' ? `branch=${user.branch}` : '')
      setChangePositionPending(aplications);
    } catch (error) {
      notification("error", error.message, "danger");
    }
  };

  const formatDate = (date: string = String(new Date())) => {
    const formattedDate = moment(date).format("MMMM D [del] YYYY");
    return formattedDate.replace(/^(.)/, (match) => match.toUpperCase());
  };

  React.useEffect(() => {
    if (user.branch) {
      fetchApplications(user.branch);
    } else if (user.rol === "Administrador") {
      fetchApplications("all");
    }
  }, [user.manager]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // || user.rol === "Administrador"
  if (user.rol === "Lider de sucursal") {
    // return <AdminDashboard rol={user.rol} />;
    return (
      <Layout>
        <div style={{ padding: "20px" }}>
          <ol className="breadcrumb float-xl-end">
            <li className="breadcrumb-item">
              <Link to="/">Inicio</Link>
            </li>
          </ol>
          <h1 className="page-header">
            Tablero <small>Resumen de los últimos eventos</small>
          </h1>

          <div>
            <Panel>
              <PanelHeader noButton={true}>Postulaciones</PanelHeader>
              <PanelBody>
                <Box sx={{ width: '100%' }}>
                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Solicitudes" {...a11yProps(0)} />
                      <Tab label="Cambios de posición" {...a11yProps(1)} />
                    </Tabs>
                  </Box> */}
                  <PerfectScrollbar
                    // className="h-100vh"
                    className="h-500px"
                    options={{ suppressScrollX: true }}
                  >
                    <CustomTabPanel value={value} index={0}>
                      {applications.map((applications, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="d-flex">
                              <Link to="/" className="w-60px">
                                <img
                                  src={
                                    applications.person.image ||
                                    "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                                  }
                                  alt=""
                                  className="mw-100 rounded-pill"
                                />
                              </Link>
                              <div className="flex-1 ps-3">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h5>
                                    {
                                      toCamelCase(`
                                    ${applications.person.firstName} ${applications.person.lastName} ${applications.person.secondLastName}
                                    `)
                                    }
                                  </h5>{" "}
                                  <div>
                                    {applications?.vacancy?.position?.name}
                                    &nbsp;&nbsp; &nbsp;
                                    <ChipStatus
                                      status={
                                        applications.application.applicationStatus
                                          .name as any
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>
                                    Postulación:{" "}
                                    {formatDate(applications.createdAt)}
                                  </p>
                                  <div>
                                    <button
                                      className="btn btn-primary mt-2"
                                      onClick={() =>
                                        navigate(
                                          "/solicitudes/aprobar/" +
                                          applications.application.id
                                        )
                                      }
                                    >
                                      Revisar postulación
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="bg-gray-500" />
                          </React.Fragment>
                        );
                      })}
                      {
                        applications.length === 0 && (
                          <div className="text-center">
                            <h4>No hay solicitudes pendientes</h4>
                          </div>
                        )
                      }
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      {changePositionPending.map((changePosition, index) => (
                        <React.Fragment key={index}>
                          <ChangePositionRow changePosition={changePosition} />
                        </React.Fragment>
                      ))}
                      {changePositionPending.length === 0 && (
                        <div className="text-center">
                          <h4>No hay cambios de posición pendientes</h4>
                        </div>
                      )}
                    </CustomTabPanel>
                  </PerfectScrollbar>
                </Box>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </Layout >
    );
  }

  if (
    user.rol === "Administrador" ||
    user.rol === "Contador" ||
    user.rol === "Talento humano" ||
    user.rol === "Reclutador"
  ) {
    return <AdminDashboard rol={user.rol} />;
  }

  if (user.rol === "Reclutamiento") {
    return <Navigate to="/vacantes" />;
  }

  if (user.rol === "Trabajador") {
    return <Navigate to={`mi-expediente`} />;
  }

  if (user.rol === "Reclutador/Archivos") {
    return <Navigate to={`solicitudes`} />;
  }

  if (user.rol === "Reloj checador") {
    return <Navigate to={`reloj-checador`} />;
  }

  if (user.rol === "Capacitación y Cultura") {
    return <Navigate to={`birthdates`} />;
  }
};

export default Dashboard;
