import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import EmptyImage from '../../assets/img/ic_content.svg';
import { setDefaultSort, setPage, setSortBy } from '../../store/slices/branchTemplateSlice.ts';
import { fixedHeadStyle, fixedStyle } from '../../utils/constanst.js';

type Props = {}

const TablePlantilla = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { templates, sortBy, defaultSort  } = useSelector((state: RootState) => state.branchTemplates);

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead  style={{ ...defaultStyle }}>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
          <th style={{ ...fixedStyle } as React.CSSProperties} onClick={() => setSort("id")}> ID {sortBy === "id" && getIconSort()}</th>
          <th onClick={() => setSort("name")}> Puesto {sortBy === "name" && getIconSort()}</th>
          <th colSpan={35}></th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {templates && templates.length > 0 ? (
            templates.map((item, index) => (
              <tr key={index}>
                <td style={{ ...fixedStyle} as React.CSSProperties} >{item.id}</td>
                <td>{item.name}</td>
                <td colSpan={35}></td>
                <td>
                  <a
                    className="btn btn-rojo m-2"
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-circle-xmark"></i>
                  </a>
                  <a
                    className="btn btn-primary m-2"
                    onClick={() => {
                      navigate(`editar/${item.id}`);
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={38}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no existen plantillas</h5>
                  <h5 className='text-center text-secondary fw-400 fs-15px'>Prueba añadiendo una en</h5>
                  <h5 className='text-center text-secondary fw-400'>el botón de 'Agregar plantilla'</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TablePlantilla