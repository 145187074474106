import httpClient from "./httpClient";

const prefix = "/free-days";

export async function fetchFreeDays() {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function createFreeDay(data) {
  return (await httpClient.post(`${prefix}`, data)).data;
}

export async function updateFreeDay(id, data) {
  return (await httpClient.patch(`${prefix}/${id}`, data)).data;
}

export async function removeFreeDay(id) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function fetchFreeDaysByPartnerId(partnerId) {
  return (await httpClient.get(`${prefix}/partner/specific/${partnerId}`)).data;
}

export async function fetchLastFreeDayByPartnerId(partnerId) {
  return (await httpClient.get(`${prefix}/partner/last/${partnerId}`)).data;
}

export async function fetchFreeDaysByDateRangeAndPartnerId(dateRange, partnerId) {
  return (await httpClient.get(`${prefix}/partner/daterange/${partnerId}/${dateRange}`)).data;
}
