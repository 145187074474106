import React, { useEffect } from 'react';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import moment from 'moment';
import Title from '../../Title.tsx';
import { Panel, PanelBody, PanelHeader } from '../../panel/panel.jsx';
import { useFormik } from 'formik';
import CustomAutocomplete from '../../formik/CustomAutocomplete.tsx';
import { fetchPartners } from '../../../services/partner.service.ts';
import CustomInput from '../../formik/CustomInput.tsx';
import { findAssistanceByPartnerAndDate, findChecksByPartnerAndDate } from '../../../services/assistanceCheck.service.ts';
import TableAssistances from './componentes/TableAssistances.tsx';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index.ts';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableChecks from './componentes/TableChecks.tsx';

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Assistances = (props: Props) => {
  useAppSettings();
  let id

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const [partners, setPartners] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [checkList, setCheckList] = React.useState<any[]>([]);
  const [checkRecords, setCheckRecords] = React.useState<any[]>([]);

  const fetchDependencies = async () => {
    const partnersData = await fetchPartners();

    const partnerList = partnersData.results.map((partner) => ({
      id: partner.id,
      name: `${partner.person.firstName} ${partner.person.lastName}`,
    }));

    setPartners(partnerList);

    if (id) {
      const partner = partnersData.results.find((partner) => partner.id === parseInt(id));
      if (partner) {
        formik.setFieldValue('partner', partner.id);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const formik = useFormik({
    initialValues: {
      checkDate: moment().format('YYYY-MM-DD'),
      partner: '',
    },
    onSubmit: async (values) => {
      if (!values.partner) {
        setCheckList([]);
        return;
      }

      setLoading(true);
      const checkList = await findAssistanceByPartnerAndDate(values.partner, values.checkDate);
      setCheckList(checkList);

      const checkRecords = await findChecksByPartnerAndDate(values.partner, values.checkDate);
      setCheckRecords(checkRecords);

      setLoading(false);
    },
  });

  useEffect(() => {
    if (formik.values.partner && id) {
      formik.submitForm();
    }
  }, [formik.values.partner]);

  return (
    <Layout loading={loading}>
      <Title baseTitle="Asistencias" basePath="/sucursales/asistencias"  title="Asistencias" />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Buscar registros por usuario
          </PanelHeader>
          <PanelBody>
            <CustomAutocomplete
              formik={formik}
              field="partner"
              label="Colaborador"
              list={partners}
              sm={3}
              onChange={() => formik.submitForm()}
            />
            <CustomInput
              formik={formik}
              field="checkDate"
              label="Día"
              type="date"
              sm={3}
              required={false}
              onChange={() => formik.submitForm()}
            />
          </PanelBody>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '20px', p: 2 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Horarios" {...a11yProps(0)} />
              <Tab label="Registros" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TableAssistances refetch={() => formik.submitForm()} checkList={checkList} setCheckList={setCheckList} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TableChecks refetch={() => formik.submitForm()} checkList={checkRecords} />
          </CustomTabPanel>
        </Panel>
      </div>
    </Layout>
  );
};

export default Assistances;
