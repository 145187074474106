import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { PartnerElement } from "../../../models/PartnersInBranch";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import { formatDate, isValidImageUrl } from "../../../utils/index.ts";
import ButtonLink from "../../ButtonLink.tsx";

const RowPartnetDocuments = ({ data }: { data: PartnerElement }) => {
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState({
    ine: false,
    perfil: false,
    solicitud: false,
    domicilio: false,
  });

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <i className="fa-solid fa-sort-up" />
            ) : (
              <i className="fa-solid fa-sort-down" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {toCamelCase(
            `${data.partner.person.firstName} ${data.partner.person.lastName} ${data.partner.person.secondLastName}`
          )}
        </TableCell>
        <TableCell>{data.partner.candidate.vacancy.position.name}</TableCell>
        <TableCell>{formatDate(String(data.recruitmentDate))}</TableCell>

        <TableCell align="center">
          {data.hasCredentials ? (
            <i
              className="fa-solid fa-thumbs-up"
              style={{ fontSize: "24px", color: "#36e060" }}
            />
          ) : (
            <i
              className="fa-solid fa-thumbs-down"
              style={{ fontSize: "24px", color: "#ff5766" }}
            />
          )}
        </TableCell>
        <TableCell align="center">
          {data.documents.missingDocuments.missingDocumentsInmediate.length +
            data.documents.missingDocuments.missingDocumentsNoInmediate.length }
        </TableCell>
        <TableCell align="center">
          <ButtonLink
            className="btn btn-primary m-2"
            text={<i className="fa-solid fa-eye"></i>}
            to={`/colaboradores/detalles/${data.partner?.id}/`}
            type="button"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Documentos pendientes por subir
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="center">¿Es inmediato?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {data.documents.missingDocuments.missingDocumentsInmediate.map(
                    (file) => (
                      <TableRow key={file.id}>
                        <TableCell component="th" scope="row">
                          {file.name}
                        </TableCell>
                        <TableCell align="center">
                          <i
                            className="fa-solid fa-circle-check"
                            style={{ fontSize: "24px", color: "#36e060" }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}

                  {data.documents.missingDocuments.missingDocumentsNoInmediate.map(
                    (file) => (
                      <TableRow key={file.name}>
                        <TableCell component="th" scope="row">
                          {file.name}
                        </TableCell>
                        <TableCell align="center">
                          <i
                            className="fa-solid fa-circle-xmark"
                            style={{ fontSize: "24px", color: "#ff5766" }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RowPartnetDocuments;

const RowDocument = ({
  name,
  icon,
}: {
  name: string;
  icon: React.ReactNode;
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="center">{icon}</TableCell>
    </TableRow>
  );
};
