import React from "react";
import PartnerPhoto from "../../../PartnerPhoto.tsx";
import { Incident } from "../../../../models/IncidentRecord.ts";

const PartnerDetailIncident = ({
  record,
  replacement = false,
  original = false,
}: {
  record: Incident;
  replacement?: boolean;
  original?: boolean;
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {replacement && <span className="text-success">Remplazo</span>}
      {original && <span className="text-success">Original</span>}
      <center>
        <PartnerPhoto curp={record.partner.person.curp} />
      </center>
      <span className="mt-2">
        {record.partner.person.firstName} {record.partner.person.lastName}{" "}
        {record.partner.person.secondLastName}
      </span>
      <span className="text-secondary">
        {record.positionName} - {record.shift}
      </span>
    </div>
  );
};

export default PartnerDetailIncident;
