import React from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useLocation } from "react-router-dom";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../Layout.tsx";
import Title from "../Title.tsx";
import { updatePositionsOrder } from "../../services/position.service.ts";
import { notification } from "../../utils/Notifications.tsx";

const EditOrderPosition = ({ closeFn, refreshFn, setLoading }) => {
  const location = useLocation();
  const { positionsList } = location.state || { positionsList: [] };

  const [data, setData] = React.useState(
    positionsList.map((item, index) => ({
      ...item,
      originalIndex: index,
    }))
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [currentResultIndex, setCurrentResultIndex] = React.useState(-1);
  const listRef = React.useRef(null);

  const [sourceIndex, setSourceIndex] = React.useState("");
  const [destinationIndex, setDestinationIndex] = React.useState("");

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items);

    const positionsOrder = items.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    try {
      await updatePositionsOrder(positionsOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const exactMatches = data.reduce((acc, puesto, index) => {
      if (puesto.name.toLowerCase() === term) {
        acc.push(index);
      }
      return acc;
    }, []);

    const partialMatches = data.reduce((acc, puesto, index) => {
      if (
        puesto.name.toLowerCase().includes(term) &&
        puesto.name.toLowerCase() !== term
      ) {
        acc.push(index);
      }
      return acc;
    }, []);

    const results = [...exactMatches, ...partialMatches];
    setSearchResults(results);
    setCurrentResultIndex(results.length > 0 ? 0 : -1);

    if (results.length > 0) {
      scrollToItem(results[0]);
    }
  };

  const scrollToItem = (index) => {
    const itemElements = listRef.current.querySelectorAll(".draggable-item");
    itemElements.forEach((item) =>
      item.classList.remove("highlight", "active", "selected")
    );

    const itemElement = itemElements[index];
    if (itemElement) {
      itemElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      itemElement.classList.add("highlight", "active", "selected");

      setSourceIndex(index + 1);
    }
  };

  const handleNextResult = () => {
    if (searchResults.length === 0) return;
    const nextIndex = (currentResultIndex + 1) % searchResults.length;
    setCurrentResultIndex(nextIndex);
    setTimeout(() => scrollToItem(searchResults[nextIndex]), 100);
    setSourceIndex(searchResults[nextIndex] + 1);
  };

  const handlePrevResult = () => {
    if (searchResults.length === 0) return;
    const prevIndex =
      (currentResultIndex - 1 + searchResults.length) % searchResults.length;
    setCurrentResultIndex(prevIndex);
    setTimeout(() => scrollToItem(searchResults[prevIndex]), 100);
    setSourceIndex(searchResults[prevIndex] + 1);
  };

  const handleManualMove = () => {
    const fromIndex = parseInt(sourceIndex, 10) - 1;
    const toIndex = parseInt(destinationIndex, 10) - 1;

    if (
      fromIndex < 0 ||
      toIndex < 0 ||
      fromIndex >= data.length ||
      toIndex >= data.length
    ) {
      alert("Los índices están fuera de rango");
      return;
    }

    const items = Array.from(data);
    const [movedItem] = items.splice(fromIndex, 1);
    items.splice(toIndex, 0, movedItem);
    setData(items);

    const newIndex = items.findIndex((item) => item.id === movedItem.id);

    setSearchTerm(movedItem.name.toLowerCase());
    const results = items.reduce((acc, puesto, index) => {
      if (puesto.name.toLowerCase().includes(movedItem.name.toLowerCase())) {
        acc.push(index);
      }
      return acc;
    }, []);

    setSearchResults(results);
    setCurrentResultIndex(results.indexOf(newIndex));

    setTimeout(() => scrollToItem(newIndex), 100);

    const positionsOrder = items.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    try {
      updatePositionsOrder(positionsOrder);
    } catch (error) {
      notification("Error", error.message, "danger");
      console.error("Error updating order:", error);
    }

    setSourceIndex("");
    setDestinationIndex("");
  };

  const handleSelectSource = (index) => {
    setSourceIndex(index + 1);
  };

  const handleSourceIndexChange = (e) => {
    const value = e.target.value;
    setSourceIndex(value);

    const index = parseInt(value, 10) - 1;

    if (index >= 0 && index < data.length) {
      scrollToItem(index);
    }
  };

  const getArrow = (currentIndex, originalIndex) => {
    if (currentIndex < originalIndex) {
      return <span style={{ color: "green", marginLeft: "8px" }}>▲</span>;
    } else if (currentIndex > originalIndex) {
      return <span style={{ color: "red", marginLeft: "8px" }}>▼</span>;
    }
    return null;
  };

  useAppSettings();

  return (
    <Layout>
      <Title
        baseTitle="Configuración"
        basePath="/configuracion"
        activeTitle={`Orden de puestos`}
        title={`Orden de puestos`}
      />
      <div className="card border-0 m-4 rounded">
        <div className="tab-content p-3 ">
          {/* Buscador */}
          <div className="mb-2 d-flex align-items-center px-3">
            <input
              type="text"
              className="form-control me-2"
              placeholder="Buscar puesto"
              value={searchTerm}
              onChange={handleSearch}
              style={{
                borderRadius: "8px",
                fontSize: "16px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              }}
            />

            {searchResults.length > 0 && (
              <div className="d-flex">
                <button
                  className="btn btn-outline-secondary me-2"
                  onClick={handlePrevResult}
                  style={{
                    borderRadius: "8px",
                    transition: "background-color 0.2s ease",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                >
                  Anterior
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleNextResult}
                  style={{
                    borderRadius: "8px",
                    padding: "10px 15px",
                    transition: "background-color 0.2s ease",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#fca5a5")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#ef4444")
                  }
                >
                  Siguiente
                </button>
              </div>
            )}
          </div>
          {searchResults.length > 0 && (
            <div className="px-4 mb-3">
              Resultados: {currentResultIndex + 1} de {searchResults.length}
            </div>
          )}

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="positions">
              {(droppableProvider) => (
                <div
                  {...droppableProvider.droppableProps}
                  ref={(el) => {
                    droppableProvider.innerRef(el);
                    listRef.current = el;
                  }}
                  style={{ overflowY: "auto", maxHeight: "450px" }}
                  className="px-3"
                >
                  {data.map((puesto, index) => (
                    <Draggable
                      index={index}
                      key={puesto.id}
                      draggableId={puesto.id.toString()}
                    >
                      {(draggableProvider) => (
                        <div
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.draggableProps}
                          {...draggableProvider.dragHandleProps}
                          className="draggable-item"
                          onClick={() => handleSelectSource(index)}
                        >
                          <p className="bg-light border shadow-sm p-3 rounded-2 mb-2">
                            {index + 1}.{" "}
                            <span
                              className={`fw-bold ${
                                index === searchResults[currentResultIndex]
                                  ? "highlight"
                                  : ""
                              }`}
                            >
                              {puesto.name}
                            </span>
                            {getArrow(index, puesto.originalIndex)}
                          </p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvider.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="d-flex mb-3 mt-3 px-3 ">
            <input
              type="number"
              className="form-control me-2"
              placeholder="Índice de origen"
              value={sourceIndex}
              onChange={handleSourceIndexChange}
            />

            <input
              type="number"
              className="form-control me-2"
              placeholder="Índice de destino"
              value={destinationIndex}
              onChange={(e) => setDestinationIndex(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={handleManualMove}
              disabled={!sourceIndex || !destinationIndex}
            >
              Mover
            </button>
          </div>
        </div>
      </div>

      <style>
        {`
  .draggable-item.highlight {
    animation: highlight 2s ease-in-out;
    background-color: #ffd700 !important;
  }

  .draggable-item.selected {
    color: red; /* Color del texto seleccionado */
    font-weight: bold;
  }

  @keyframes highlight {
    0% { background-color: #ffd700; }
    100% { background-color: transparent; }
  }
`}
      </style>
    </Layout>
  );
};

export default EditOrderPosition;
