import React, { useEffect } from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../Layout.tsx";
import Title from "../../Title.tsx";
import EmptyImage from "../../../assets/img/ic_content.svg";
import { useNavigate, useParams } from "react-router-dom";
import { readPositionsByBranch } from "../../../services/branchSchedule.service.ts";
import { readPartnersByBranch } from "../../../services/partner.service.ts";
import moment from "moment";
import { Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {};

const ReportePlantilla = (props: Props) => {
  let id


  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [partners, setPartners] = React.useState<any[]>([]);
  const [oldPartners, setOldPartners] = React.useState<any[]>([]);
  const [type, setType] = React.useState<any>("Todos");
  const [sortBy, setSortBy] = React.useState<any>("");
  const [defaultSort, setDefaultSort] = React.useState<any>("desc");
  const [sortedData, setSortedData] = React.useState<any[]>([]);

  useAppSettings();

  const fetchDependencies = async () => {
    try {
      const positions = await readPositionsByBranch(Number(id));
      setPositions(positions);

      const partners = await readPartnersByBranch(Number(id), { positionChange: true });
      console.log(partners);
      setPartners(partners.partners);
      setOldPartners(partners.positionChanges);

      const coveredPositions = new Set(
        partners.partners.map((partner) => partner?.candidate?.vacancy?.position?.id)
      );

      const response = positions.map((position) => {
        const isCovered = coveredPositions.has(position.position.id)
        return {
          ...position,
          isCovered,
        };
      });
      handleSort("shift", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, [id]);

  const handleSort = (sort: string, data?: any) => {
    if (!data) data = sortedData;

    if (sort === sortBy) {
      setDefaultSort(defaultSort === "asc" ? "desc" : "asc");
    } else {
      setSortBy(sort);
      setDefaultSort("asc");
    }

    const sorted = [...data].sort((a, b) => {
      if (sort === "name") {
        return defaultSort === "asc"
          ? a.position.name.localeCompare(b.position.name)
          : b.position.name.localeCompare(a.position.name);
      }
      if (sort === "shift") {
        const shiftOrder = ["Matutino", "Intermedio", "Vespertino"];
        return defaultSort === "asc"
          ? shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift)
          : shiftOrder.indexOf(b.shift) - shiftOrder.indexOf(a.shift);
      }
      if (sort === "isCovered") {
        return defaultSort === "asc"
          ? a.isCovered - b.isCovered
          : b.isCovered - a.isCovered;
      }
      return 0;
    });

    setSortedData(sorted);
  };

  const getAntiguedad = (date: string) => {
    const currentDate = moment();
    const recruitmentDate = moment(date);

    if (currentDate.isSame(recruitmentDate, "day")) {
      return "0 días";
    }

    const years = currentDate.diff(recruitmentDate, "years");
    recruitmentDate.add(years, "years");
    const months = currentDate.diff(recruitmentDate, "months");
    recruitmentDate.add(months, "months");
    const days = currentDate.diff(recruitmentDate, "days");

    return `${years > 0 ? years + " años" : ""}${years > 0 && months > 0 ? ", " : ""
      }${months > 0 ? months + " meses" : ""}${(years > 0 || months > 0) && days > 0 ? ", " : ""
      }${days > 0 ? days + " días" : ""}`;
  };

  const [timer, setTimer] = React.useState<any>(null);

  const [search, setSearch] = React.useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!partners || !partners.length) return null;

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle="Plantilla de puestos"
        title="Plantilla de puestos contra colaboradores"
      />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Matutino" {...a11yProps(1)} />
              <Tab label="Intermedio" {...a11yProps(2)} />
              <Tab label="Vespertino" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <div className="p-3 input-group justify-content-between">
            <div className="flex-fill position-relative">
              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  className="form-control px-35px bg-light"
                  placeholder="Ingresa un nombre..."
                  id="name"
                  name="name"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setTimer(
                      setTimeout(() => {
                        clearTimeout(timer);
                      }, 1000)
                    );
                  }}
                  value={search}
                />
              </div>
            </div>
            <button
              className={`btn ${type === "No cubiertos" ? "btn-primary" : "btn-secondary"
                }`}
              onClick={() => setType("No cubiertos")}
            >
              No cubiertos
            </button>
            <button
              className={`btn ${type === "Cubiertos" ? "btn-primary" : "btn-secondary"
                }`}
              onClick={() => setType("Cubiertos")}
            >
              Cubiertos
            </button>
            <button
              className={`btn ${type === "Todos" ? "btn-primary" : "btn-secondary"
                }`}
              onClick={() => setType("Todos")}
            >
              Todos
            </button>
          </div>
          <div className='ps-3'>
            <h5>Simbología:</h5>
            <ul className="list-inline">
              <li className="list-inline-item">
                <span className="badge text-black" style={{ border: '1px solid #0002' }}>Puesto cubierto</span>
              </li>
              <li className="list-inline-item">
                <span className="badge text-black" style={{ backgroundColor: '#F6FB7AAA', border: '1px solid #0002' }}>Puesto no cubierto</span>
              </li>
            </ul>
          </div>
          <div className="tab-content px-3">
            <div className="table-sticky mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0">
                <thead className="sticky-thead">
                  <tr>
                    <th>Id</th>
                    <th
                      onClick={() => handleSort("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Posición
                      <i
                        className={`ms-1 fa-solid fa-sort-${sortBy === "name" && defaultSort
                          }`}
                      ></i>
                    </th>
                    <th>Colaborador</th>
                    <th
                      onClick={() => handleSort("shift")}
                      style={{ cursor: "pointer" }}
                    >
                      Turno
                      <i
                        className={`ms-1 fa-solid fa-sort-${sortBy === "shift" && defaultSort
                          }`}
                      ></i>
                    </th>
                    <th>Antigüedad</th>
                    <th>Sin cubrir desde</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {positions && positions.length > 0 ? (
                    sortedData
                      .filter(position => position.numberOfWorkers !== '0')
                      .filter((position) =>
                        value === 0
                          ? true
                          : position.shift ===
                          ["Matutino", "Intermedio", "Vespertino"][value - 1]
                      )
                      .filter((position) => {
                        const searchLower = search.toLowerCase();

                        // Si search está vacío, no aplicar ningún filtro
                        if (search === "") return true;

                        // Filtrar por nombre de la posición
                        const positionNameLower =
                          position.position.name.toLowerCase();
                        if (positionNameLower.includes(searchLower))
                          return true;

                        // Filtrar por datos del socio si existe un socio asociado
                        const partner = partners.find(
                          (partner) =>
                            partner.candidate.vacancy.position.id ===
                            position.position.id &&
                            partner.candidate.shift.name === position.shift
                        );

                        if (!partner) return false;

                        const firstNameLower =
                          partner.person.firstName.toLowerCase();
                        const lastNameLower =
                          partner.person.lastName.toLowerCase();
                        const secondLastNameLower =
                          partner.person.secondLastName.toLowerCase();

                        return (
                          firstNameLower.includes(searchLower) ||
                          lastNameLower.includes(searchLower) ||
                          secondLastNameLower.includes(searchLower)
                        );
                      })
                      .filter((position) =>
                        type === "Todos"
                          ? true
                          : type === "Cubiertos"
                            ? position.isCovered &&
                            !!partners.find(
                              (partner) =>
                                partner.candidate.vacancy.position.id ===
                                position.position.id &&
                                partner.candidate.shift.name === position.shift
                            )
                            : partners.find(
                              (partner) =>
                                !position?.isCovered ||
                                (partner.candidate.vacancy.position.id ===
                                  position.position.id &&
                                  partner.candidate.shift.name ===
                                  position.shift &&
                                  partner.partnerStatus?.id === 3)
                            )
                      )
                      .map((position, index) => {
                        let partner = partners.find(
                          (partner) =>
                            partner.candidate.vacancy.position.id ===
                            position.position.id &&
                            partner.candidate.shift.name === position.shift
                        );

                        const parnerInactive =
                          partner?.partnerStatus?.name === "Baja";

                        const anquity = partner
                          ? moment(partner?.createdAt).fromNow()
                          : moment(partner?.banDate).fromNow();

                        if (type === "Cubiertos" && parnerInactive) {
                          return <React.Fragment key={index}></React.Fragment>;
                        }
                        if (parnerInactive) {
                          partner = null;
                        }

                        const oldPartner = oldPartners.find(
                          (oldPartner) =>
                            oldPartner.oldPosition.id ===
                            position.position.id && oldPartner.approvalDate
                        )

                        const newPartner = oldPartners.find(
                          (oldPartner) =>
                            oldPartner.newPosition?.id ===
                            position.position.id && oldPartner.approvalDate
                        )

                        return (
                          <tr
                            style={{
                              backgroundColor: !partner ? "#F6FB7AAA" : "white",
                            }}
                            key={index}
                          >
                            <td>{position.id}</td>
                            <td>{position.position.name}</td>
                            <td>
                              {position?.isCovered && partner
                                ? toCamelCase(`${partner?.person.firstName} ${partner?.person.lastName}  ${partner?.person.secondLastName}`) ||
                                "Desconocido"
                                : "No cubierto"}
                            </td>
                            <td>{position.shift}</td>
                            <td>
                              {(() => {
                                const currentAntiquity = getAntiguedad(position.position.createdAt);
                                const oldAntiquity = oldPartner ? getAntiguedad(oldPartner.approvalDate) : newPartner ? getAntiguedad(newPartner.approvalDate) : currentAntiquity;
                                
                                if (partner && position?.isCovered && !parnerInactive) {
                                  return newPartner ? getAntiguedad(newPartner.approvalDate) : partner ? getAntiguedad(partner?.createdAt) || anquity : oldAntiquity;
                                }
                                
                                return ' - ';
                              })()}
                            </td>
                            <td>
                              {(() => {
                                const currentAntiquity = getAntiguedad(position.position.createdAt);
                                const oldAntiquity = oldPartner ? getAntiguedad(oldPartner.approvalDate) : newPartner ? getAntiguedad(newPartner.approvalDate) : currentAntiquity;
                                
                                if (partner && position?.isCovered && !parnerInactive) {
                                  return ' - ';
                                }
                                
                                return oldAntiquity;
                              })()}
                            </td>

                            <td>
                              <Tooltip title={`Detalles`} arrow>
                                <button
                                  className="btn btn-primary m-2"
                                  disabled={!partner || parnerInactive}
                                  onClick={() => {
                                    navigate(
                                      `/colaboradores/detalles/${partner?.id}/`
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="py-4">
                          <div className="d-flex">
                            <img
                              src={EmptyImage}
                              alt=""
                              className="mx-auto w-250px"
                            />
                          </div>
                          <h5 className="text-secondary text-center fs-20px">
                            No hay una plantilla asignada
                          </h5>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportePlantilla;
