export const DefaultBadge = {
  title: "",
  description: "",
  parentBadgeId: -99,
  parentId: -99,
};

export interface EditBadgeProperties {
  title: string;
  description: string;
  parentBadgeId: number;
}

export const FillBadge = (data: any): EditBadgeProperties | null => {
  return {
    title: data.title,
    description: data.description,
    parentBadgeId: data.parentBadge ? data.parentBadge.id : -99,
  };
};
