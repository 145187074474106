import React from "react";
import { useDispatch } from "react-redux";
import { IDeleteNotificationUser } from "../../../interfaces/others/IDeleteUser.ts";
import { deleteNotificationUser } from "../../../services/notificationUser.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import { setFetch } from "../../../store/slices/notificationsUserSlice.ts";

const DeleteNotificationUser: React.FC<IDeleteNotificationUser> = ({
  id,
  userName,
  rule,
  closeFn,
}) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await deleteNotificationUser(id);
      notification(
        "",
        `Usuario '${userName}' con la regla de notificación '${rule}' eliminado correctamente`,
        "info"
      );
    } catch (error) {
      notification(
        "Error :(",
        `Ocurrio un error al tratar de eliminar a ${userName} con la regla de notificación ${rule}`,
        "danger"
      );
    } finally {
      dispatch(setFetch(true));
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas eliminar a {userName} con la regla {rule}?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => handleDelete()}>
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteNotificationUser;
