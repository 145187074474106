import React, { useState } from "react";
import { notification } from "../../utils/Notifications.tsx";
import {
  deleteProofFile,
  uploadFilesToIncapacity,
} from "../../services/incapacities.service.ts";

const IncapacityFormEditFiles = ({ formik, reloadFiles }) => {
  const { proofFiles } = formik.values;
  const [newFiles, setNewFiles] = useState([]);
  const idIncapacity = formik.values.id;
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleDeleteNewFile = (fileIndex) => {
    setNewFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex)
    );
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setNewFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      await uploadFilesToIncapacity(Number(idIncapacity), newFiles);
      notification("Éxito", "Archivos subidos correctamente", "success");
      setNewFiles([]);
      setLoading(false);
      reloadFiles();
    } catch (error) {
      console.error("Error al subir los archivos:", error);
      notification("Error", "No se pudieron subir los archivos", "danger");
      setLoading(false);
    }
  };

  const handleDelete = async (fileId) => {
    setLoading(true);
    try {
      await deleteProofFile(fileId);
      notification("Éxito", "Archivo eliminado correctamente", "success");
      setLoading(false);
      reloadFiles();
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      notification("Error", "No se pudo eliminar el archivo", "danger");
      setLoading(false);
    }
  };

  const handleImageClick = (file) => {
    if (file.type && file.type.match(/(png|jpg|jpeg)$/)) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleExistingImageClick = (file) => {
    if (file.proofFile.extension.match(/(png|jpg|jpeg)$/)) {
      setPreviewImage(file.proofFile.url);
    }
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  return (
    <div className="row p-4">
      {!formik.values.approved && (
        <div
          className="mb-4 border-dashed text-center p-4"
          style={{
            border: "2px dashed #334155",
            borderRadius: "12px",
            cursor: "pointer",
            backgroundColor: "#f8f9fa",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <h5 className="text-dark">
            Arrastra y suelta archivos aquí o selecciona archivos
          </h5>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="d-none"
            id="file-upload"
            accept=".png,.jpg,.jpeg,.pdf"
          />
          <label htmlFor="file-upload" className="btn btn-dark">
            Seleccionar archivos
          </label>
        </div>
      )}
      {newFiles.length > 0 && (
        <div className="col-12 mb-4">
          <h6>Nuevos archivos:</h6>
          <div className="row">
            {newFiles.map((file, index) => (
              <div key={index} className="col-md-3 mb-3">
                <div
                  className="card shadow-sm border-light rounded position-relative"
                  style={{ transition: "transform 0.2s", cursor: "pointer" }}
                  onClick={() => handleImageClick(file)}
                >
                  <div className="card-body text-center">
                    <div className="mb-3">
                      {file.type.match(/(png|jpg|jpeg)$/) ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="img-fluid rounded"
                          style={{
                            maxHeight: "150px",
                            objectFit: "cover",
                          }}
                        />
                      ) : file.type.match(/(pdf)$/) ? (
                        <div>
                          <p>Archivo PDF, haz clic en "Ver".</p>
                          <a
                            href={URL.createObjectURL(file)}
                            className="btn btn-secondary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver PDF
                          </a>
                        </div>
                      ) : (
                        <p>Vista previa no disponible</p>
                      )}
                    </div>

                    <div className="position-absolute top-50 start-50 translate-middle text-white fs-6 fw-bold opacity-0 hover-opacity-100">
                      {file.type.match(/(png|jpg|jpeg)$/) ? "Ver Imagen" : ""}
                    </div>

                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteNewFile(index);
                        }}
                        title="Eliminar"
                        disabled={loading}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {previewImage && (
        <div
          className="modal fade show d-block bg-black bg-opacity-75"
          tabIndex="-1"
          onClick={closePreview}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={previewImage}
                  alt="Vista previa"
                  className="img-fluid"
                  style={{ maxHeight: "90vh", objectFit: "contain" }}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger" onClick={closePreview}>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {proofFiles && proofFiles.length > 0 ? (
        proofFiles.map((file) => (
          <div key={file.id} className="col-md-3 mb-3">
            <div
              className="card shadow-sm border-light rounded position-relative"
              style={{ transition: "transform 0.2s", cursor: "pointer" }}
              onClick={() => handleExistingImageClick(file)}
            >
              <div className="card-body text-center">
                <div className="position-relative mb-3">
                  <div className="top-0 end-0 p-2">
                    <a
                      href={file.proofFile.url}
                      className="btn btn-primary btn-sm me-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Descargar"
                    >
                      <i className="fas fa-download"></i>
                    </a>
                    {!formik.values.approved && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(file.id);
                        }}
                        title="Eliminar"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </div>
                  {file.proofFile.extension.match(/(png|jpg|jpeg)$/) ? (
                    <img
                      src={file.proofFile.url}
                      alt={file.proofFile.name}
                      className="img-fluid rounded"
                      style={{
                        maxHeight: "150px",
                        objectFit: "cover",
                      }}
                    />
                  ) : file.proofFile.extension === "pdf" ? (
                    <div className="fs-3">
                      <p>
                        Este es un archivo PDF, haz clic en descargar para
                        visualizarlo.
                      </p>
                    </div>
                  ) : (
                    <p>Vista previa no disponible</p>
                  )}
                </div>

                <div className="position-absolute top-50 start-50 translate-middle text-white fs-6 fw-bold opacity-0 hover-opacity-100">
                  {file.proofFile.extension.match(/(png|jpg|jpeg)$/)
                    ? "Ver Imagen"
                    : ""}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No hay archivos adjuntos disponibles.</p>
      )}

      {newFiles.length > 0 && (
        <div className="col-12 d-flex justify-content-end mt-3">
          <button
            disabled={loading}
            className="btn btn-warning btn-lg p-3"
            onClick={handleUpload}
          >
            {loading ? "Subiendo archivos..." : "Subir nuevos archivos"}
          </button>
        </div>
      )}
    </div>
  );
};

export default IncapacityFormEditFiles;
