import React from "react";
import { useParams } from "react-router-dom";
import ChipStatus from "./ChipStatus.tsx";
import { getCurrencyFormat } from "../../utils/index.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import EmptyImage from "../../assets/img/ic_content.svg";

const ApplicationListChangeSalary = ({ applications, size = "lg" }) => {
  const { curp } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="col-xl-8" id="bsSpyContent">
      <div id="Aplications" className="mb-4 pb-3">
        {size === "lg" && (
          <React.Fragment>
            <h4 className="d-flex align-items-center mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                data-icon="solar:user-bold-duotone"
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1 iconify--solar"
              >
                <circle cx={12} cy={6} r={4} fill="currentColor" />
                <path
                  fill="currentColor"
                  d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
                  opacity=".5"
                />
              </svg>{" "}
              Aplicaciones
            </h4>
            <p>
              Aquí puedes encontrar todas las postulaciones que se han realizado
              con la CURP {curp}.
            </p>
          </React.Fragment>
        )}
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            {applications?.salaryHistory?.map((salaryChange?, index) => (
              <div
                className="list-group-item d-flex align-items-center"
                key={index}
              >
                <div className="flex-fill">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 15 }}>
                        {`Salario anterior: ${getCurrencyFormat(
                          salaryChange?.previousSalary
                        )}`}
                      </span>
                      <ChipStatus status="Cambio de salario" />
                    </div>
                    <div style={{ fontSize: 14, color: "#6c757d" }}>
                      {`Nuevo salario: ${getCurrencyFormat(
                        salaryChange?.newSalary
                      )}`}
                    </div>
                    {salaryChange?.reason && (
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        {`Razón: ${salaryChange?.reason}`}
                      </div>
                    )}
                    <div style={{ fontSize: 14, color: "#6c757d" }}>
                      {`Actualizado por: ${salaryChange?.updatedBy.name} ${salaryChange?.updatedBy.lastName}`}
                    </div>
                    <div style={{ fontSize: 14, color: "#6c757d" }}>
                      {`Fecha de actualización: ${format(
                        parseISO(salaryChange?.updatedAt),
                        "PPP",
                        { locale: es }
                      )}`}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {applications?.salaryHistory?.length === 0 && (
              <div className="py-4">
                <div className="d-flex">
                  <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                </div>
                <h5 className="text-secondary text-center fs-20px">
                  No existen registros aún
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationListChangeSalary;
