import React from "react";

import {
  ShiftElement,
  SortData,
  Partner,
} from "../../../models/ReportPositionsBranch.ts";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import ButtonLink from "../../ButtonLink.tsx";
import { RootState } from "../../../store/index.ts";
import { useSelector } from "react-redux";

const PositionReportRow = ({
  data,
  index,
  partner,
}: {
  data: SortData;
  index: number;
  partner: Partner;
}) => {
  const { modalType } = useSelector((state: RootState) => state.report);
  const [open, setOpen] = React.useState(index === 0);

  const [matutino] = React.useState<ShiftElement | undefined>(
    data?.shifts?.find((record) => record?.shift === "Matutino")
  );
  const [intermedio] = React.useState<ShiftElement | undefined>(
    data?.shifts?.find((record) => record?.shift === "Intermedio")
  );
  const [vespertino] = React.useState<ShiftElement | undefined>(
    data?.shifts?.find((record) => record?.shift === "Vespertino")
  );

  if (modalType === "chilitos-sin-coincidencia") {
    return <PartnerUnmatchedTable data={partner} />;
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell width={10} sx={{ m: 0 }}>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <i className="fa-solid fa-arrow-up"></i>
            ) : (
              <i className="fa-solid fa-arrow-down"></i>
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={{ m: 0 }}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight="bold"
          >
            {data.position.name}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {matutino && (
              <Box sx={{ margin: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    fontWeight="bold"
                  >
                    Matutino
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Requeridos {matutino?.requieredWorkers}
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Contratados {matutino?.numberOfWorkers}
                  </Typography>
                </Box>
                <PartnerTable data={matutino?.partners || []} />
              </Box>
            )}

            {intermedio && (
              <Box sx={{ margin: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    fontWeight="bold"
                  >
                    Intermedio
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Requeridos {intermedio?.requieredWorkers}
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Contratados {intermedio?.numberOfWorkers}
                  </Typography>
                </Box>
                <PartnerTable data={intermedio?.partners || []} />
              </Box>
            )}

            {vespertino && (
              <Box sx={{ margin: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    fontWeight="bold"
                  >
                    Vespertino
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Requeridos {vespertino?.requieredWorkers}
                  </Typography>
                  <Typography variant="h6" gutterBottom component="div">
                    Contratados {vespertino?.numberOfWorkers}
                  </Typography>
                </Box>
                <PartnerTable data={vespertino?.partners || []} />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default PositionReportRow;

const PartnerTable = ({ data }: { data: Partner[] }) => {
  return (
    <Table size="small" aria-label="purchases">
      <TableBody>
        {data.map((record) => {
          const { person } = record.candidate;
          return (
            <TableRow key={record.id}>
              <TableCell component="th" scope="row">
                {toCamelCase(
                  `${person.firstName} ${person.lastName} ${person.secondLastName}`
                )}
              </TableCell>
              <TableCell width={"20%"}>
                <ButtonLink
                  className="btn btn-primary m-2"
                  text={<i className="fa-solid fa-eye"></i>}
                  to={`/colaboradores/detalles/${record?.id}/`}
                  type="button"
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const PartnerUnmatchedTable = ({ data }: { data: Partner }) => {
  
  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row">
        {toCamelCase(
          `${data.candidate.person.firstName} ${data.candidate.person.lastName} ${data.candidate.person.secondLastName}`
        )}
      </TableCell>
      <TableCell width={"30%"}>{data.candidate.vacancy.position.name}</TableCell>
      <TableCell width={"20%"}>{data.candidate.shift.name}</TableCell>
      <TableCell width={"20%"}>
        <ButtonLink
          className="btn btn-primary m-2"
          text={<i className="fa-solid fa-eye"></i>}
          to={`/colaboradores/detalles/${data?.id}/`}
          type="button"
        />
      </TableCell>
    </TableRow>
  );
};
