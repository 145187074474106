import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";
import {
  setDefaultSort,
  setPage,
  setSortBy,
} from "../../../../store/slices/chequeoSlice.ts";
import { formatDate } from "../../../../utils/index.ts";

moment.locale("es");

const TableChequeo = () => {
  const { records, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.chequeo
  );

  const dispatch = useDispatch();

  const calcularMinutosExtra = (
    horaSalidaHorario: string,
    horaSalidaRegistro: string
  ) => {
    if (!horaSalidaHorario || !horaSalidaRegistro) return "";
    const start = moment(horaSalidaHorario, "HH:mm");
    const end = moment(horaSalidaRegistro, "HH:mm:ss");
    const diff = end.diff(start, "minutes");
    return diff > 0 ? diff : 0;
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th onClick={() => setSort("id")}>
              Id {sortBy === "id" && getIconSort()}
            </th>
            <th onClick={() => setSort("partner")}>
              Colaborador {sortBy === "partner" && getIconSort()}
            </th>
            <th onClick={() => setSort("position")}>
              Posición {sortBy === "position" && getIconSort()}
            </th>
            <th onClick={() => setSort("dayOfWeek")}>
              Día de la semana {sortBy === "dayOfWeek" && getIconSort()}
            </th>
            <th onClick={() => setSort("shift")}>
              Turno {sortBy === "shift" && getIconSort()}
            </th>
            <th onClick={() => setSort("startTime")}>
              Hora entrada horario {sortBy === "startTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("endTime")}>
              Hora salida horario {sortBy === "endTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceCheck")}>
              Hora entrada registrada{" "}
              {sortBy === "assistanceCheck" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceCheckout")}>
              Hora salida registrada{" "}
              {sortBy === "assistanceCheckout" && getIconSort()}
            </th>
            <th onClick={() => setSort("extraTime")}>
              Minutos extra {sortBy === "extraTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceStatus")}>
              Estatus de asistencia{" "}
              {sortBy === "assistanceStatus" && getIconSort()}
            </th>
            <th onClick={() => setSort("isReplacement")}>
              Es reemplazo {sortBy === "isReplacement" && getIconSort()}
            </th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 ? (
            records.map((item, index) => {
              const person = item.partner.person;
              const horaEntradaHorario = item.startTime?.split(" - ")[0];
              const horaSalidaHorario = item.endTime?.split(" - ")[1];
              const horaEntradaRegistrada = item.assistanceCheck
                ? moment(item.assistanceCheck.checkTime, "HH:mm:ss").format(
                    "HH:mm"
                  )
                : "";
              const horaSalidaRegistrada = item.assistanceCheckout
                ? moment(item.assistanceCheckout.checkTime, "HH:mm:ss").format(
                    "HH:mm"
                  )
                : "";
              const minutosExtra = calcularMinutosExtra(
                horaSalidaHorario,
                horaSalidaRegistrada
              );

              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{`${person.firstName} ${person.lastName} ${person.secondLastName}`}</td>
                  <td>{item.positionName}</td>
                  <td>{formatDate(item?.exactDate)}</td>
                  <td>{item.shift}</td>
                  <td>{horaEntradaHorario || ""}</td>
                  <td>{horaSalidaHorario || ""}</td>
                  <td>{horaEntradaRegistrada || ""}</td>
                  <td>{horaSalidaRegistrada || ""}</td>
                  <td>{minutosExtra !== "" ? `${minutosExtra} min` : ""}</td>
                  <td>{item.assistanceStatus}</td>
                  <td>{item.isReplacement ? "Sí" : "No"}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no existen datos
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableChequeo;
