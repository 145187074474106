import React, { useEffect, useState } from 'react';
import FullScreenContainer from '../../../FullScreenContainer.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
  generateGradientBackground,
  generateTimeIntervals,
  getBackgroundColor,
  shifts,
  weekDays,
  weeks
} from '../../../../utils/constanst.js';
import { v4 as uuid } from 'uuid';
import GridLayout from 'react-grid-layout';
import { Tooltip, Typography } from '@mui/material';
import { readHoursByBranch, readPositionsByBranchAndShift, readSchedulesByBranchShiftWeekDay } from '../../../../services/branchSchedule.service.ts';
import { readPartnersByBranch } from '../../../../services/partner.service.ts';
import { useWebSocket } from '../../../../hooks/useWebSocket.tsx';
import Loader from '../../../Loader.tsx';

type Props = {};

const ScheduleRealTimeComponent = (props: Props) => {
  const screenWidth = window.screen.width;

  const navigate = useNavigate();
  const { id } = useParams();
  const [hours, setHours] = useState([]);
  const [positionsArray, setPositionsArray] = useState([]);
  const [existingsSchedules, setExistingsSchedules] = useState([]);
  const [positionsSorted, setPositionsSorted] = useState([]);
  const [hoursRange, setHoursRange] = useState<any>([]);

  const loadDependencies = async () => {
    const positions = await readPositionsByBranchAndShift(Number(id), 'Todos');

    const positionsArray = positions.flatMap((position) => {
      return Array.from({ length: position.numberOfWorkers }, (_, i) => ({
        id: i,
        positionId: position.position.id,
        positionName: `${position.position.name} ${i + 1}`,
        originalPositionName: position.position.name,
        shift: position.shift,
      }));
    });

    setPositionsArray(positionsArray);

    const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];
    const sorted = positionsArray.sort((a, b) => shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift));

    const resultArray: any = [];
    let currentShift = '';
    sorted.forEach(position => {
      if (position.shift !== currentShift) {
        currentShift = position.shift;
        resultArray.push({ title: currentShift, shift: currentShift });
      }
      resultArray.push(position);
    });
    setPositionsSorted(resultArray);

    const weekDay = new Date().getDay();
    console.log('weekDay', weekDay);

    const schedules = await readSchedulesByBranchShiftWeekDay(Number(id), 'Todos', weeks[1]?.value, weekDays[weekDay - 1]?.name);
    setExistingsSchedules(schedules);

    const branchHours = await readHoursByBranch(Number(id));
    if (!branchHours) return;
    setHours(branchHours);

    const hoursArray = generateTimeIntervals(branchHours?.matutinoStart, branchHours?.vespertinoEnd);
    hoursArray.unshift({ key: -99, display: 'Hora' }, { key: -99, display: 'Hora' });
    setHoursRange(hoursArray);
  };

  useEffect(() => {


    loadDependencies();
  }, [id]);

  const refetch = async () => {

    const weekDay = new Date().getDay();
    console.log('weekDay', weekDay);

    const schedules = await readSchedulesByBranchShiftWeekDay(Number(id), 'Todos', weeks[1]?.value, weekDays[weekDay - 1]?.name);
    setExistingsSchedules(schedules);
  }

  const [layout, setLayout] = useState<any>([]);
  const [workers, setWorkers] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (existingsSchedules?.length > 0) {

      const newLayout = existingsSchedules.map((schedule: any) => {
        const position: any = positionsArray.find((position: any) => position.positionName === schedule.positionName);
        const exist = positionsArray.findIndex((position: any) => position.positionName === schedule.positionName && position.shift === schedule.shift);

        return {
          i: uuid(),
          minH: 1,
          maxH: 1,
          minW: 1,
          show: exist !== -1,
          pending: false,
          name: schedule.partner.person.firstName,
          position: schedule.positionName,
          originalId: schedule.partner.id,
          shift: schedule.shift,
          x: Number(schedule.positionX),
          y: positionsArray.findIndex((position: any) => position.positionName === schedule.positionName && position.shift === schedule.shift),
          w: Number(schedule.positionW),
          h: 1,
          positionId: position?.positionId,
          status: schedule.assistanceCheck?.status || 'Sin registro',
        };
      });
      setLayout(newLayout);
    } else {
      setLayout([]);
    }
  }, [existingsSchedules, positionsArray]);

  useEffect(() => {
    const fetchWorkers = async () => {
      const workers = await readPartnersByBranch(Number(id));
      setWorkers(workers.map(worker => ({
        ...worker,
        show: true,
        pending: false,
      })));
    };

    fetchWorkers();
  }, [id]);

  const sortWorkers = () => {
    const reorderedShifts = [shifts['Todos'], ...shifts.filter((_, index) => index !== 3)];

    const resultArray = [];
    reorderedShifts.forEach(shift => {
      const workersByShift = workers.filter(worker => worker.candidate?.shift?.name === shift?.name);
      resultArray.push(...workersByShift);
    });

    setWorkers(resultArray);
  };

  useEffect(() => {
    sortWorkers();
  }, []);

  useEffect(() => {
    const hasPending = layout.findIndex((item) => item?.pending);
    if (lastItem && hasPending !== -1) {
      updatePending(lastItem, hasPending);
      setLastItem(null);
    }
  }, [lastItem, layout]);

  useEffect(() => {
    setLayout((currentLayout) => currentLayout.map((record) => ({ ...record, i: uuid() })));
  }, [update]);

  const updatePending = (oldRecord, newRecordIndex) => {
    const newLayout = [...layout];
    newLayout[newRecordIndex] = {
      ...newLayout[newRecordIndex],
      x: oldRecord.x,
      y: oldRecord.y,
      w: 17,
      h: 1,
      pending: false,
      show: true,
    };

    const itemsFounds = newLayout.filter((item) => item.originalId === layout[newRecordIndex].originalId);

    setLayout((currentLayout) => {
      const updatedLayout = [...currentLayout];
      updatedLayout[newRecordIndex] = {
        ...updatedLayout[newRecordIndex],
        x: oldRecord.x,
        y: oldRecord.y,
        w: itemsFounds.length > 2 ? 2 : 15,
        h: 1,
        pending: false,
        show: true,
      };
      if (oldRecord.x === 0 || oldRecord.x === 1) {
        updatedLayout.splice(newRecordIndex, 1);
      }
      const maxY = Number(positionsArray?.length);
      if (oldRecord.y >= maxY) {
        updatedLayout.splice(newRecordIndex, 1);
      }
      return updatedLayout;
    });
  };

  const [currentTime, setCurrentTime] = React.useState(0);

  const calculateCurrentTimePeriod = async () => {
    const minutesInterval = 5; // Intervalo de minutos que define un período
    const periodsPerHour = 60 / minutesInterval; // Número de períodos por hora

    const currentTime = new Date();
    const hour = currentTime.getHours();
    const minute = currentTime.getMinutes();

    const startHour = parseInt(hoursRange[2]?.display.split(":")[0], 10);
    const hourDifference = hour - startHour + 1;

    const index = (hourDifference) * (periodsPerHour) + Math.floor(minute / minutesInterval);

    console.log('calculateCurrentTimePeriod', index);
    setCurrentTime(index);
  };

  React.useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const minutes = now.getMinutes();

      console.log('checkTime', minutes);

      if (minutes % 5 === 0) {
        calculateCurrentTimePeriod();
        clearInterval(initialInterval);

        setInterval(calculateCurrentTimePeriod, 5 * 60 * 1000);
        console.log('checkTime - setInterval', minutes);
        refetch();
      }
    };

    const initialInterval = setInterval(checkTime, 60000);

    checkTime();
    return () => clearInterval(initialInterval);
  }, []);

  useEffect(() => {
    calculateCurrentTimePeriod();
  }, [hoursRange]);

  const { isConnected, assistancesObserver } = useWebSocket();

  useEffect(() => {
    if (isConnected) {
      const unsubscribe = assistancesObserver((data) => {
        const { branchId } = data;

        if (branchId === Number(id)) {
          refetch();
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [isConnected]);

  if (!isConnected) return <Loader isLoading={true} />;

  if (!hoursRange.length) return <>
    <FullScreenContainer open={true} tittle="Asistencia por sucursal" onClsFn={() => navigate('/sucursales')}>
      <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            La sucursal no cuenta con un horario establecido.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa el horario de la sucursal para poder asignar los trabajadores.
          </p>
        </div>
      </div>
    </FullScreenContainer>
  </>;

  return (
    <FullScreenContainer open={true} tittle="Asistencia por sucursal" onClsFn={() => navigate('/sucursales')}>
      <div className={`d-flex flex-row align-items-center px-3`}>
        <label
          className={`form-label text-end me-3`}
        >
          Simbología:
        </label>
        <div role="group">
          <ul className="list-inline mt-2">
            <li className="list-inline-item">
              <span className="badge text-white bg-success" style={{ border: '1px solid #0002' }}>Asistencia</span>
            </li>
            <li className="list-inline-item">
              <span className="badge text-white bg-danger" style={{ border: '1px solid #0002' }}>Retardo</span>
            </li>
            <li className="list-inline-item">
              <span className="badge text-white bg-secondary" style={{ backgroundColor: '#FF6347', border: '1px solid #0002' }}>Sin registro</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="postion-relative"
        style={{
          maxWidth: '100vw',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{ width: '3px', zIndex: 1000, left: `${(((screenWidth) / (((hoursRange.length) * 6))) * currentTime)}px` }}
          className='position-absolute h-100'
        >
          <div className='w-10px h-10px bg-danger' />
          <div className="w-50" style={{ border: '1px dashed #F00', height: '80vh' }} />
        </div>


        <div
          className="row d-flex justify-content-between"
          style={{
            flex: 1,
            flexDirection: "row",
            overflow: "auto",
            border: "1px solid #AFAFAF55",
            position: "relative",
            maxHeight: "80vh",
          }}
        >
          <>
            <div style={{ position: 'sticky', top: 0, zIndex: 5, backgroundColor: 'white' }}>
              <GridLayout
                className="layout"
                layout={Array.from({ length: (hoursRange.length) }, (_, i) => ({
                  i: i.toString(),
                  x: i,
                  y: 0,
                  w: 1,
                  h: 1,
                  static: true,
                }))}
                cols={hoursRange.length}
                rowHeight={30}
                width={screenWidth}
                margin={[0, 0]}
                compactType={null}
              >
                {
                  hoursRange.map((hour, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        textAlign: "center",
                        borderRight: (index !== 0) ? "2px solid #EEE" : "none",
                        borderBottom: '2px solid #EEE',
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 600,
                        fontSize: "10px",
                      }}
                    >
                      {index > 1 && hour.display}
                    </div>
                  ))
                }
              </GridLayout>
            </div>


            <GridLayout
              className="layout"
              layout={layout}
              cols={hoursRange.length}
              maxRows={positionsArray?.length}
              rowHeight={50}
              width={screenWidth}
              preventCollision={false}
              allowOverlap={true}
              isResizable={false}
              isDroppable={false}
              compactType={null}
              margin={[0, 0]}
              style={{
                width: `${screenWidth}px`,
                backgroundColor: 'white',
              }}
            >
              {positionsArray?.map((key, index) => {
                return (
                  <div
                    key={index}
                    data-grid={{ x: 0, y: index, w: key?.title ? 36 : 2, h: 1, static: true }}
                    style={{
                      fontSize: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "left",
                      borderRight: "2px solid #EEE",
                      borderBottom: `${key?.title ? '3px' : '2px'} solid #EEE`,
                      borderTop: `${key?.title ? '3px solid' : ''} ${key?.title === 'Matutino' ? '#FFD700' : key.title === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                      paddingInline: "5px",
                      fontWeight: 600,
                      borderLeft: `3px solid ${key.shift === 'Matutino' ? '#FFD700' : key.shift === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                      backgroundColor: 'white',
                      zIndex: 1000,
                    }}
                  >
                    {key.title && <p className="w-100 mt-3 fw-800 bg">{key?.title ?? key.positionName}</p>}
                    <p className="w-100 mt-auto">{key.positionName}</p>
                  </div>
                );
              })}
              {layout.map((item, index) => {
                if (!item?.x || !item?.show) return <></>;
                return (
                  <div
                    key={item?.i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    data-grid={{ x: item?.x, y: item?.y, w: item?.w, h: 1, static: true }}

                  >
                    <div
                      style={{
                        paddingLeft: "8px",
                        width: "100%",
                        borderRadius: "5px",
                        paddingBlock: "4px",
                      }}
                      className={`shadow-sm ${item?.status === "Asistencia" ? "bg-success"
                        : item?.status === "Retardo" ? "bg-danger"
                          : "bg-secondary"
                        }`}
                    >
                      <Tooltip
                        title={`${item.name} | ${item.position} | ${item.w / 2} hrs`}
                        arrow
                      >
                        <div className="d-flex flex-row">
                          <Typography fontWeight={500} marginLeft={1.5} color='white'>
                            {item?.name?.split(" ")[0]}
                          </Typography>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </GridLayout>
          </>
        </div >
      </div>

    </FullScreenContainer>
  );
};

export default ScheduleRealTimeComponent;
