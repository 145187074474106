import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import "moment/locale/es";
import {
  setCurrentRecord,
  setDefaultSort,
  setDeleteModal,
  setPage,
  setSortBy,
} from "../../store/slices/listaNegraSlice.ts";
import EmptyImage from "../../assets/img/ic_content.svg";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { fixedHeadStyle, fixedStyle } from "../../utils/constanst.js";
moment.locale("es");

const TableListaNegra = () => {
  const dispatch = useDispatch();
  const { listaNegraList, deleteModal, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.listaNegra
  );

  const formatDate = (date: string = String(new Date())) => {
    const formattedDate = moment(date).format("MMMM D [del] YYYY");
    return formattedDate.replace(/^(.)/, (match) => match.toUpperCase());
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead style={{ ...defaultStyle }}>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
            <th style={{ ...fixedStyle } as React.CSSProperties} onClick={() => setSort("id")}> ID {sortBy === "id" && getIconSort()}</th>
            <th onClick={() => setSort("name")}> Nombre completo {sortBy === "name" && getIconSort()}</th>
            <th onClick={() => setSort("curp")}> CURP {sortBy === "curp" && getIconSort()}</th>
            <th onClick={() => setSort("reason")}> Razón {sortBy === "reason" && getIconSort()}</th>
            <th onClick={() => setSort("banDate")}> Fecha de baneo {sortBy === "banDate" && getIconSort()}</th>
            <th onClick={() => setSort("isPermanent")}> Permanente {sortBy === "isPermanent" && getIconSort()}</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {listaNegraList?.length > 0 ?
            listaNegraList.map((item, index) => (
              <tr key={index}>
                <td style={{ ...fixedStyle } as React.CSSProperties}>{item.id}</td>
                <td>
                  {toCamelCase(item?.people?.firstName)} {toCamelCase(item?.people?.lastName)}{" "}
                  {toCamelCase(item?.people?.secondLastName)}
                  {item?.name ? item?.name : '-'}
                </td>
                <td>{item?.curp}</td>
                <td>{item?.reason}</td>
                <td>{item?.isPermanent ? "-" : formatDate(item?.unbanDate)}</td>
                <td>
                  <div
                    style={{
                      backgroundColor: `${!item?.isPermanent ? "green" : "red"}`,
                      color: "white",
                      padding: "3px 7px",
                      borderRadius: "10px",
                      display: `${item?.isPermanent ? "inline-block" : "none"}`,
                    }}
                  >
                    {item?.isPermanent ? "Permanente" : ""}
                  </div>
                </td>
                <td>
                  <a
                  href="#/"
                    className="btn btn-rojo m-2"
                    onClick={() => {
                      dispatch(
                        setCurrentRecord({
                          id: item.id,
                          curp: item.curp,
                        })
                      );
                      dispatch(setDeleteModal(true));
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-circle-xmark"></i>
                  </a>
                </td>
              </tr>
            ))
            :
            <tr>
              <td colSpan={7}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no hay nadie en la lista negra</h5>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default TableListaNegra;
