import * as Yup from "yup";

export const NotificationUserSchema = Yup.object().shape({
  idUserType: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idUser: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idRule: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idBranch: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
});
