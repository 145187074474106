import React from "react";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { toCamelCase } from "../../../../hooks/toCameCase.tsx";
import EmptyImage from "../../../EmptyImage.tsx";
import {
  AssistanceCheck,
  Incident,
} from "../../../../models/IncidentRecord.ts";
import RowsIncidents from "./RowsIncidents.tsx";
import RowNoSchedule from "./RowNoSchedule.tsx";
import { Partner } from "../../../../models/Partner.ts";

const TableIncidencias = ({
  records,
  withoutSchedule,
}: {
  records: { key: string; records: Incident[] }[];
  withoutSchedule: { partners: Partner[]; records: AssistanceCheck[] };
}) => {
  return (
    <React.Fragment>
      <div className="table-sticky mb-3">
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead className="sticky-thead table-light text-center">
            <tr>
              <th rowSpan={2} className="align-middle">
                Id
              </th>
              <th rowSpan={2} className="align-middle">
                Colaborador
              </th>
              <th colSpan={2} className="align-middle">
                Horario Planeado
              </th>
              <th colSpan={2} className="align-middle">
                Incidencias
              </th>
              {/* <th rowSpan={2} className="align-middle">Minutos Extra</th> */}
              <th rowSpan={2} className="align-middle">
                Tipo
              </th>
              <th rowSpan={2} className="align-middle">
                Acción
              </th>
            </tr>
            <tr>
              <th>Entrada</th>
              <th>Salida</th>
              <th>Entrada</th>
              <th>Salida</th>
            </tr>
          </thead>
          <tbody>
            {records.map((incident) =>
              incident.records.map((incidents, index) => (
                <RowsIncidents
                  key={index}
                  record={incidents}
                  title={incident.key}
                />
              ))
            )}

            {withoutSchedule.partners.map((partner, index) => (
              <RowNoSchedule
                key={index}
                partner={partner}
                records={withoutSchedule.records}
              />
            ))}

          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "200px",
            textAlign: "center",
          }}
        >
          {withoutSchedule.partners.length === 0 &&
              records.every((incident) => incident.records.length === 0) &&(
            <EmptyImage
              className="mx-auto w-250px"
              text="No hay nada pendiente"
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableIncidencias;
