import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { v4 as uuid } from "uuid";
import { useFormik } from 'formik';
//import { updatePartnersSchedules } from '../../services/partner.service.ts';
import { notification } from '../../utils/Notifications.tsx';
import { Box, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { useAppSettings } from '../../hooks/useAppSettings.tsx';
import { readHoursByBranch } from '../../services/branchSchedule.service.ts';
import { generateTimeIntervals, generateTimeSlots } from '../../utils/constanst.js';
import { getUserSchedule, updateUserSchedule } from '../../services/partner.service.ts';
import { toCamelCase } from '../../hooks/toCameCase.tsx';

const PersonalAScheudule = ({ partner }) => {
  useAppSettings();
  const [branchHours, setBranchHours] = useState<any[]>([]);
  const [shiftsRanges, setShiftsRanges] = useState<any[]>([]);
  const [layout, setLayout] = useState<any[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  const [dragAll, setDragAll] = useState<boolean>(false);

  const fetchDependencies = async () => {
    const branchHours = await readHoursByBranch(partner.candidate.branch.id);

    if (!branchHours) return;

    const hoursArray = await generateTimeIntervals(branchHours?.matutinoStart, branchHours?.vespertinoEnd);
    setBranchHours(hoursArray)

    const shiftsRanges = await generateTimeSlots(branchHours);
    setShiftsRanges(shiftsRanges);
  }

  useEffect(() => {
    fetchDependencies();
  }, [partner.id]);

  const weekDays = [
    { id: 0, name: '' },
    { id: 1, name: 'Lunes' },
    { id: 2, name: 'Martes' },
    { id: 3, name: 'Miércoles' },
    { id: 4, name: 'Jueves' },
    { id: 5, name: 'Viernes' },
    { id: 6, name: 'Sábado' },
    { id: 7, name: 'Domingo' },
  ];

  const defaultLayout = async () => {
    const response = await getUserSchedule(partner.id);
    console.log("response", response);

    let defaultLayout = [];

    if (response.length > 0) {
      defaultLayout = response.map((item, index) => {
        return {
          h: branchHours.findIndex(hour => hour?.display === item.endTime) - branchHours.findIndex(hour => hour?.display === item.startTime) + 1,
          i: uuid(),
          maxH: 1,
          minH: 1,
          minW: 1,
          maxW: 1,
          name: partner.candidate.vacancy.position.name,
          originalId: partner.candidate.vacancy.position.id,
          pending: false,
          position: partner.candidate.vacancy.position.name,
          show: true,
          w: 1,
          x: weekDays.findIndex(day => day.name === item.day),
          y: branchHours.findIndex(hour => hour?.display === item.startTime),
        };
      });
    } else {
      defaultLayout = weekDays.flatMap((item, index) => {
        if (index === 0) return null;
        if (item.name === partner.candidate.freeDay) return null;

        const daySchedules = [
          {
            startTime: partner.candidate.shift?.name === 'Matutino' ? branchHours[0]?.display : branchHours[branchHours.length - 1]?.display,
            endTime: partner.candidate.shift?.name === 'Matutino' ? branchHours[2]?.display : branchHours[branchHours.length - 1]?.display,
          }
        ];

        return daySchedules.map(schedule => ({
          h: partner.candidate.shift?.name === 'Matutino' ? branchHours.findIndex(item => item?.display === shiftsRanges[0]?.ranges[0]?.display) + 2 : branchHours.indexOf(branchHours[branchHours.length - 1]) - 17,
          i: uuid(),
          maxH: 1,
          minH: 1,
          minW: 1,
          maxW: 1,
          name: partner.candidate.vacancy.position.name,
          originalId: partner.candidate.vacancy.position.id,
          pending: false,
          position: partner.candidate.vacancy.position.name,
          show: true,
          w: 1,
          x: index,
          y: partner.candidate.shift?.name === 'Matutino' ? 0 :
            partner.candidate.shift?.name === 'Intermedio' ? branchHours.findIndex(item => item?.display === shiftsRanges[1]?.ranges[0]?.display)
              : branchHours.indexOf(branchHours[branchHours.length - 1]) - 16,
        }));
      });
    }

    setLayout(defaultLayout.flat().filter(Boolean));
  };


  useEffect(() => {
    defaultLayout();
  }, [partner.id, branchHours, shiftsRanges]);
  const MAX_HEIGHT = branchHours.length - 3;

  const exceedsMaxSize = (item) => {
    return item.x + item.h > MAX_HEIGHT;
  };

  const onDragStop = (layout_, oldItem, newItem, placeholder, e, element) => {
    if (dragAll) {
      const daysWithMultipleSchedules = layout.reduce((acc, item) => {
        if (!acc[item.x]) {
          acc[item.x] = 1;
        } else {
          acc[item.x] += 1;
        }
        return acc;
      }, {});

      const hasMultipleSchedules = Object.values(daysWithMultipleSchedules).some(count => count > 1);

      if (hasMultipleSchedules) {
        notification(
          "No se puede hacer arrastre múltiple.",
          "Hay días con más de un horario.",
          "warning"
        );
        return;
      }

      const deltaX = newItem.x - oldItem.x;
      const deltaY = newItem.y - oldItem.y;

      const updatedLayout = layout.map(item => {
        const newItemPosition = {
          x: item.x + deltaX,
          y: item.y + deltaY,
          w: item.w,
          h: item.h,
        };

        if (exceedsMaxSize(newItemPosition)) {
          return item;
        }

        return {
          ...item,
          x: newItemPosition.x,
          y: newItemPosition.y,
        };
      });

      setLayout(updatedLayout);
      setUpdate(!update);
      return;
    }

    if (newItem.x === 0) {
      setLayout((layout) => [...layout]);
      setUpdate(!update);
      return;
    }

    const updatedLayout = layout.map(item => {
      if (item.i === oldItem.i) {
        const newItemSize = {
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        };

        if (exceedsMaxSize(newItemSize)) {
          return item;
        }

        return {
          ...item,
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        };
      }
      return item;
    });

    setUpdate(!update);
    setLayout(updatedLayout);
  };





  const formik = useFormik({
    initialValues: {
      dayOfWeek: "",
      branchId: 0,
      _jsonData: [],
      get jsonData() {
        return this._jsonData;
      },
      set jsonData(value) {
        this._jsonData = value;
      },
      dateRange: "",
    },
    onSubmit: async (values) => {
      try {
        const data = weekDays.flatMap((item, index) => {
          if (index === 0) return null;

          // Agrupar los horarios por día y garantizar que se manejen varios horarios en el mismo día
          const daySchedules = layout
            .filter(layoutItem => layoutItem.x === index)
            .map(layoutItem => ({
              day: item.name,
              startTime: branchHours[layoutItem.y]?.display,
              endTime: branchHours[layoutItem.y + layoutItem.h - 1]?.display,
            }));

          return daySchedules.length > 0 ? daySchedules : null;
        });

        const cleanedData = data.filter(Boolean).flat();

        // Guardar todos los horarios del día para el socio (partner) de manera adecuada

        console.log("cleanedData", cleanedData);
        console.log("layout", layout);
        await updateUserSchedule(partner.id, cleanedData);

        notification(
          "Éxito",
          `El horario fue actualizado correctamente`,
          "success"
        );
      } catch (error) {
        notification(
          "error",
          "Ocurrió un error al guardar el horario",
          "danger"
        );
        console.log("error", error);
      }
    },


  });

  const handleDrop = (item) => {
    if (item.x === 0) {
      setLayout((layout) => [...layout]);
      setUpdate(!update)
      return;
    }

    const newItem = {
      i: uuid(),
      x: item.x,
      y: item.y,
      w: 1,  // Ensure w is defined
      h: 2,  // Ensure h is defined
      minH: 1,
      maxH: 1,
      minW: 1,
      maxW: 1,
      show: false,
      pending: true,
      name: partner.candidate.vacancy.position.name,
      position: partner.candidate.vacancy.position.name,
      originalId: partner.candidate.vacancy.position.id,
    };

    setLayout((currentLayout) => [...currentLayout, newItem]);
  };

  const handleRemove = (id: string) => {
    setLayout((currentLayout) => currentLayout.filter(item => item.i !== id));
  };

  const handleResize = (layout_, oldItem, newItem, placeholder, e, element) => {
    newItem.w = oldItem.w;

    const updatedLayout = layout.map(item => {
      if (item.i === oldItem.i) {
        return {
          ...item,
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        };
      }
      return item;
    }
    );
    setUpdate(!update)
    setLayout(updatedLayout);
  };

  useEffect(() => {
    setLayout((currentLayout) => {
      const newLayout = currentLayout.map((record) => ({
        ...record,
        i: uuid(),
      }));
      return newLayout;
    });
  }, [update]);

  const calculateHoursRange = (item) => {

    return ` ${branchHours[item.y].display.split(' - ')[0]} - ${branchHours[item.y + item.h - 1].display.split(' - ')[1]}`;
  }

  const handleToggleDragAll = () => {
    setDragAll(!dragAll);
  };

  // console.log("branchHours", branchHours);

  return (
    <div className="tab-pane fade " id="horario">
      <div className="d-flex flex-column">
        <div className='d-flex flex-row'>

          <div
            className="card droppable-element p-1"
            unselectable="on"
            style={{
              border: "1px solid #e4e6e7a6",
              marginRight: "5px",
            }}
          >
            <button onClick={handleToggleDragAll} className="btn btn-primary">
              {dragAll ? 'Desactivar arrastre múltiple' : 'Activar arrastre múltiple'}
            </button>
            <h4 className="text-center mb-3">Colaborador</h4>
            <Card
              sx={{
                display: "flex",
                backgroundColor: "rgba(255, 255, 255, 0)",
                flexDirection: { xs: "column", sm: "row" },
                py: 0,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                gap: 1,
              }}
              draggable={true}
            >
              <Grid item xs={12} sm={8}>
                <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", textAlign: { xs: "center", sm: "left" } }}
                    style={{ padding: 2 }}
                  >
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        userSelect: "none",
                      }}
                    >
                      {toCamelCase(`${partner.person.firstName} ${partner.person.lastName}`)}
                    </h4>
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "0.7rem",
                        marginBlock: "0.2rem",
                        userSelect: "none",
                      }}
                    >
                      {partner.candidate.vacancy.position.name}
                    </h4>
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "0.7rem",
                        color: "#6e6e6e",
                        userSelect: "none",
                      }}
                    >
                      Horario {partner.candidate.shift?.name}
                    </h4>
                  </CardContent>
                </Box>
              </Grid>
            </Card>
          </div>

          {layout && (
            <div className='border rounded-3' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
              <div className="col-12" style={{ boxShadow: '4px 6px 6px rgba(0, 0, 0, 0.1)', overflowY: 'auto', maxHeight: '90vh' }}>
                <>
                  <GridLayout
                    className="layout"
                    layout={weekDays.map((_, i) => ({
                      i: i.toString(),
                      x: i,
                      y: 0,
                      w: 1,
                      h: 1,
                      static: true,
                    }))}
                    cols={8}
                    rowHeight={30}
                    width={1212}
                    isDraggable={false}
                    margin={[0, 0]}
                  >
                    {weekDays.map((item) => (
                      <div
                        key={item.id}
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '12px',
                          fontWeight: 600,
                          borderRight: '2px solid #EEE',
                          borderBottom: '2px solid #EEE',
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </GridLayout>

                  <GridLayout
                    className="layout"
                    layout={layout}
                    cols={8}
                    maxRows={branchHours.length}
                    rowHeight={23}
                    width={1212}
                    preventCollision={true}
                    allowOverlap={false}
                    margin={[3, 0]}
                    onDragStop={onDragStop}
                    onResizeStop={handleResize}
                    isDroppable={true} // Make GridLayout droppable
                    verticalCompact={false}
                    onDrop={(layout, item, e) => {
                      handleDrop(item);
                    }}
                  >
                    {
                      branchHours.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              borderRight: '2px solid #EEE',
                              borderBottom: '2px solid #EEE',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '10px',
                              padding: '2px',
                              fontWeight: 600,
                            }}
                            data-grid={{ x: 0, y: index, w: 1, h: 1, static: true }}
                          >
                            {item?.display}
                          </div>
                        );
                      })
                    }
                    {layout.map((item) => (
                      <div
                        key={item.i}
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          display: 'flex',
                          backgroundColor: '#41b1c355',
                          border: '1px solid #0000',
                          borderLeft: '3px solid #41b1c3',
                          borderRadius: '5px',
                          textAlign: 'center',
                          paddingInline: '5px',
                        }}
                        className="shadow-sm"
                        data-grid={{ x: item.x, y: item.y, w: item.w, h: item.h }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}
                          className='position-relative'
                        >
                          <div
                            onMouseDown={(e) => e.stopPropagation()}
                            onTouchStart={(e) => e.stopPropagation()}
                            className='position-absolute top-0 m-2'
                            style={{ right: 0, cursor: 'pointer' }}
                            onClick={() => handleRemove(item.i)}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </div>

                          <div className="d-flex flex-row my-auto">
                            <p className="fs-12px text-center" style={{ color: '#41b1c3' }}>
                              <b>{item.name}</b>
                              <br />
                              <p className='ms-3'>
                                {item.h > 2 && calculateHoursRange(item)}
                              </p>
                            </p>
                          </div>
                        </div>

                      </div>
                    ))}
                  </GridLayout>
                </>
              </div>
            </div>
          )}


        </div>
        <button
          onClick={() =>
            formik.handleSubmit()
          }
          className="btn btn-primary ms-auto mt-3">
          Guardar
        </button>
      </div>
    </div>
  );
};

export default PersonalAScheudule;
