import * as Yup from "yup";

export const IncapacityShema = Yup.object().shape({
  title: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  daysOff: Yup.number().required("Campo requerido"),
  startDate: Yup.number().required("Campo requerido"),
  endDate: Yup.number().required("Campo requerido"),
});

export const CreateIncapacityShema = Yup.object().shape({
  title: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
});
