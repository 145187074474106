import httpClient from "./httpClient";

const prefix = "/badges";

export async function readBadges() {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function createBadge(badgeData: {
  title: string;
  description: string;
  parentId?: number;
}) {
  return (await httpClient.post(`${prefix}`, badgeData)).data;
}

export async function updateBadge(badgeId: number, badgeData: any) {
  return (await httpClient.put(`${prefix}/${badgeId}`, badgeData)).data;
}

export async function paginateBadges(query: string) {
  return (await httpClient.get(`${prefix}/paginate?${query}`)).data;
}

export async function readBadgeById(badgeId) {
  return (await httpClient.get(`${prefix}/${badgeId}`)).data;
}

export async function assignBadgeToPartner(badgeId, partnerId) {
  return (await httpClient.post(`${prefix}/${badgeId}/partner/${partnerId}`))
    .data;
}

export async function deleteBadge(badgeId) {
  return (await httpClient.delete(`${prefix}/${badgeId}`)).data;
}

export async function getBadgesForPartner(partnerId) {
  return (await httpClient.get(`${prefix}/partner/${partnerId}`)).data;
}

export async function removeBadgeFromPartner(badgeId, partnerId) {
  return (await httpClient.delete(`${prefix}/${badgeId}/partner/${partnerId}`))
    .data;
}
