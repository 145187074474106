import React from 'react'
import { PanelBody } from '../../panel/panel';
import CustomInput from '../../formik/CustomInput.tsx';
import CustomSelectField from '../../formik/CustomSelectField.tsx';

const SucursalFormGeneral = ({ formik, businessNames, editing }) => {
  return (
    <PanelBody>
      <CustomInput
        formik={formik}
        field="name"
        label="Nombre de la sucursal"
        placeholder="Nombre de la sucursal"
        sm={3}
        disabled={editing}
        unclickable={editing}
      />
      {
        !editing && (
          <CustomSelectField
            formik={formik}
            field="idBusinessName"
            list={businessNames ?? []}
            label="Razón social"
            disabled={!!editing}
            sm={3}
          />
        )
      }
    </PanelBody>
  )
}

export default SucursalFormGeneral