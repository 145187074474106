import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Title from "../../components/Title.tsx";
import { Panel, PanelHeader } from "../../components/panel/panel.jsx";
import Button from "../../components/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Layout from "../../components/Layout.tsx";
import { notification } from "../../utils/Notifications.tsx";
import ContractForm from "../../components/positions/ContractForm.tsx";
import ResponsabilityiesContract from "../../components/positions/ResponsabilityiesContract.tsx";
import { ContractSchema } from "../../validation/schemas/ContractSchema.ts";
import { saveBaseContract } from "../../services/contract.service.ts";

const NewContract = () => {
  useAppSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    validationSchema: ContractSchema,
    initialValues: {
      contractName: "",
      idDepartment: -99,
      responsibilities: [],
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("values", values);
        const response = await saveBaseContract(values);
        console.log("response", response);
        notification("Éxito", "Contrato creado correctamente", "success");
      } catch (error) {
        console.log("error", error);
      } finally {
        navigate("/puestos");
        //window.location.href = "/puestos";
        setLoading(false);
      }
    },
  });

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Puestos"
        basePath="/puestos"
        activeTitle="Nuevo contrato"
        title="Nuevo contrato"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales del contrato
          </PanelHeader>
          <ContractForm formik={formik} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Responsabilidades
          </PanelHeader>
          <ResponsabilityiesContract
            formik={formik}
            originalField="responsibilities"
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/puestos")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewContract;
