import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetState, setLoading, setTemplates, setTotal } from '../../../store/slices/branchTemplateSlice.ts';
import { paginateTemplates } from '../../../services/branchSchedule.service.ts';
import { notification } from '../../../utils/Notifications.tsx';
import FilterPlantillas from '../../../components/plantilla/FilterPlantilla.tsx';
import TablePlantilla from '../../../components/plantilla/TablePlantilla.tsx';
import PaginationPlantillas from '../../../components/plantilla/PaginationPlantilla.tsx';

type Props = {}

const PlantillasComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { page, limit, param, fetch, query, sortBy, defaultSort } = useSelector((state: RootState) => state.branchTemplates) || { fetch: false };

  const fetchPlantillas = async () => {
    dispatch(setLoading(true));
    console.log('fetchPlantillas');
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&sort=${sortBy}&order=${defaultSort}&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "type=all";
      }
      const response = await paginateTemplates(queryToSend);
      dispatch(setTemplates(response.templates));
      dispatch(setTotal(response.total));
    } catch (error) {
      console.log("error", error);
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  }

  React.useEffect(() => {
    fetchPlantillas();
  }, [page, limit, param, fetch, query, , sortBy, defaultSort ]);

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <>
      <div className="tab" id="plantillasTab">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <FilterPlantillas />
            <TablePlantilla />
            <PaginationPlantillas />
          </div>
        </div>
      </div>
    </>
  )
}

export default PlantillasComponent