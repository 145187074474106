import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuery } from "../../store/slices/notificationsUserSlice.ts";
import { readAllUser } from "../../services/user.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import { listAllRoles } from "../../services/role.service.js";

const FilterNotificationsUser: React.FC<FilterNotificationsUserProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = React.useState<any>(null);
  const initialValues = {
    typeUser: "all",
    branch: "all",
    rule: "all",
    param: "",
  };
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        console.log(queryString);
        dispatch(setQuery(queryString));

        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const updatedFilters = { ...filters, ...params };
    setFilters(updatedFilters);
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            updatedFilters[key]
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  interface User {
    id: number;
    name: string;
    rol: {
      id: number;
      name: string;
    };
    branch?: string;
  }

  const [users, setUsers] = React.useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);
  const [branches, setBranches] = React.useState([]);
  interface Role {
    id: number;
    name: string;
  }

  const [typeUsers, setTypeUsers] = React.useState<Role[]>([]);

  const fetchFilters = async () => {
    try {
      const users = await readAllUser();
      const branches = await readBranches();
      const roles = await listAllRoles();
      setUsers(users);
      setTypeUsers(roles);
      console.log(roles);
      setFilteredUsers(users);
      setBranches(branches);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchFilters();
  }, []);

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "param":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "typeUser":
            keyName = "Tipo de usuario";
            valueName =
              typeUsers.find((role) => role.id === Number(value))?.name ||
              value;
            break;
          case "branch":
            keyName = "Sucursal";
            valueName =
              branches.find((branch) => branch.id === Number(value))?.name ||
              value;
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter(
        (key) =>
          updatedFilters[key] !== -1 &&
          updatedFilters[key] !== "" &&
          updatedFilters[key] !== "all"
      )
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            updatedFilters[key]
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Tipo de usuario:</label>
          <select
            className="form-select"
            name="typeUser"
            value={filters.typeUser}
            onChange={(e) => updateFilters({ typeUser: e.target.value })}
          >
            <option value="all">Todos</option>
            {typeUsers.map((role: any) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
          <label>Sucursal:</label>
          <select
            className="form-select"
            name="branch"
            value={filters.branch}
            onChange={(e) => updateFilters({ branch: e.target.value })}
          >
            <option value="all">Todos</option>
            {branches.map((branch: any) => (
              <option key={branch.id} value={branch.id}>
                {branch.name}
              </option>
            ))}
          </select>

          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters(initialValues);
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un nombre..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
            <button
              className="btn btn-success"
              style={{ marginLeft: "1%" }}
              onClick={() => navigate("nueva-notificacion")}
            >
              Nueva regla de notificación
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters).map((filter: any) => (
            <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {filter.keyName}: {filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FilterNotificationsUser;

interface FilterNotificationsUserProps {}
