import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../Layout.tsx";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { readBranch, readBranches } from "../../../services/branch.service.ts";
import Title from "../../Title.tsx";
import { readPartnersByBranch } from "../../../services/partner.service.ts";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {};

const BirthDatesReport = (props: Props) => {
  useAppSettings();
  let id;

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [branch, setBranch] = useState<any>();
  const [currentBirthDates, setCurrentBirthDates] = useState<any[]>([]);
  const [pastBirthDates, setPastBirthDates] = useState<any[]>([]);
  const [futureBirthDates, setFutureBirthDates] = useState<any[]>([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchDependencies = async () => {
    try {
      const today = moment();
      const branch = await readBranch(Number(id));
      setBranch(branch);

      const partners = await readPartnersByBranch(Number(id));

      const currentBd: any[] = [];
      const pastBd: any[] = [];
      const futureBd: any[] = [];

      partners.forEach((partner: any) => {
        const birthDate = moment(partner.person.birthdate);
        const currentYearBirthDate = moment(birthDate).year(today.year());

        if (today.isSame(currentYearBirthDate, "day")) {
          currentBd.push(partner);
        } else if (today.isAfter(currentYearBirthDate)) {
          pastBd.push(partner);
        } else if (today.isBefore(currentYearBirthDate)) {
          futureBd.push(partner);
        }
      });

      // Sort by month
      const sortByMonth = (arr: any[]) =>
        arr.sort((a, b) => {
          const monthA = moment(a.person.birthdate).month();
          const monthB = moment(b.person.birthdate).month();
          return monthA - monthB;
        });

      setCurrentBirthDates(sortByMonth(currentBd));
      setPastBirthDates(sortByMonth(pastBd));
      setFutureBirthDates(sortByMonth(futureBd));
    } catch (error) {
      console.error("Error fetching dependencies:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const isCloser = (birthdate: any) => {
    const today = moment().startOf('day');
    const days = 15;

    let birthdayThisYear = moment(birthdate).year(today.year());

    if (birthdayThisYear.isBefore(today)) {
      birthdayThisYear = birthdayThisYear.add(1, 'year');
    }

    const isCloser = birthdayThisYear.isBetween(today, moment(today).add(days, 'days'), 'day', '[]');

    if(isCloser) {
      const remainingDays = birthdayThisYear.diff(today, 'days');
      return remainingDays;
    } else {
      return 'N/A';
    }
  };

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Cumpleaños"
        basePath="/sucursal/birthdates"
        title={`Cumpleaños`}
      />
      <div className="card border-0 m-4 rounded p-4">
        <h1 className="mb-4">General</h1>
        <div className="d-flex flex-row">
          <div className="d-flex align-items-center me-4">
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: currentBirthDates.length, label: "Hoy" },
                    { id: 1, value: pastBirthDates.length, label: "Pasados" },
                    { id: 2, value: futureBirthDates.length, label: "Futuros" },
                  ],
                },
              ]}
              width={400}
              height={200}
              sx={{
                width: 200,
              }}
            />
          </div>
          <div
            className="d-flex flex-row overflow-auto"
            style={{ maxWidth: "100%" }}
          >
            <div className="d-flex flex-column flex-1">
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: [branch?.name],
                  },
                ]}
                series={[
                  {
                    data: [currentBirthDates.length],
                    label: "Actuales",
                  },
                  {
                    data: [pastBirthDates.length],
                    label: "Pasados",
                  },
                  {
                    data: [futureBirthDates.length],
                    label: "Futuros",
                  },
                ]}
                width={400}
                height={200}
              />
            </div>
          </div>
        </div>

        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Actuales" {...a11yProps(0)} />
            <Tab label="Próximos" {...a11yProps(1)} />
            <Tab label="Pasados" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="tables-grid">
            <div className="table-container">
              <h3>{branch?.name}</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "40%" }}>Nombre</TableCell>
                    <TableCell style={{ width: "30%" }}>
                      Fecha de Nacimiento
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>Edad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentBirthDates.map(
                    (partner: any, partnerIndex: number) => (
                      <TableRow key={partnerIndex}>
                        <TableCell>
                          {toCamelCase(
                            `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(partner.person.birthdate).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          {moment().diff(
                            moment(partner.person.birthdate),
                            "years"
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  {currentBirthDates.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No hay cumpleaños hoy</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="tables-grid">
            <div className="table-container">
              <h3>{branch?.name}</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "40%" }}>Nombre</TableCell>
                    <TableCell style={{ width: "30%" }}>
                      Fecha de Nacimiento
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>Edad</TableCell>
                    <TableCell style={{ width: "30%" }}>
                      Días restantes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {futureBirthDates.map(
                    (partner: any, partnerIndex: number) => (
                      <TableRow key={partnerIndex}>
                        <TableCell>
                          {toCamelCase(
                            `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(partner.person.birthdate).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          {moment().diff(
                            moment(partner.person.birthdate),
                            "years"
                          )}
                        </TableCell>
                        <TableCell>
                          {isCloser(partner.person.birthdate)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  {futureBirthDates.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        No hay cumpleaños futuros
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div className="tables-grid">
            <div className="table-container">
              <h3>{branch?.name}</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "40%" }}>Nombre</TableCell>
                    <TableCell style={{ width: "30%" }}>
                      Fecha de Nacimiento
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>Edad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pastBirthDates.map((partner: any, partnerIndex: number) => (
                    <TableRow key={partnerIndex}>
                      <TableCell>
                        {toCamelCase(
                          `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(partner.person.birthdate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment().diff(
                          moment(partner.person.birthdate),
                          "years"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {pastBirthDates.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        No hay cumpleaños pasados
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </CustomTabPanel>
      </div>
    </Layout>
  );
};

export default BirthDatesReport;
