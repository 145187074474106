import React, { useState, useEffect, DragEvent } from "react";
import { IFormikFieldProps } from "../../interfaces/formik/IFormikField";
import { AttachCircle } from "iconsax-react";
import Draggable from "react-draggable";

const CustomFileInput = ({
  sm = 12,
  smLabel = 3,
  hidden = false,
  required = true,
  type = "file",
  accept = "",
  formik,
  showLabel = true,
  ...props
}: IFormikFieldProps & { accept?: string; showLabel?: boolean }) => {
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [previewType, setPreviewType] = useState<string | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    const initialFile = formik.values[props.field];
    const fileType = formik.values["imageFileType"];

    if (initialFile && typeof initialFile === "string") {
      setPreview(initialFile);

      if (["png", "jpg", "jpeg", "gif", "bmp", "tiff"].includes(fileType)) {
        setPreviewType("image");
      } else if (
        ["mp4", "webm", "mov", "avi", "mkv", "flv"].includes(fileType)
      ) {
        setPreviewType("video");
      } else if (["mp3", "wav", "ogg", "m4a", "flac"].includes(fileType)) {
        setPreviewType("audio");
      } else {
        setPreviewType(null);
      }
    }
  }, [formik.values, props.field]);

  const error =
    formik.touched[props.field] && Boolean(formik.errors[props.field]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingOver(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleFile = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    setPreview(fileURL);

    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      setPreviewType("image");
    } else if (fileType.startsWith("audio/")) {
      setPreviewType("audio");
    } else if (fileType.startsWith("video/")) {
      setPreviewType("video");
    } else {
      setPreviewType(null);
    }

    formik.setFieldValue(props.field, file);
    formik.setFieldValue("imageFileType", file.type.split("/")[1]);
  };

  const handleRemoveFile = () => {
    setPreview(null);
    setPreviewType(null);
    formik.setFieldValue(props.field, null);
    formik.setFieldValue("imageFileType", "");
  };

  return (
    <div className={`row fs-13px col-md-12 ${hidden ? "d-none" : ""}`}>
      {showLabel && (
        <label
          className={`form-label col-form-label col-md-${smLabel} text-end`}
          style={{ color: error ? "red" : "inherit" }}
        >
          {props.label}&nbsp;
          {required && (
            <span
              style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}
            >
              *
            </span>
          )}
        </label>
      )}
      <div className={`col-md-${sm}`} style={{ cursor: "pointer" }}>
        <div
          className={`border border-dashed border-2 border-light rounded-3 ${
            isDraggingOver ? "border-success bg-light" : "border-dotted"
          } p-3 text-center ${error ? "border-danger" : "border-secondary"} ${
            preview ? "d-none" : ""
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={() => document.getElementById(props.field)?.click()}
        >
          <div className="d-flex align-content-center justify-content-center mt-2">
            <AttachCircle size="24" color="#000" className="me-2" />
            <p>Arrastra y suelta un archivo aquí o haz clic para seleccionar</p>
          </div>
          <input
            type={type}
            className="form-control d-none"
            id={props.field}
            name={props.field}
            placeholder={props.placeholder}
            onChange={handleFileChange}
            accept={accept}
          />
        </div>
        {preview && previewType && (
          <div
            className="mt-2 col-12"
            style={{
              position: "relative",
              height: "250px",
              overflow: "hidden",
              backgroundColor: "#f0f0f0",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Draggable bounds="parent" defaultPosition={{ x: 0, y: 0 }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                {previewType === "image" && (
                  <img
                    src={preview as string}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {previewType === "audio" && (
                  <audio controls style={{ width: "100%", height: "100%" }}>
                    <source src={preview as string} />
                    Your browser does not support the audio element.
                  </audio>
                )}
                {previewType === "video" && (
                  <video controls style={{ width: "100%", height: "100%" }}>
                    <source src={preview as string} />
                    Your browser does not support the video element.
                  </video>
                )}
              </div>
            </Draggable>
            <button
              onClick={handleRemoveFile}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "rgba(255, 255, 255, 0.8)",
                border: "none",
                borderRadius: "100%",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#ff0000",
                }}
              >
                X
              </span>
            </button>
          </div>
        )}
        <div
          className="invalid-feedback"
          style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
        >
          {formik.touched[props.field] && formik.errors[props.field] ? (
            <>{formik.errors[props.field]}</>
          ) : (
            <p> </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomFileInput;
