import React, { ForwardedRef } from "react";

const PrintReport: React.FC<any> = React.forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
      >
        <div className="print-header" >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src="/assets/img/logo/logo_chili_rojo.png"
              alt="ChiliGuajili"
              style={{ width: "50px", height: "auto" }}
            />
            <h2
              style={{
                margin: 0,
                marginLeft: "3%",
                width: "100%",
              }}
            >
              {props.title}
            </h2>
          </div>
        </div>
        <div
          className="print-content"
        >
          {props.records}
        </div>
        <div className="page-break"></div>
      </div>
    );
  }
);

export default PrintReport;
