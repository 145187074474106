import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { IChangePositionModalTraspasos } from "../../interfaces/others/IChangePositionModal.ts";
import { ChangePositionSchemaTraspasos } from "../../validation/schemas/ChangePositionSchemaTraspasos.ts";
import { ChangePositionTraspasos } from "../../interfaces/formik/IChangePositionTraspasos.ts";
import { Branch, Position } from "../../models/Partner.ts";
import { notification } from "../../utils/Notifications.tsx";
import { readPositions } from "../../services/position.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import {
  checkPending,
  savePositionChange,
} from "../../services/changePosition.service.ts";
import { setLoading } from "../../store/slices/colaboradoresSlice.ts";
import { ChangePosition } from "../../models/ChangePosition.ts";
import PendingChange from "./PendingChange.tsx";
import {
  readPartnerByBranches,
  readPartnersByBranch,
} from "../../services/partner.service.ts";

const ChangePositionModalTraspasos: React.FC<IChangePositionModalTraspasos> = ({
  closeFn,
  branchId,
}) => {
  const dispatch = useDispatch();
  const [positions, setPositions] = React.useState<Position[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [partners, setPartners] = React.useState<
    {
      id: number;
      person: { firstName: string; lastName: string; secondLastName: string };
    }[]
  >([]);
  const [pendingChange, setPendingChange] =
    React.useState<ChangePosition | null>(null);

  const loadDependencies = async () => {
    dispatch(setLoading(true));
    try {
      const positionResponde = await readPositions();
      setPositions(positionResponde);

      const partnerslist = await readPartnerByBranches(Number(branchId));
      setPartners(partnerslist);

      console.log("partnerslist", partnerslist);

      const branchResponse = await readBranches();
      setBranches(branchResponse);
    } catch (error) {
      notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    loadDependencies();
  }, [branchId]);

  const formik = useFormik({
    validationSchema: ChangePositionSchemaTraspasos,
    initialValues: ChangePositionTraspasos,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      try {
        await savePositionChange({
          newPosition: values.idPosition,
          newBranch: values.idBranch,
          reasonForPositionChange: values.reason,
          partner: values.idPartner,
        });

        notification(
          "",
          "La solicitud de cambio de puesto fue enviada con éxito",
          "success"
        );

        const pending = await checkPending(values.idPartner);
        if (pending) {
          setPendingChange(pending);
          notification("", "Hay un cambio pendiente.", "info");
        }
      } catch (error) {
        console.log(error);
        notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const partnerNames = partners.length
    ? partners.map((partner) => ({
        id: partner.id,
        name: `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`,
      }))
    : [{ id: -99, name: "No hay colaboradores en esta sucursal" }];

  const [selectedPartner, setSelectedPartner] = React.useState<any | null>(
    null
  );

  React.useEffect(() => {
    const fetchPendingChange = async () => {
      const partnerSeleccionado = partners.find(
        (p) => p.id === formik.values.idPartner
      );
      setSelectedPartner(partnerSeleccionado || null);

      if (partnerSeleccionado) {
        try {
          const response = await checkPending(partnerSeleccionado.id);
          if (response) {
            setPendingChange(response);
          }
        } catch (error) {
          console.log(error);
          notification(
            "",
            "Ah ocurrido un error, intentalo mas tarde",
            "danger"
          );
        }
      }
    };

    fetchPendingChange();
  }, [formik.values.idPartner, partners]);

  return (
    <React.Fragment>
      {pendingChange && (
        <PendingChange
          partner={partners.find((p) => p.id === formik.values.idPartner)}
          pendingChange={pendingChange}
          closeFn={closeFn}
        />
      )}

      {!pendingChange && (
        <>
          <CustomSelectField
            formik={formik}
            field="idPartner"
            list={partnerNames}
            label="Colaborador"
            placeholder="Colaborador"
          />

          <CustomSelectField
            formik={formik}
            field="idPosition"
            list={positions}
            label="Nueva posición"
            placeholder="posición"
          />
          <CustomSelectField
            formik={formik}
            field="idBranch"
            list={branches}
            label="Nueva sucursal"
            placeholder="sucursal"
          />
          <CustomInput
            formik={formik}
            field="reason"
            label="Motivo de cambio de puesto"
            placeholder="Motivo de cambio de puesto"
            sm={9}
            smLabel={3}
          />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-secondary me-2"
              onClick={() => closeFn()}
            >
              Cancelar
            </button>
            <button
              className="btn btn-success"
              disabled={!formik.isValid || !formik.dirty || partners.length === 0}
              onClick={() => formik.handleSubmit()}
            >
              Solicitar
            </button>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ChangePositionModalTraspasos;
