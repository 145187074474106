import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import ProfileHeader from "../../components/profile/ProfileHeader.tsx";
import { useParams } from "react-router-dom";
import { notification } from "../../utils/Notifications.tsx";
import { fetchPartnerById } from "../../services/application.service.ts";
import { Partner } from "../../models/Partner.ts";
import S3Service from "../../services/s3.service.ts";
import TimeLineDocuments from "../../components/profile/TimeLineDocuments.tsx";
import InformationUser from "../../components/profile/InformationUser.tsx";
import InformationPosition from "../../components/profile/InformationPosition.tsx";
import Contracts from "../../components/profile/Contracts.tsx";
import Layout from "../../components/Layout.tsx";
import { useLocation } from "react-router-dom";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import AccountantContract from "../../components/profile/AccountantContract.tsx";
import PersonalAScheudule from "../../components/profile/PersonalAScheudule.tsx";
import InformationBadges from "../../components/profile/InformationBadges.tsx";
import Incapacities from "../../components/profile/Incapacities.tsx";

const ProfilePartner = () => {
  useAppSettings();
  const { user } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const confetti = location.state?.confetti;
  const { id } = useParams();
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [partner, setPartner] = React.useState<Partner>({} as Partner);
  const [files, setFiles] = React.useState({
    profile: "",
  });

  const fetchPartner = async () => {
    try {
      const response = await fetchPartnerById(Number(id));
      setPartner(response);
      const profileUrl = await S3Service.getFile(
        "PERFIL_" + response.person.curp
      );
      setFiles({ profile: profileUrl.url });
    } catch (error) {
      setError(error?.message);
      notification(
        "Error",
        "Ocurrió un error al recuperar al colaborador",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const largeProps: ConfettiProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 300,
    width: 1600,
    colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
  };

  React.useEffect(() => {
    fetchPartner();
  }, []);

  if (error) return <>error</>;

  return (
    <Layout loading={loading}>
      {!loading && (
        <div id="content" className="app-content p-0">
          <ProfileHeader partner={partner} profileUrl={files.profile} />
          <div className="profile-content">
            <div className="tab-content p-0">
              <InformationUser
                partner={partner}
                reloadPage={() => fetchPartner()}
              />
              <TimeLineDocuments partner={partner} reloadPage={fetchPartner} />
              <InformationPosition
                partner={partner}
                reloadPage={() => fetchPartner()}
              />
              {user.rol !== "Trabajador" && <Contracts partner={partner} />}
              {(user.rol === "Contador" || user.rol === "Administrador")  && <AccountantContract partner={partner} />}
              <PersonalAScheudule partner={partner} />
              {(user.rol === "Reclutamiento" || user.rol === "Administrador")  && <InformationBadges partner={partner} reloadPage={() => fetchPartner()} />}
              {(user.rol === "Trabajador" || user.rol === "Administrador") && (
                <Incapacities partner={partner} reloadPage={fetchPartner} />
              )}
            </div>
          </div>
        </div>
      )}
      {confetti && <ConfettiExplosion {...largeProps} />}
    </Layout>
  );
};

export default ProfilePartner;
