import React from "react";
import { CardMedia } from "@mui/material";
import S3Service from "../services/s3.service.ts";

const PartnerPhoto = ({ curp }: { curp: string }) => {
  const [profileImage, setProfileImage] = React.useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );

  const fetchProfileImage = async () => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + curp);
      setProfileImage(profileUrl.url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CardMedia
      component="img"
      image={profileImage}
      alt="Colaborador"
      onError={() =>
        setProfileImage(
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        )
      }
      className="rounded-circle"
      style={{ width: 50, height: 50 }}
    />
  );
};

export default PartnerPhoto;
