import React, { useEffect } from "react";
import { DatePicker } from "antd";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import CustomInput from "../formik/CustomInput.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import CustomTextArea from "../formik/CustomTextArea.tsx";
import { PanelBody } from "../panel/panel.jsx";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const IncapacityFormEdit = ({ formik, isPartner, isCreate }) => {
  const calculateDaysOff = (
    start: dayjs.Dayjs | null,
    end: dayjs.Dayjs | null
  ) => {
    if (start && end) {
      const difference = end.diff(start, "day");
      return difference + 1;
    }
    return 0;
  };

  const handleDateChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    const [start, end] = dates;

    formik.setFieldValue("startDate", start ? start.unix() : null);
    formik.setFieldValue("endDate", end ? end.unix() : null);
    const daysOff = calculateDaysOff(start, end);
    formik.setFieldValue("daysOff", daysOff);
  };

  useEffect(() => {
    if (formik.values.startDate && formik.values.endDate) {
      const startDate = dayjs.unix(formik.values.startDate);
      const endDate = dayjs.unix(formik.values.endDate);

      formik.setFieldValue("dateRange", [startDate, endDate]);
    }
  }, [formik.values.startDate, formik.values.endDate]);

  return (
    <React.Fragment>
      <div>
        <PanelBody>
          <CustomInput
            formik={formik}
            field="title"
            label="Nombre de la incapacidad"
            placeholder="Incapacidad"
            sm={5}
            disabled={formik.values.approved}
          />
          <CustomTextArea
            formik={formik}
            field="description"
            label="Descripción de la incapacidad"
            placeholder="Descripción de la incapacidad"
            sm={5}
            disabled={formik.values.approved}
          />
          {(!isPartner ||
            (formik.values.startDate && formik.values.endDate)) ? (
            <>
              <div className={`row fs-13px col-md-12`}>
                <label
                  className={`form-label col-form-label col-md-3 text-end`}
                  style={{
                    color:
                      formik.touched.startDate && formik.errors.startDate
                        ? "red"
                        : "inherit",
                  }}
                >
                  {!isPartner
                    ? "Fecha de la incapacidad"
                    : "Rango de fechas autorizadas"}
                  <span
                    style={{
                      color:
                        formik.touched.startDate && formik.errors.startDate
                          ? "red"
                          : "inherit",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className={`col-md-5`}>
                  <RangePicker
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    disabled={isPartner || formik.values.approved}
                    inputReadOnly={isPartner}
                    placeholder={["Fecha de inicio", "Fecha de fin"]}
                    style={{ width: "100%" }}
                    value={
                      formik.values.startDate && formik.values.endDate
                        ? [
                            dayjs.unix(formik.values.startDate),
                            dayjs.unix(formik.values.endDate),
                          ]
                        : null
                    }
                    size="large"
                    allowClear={false}
                  />
                  <div
                    className="invalid-feedback"
                    style={{
                      marginTop: "1px",
                      display: "flex",
                      marginLeft: "0.25em",
                    }}
                  >
                    {formik.touched.startDate && formik.errors.startDate ? (
                      <>{formik.errors.startDate}</>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className={`mt-3 mb-3 row fs-13px col-md-12 align-items-center`}
              >
                <label
                  className={`form-label col-form-label col-md-3 text-end`}
                >
                  {!isPartner
                    ? "Días de incapacidad calculadas"
                    : "Días autorizados"}
                </label>
                <div className={`col-md-5`}>
                  <p className="mb-0 fs-5 fw-bold ">
                    {formik.values.daysOff || 0} dia(s)
                  </p>
                </div>
              </div>
            </>
          ) : null}
          {isCreate && (
            <CustomCheckboxField
              formik={formik}
              field="approved"
              label="Aprobar incapacidad"
            />
          )}
          {!isCreate && (
            <div className={`row fs-13px col-md-12 align-items-center`}>
              <label className={`form-label col-form-label col-md-3 text-end`}>
                Estatus de la incapacidad:
              </label>
              <div className={`col-md-5`}>
                <span
                  className="badge fs-6"
                  style={{
                    backgroundColor: formik.values.approved
                      ? "green"
                      : "yellow",
                    border: "1px solid #0002",
                    color: formik.values.approved ? "white" : "black",
                  }}
                >
                  {formik.values.approved
                    ? "Aprobada"
                    : "Pendiente por revisar"}
                </span>
              </div>
            </div>
          )}
        </PanelBody>
      </div>
    </React.Fragment>
  );
};

export default IncapacityFormEdit;
