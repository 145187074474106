import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setLoading,
  setShowClock,
  setShowModal,
} from "../../../../store/slices/incidentSlice.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import { RootState } from "../../../../store/index.ts";
import {
  fetchAssistenceByUser,
  updateAssistanceCheck,
} from "../../../../services/assistanceCheck.service.ts";
import { AssitenceCheck } from "../../../../models/AssitenceCheck.ts";
import CustomSelectField from "../../../formik/CustomSelectField.tsx";
import { useFormik } from "formik";
import { ModifyAssitanceFilled } from "../../../../interfaces/formik/IModifyAssitance.ts";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DemoContainer,
  DemoItem,
} from "@mui/x-date-pickers/internals/demo/DemoContainer";
import CustomInput from "../../../formik/CustomInput.tsx";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";
import useLastNegativeOne from "../../../../hooks/useLastNegativeOne.tsx";
import {
  AssistanceStatus,
  convertToDayjs,
  differenceDayjs,
  getStartTimeRecord,
  isDayjs,
} from "../../../../utils/index.ts";
import { ModifyAssitanceSchema } from "../../../../validation/schemas/ModifyAssitanceSchema.ts";

const ModifyAssitence = () => {
  const dispatch = useDispatch();
  const [records, setRecords] = React.useState<AssitenceCheck[]>([]);
  const { date, currentRecord, showClock, refreshFunction } = useSelector(
    (state: RootState) => state.incident
  );

  const formik = useFormik({
    initialValues: ModifyAssitanceFilled(currentRecord),
    validationSchema: ModifyAssitanceSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      try {
        await updateAssistanceCheck(values.id, {
          checkTimeId: values.checkTimeId,
          checkoutId: values.checkoutId,
          fakeTimeEntry: isDayjs(values.fakeTimeEntry)
            ? values.fakeTimeEntry.format("HH:mm")
            : "",
          fakeTimeOut: isDayjs(values.fakeTimeOut) ? values.fakeTimeOut.format('HH:mm') : "",
          reasonToModify: values.reasonToModify,
          status: AssistanceStatus.find((_) => _.id === values.status)?.name,
        });
      } catch (error) {
        notification(
          "",
          error?.message ?? "Ocurrio un error al actualizar el registro",
          "danger"
        );
      } finally {
        refreshFunction();
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      }
    },
  });

  const { lastNegativeOne, updateLastNegativeOne } = useLastNegativeOne(
    formik.values.checkTimeId,
    formik.values.checkoutId
  );

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const response = await fetchAssistenceByUser(
        currentRecord.partner.id,
        date?.format("YYYY-MM-DD")
      );
      setRecords(response);
    } catch (error) {
      notification(
        "",
        error?.message ?? "Ocurrio un error al obtener los registros",
        "danger"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (formik.values.checkTimeId === -1 || formik.values.checkoutId === -1) {
      dispatch(setShowClock(true));
    } else {
      dispatch(setShowClock(false));
    }
  }, [formik.values.checkTimeId, formik.values.checkoutId]);

  React.useEffect(() => {
    const { checkTimeId, checkoutId, fakeTimeEntry, fakeTimeOut } =
      formik.values;
    let totalMinutesWorked = 0;

    // Ambos son registros manuales
    if (
      checkTimeId === -1 &&
      checkoutId === -1 &&
      fakeTimeEntry &&
      fakeTimeOut
    ) {
      totalMinutesWorked = differenceDayjs(fakeTimeEntry, fakeTimeOut);
    }
    // Solo el registro de entrada es manual
    if (
      checkTimeId === -1 &&
      fakeTimeEntry &&
      checkoutId !== -1 &&
      checkoutId !== -99
    ) {
      const record = records
        .map((_) => ({ id: _.id, name: _.checkTime }))
        .find((_) => _.id === checkoutId);

      if (!record?.name) return;

      const { startTime } = getStartTimeRecord(record?.name);
      totalMinutesWorked = differenceDayjs(
        fakeTimeEntry,
        convertToDayjs(startTime)
      );
    }

    // Solo el registro de salida es manual
    if (
      checkoutId === -1 &&
      fakeTimeOut &&
      checkTimeId !== -1 &&
      checkTimeId !== -99
    ) {
      const record = records
        .map((_) => ({ id: _.id, name: _.checkTime }))
        .find((_) => _.id === checkTimeId);

      if (!record?.name) return;

      const { startTime } = getStartTimeRecord(record?.name);
      totalMinutesWorked = differenceDayjs(
        convertToDayjs(startTime),
        fakeTimeOut
      );
    }

    // Ambos registros son automaticos

    if (
      !fakeTimeEntry &&
      !fakeTimeOut &&
      checkTimeId !== -99 &&
      checkoutId !== -99 &&
      records.length > 0
    ) {
      const recordEntry = records
        .map((_) => ({ id: _.id, name: _.checkTime }))
        .find((_) => _.id === checkTimeId);
      const recordOut = records
        .map((_) => ({ id: _.id, name: _.checkTime }))
        .find((_) => _.id === checkoutId);

      if (!recordEntry?.name || !recordOut?.name) return;

      const { startTime: startTimeEntry } = getStartTimeRecord(
        recordEntry?.name
      );
      const { startTime: startTimeOut } = getStartTimeRecord(recordOut?.name);
      totalMinutesWorked = differenceDayjs(
        convertToDayjs(startTimeEntry),
        convertToDayjs(startTimeOut)
      );
    }

    formik.setFieldValue("minutesWorked", totalMinutesWorked);
  }, [
    formik.values.checkTimeId,
    formik.values.checkoutId,
    formik.values.fakeTimeEntry,
    formik.values.fakeTimeOut,
    records,
  ]);

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className={`${showClock ? "col-md-7" : "col-md-12"}`}>
          <CustomSelectField
            formik={formik}
            field="checkTimeId"
            label="Registro de entrada"
            list={records.map((_) => ({ id: _.id, name: _.checkTime }))}
            sm={8}
            smLabel={4}
            customOption={true}
            secondField="fakeTimeEntry"
            customComponent={
              <>
                {formik.values.fakeTimeEntry &&
                  formik.values.checkTimeId === -1 && (
                    <button
                      className="btn btn-secondary mt-1"
                      onClick={() => {
                        dispatch(setShowClock(true));
                        updateLastNegativeOne("checkTimeId");
                      }}
                    >
                      {isDayjs(formik.values.fakeTimeEntry) &&
                        formik.values.fakeTimeEntry?.format("hh:mm A")}
                    </button>
                  )}
              </>
            }
          />
          <CustomSelectField
            formik={formik}
            field="checkoutId"
            label="Registro de salida"
            list={records.map((_) => ({ id: _.id, name: _.checkTime }))}
            sm={8}
            smLabel={4}
            customOption={true}
            secondField="fakeTimeOut"
            customComponent={
              <>
                {formik.values.fakeTimeOut &&
                  formik.values.checkoutId === -1 && (
                    <button
                      className="btn btn-secondary mt-1"
                      onClick={() => {
                        dispatch(setShowClock(true));
                        updateLastNegativeOne("checkoutId");
                      }}
                    >
                      {isDayjs(formik.values.fakeTimeOut) &&
                        formik.values.fakeTimeOut?.format("hh:mm A")}
                    </button>
                  )}
              </>
            }
          />

          <CustomSelectField
            formik={formik}
            field="status"
            label="Estatus"
            list={AssistanceStatus}
            sm={8}
            smLabel={4}
          />

          <div className="d-flex">
            <div className="col-md-6">
              <CustomInput
                formik={formik}
                field="requiredWorkedTime"
                label="Minutos requeridos"
                smLabel={8}
                sm={4}
                disabled
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                formik={formik}
                field="minutesWorked"
                label="Minutos trabajados"
                smLabel={8}
                sm={4}
                disabled
              />
            </div>
          </div>

          <CustomTextArea
            formik={formik}
            field="reasonToModify"
            label="Razón de modificación"
            placeholder="Razón de modificación"
            smLabel={4}
            sm={8}
          />
        </div>
        {Boolean(showClock) && (
          <div className="col-md-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker", "TimePicker"]}>
                <DemoItem>
                  <StaticTimePicker
                    orientation="landscape"
                    onAccept={(e) => {
                      console.log(lastNegativeOne);
                      if (lastNegativeOne === "checkTimeId") {
                        formik.setFieldValue("fakeTimeEntry", e);
                      } else {
                        formik.setFieldValue("fakeTimeOut", e);
                      }
                      dispatch(setShowClock(false));
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button
          className="btn btn-secondary me-2"
          onClick={() => dispatch(setShowModal(false))}
        >
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModifyAssitence;
