import React from "react";
import { IFormikFieldProps } from "../../interfaces/formik/IFormikField";

const CustomTextArea = ({
  sm = 12,
  smLabel = 3,
  min = 0,
  max = 1000000,
  hidden = false,
  required = true,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  type = "text",
  classInput = "",
  labelRequired = true,
  ...props
}: IFormikFieldProps & { classInput?: string; labelRequired?: boolean }) => {
  const error =
    props.formik.touched[props.field] &&
    Boolean(props.formik.errors[props.field]);
  return (
    <div className={`row fs-13px col-md-12 ${classInput}`}>
      {labelRequired && (
      <label
        className={`form-label col-form-label col-md-${smLabel} text-end`}
        style={{ color: error ? "red" : "inherit" }}
      >
        {props.label}&nbsp;
        {required && (
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        )}
      </label>
      )}
      <div className={`col-md-${sm}`}>
        <textarea
          // type={type}
          disabled={props.disabled}
          className={`form-control fs-13px ${error ? "is-invalid" : ""}`}
          style={props.disabled ? { cursor: "not-allowed" } : {}}
          id={props.field}
          name={props.field}
          placeholder={props.placeholder}
          onChange={(event) => {
            if (type === "number") {
              const parsed = parseInt(event.target.value);
              const finalParsed = isNaN(parsed) ? 0 : parsed;

              if (finalParsed < min) {
                event.target.value = "0";
              } else if (finalParsed > max) {
                event.target.value = String(max);
              }
            }

            props.formik.handleChange(event);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              props.formik.handleSubmit();
            }
          }}
          value={props.formik.values[props.field]}
        />
        <div
          className="invalid-feedback"
          style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
        >
          {props.formik.touched[props.field] &&
          props.formik.errors[props.field] ? (
            <>{props.formik.errors[props.field]}</>
          ) : (
            <p> </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTextArea;
