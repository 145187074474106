import { setMaxIdleHTTPParsers } from "http";
import { Incapacity, ProofFile, UserReviewer } from "../others/IEditIncapacity";

export const DefaultIncapacity = {
  title: null,
  description: null,
  approved: false,
  daysOff: null,
  startDate: null,
  endDate: null,
  dateRange: [],
  idPartner: null,
};

export interface EditIncapacityProperties {
  id ?: number;
  title: string;
  description: string;
  approved: boolean;
  daysOff: number;
  startDate: number;
  endDate: number;
  proofFiles: ProofFile[]; 
  reviewerBy: UserReviewer[];

}

export const FillIncapacity = (data: Incapacity): EditIncapacityProperties | null => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    approved: data.approved,
    daysOff: data.daysOff,
    startDate: data.startDate,
    endDate: data.endDate,
    proofFiles: data.proofFiles || [],
    reviewerBy: data.reviewerBy || [],
  };
} 
 

