import React from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../../components/Layout.tsx";
import Title from "../../../components/Title.tsx";
import { Panel, PanelHeader } from "../../../components/panel/panel";
import { useFormik } from "formik";
import { NotificationUserSchema } from "../../../validation/schemas/NotificationUserSchema.ts";
import {
  DefaultNotificationUser,
  INotificationUser,
  NotificationUserFill,
  NotificationUserInitial,
} from "../../../interfaces/formik/INotificationUser.ts";
import {
  readNotificationUser,
  updateNotificationUser,
} from "../../../services/notificationUser.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import Button from "../../../components/Button.tsx";
import { useNavigate, useParams } from "react-router-dom";
import NotificationUserForm from "./NotificationUserForm.tsx";

const EditNotification = () => {
  const { id } = useParams();
  useAppSettings();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [initialValues, setInitialValues] = React.useState<INotificationUser>(
    NotificationUserInitial
  );

  const typeRule = [
    { id: 1, name: "Inasistencias" },
    { id: 2, name: "Posiciones abiertas" },
    { id: 3, name: "Cumpleaños" },
  ];

  const fetchNotificationUser = async () => {
    if (!id) return navigate("/notificaciones-user");
    try {
      const response = await readNotificationUser(Number(id));
      console.log("DATOS OBTENIDOS PARA EDITAR", response);

      setInitialValues(NotificationUserFill(response, typeRule));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchNotificationUser();
  }, []);

  const formik = useFormik({
    validationSchema: NotificationUserSchema,
    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const rule = typeRule.find((rule) => rule.id === values.idRule);
        const notificationUser = await updateNotificationUser(Number(id), {
          idUser: values.idUser,
          idBranch: values.idBranch,
          rule: rule ? rule.name : "",
        });

        console.log("Submitting form with values:", values);
        notification(
          "Éxito",
          "Regla de notificación actualizada correctamente",
          "success"
        );
        navigate("/notificaciones");
      } catch (error) {
        notification(
          "Error",
          "Error al actualizar la regla de notificación",
          "danger"
        );
        console.error("Error updating notification user:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Layout loading={loading}>
        <Title
          baseTitle="Notificaciones"
          basePath="/notificaciones"
          activeTitle="Editar regla de notificación"
          title="Editar regla de notificación"
        />

        <div className="card border-0 m-4 rounded">
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Datos generales de la regla de notificación
            </PanelHeader>
            <NotificationUserForm formik={formik} />
          </Panel>
        </div>

        <div className="card border-0 m-4 rounded p-3">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <Button
                onClick={() => navigate("/notificaciones-user")}
                title="Cancelar"
                variant="secondary"
                type="button"
              />
            </div>
            <div className="col-md-3">
              <Button
                onClick={() => formik.handleSubmit()}
                title="Actualizar"
                variant="azul"
                type="submit"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditNotification;
