import React from "react";
import EmptyImagePath from "../assets/img/ic_content.svg";

const EmptyImage = ({
  className,
  text = "",
}: {
  className: string;
  text?: string;
}) => {
  return (
    <React.Fragment>
      <div className="d-flex" style={{ flexDirection: "column" }}>
        <img src={EmptyImagePath} alt="" className={className} />
        <h5 className="text-secondary text-center fs-20px">{text}</h5>
      </div>
    </React.Fragment>
  );
};

export default EmptyImage;
