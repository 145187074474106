import React, { useEffect } from "react";
import { Incident } from "../../../../models/IncidentRecord";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import {
  setCurrentRecord,
  setLoading,
  setShowModal,
  setTitle,
} from "../../../../store/slices/incidentSlice.ts";
import PartnerDetailIncident from "./PartnerDetailIncident.tsx";
import { useFormik } from "formik";
import {
  IncidentReason,
  IncidentReasonSchema,
} from "../../../../validation/schemas/IncidentReasonSchema.ts";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";
import { notification } from "../../../../utils/Notifications.tsx";
import { updateIncident } from "../../../../services/assistanceCheck.service.ts";

const RowsIncidents = ({
  record,
  title,
}: {
  record: Incident;
  title: string;
}) => {
  const dispatch = useDispatch();
  // replacementOf
  const { selectButton, refreshFunction } = useSelector(
    (state: RootState) => state.incident
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IncidentReason,
    validationSchema: IncidentReasonSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      try {
        const reason = await updateIncident(values.reason, record.id);
        console.log(reason);
        notification("", "Incidencia actualizada", "success");
      } catch (error) {
        notification(
          "",
          error?.message ?? "Ocurrió un error al actualizar la incidencia",
          "danger"
        );
      } finally {
        await dispatch(refreshFunction());
        dispatch(setLoading(false));
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [record]);

  return (
    <React.Fragment>
      <tr className="text-center">
        <td>{record.partner.id}</td>
        <td>
          {selectButton === "Remplazos" && record.replacementOf ? (
            <div className="d-flex flex-row justify-content-between align-items-center">
              <PartnerDetailIncident record={record} replacement />
              <i className="fa-solid fa-repeat fs-2"></i>
              <PartnerDetailIncident record={record.replacementOf} original />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <PartnerDetailIncident record={record} />
            </div>
          )}
        </td>
        {/* Cambiar cuando sea remplazo la hora de entrada es diferente aqui */}
        {selectButton === "Remplazos" && record.replacementOf ? (
          <React.Fragment>
            <td>{record.entryTime ?? record.startTime.split(" - ")[0]}</td>
            <td>{record.departureTime ?? record.endTime.split(" - ")[1]}</td>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <td>{record.startTime.split(" - ")[0]}</td>
            <td>{record.endTime.split(" - ")[1]}</td>
          </React.Fragment>
        )}
        <>
          <td>
            {record.assistanceCheck
              ? record?.assistanceCheck.checkTime
              : "Sin información"}
          </td>
          <td>
            {record?.assistanceCheckout
              ? record.assistanceCheckout.checkTime
              : "Sin información"}
          </td>
        </>
        <td>
          {/* {record.assistanceStatus ?? "-"} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p className="w-100 mt-1 fs-13px fw-800 bg">{title}</p>
            <CustomTextArea
              formik={formik}
              field="reason"
              label={""}
              labelRequired={false}
            />
          </div>
        </td>
        <td>
          {["Guardar"].map((status, statusIndex) => {
            const iconClass = getStatusIconClass(status);

            return (
              <Tooltip key={statusIndex} title={status} arrow>
                <button
                  type="button"
                  className="btn p-0"
                  onClick={() => {
                    // dispatch(setShowModal(true));
                    // dispatch(setTitle("Modificar registro"));
                    // dispatch(setCurrentRecord(record));
                    formik.handleSubmit();
                  }}
                >
                  <i className={`${iconClass} fs-1 mx-2`}></i>
                </button>
              </Tooltip>
            );
          })}
          {selectButton !== "Remplazos" && selectButton !== "Completados" && (
            <Tooltip title="Cambiar" arrow>
              <button
                type="button"
                className={`btn p-0`}
                onClick={() => {
                  dispatch(setShowModal(true));
                  dispatch(setTitle("Cambiar chilito"));
                  dispatch(setCurrentRecord(record));
                }}
                style={{
                  border: "none",
                }}
              >
                <i
                  className={`fa-solid fa-circle-arrow-right fs-1 mx-2 text-indigo-600`}
                ></i>
              </button>
            </Tooltip>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default RowsIncidents;

const getStatusIconClass = (status: string) => {
  switch (status) {
    case "Asistencia":
      return "fa-solid fa-circle-check text-success";
    case "Fuera de horario":
      return "fa-solid fa-circle-minus text-secondary";
    case "Retardo":
      return "fa-solid fa-circle-xmark text-danger";
    case "Sin registro":
      return "fa-solid fa-circle-question text-warning";
    case "Editar":
      return "fa-solid fa-pen-to-square text-warning";
    case "Guardar":
      return "fa-solid fa-floppy-disk text-success";
    default:
      return "";
  }
};
