import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store/index.ts";
import { setShowModal } from "../../../store/slices/ReportsSlice.ts";

import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import ButtonLink from "../../ButtonLink.tsx";

const TableUnfilled = () => {
  const dispatch = useDispatch();
  const { records } = useSelector((state: RootState) => state.report);

  const showModal = (branchId: number, modalType: string) =>
    dispatch(
      setShowModal({
        showModal: true,
        modalType: modalType,
        branchSelected: branchId,
      })
    );

  return (
    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Razón social</th>
          <th style={{ textAlign: "center" }}>Chilitos requeridos</th>
          <th style={{ textAlign: "center" }}>Chilitos en sucursal</th>
          <th style={{ textAlign: "center" }}>Chilitos en plantilla</th>
          <th style={{ textAlign: "center" }}>Chilitos sin coincidencia</th>
          <th style={{ textAlign: "center" }}>Sobrecarga en puestos</th>
          <th style={{ textAlign: "center" }}>Puestos sin cubrir</th>
        </tr>
      </thead>
      <tbody>
        {records.map((item) => (
          <tr key={item.branch.id}>
            <td>{item.branch.id}</td>
            <td>{toCamelCase(item.branch.name)}</td>
            <td>{toCamelCase(item.branch.businessName.name)}</td>
            <td style={{ textAlign: "center" }}>
              <ButtonLink
                className="btn btn-verde d-block btn-lg fs-14px"
                text={item.requiredWorkers}
                to={`/sucursales/editar/${item.branch.id}`}
                type="button"
              />
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <ButtonLink
                className="btn btn-azul d-block btn-lg fs-14px"
                text={item.totalPartners}
                to={`/sucursales/colaboradores/${item.branch.id}`}
                type="button"
              />
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <button
                type="button"
                className={`btn btn-${
                  item.totalWorkers < item.requiredWorkers ? "rojo" : "verde"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
                onClick={() =>
                  showModal(item.branch.id, "chilitos-en-plantilla")
                }
              >
                {item.totalWorkers}
              </button>
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className={`btn btn-${
                  item.workersWithout > 0 ? "amarillo" : "verde"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
                onClick={() =>
                  showModal(item.branch.id, "chilitos-sin-coincidencia")
                }
              >
                {item.workersWithout}
              </button>
            </td>

            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className={`btn btn-${
                  item.totalOverloaded > 0 ? "amarillo" : "verde"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
                onClick={() =>
                  showModal(item.branch.id, "sobrecarga-en-puestos")
                }
              >
                {item.totalOverloaded}
              </button>
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className={`btn btn-${
                  item.totalUnfilled > 0 ? "rojo" : "verde"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
                onClick={() => showModal(item.branch.id, "puestos-sin-cubrir")}
              >
                {item.totalUnfilled}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableUnfilled;
