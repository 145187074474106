import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Title: React.FC<ITitleProps> = ({
  baseTitle,
  basePath,
  activeTitle,
  title,
  subtitle,
  iconTitle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;

  return (
    <div className="border-0 m-4">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <a style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            Inicio
          </a>
        </li>
        <li className="breadcrumb-item">
          <a style={{ cursor: "pointer" }} onClick={() => navigate(basePath)}>
            {baseTitle}
          </a>
        </li>
        {activeTitle && (
          <li className="breadcrumb-item">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => navigate(activePath)}
            >
              {activeTitle}
            </a>
          </li>
        )}
      </ol>
      <h1 className="page-header">
        <button
          className="bg-white border border-black  rounded-4 "
          onClick={() => navigate(-1)}
          style={{ marginRight: "10px" }}
        >
          <i className="fa fa-arrow-left "></i>
        </button>
        {title} {iconTitle}
        <small>{subtitle}</small>
      </h1>
    </div>
  );
};

export default Title;

interface ITitleProps {
  baseTitle: string;
  basePath: string;

  activeTitle?: string;

  title: string;
  subtitle?: string;

  iconTitle?: React.ReactNode;
}