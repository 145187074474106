import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import FooterChili from "../../components/FooterChili.jsx";
import { Formik, useFormik } from "formik";
import { UserInitial } from "../../interfaces/formik/IUser.ts";
import UserSchema from "../../validation/schemas/UserSchema.ts";
import UserForm from "../../components/forms/UserForm.tsx";
import Title from "../../components/Title.tsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { notification } from "../../utils/Notifications.tsx";
import { saveUser } from "../../services/user.service.ts";
import Loader from "../../components/Loader.tsx";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button.tsx";
import Layout from "../../components/Layout.tsx";

const NewUser = () => {
  const context = useAppSettings();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: UserInitial,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          name: values.name,
          lastName: values.last_name,
          secondLastName: values.second_last_name,
          phone: values.phone,
          status: values.status,
          email: values.email,
          password: values.password,
          idRol: Number(values.id_rol),
          ...(values.id_branch && { idBranch: Number(values.id_branch) }),
        };
        await saveUser(body);
        notification("Éxito", "Usuario creado correctamente", "success");
        navigate("/usuarios");
      } catch (error) {
        notification("Error :(", error.message, "danger");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Layout>
      <Title
        baseTitle="Usuarios"
        basePath="/usuarios"
        activeTitle="Nuevo"
        title="Nuevo usuario"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales del usuario
          </PanelHeader>
          <UserForm formik={formik} />
        </Panel>
      </div>

      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/usuarios")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewUser;
