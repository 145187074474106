import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setLimit } from '../../../store/slices/sucursalesSlice.ts';
import { RootState } from '../../../store';

const PaginationSucursales = ({ rol }) => {
  const dispatch = useDispatch();
  const { page, limit, sucursalesList, total } = useSelector(
    (state: RootState) => state.sucursales
  );

  const handlePreviousPage = () => {
    if (page > 0) {
      dispatch(setPage(page - 1));
    }
  };

  const handleNextPage = () => {
    const lastItemIndex = (page + 1) * limit;
    if (lastItemIndex < total) {
      dispatch(setPage(page + 1));
    }
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(event.target.value);
    dispatch(setLimit(newLimit));
    dispatch(setPage(0));
  };

  if (rol === 'Lider de sucursal') return null;

  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Mostrando {Math.min(page * limit + sucursalesList.length, total)} de {total} registros de sucursales
      </div>
      <div className="d-flex align-items-center">
        <span className="me-2">Mostrar:</span>
        <select className="form-select me-3" value={limit} onChange={handleLimitChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li className={`page-item ${page === 0 && "disabled"}`}>
          <a href="#/" className="page-link" onClick={handlePreviousPage}>
            Anterior
          </a>
        </li>
        <li className={`page-item ${(page + 1) * limit >= total && "disabled"}`}>
          <a href="#/" className="page-link" onClick={handleNextPage}>
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PaginationSucursales;
