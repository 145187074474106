import * as yup from "yup";

const IncidentSchema = yup.object({
  partnerReplacement: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  // Hora entrada
  // Hora salida

  entryTime: yup.string().required("Campo requerido"),
  departureTime: yup.string().required("Campo requerido"),
  observations: yup.string().required("Campo requerido"),
});

export default IncidentSchema;
