import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Application } from "../../models/Partner";
// import {
//   ActionMenuListaNegraSelected,
//   MenuSelectedListaNegraEnum,
// } from "../../types/BlackListSliceTypes.ts";

const initialState = {
  // menuSelected: MenuSelectedListaNegraEnum.todos,
  total: 0,
  solicitudesList: [] as Application[],
  page: 0,
  limit: 50,
  loading: true,
  param: "",
  fetch: false,
  showModal: false,
  query: "",
  modalBan: false,
  modalSetStatus: false,
  currentUser: null,
  sortBy: "id",
  defaultSort: "asc",
};

const solicitudesSlice = createSlice({
  name: "solicitudes",
  initialState,
  reducers: {
    // setMenu: (state, action: ActionMenuListaNegraSelected) => {
    //   state.menuSelected = action.payload;
    //   state.page = 0;
    // },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSolicitudes: (state, action: PayloadAction<Application[]>) => {
      state.solicitudesList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setModalBan: (state, action: PayloadAction<boolean>) => {
      state.modalBan = action.payload;
    },
    setModalSetStatus: (state, action: PayloadAction<boolean>) => {
      state.modalSetStatus = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
  },
});

export const {
  // setMenu,
  setPage,
  setLimit,
  setSolicitudes,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setShowModal,
  setQuery,
  resetState,
  setModalBan,
  setModalSetStatus,
  setCurrentUser,
  setSortBy,
  setDefaultSort,
} = solicitudesSlice.actions;

export default solicitudesSlice.reducer;
