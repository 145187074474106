import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuery } from "../../store/slices/branchTemplateSlice.ts";
import * as bopen from "bopen"

const FilterPlantillas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    type: "all",
    param: "",
  };
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        console.log(queryString);
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filtros)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "param":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "type":
            keyName = "Razón social";
            valueName = value;
            break;
          case "active":
            keyName = "Activo";
            valueName = value === "true" ? "Si" : "No";
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  }

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "" && updatedFilters[key] !== "Todos")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(updatedFilters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  return (
    <div>
      <div className="input-group mb-2">
        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}>
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
            <button
              className="btn btn-red ms-2"
              onClick={() => navigate(`nuevo`)}
            >
              Agregar plantilla
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        {
          showPills && selectedFilters(filters).map((filter: any) => (
            <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {filter.keyName}: {filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))
        }
      </div>
    </div>

  );
};

export default FilterPlantillas;
