import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationsUsers from "../../models/NotificationsUsers.ts";
import exp from "constants";

const initialState = {
  typeUser: "",
  branch: "",
  rule: "",
  total: 0,
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  fetch: false,
  query: "",
  notificationsUser: [] as NotificationsUsers[],
};

const notificationsUserSlice = createSlice({
  name: "notificationsUser",
  initialState,
  reducers: {
    setTypeUser: (state, action: PayloadAction<string>) => {
      state.typeUser = action.payload;
      state.page = 0;
    },
    setBranch: (state, action: PayloadAction<string>) => {
      state.branch = action.payload;
      state.page = 0;
    },
    setRule: (state, action: PayloadAction<string>) => {
      state.rule = action.payload;
      state.page = 0;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setNotificationsUser: (
      state,
      action: PayloadAction<NotificationsUsers[]>
    ) => {
      state.notificationsUser = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setTypeUser,
  setBranch,
  setRule,
  setTotal,
  setPage,
  setLimit,
  setNotificationsUser,
  setLoading,
  setParam,
  setFetch,
  setQuery,
  resetState,
} = notificationsUserSlice.actions;

export default notificationsUserSlice.reducer;


