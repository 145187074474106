import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { StatsChilito } from "../../models/StatsChilito";
import TableChilitosStats from "../../components/reports/chilitosStats/TableChilitosStats.tsx";
import { getStatsChilitos } from "../../services/candidate.service.ts";
import { notification } from "../../utils/Notifications.tsx";

const ChilitosStats = () => {
  useAppSettings();

  const [records, setRecords] = React.useState<StatsChilito[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchRecords = async () => {
    try {
      const data = await getStatsChilitos();
      setRecords(data);
    } catch (error) {
      console.error(error);
      notification(
        "Error",
        "Error al obtener los registros",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Reportes"
        basePath="/reportes"
        title="Estadisticas de los chilitos"
      />

      <div className="border-0 m-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <TableChilitosStats records={records} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChilitosStats;
