import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../../components/Layout.tsx";
import { findAll } from "../../services/changePosition.service.ts";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Title from "../../components/Title.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrent,
  setFilters,
  setLoading,
  setQuery,
  setRecords,
  setTotal,
} from "../../store/slices/traspasosSlice.ts";
import { notification } from "../../utils/Notifications.tsx";
import { RootState } from "../../store/index.ts";
import ModalContainer from "../../components/ModalContainer.tsx";
import ChangePositionModal from "../../components/colaboradores/ChangePositionModal.tsx";
import { useParams } from "react-router-dom";
import TableTraspasos from "../../components/traspasos/TableTraspasos.tsx";
import FilterTraspasos from "../../components/traspasos/FilterTraspasos.tsx";
import PaginationTraspasos from "../../components/traspasos/PaginationTraspasos.tsx";
import DetailsModal from "../../components/traspasos/DetailsModal.tsx";
import {
  setModalChildrenTraspasos,
  setModalTitle,
  setModalTitleTraspasos,
  setShowModal as setShowModalSlice,
  setShowModalTraspasos,
} from "../../store/slices/colaboradoresSlice.ts";
import ChangePositionModalTraspasos from "../../components/colaboradores/ChangePositionModalTraspasos.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Traspasos = () => {
  useAppSettings();
  let id;

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      updateQuery("entrantes");
    } else {
      updateQuery("salientes");
    }
  };

  const {
    loading,
    current,
    page,
    limit,
    query,
    param,
    fetch,
    sortBy,
    defaultSort,
  } = useSelector((state: RootState) => state.traspasos);
  const fetchDependencies = async () => {
    dispatch(setLoading(true));

    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query;
      if (queryToSend.length === 0) {
        queryToSend = "tab=entrantes&type=approved";
      }
      const querySend = `?sort=${sortBy}&order=${defaultSort}&offset=${offset}&limit=${limit}&branch=${id}&${queryToSend}`;

      const response = await findAll(querySend);

      dispatch(setRecords(response.records));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateQuery = (tab: string) => {
    let newQuery = `tab=${tab}&type=approved`;
    dispatch(setQuery(newQuery));
    dispatch(setFilters({ type: "approved", tab: tab }));
  };

  useEffect(() => {
    fetchDependencies();
  }, [page, limit, param, fetch, query, sortBy, defaultSort]);

  const { showModalTraspasos, modalChildrenTraspasos, modalTitleTraspasos } =
    useSelector((state: RootState) => state.colaboradores);
  return (
    <Layout loading={loading}>
      <Title baseTitle="Traspasos" basePath="/traspasos" title="Traspasos" />
      <div className="card border-0 m-4">
        <div className="tab-content">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Entrantes" {...a11yProps(0)} />
              <Tab label="Salientes" {...a11yProps(1)} />
            </Tabs>

            <button
              className="btn btn-azul me-2"
              onClick={() => {
                dispatch(setShowModalTraspasos(true));
                dispatch(
                  setModalChildrenTraspasos(
                    <ChangePositionModalTraspasos
                      closeFn={() => dispatch(setShowModalTraspasos(false))}
                      branchId={id}
                    />
                  )
                );
                dispatch(setModalTitleTraspasos("Solicitar traspaso"));
              }}
            >
              Nuevo traspaso
            </button>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <FilterTraspasos />
            <TableTraspasos />
            <PaginationTraspasos />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FilterTraspasos />
            <TableTraspasos />
            <PaginationTraspasos />
          </CustomTabPanel>
        </div>
      </div>
      <ModalContainer
        open={current !== null}
        title="Detalles del traspaso"
        closeFn={() => dispatch(setCurrent(null))}
      >
        <DetailsModal
          closeFn={() => dispatch(setCurrent(null))}
          record={current}
        />
      </ModalContainer>
      <ModalContainer
        open={showModalTraspasos}
        closeFn={() => dispatch(setShowModalTraspasos(false))}
        title={modalTitleTraspasos}
        children={modalChildrenTraspasos}
      />
    </Layout>
  );
};

export default Traspasos;
