import { getCurrencyFormat } from "../../utils/index.ts";

export interface IPosition {
  id: number;
  name: string;
  minSalary: number;
  maxSalary: number;
}

export interface IBranch {
  id: number;
  name: string;
}

export interface IVacancy {
  id: number;
  position: IPosition;
}

export interface ICandidate {
  id: number;
  vacancy: IVacancy;
  branch: IBranch;
}

export interface ISalaryHistoryPut {
  id: number;
  currentSalary: number;
  minSalary: number;
  maxSalary: number;
  newSalary: number | null;
  user: string;
  updateReason: string;
  partnerInfo: number;
}

export const SalaryHistoryInitial: ISalaryHistoryPut = {
  id: -1,
  currentSalary: 0,
  minSalary: 0,
  maxSalary: 0,
  newSalary: null,
  user: "",
  updateReason: "",
  partnerInfo: -1,
};

export const SalaryHistoryFill = (data) => {
  console.log("DATOS PARA FILL SALARIOS ", data);

  return {
    id: data?.id,
    currentSalary: Number(data?.candidate?.monthlySalary),
    minSalary: Number(data?.candidate?.vacancy?.position?.minSalary),
    maxSalary: Number(data?.candidate?.vacancy?.position?.maxSalary),
    currentSalaryString: getCurrencyFormat(data?.candidate?.monthlySalary),
    minSalaryString: getCurrencyFormat(data?.candidate?.vacancy?.position?.minSalary),
    maxSalaryString: getCurrencyFormat(data?.candidate?.vacancy?.position?.maxSalary),
    newSalary: null,
    partnerInfo: data?.id,
    user: data?.user,
    updateReason: data?.updateReason,
  };
};