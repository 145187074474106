import React from "react";
import { findAllAssistance } from "../../../../services/assistanceCheck.service.ts";
import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";
import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import FilterChequeo from "./FilterChequeo.tsx";
import PaginationChequeo from "./PaginationChequeo.tsx";
import TableChequeo from "./TableChequeo.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import {
  setLoading,
  setRecords,
  setTotal,
} from "../../../../store/slices/chequeoSlice.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import { useParams } from "react-router-dom";
import { findAllSchedulesByBranch } from "../../../../services/branchSchedule.service.ts";

const Chequeo = () => {
  useAppSettings();
  // const { id } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  let id;

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }
  
  const dispatch = useDispatch();
  const { page, limit, param, fetch, query, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.chequeo
  );

  React.useEffect(() => {
    fetchPuestos();
  }, [page, limit, param, fetch, query, sortBy, defaultSort]);

  const fetchPuestos = async () => {
    dispatch(setLoading(true));
    try {
      if (!query.includes("dateStart")) {
        dispatch(setLoading(false));
        return;
      }
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend =
        query +
        `&sort=${sortBy}&order=${defaultSort}&limit=${limit}&offset=${offset}`;
      if (id) queryToSend += `&checkBranch=${id}`;
      console.log("Queryyy final", queryToSend);
      const response = await findAllSchedulesByBranch(Number(id), queryToSend);
      dispatch(setRecords(response.records));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Layout>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle="Chequeos"
        title="Resumen de registros"
      />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <FilterChequeo id={id} />
            <TableChequeo />
            <PaginationChequeo />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chequeo;