import httpClient from "./httpClient";

const prefix = "/position-change";

export async function findAll(query: string) {
  return (await httpClient.get(`${prefix}${query}`)).data;
}

export async function savePositionChange(body: {}) {
  return (await httpClient.post(`${prefix}/save`, body)).data;
}

export async function checkPending(id: number) {
  return (await httpClient.get(`${prefix}/check-pending/${id}`)).data;
}

export async function authorizeOperation(id: number) {
  return (await httpClient.patch(`${prefix}/authorize/${id}`)).data;
}

export async function rejectOperation(
  id: number,
  body: { reasonForRejection: string }
) {
  return (await httpClient.patch(`${prefix}/reject/${id}`, body)).data;
}


export async function checkAllPending(query?: string) {
  return (await httpClient.get(`${prefix}/check-all-pending?${query}`)).data;
}