import React, { useState } from "react";
import { notification } from "../../utils/Notifications.tsx";
import { deleteProofFile } from "../../services/incapacities.service.ts";

const IncapacityFormEditFilesCreate = ({ formik, reloadFiles }) => {
  const { proofFiles } = formik.values;
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleDeleteNewFile = (fileIndex) => {
    const updatedFiles = proofFiles.filter((_, index) => index !== fileIndex);
    formik.setFieldValue("proofFiles", updatedFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedFiles = [...proofFiles, ...files];
    formik.setFieldValue("proofFiles", updatedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = [...proofFiles, ...files];
    formik.setFieldValue("proofFiles", updatedFiles);
  };

  const handleDelete = async (fileId) => {
    setLoading(true);
    try {
      await deleteProofFile(fileId);
      notification("Éxito", "Archivo eliminado correctamente", "success");
      setLoading(false);
      reloadFiles();
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      notification("Error", "No se pudo eliminar el archivo", "danger");
      setLoading(false);
    }
  };



  const handleImageClick = (file) => {
    if (file.type && file.type.match(/(png|jpg|jpeg)$/)) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleExistingImageClick = (file) => {
    if (file.proofFile.extension.match(/(png|jpg|jpeg)$/)) {
      setPreviewImage(file.proofFile.url);
    }
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  

  return (
    <div className="row p-4">
      <div
        className="mb-4 border-dashed text-center p-4"
        style={{
          border: "2px dashed #334155",
          borderRadius: "12px",
          cursor: "pointer",
          backgroundColor: "#f8f9fa",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <h5 className="text-dark">
          Arrastra y suelta archivos aquí o selecciona archivos
        </h5>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="d-none"
          id="file-upload"
          accept=".png,.jpg,.jpeg,.pdf"
        />
        <label htmlFor="file-upload" className="btn btn-dark">
          Seleccionar archivos
        </label>
      </div>

      {proofFiles.length > 0 && (
        <div className="col-12 mb-4">
          <h6>Archivos seleccionados:</h6>
          <div className="row">
            {proofFiles.map((file, index) => (
              <div key={index} className="col-md-3 mb-3">
                <div
                  className="card shadow-sm border-light rounded position-relative"
                  style={{ transition: "transform 0.2s", cursor: "pointer" }}
                  onClick={() => handleImageClick(file)}
                >
                  <div className="card-body text-center">
                    <div className="mb-3">
                      {file.type && file.type.match(/(png|jpg|jpeg)$/) ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="img-fluid rounded"
                          style={{
                            maxHeight: "150px",
                            objectFit: "cover",
                          }}
                        />
                      ) : file.type && file.type.match(/(pdf)$/) ? (
                        <div>
                          <p>Archivo PDF, haz clic en "Ver".</p>
                          <a
                            href={URL.createObjectURL(file)}
                            className="btn btn-secondary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver PDF
                          </a>
                        </div>
                      ) : (
                        <p>Vista previa no disponible</p>
                      )}
                    </div>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNewFile(index);
                      }}
                      title="Eliminar"
                      disabled={loading}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {previewImage && (
        <div
          className="modal fade show d-block bg-black bg-opacity-75"
          tabIndex="-1"
          onClick={closePreview}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={previewImage}
                  alt="Vista previa"
                  className="img-fluid"
                  style={{ maxHeight: "90vh", objectFit: "contain" }}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger" onClick={closePreview}>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncapacityFormEditFilesCreate;
