import * as yup from "yup";

export const ModifyAssitanceSchema = yup.object({
  status: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),

  reasonToModify: yup
    .string()
    .required("Campo requerido")
    .max(100, "Máximo 100 caracteres"),
});
