import React, { useEffect, useState, useRef } from "react";
import { Partner } from "../../models/Partner";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { getBadgesForPartner } from "../../services/badges.service.ts";
import EmptyImage from "../../assets/img/ic_content.svg";
import MedalIconPng from "../../assets/img/medalIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  setId,
  setIdToRemove,
  setName,
  setNametoRemove,
  setShowModalAssign,
  setShowModalRemovePartner,
} from "../../store/slices/badgesSlice.ts";
import { BadgesProperties } from "../../models/Badges.ts";
import ModalContainer from "../ModalContainer.tsx";
import { RootState } from "../../store/index.ts";
import DeleteBadgeToPartner from "../../pages/badges/DeleteBadgeToPartner.tsx";
import AssignBadgeToPartner from "../../pages/badges/AssignBadgeToPartner.tsx";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { Tooltip, Typography } from "@mui/material";

const InformationBadges = ({
  partner,
  reloadPage,
  disabled = false,
}: {
  partner: Partner;
  reloadPage: any;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    idToRemove,
    fetchRemove,
    shotModalAssign,
    showModaltoRemove,
    nametoRemove,
  } = useSelector((state: RootState) => state.badges);
  const [badges, setBadges] = useState<BadgesProperties[]>([]);
  const dispatch = useDispatch();

  const fetchBadges = async () => {
    setLoading(true);
    try {
      const data = await getBadgesForPartner(partner.id);
      setBadges(data);
    } catch (error) {
      console.error("Error al cargar incapacidades", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBadges();
  }, [partner, reloadPage, fetchRemove]);

  return (
    <React.Fragment>
      <div className="tab-pane fade" id="insignias">
        {!disabled && (
          <button
            className="btn btn-success mb-4 rounded-3 d-flex align-items-center"
            onClick={() => {
              dispatch(setShowModalAssign(true));
            }}
          >
            <i className="fa-solid fa-medal me-2"></i>
            Asignar nueva insignia
          </button>
        )}
        <div className="row row-cols-2 row-cols-md-6 g-3">
          {badges.length === 0 ? (
            <div className="py-4 text-center">
              <img src={EmptyImage} alt="" className="mx-auto w-250px" />
              <h5 className="text-secondary fs-20px">Aún no hay insignias</h5>
            </div>
          ) : (
            badges.map((badge) => (
              <div key={badge?.id} className="col">
                <div
                  className="card rounded-4 h-100 shadow-sm position-relative text-center"
                  style={{ height: "100%" }}
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    <img
                      src={MedalIconPng}
                      className="mb-2"
                      style={{ width: "70px", height: "70px" }}
                      alt=""
                    />
                    {!disabled && (
                      <i
                        className="fa-solid fa-trash position-absolute"
                        style={{
                          top: 10,
                          right: 10,
                          cursor: "pointer",
                          fontSize: "15px",
                          color: "#e11d48",
                        }}
                        onClick={() => {
                          dispatch(setIdToRemove(Number(badge.id)));
                          dispatch(setNametoRemove(badge.title));
                          dispatch(setShowModalRemovePartner(true));
                        }}
                      ></i>
                    )}

                    <Tooltip
                      title={badge?.title}
                      arrow
                      disableHoverListener={!isTextTruncated(badge?.title)}
                    >
                      <h5
                        className="card-title fs-4 mt-2 fw-bold mb-2 text-truncate"
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        {badge?.title}
                      </h5>
                    </Tooltip>

                    <Tooltip
                      title={badge?.description}
                      arrow
                      disableHoverListener={
                        !isTextTruncated(badge?.description)
                      }
                    >
                      <p
                        className="card-text mb-2 text-truncate"
                        style={{
                          maxWidth: "90%",
                        }}
                      >
                        {badge?.description}
                      </p>
                    </Tooltip>

                    <Tooltip
                      title={formatDistanceToNow(parseISO(badge?.assignedAt), {
                        addSuffix: true,
                        locale: es,
                      })}
                      arrow
                      disableHoverListener={
                        !isTextTruncated(
                          formatDistanceToNow(parseISO(badge?.assignedAt), {
                            addSuffix: true,
                            locale: es,
                          })
                        )
                      }
                    >
                      <p
                        className="text-muted mt-auto align-self-end"
                        style={{
                          fontSize: "11px",
                          textAlign: "right",
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatDistanceToNow(parseISO(badge?.assignedAt), {
                          addSuffix: true,
                          locale: es,
                        })}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <ModalContainer
        open={showModaltoRemove}
        closeFn={() => {
          dispatch(setShowModalRemovePartner(false));
          dispatch(setId(-1));
          dispatch(setName(""));
        }}
        title={`Eliminar la insignia ${nametoRemove} al colaborador ${partner.person.firstName}`}
        children={
          <DeleteBadgeToPartner
            name={nametoRemove}
            id={idToRemove}
            idPartner={partner?.id}
            closeFn={() => {
              dispatch(setShowModalRemovePartner(false));
              dispatch(setId(-1));
              dispatch(setName(""));
            }}
          />
        }
      />
      <ModalContainer
        open={shotModalAssign}
        closeFn={() => {
          dispatch(setShowModalAssign(false));
        }}
        title={`Asignar nueva insignia al colaborador ${partner.person.firstName}`}
        children={
          <AssignBadgeToPartner
            partner={partner}
            badgesOfPartner={badges}
            closeFn={() => {
              dispatch(setShowModalAssign(false));
            }}
          />
        }
      />
    </React.Fragment>
  );
};

const isTextTruncated = (text: string | undefined): boolean => {
  if (!text) return false;

  const span = document.createElement("span");
  span.style.position = "absolute";
  span.style.visibility = "hidden";
  span.style.whiteSpace = "nowrap";
  span.textContent = text;

  document.body.appendChild(span);
  const isTruncated = span.offsetWidth > 200;
  document.body.removeChild(span);

  return isTruncated;
};

export default InformationBadges;
