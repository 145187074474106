import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMenuSelected,
  MenuSelectedEnum,
} from "../../types/UserSliceTypes.ts";
import User from "../../models/User.ts";

const initialState = {
  menuSelected: MenuSelectedEnum.all,
  total: 0,
  users: [] as User[],
  page: 0,
  limit: 50,
  loading: true,
  param: "",
  fetch: false,
  query: "",
  sortBy: "id",
  defaultSort: "asc",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMenu: (state, action: ActionMenuSelected) => {
      state.menuSelected = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
  },
});

export const {
  setMenu,
  setPage,
  setLimit,
  setUsers,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  resetState,
  setSortBy,
  setDefaultSort,
} = userSlice.actions;

export default userSlice.reducer;
