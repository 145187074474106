import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import {
  fetchPartnerById,
  fetchPartnerByUser,
} from "../../services/application.service.ts";
import { Partner } from "../../models/Partner.ts";
import S3Service from "../../services/s3.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import Layout from "../../components/Layout.tsx";
import ProfileHeader from "../../components/profile/ProfileHeader.tsx";
import InformationUser from "../../components/profile/InformationUser.tsx";
import TimeLineDocuments from "../../components/profile/TimeLineDocuments.tsx";
import InformationPosition from "../../components/profile/InformationPosition.tsx";
import Contracts from "../../components/profile/Contracts.tsx";
import InformationBadges from "../../components/profile/InformationBadges.tsx";
import Incapacities from "../../components/profile/Incapacities.tsx";
import IncapacitiesPartner from "../../components/profile/IncapacitiesPartner.tsx";

const MyExpedient = () => {
  const { user, token } = useSelector((state: RootState) => state.auth);
  const context = useAppSettings();
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [partner, setPartner] = React.useState<Partner>({} as Partner);
  const [files, setFiles] = React.useState({
    profile: "",
  });

  const fetchPartner = async () => {
    setLoading(true);
    try {
      const response = await fetchPartnerByUser(Number(user.id));
      setPartner(response);
      const profileUrl = await S3Service.getFile(
        "PERFIL_" + response.person.curp
      );
      setFiles({ profile: profileUrl.url });
      console.log(response);
    } catch (error) {
      setError(error?.message);
      notification(
        "Error",
        "Ocurrió un error al recuperar al colaborador",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (token) {
      fetchPartner();
    }
  }, [token]);

  return (
    <Layout loading={loading}>
      {!loading && (
        <div id="content" className="app-content p-0">
          <ProfileHeader partner={partner} profileUrl={files.profile} />
          <div className="profile-content">
            <div className="tab-content p-0">
              <InformationUser
                partner={partner}
                reloadPage={() => fetchPartner()}
                disabled={true}
              />
              <TimeLineDocuments
                partner={partner}
                reloadPage={() => fetchPartner()}
              />
              <InformationPosition
                partner={partner}
                reloadPage={() => fetchPartner()}
              />
              <Contracts partner={partner} />
              <InformationBadges
                partner={partner}
                reloadPage={() => fetchPartner()}
                disabled={true}
              />
              <IncapacitiesPartner partner={partner} reloadPage={fetchPartner} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default MyExpedient;
