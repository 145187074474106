import * as yup from "yup";

export const EditTimeAppoinmentSchema = yup.object({
  time: yup
    .number()
    .min(10, "El valor debe de ser mayor a 10")
    .max(30, "El valor debe de ser menor a 30")
    .required("El tiempo por cita es requerido"),
});

export const EditSalary = yup.object({
  salary: yup
    .number()
    .min(1000, "El valor debe de ser mayor a 1000")
    .max(100000, "El valor debe de ser menor a 100000")
    .required("El salario es requerido"),
});
