import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import EmptyImage from "../../assets/img/ic_content.svg";
import { notification } from "../../utils/Notifications.tsx";
import { deleteBadge } from "../../services/badges.service.ts";
import ModalContainer from "../../components/ModalContainer.tsx";
import DeleteBadge from "./DeleteBadge.tsx";
import Loader from "../../components/Loader.tsx";
import EditBadge from "./EditBadge.tsx";
import MedalIconPng from "../../assets/img/medalIcon.png";

import {
  setId,
  setName,
  setShowModal,
  setShowModalBadges,
} from "../../store/slices/badgesSlice.ts";
import { Tooltip } from "@mui/material";

type Props = {
  reloadPage: () => Promise<void>;
};

const TableBadges = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { badges, idEdit, showModal, name, showModalBadges } = useSelector(
    (state: RootState) => state.badges
  );

  const handleEdit = (badgeId: number) => {
    navigate(`/badges/edit/${badgeId}`);
  };

  return (
    <React.Fragment>
      <div className="row ">
        {badges.length === 0 ? (
          <div className="py-4 text-center">
            <img src={EmptyImage} alt="" className="mx-auto w-250px" />
            <h5 className="text-secondary fs-20px">Aún no hay insignias</h5>
          </div>
        ) : (
          badges.map((badge) => (
            <div key={badge.id} className="col-md-3 col-sm-6 mb-4 d-flex">
              <div
                className="card rounded-4 h-100 shadow-sm position-relative text-center"
                style={{ width: "100%", height: "250px" }}
              >
                <div className="card-body d-flex flex-column text-center">
                  <img
                    src={MedalIconPng}
                    className="mb-2  position-absolute"
                    style={{ width: "50px", height: "50px", top: 10, left: 10 }}
                    alt=""
                  />

                  <i
                    className="fa-solid fa-edit position-absolute"
                    style={{
                      top: 10,
                      right: 40,
                      cursor: "pointer",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      dispatch(setId(Number(badge.id)));
                      dispatch(setName(badge.title));
                      dispatch(setShowModalBadges(true));
                    }}
                  ></i>
                  <i
                    className="fa-solid fa-trash position-absolute"
                    style={{
                      top: 10,
                      right: 10,
                      cursor: "pointer",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      dispatch(setId(Number(badge.id)));
                      dispatch(setName(badge.title));
                      dispatch(setShowModal(true));
                    }}
                  ></i>

                  <div className="mt-5 ">
                    <Tooltip
                      title={badge?.title}
                      arrow
                      disableHoverListener={!isTextTruncated(badge?.title)}
                    >
                      <h5
                        className="card-title text-center fs-4 mt-2 fw-bold mb-2 text-truncate"
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        {badge?.title}
                      </h5>
                    </Tooltip>
                    <Tooltip
                      title={badge?.description}
                      arrow
                      disableHoverListener={
                        !isTextTruncated(badge?.description)
                      }
                    >
                      <p
                        className="card-text text-center mb-2 text-truncate"
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        {badge?.description}
                      </p>
                    </Tooltip>
                    {badge.parentBadge && (
                      <p className="text-muted mt-2 text-end  small">
                        Se desbloquea al completar:
                        <strong>{badge.parentBadge.title}</strong>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => {
          dispatch(setShowModal(false));
          dispatch(setId(-1));
          dispatch(setName(""));
        }}
        title={`Eliminar la insignia ${name}`}
        children={
          <DeleteBadge
            name={name}
            id={idEdit}
            closeFn={() => {
              dispatch(setShowModal(false));
              dispatch(setId(-1));
              dispatch(setName(""));
            }}
          />
        }
      />
      <ModalContainer
        open={showModalBadges}
        closeFn={() => {
          dispatch(setShowModalBadges(false));
          dispatch(setId(-1));
          dispatch(setName(""));
        }}
        title={!idEdit ? "Crear insignia" : `Editar la insignia ${name}`}
        children={
          <EditBadge
            name={name}
            id={idEdit}
            closeFn={() => {
              dispatch(setShowModalBadges(false));
              dispatch(setId(-1));
              dispatch(setName(""));
            }}
          />
        }
      />
    </React.Fragment>
  );
};

const isTextTruncated = (text: string | undefined): boolean => {
  if (!text) return false;

  const span = document.createElement("span");
  span.style.position = "absolute";
  span.style.visibility = "hidden";
  span.style.whiteSpace = "nowrap";
  span.textContent = text;

  document.body.appendChild(span);
  const isTruncated = span.offsetWidth > 200;
  document.body.removeChild(span);

  return isTruncated;
};

export default TableBadges;
