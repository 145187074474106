import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setPage } from "../../../../store/slices/inasistenciasSlice.ts";

const PaginationInasistencias = () => {
  const dispatch = useDispatch();
  const { page, limit, records, total } = useSelector(
    (state: RootState) => state.inasistencias
  );

  const handlePreviousPage = () => {
    if (page > 0) {
      dispatch(setPage(page - 1));
    }
  };

  const handleNextPage = () => {
    const lastItemIndex = (page + 1) * limit;
    if (lastItemIndex < total) {
      dispatch(setPage(page + 1));
    }
  };
  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Mostrando {Math.min(page * limit + records?.length, total)} de {total}{" "}
        registros
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li className={`page-item ${page === 0 && "disabled"} `}>
          <a href="#/" className="page-link" onClick={handlePreviousPage}>
            Anterior
          </a>
        </li>

        <li
          className={`page-item ${(page + 1) * limit >= total && "disabled"} `}
        >
          <a href="#/" className="page-link" onClick={handleNextPage}>
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PaginationInasistencias;
