import * as Yup from "yup";

export const PositionSchema = Yup.object().shape({
  positionName: Yup.string().required("Campo requerido"),
  highRisk: Yup.boolean().required("Campo requerido"),
  idDepartment: Yup.number().required("Campo requerido"),
  idBusinessName: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  documentTypesInmediate: Yup.array()
    .min(1, "Debe seleccionar al menos un documento")
    .required("Campo requerido"),
  documentTypesNoInmediate: Yup.array()
    .min(1, "Debe seleccionar al menos un documento")
    .required("Campo requerido"),
  minSalary: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido")
    .test(
      "minSalary",
      "El salario mínimo no puede ser mayor al salario máximo",
      function (value) {
        return value <= this.parent.maxSalary;
      }
    ),
  maxSalary: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido")
    .test(
      "maxSalary",
      "El salario máximo no puede ser menor al salario mínimo",
      function (value) {
        return value >= this.parent.minSalary;
      }
    ),
  funtions: Yup.array().of(Yup.string().required("Campo requerido")),
});
