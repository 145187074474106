import React from "react";
import { Link } from "react-router-dom";

const ModalTacticaJuego = ({
  closeFn,
  branchId,
}: {
  closeFn: () => void;
  branchId: number;
}) => {
  return (
    <React.Fragment>
      {/* Grid de 3 botones */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <Link
              to={`horario/${branchId}`}
              className="btn btn-success w-100 d-flex flex-column align-items-center fs-15px"
            >
              <img
                src="../assets/img/icons/schedule.png"
                style={{ width: "50px" }}
              />
              <span>Horario</span>
            </Link>
          </div>

          <div className="col-12 col-md-4 mb-3">
            <Link
              to={`chequeo/${branchId}`}
              className="btn btn-verde w-100 d-flex flex-column align-items-center fs-15px"
            >
              <img
                src="../assets/img/icons/summarySchedule.png"
                style={{ width: "50px" }}
              />
              <span>Reporte reloj</span>
            </Link>
          </div>

          <div className="col-12 col-md-4 mb-3">
            <Link
              to={`resumen-horario/${branchId}`}
              className="btn btn-amarillo w-100 d-flex flex-column align-items-center fs-15px"
            >
              <img
                src="../assets/img/icons/gridIcon.webp"
                style={{ width: "50px" }}
              />
              <span>Resumen</span>
            </Link>
          </div>

          <div className="col-12 col-md-4 mb-3">
            <Link
              to={`incidencias/${branchId}`}
              className="btn btn-danger w-100 d-flex flex-column align-items-center fs-15px"
            >
              <img
                src="../assets/img/icons/incidencias.png"
                style={{ width: "50px" }}
              />
              <span>Incidencias</span>
            </Link>
          </div>

          <div className="col-12 col-md-4 mb-3">
            <Link
              to={`resumen-horario-asistencia/${branchId}`}
              className="btn btn-info w-100 d-flex flex-column align-items-center fs-15px"
            >
              <img
                src="../assets/img/icons/coberturaTiempoReal.png"
                style={{ width: "50px" }}
              />
              <span>Cobertura</span>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalTacticaJuego;
