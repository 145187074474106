import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  records: [],
  page: 0,
  limit: 5,
  loading: true,
  param: "",
  fetch: false,
  filtros: {
    search: "",
    ingreso: "",
    sucursal: -1,
    puesto: -1,
  },
  query: "",
  sortBy: "id",
  defaultSort: "desc",
  messageModal: false,
};

const inasistenciasSlice = createSlice({
  name: "inasistencias",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setRecords: (state, action: PayloadAction<[]>) => {
      state.records = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setFiltros: (state, action: PayloadAction<any>) => {
      state.filtros = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
    setMessageModal: (state, action: PayloadAction<boolean>) => {
      state.messageModal = action.payload;
    }
  },
});

export const {
  setPage,
  setLimit,
  setRecords,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setFiltros,
  setQuery,
  resetState,
  setSortBy,
  setDefaultSort,
  setMessageModal,
} = inasistenciasSlice.actions;

export default inasistenciasSlice.reducer;
