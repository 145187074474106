import httpClient from "./httpClient";

const prefix = "/partners";

export async function unsubscribePartner(
  id: number,
  body: { reasonUnsubscribe: string }
) {
  return (await httpClient.patch(`${prefix}/unsubscribe/${id}`, body)).data;
}

export async function unsubscribeProgrammingPartner(
  id: number,
  body: any
) {
  return (await httpClient.patch(`${prefix}/unsubscribe-programming/${id}`, body)).data;
}

export async function fetchPartner(id: number) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}

export async function fetchPartners(query: string = "") {
  return (await httpClient.get(`${prefix}${query}`)).data;
}

export async function getInfoPositionPartner(id: number) {
  return (await httpClient.get(`${prefix}/get-position-info/${id}`)).data;
}

export async function getSalaryHistory(id: number) {
  return (await httpClient.get(`${prefix}/get-salary-history/${id}`)).data;
}

export async function updateSalaryForHistory(
  id: number,
  body: { newSalary: number; reason?: string; userId?: number }
) {
  return (await httpClient.patch(`${prefix}/update-monthly-salary/${id}`, body))
    .data;
}

export async function updateParnterMessage(
  id: number,
  body: { message: string }
) {
  return (await httpClient.patch(`${prefix}/message/${id}`, body)).data;
}

export async function readPartnersByBranch(id: number, params?: any) {
  return (await httpClient.get(`${prefix}/branch/${id}`, { params })).data;
}

export async function readPartnerByBranches(id: number) {
  return (await httpClient.get(`${prefix}/branch/${id}`)).data;
}

export async function reactivePartner(id: number) {
  return (await httpClient.patch(`${prefix}/reactivate/${id}`)).data;
}

export async function updatePartner(id: number, body: any) {
  return (await httpClient.put(`${prefix}/update-partner/${id}`, body)).data;
}

export async function updateSalary(id: number, body: any) {
  return (await httpClient.patch(`${prefix}/update-salary/${id}`, body)).data;
}

export async function updateIMSSSalary(id: number, body: any) {
  return (await httpClient.patch(`${prefix}/update-imss-salary/${id}`, body))
    .data;
}

export async function updateSignature(id: number, body: any) {
  return (await httpClient.patch(`${prefix}/update-signature/${id}`, body))
    .data;
}

export async function fetchPartnerCredentials(id: number) {
  return (await httpClient.get(`${prefix}/get-credentials/${id}`)).data;
}

export async function fetchPartnersIncompleted(query?: string) {
  return (await httpClient.get(`${prefix}/get-pending-partners?${query}`)).data;
}

export async function savePartnerCredentials(body: {
  email: string;
  partnerId: number;
}) {
  return (await httpClient.post(`${prefix}/save-credentials`, body)).data;
}

export async function deletePartner(id: number) {
  return (await httpClient.delete(`${prefix}/delete-partner/${id}`)).data;
}

export async function fethContractStats() {
  return (await httpClient.get(`${prefix}/contract-stats`)).data;
}

export async function getMessagePartner(id: number) {
  return (await httpClient.get(`${prefix}/get-message-partner/${id}`)).data;
}

export async function saveMessagePartner(body: {}) {
  return (await httpClient.post(`${prefix}/save-message-partner`, body)).data;
}

export async function updateMessagePartner(id: number, body: {}) {
  return (await httpClient.put(`${prefix}/update-message-partner/${id}`, body))
    .data;
}

export async function updateUserSchedule(id: number, body: {}) {
  return (await httpClient.post(`${prefix}/update-user-schedule/${id}`, body))
    .data;
}

export async function getUserSchedule(id: number) {
  return (await httpClient.get(`${prefix}/get-user-schedule/${id}`)).data;
}

export async function cancelProgramingPartner(id: number) {
  return (await httpClient.patch(`${prefix}/cancel-programming/${id}`)).data;
}

export async function getBranchesStats(){
  return (await httpClient.get(`${prefix}/branch-stats/all`)).data;
}

export async function getUnfilledPositionByBranch(id: number){
  return (await httpClient.get(`${prefix}/unfilled-positions/${id}`)).data;
}

export async function getHiredPositionByBranch(id: number){
  return (await httpClient.get(`${prefix}/hired-positions/${id}`)).data;
}

export async function getOverlappedPositionByBranch(id: number){
  return (await httpClient.get(`${prefix}/overlapped-positions/${id}`)).data;
}

export async function getTemplateLeader(){
  return (await httpClient.get(`${prefix}/leaders-report`)).data;
}

export async function getUnmatchedPartners(id: number){
  return (await httpClient.get(`${prefix}/unmatched-positions/${id}`)).data;
}

export async function autocompletePartners(value?: string){
  return (await httpClient.get(`${prefix}/autocomplete?search=${value}`)).data;
}