import React from 'react'
import { useAppSettings } from '../../../hooks/useAppSettings.tsx'
import Layout from '../../Layout.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import Title from '../../Title.tsx'
import { readBranch } from '../../../services/branch.service.ts'
import Button from '../../Button.tsx'
import { Panel, PanelHeader } from '../../panel/panel.jsx'
import { useFormik } from 'formik'
import ReporteSucursalForm from './ReporteSucursalForm.tsx'
import { readHoursByBranch, readPositionsByBranchAndShift, readSchedulesByBranchShiftWeekDay } from '../../../services/branchSchedule.service.ts'
import { generateReportObject, generateShiftReport, shifts, weekDays, weeks } from '../../../utils/constanst.js'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/index.ts'


const ReporteSucursal = () => {
  useAppSettings()
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true)
  const [branch, setBranch] = React.useState<any>(null)
  const [report, setReport] = React.useState<any>(null)

  const fetchDependencies = async () => {
    const branch = await readBranch(Number(id))
    setBranch(branch)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchDependencies()
  }, [])

  const formik = useFormik({
    initialValues: {
      week: -99,
      shift: -99,
      dayOfWeek: -99,
    },
    onSubmit: async (values) => {
      if (values.week === -99 || values.shift === -99 || values.dayOfWeek === -99) {
        return;
      }
      const schedules = await readSchedulesByBranchShiftWeekDay(Number(id), shifts[values.shift]?.name, weeks[values.week]?.value, weekDays[values.dayOfWeek]?.name);
      console.log('schedules: ', schedules);

      const shift = shifts.find((shift) => shift.id === values.shift)?.name ?? '';
      const positions = await readPositionsByBranchAndShift(Number(id), shift);

      const positionsArray = positions.map((position) => {
        const totalWorkersRequired = position.numberOfWorkers;

        const workers: any = [];

        for (let i = 0; i < totalWorkersRequired; i++) {
          workers.push({
            id: i,
            positionId: position.id,
            positionName: position.position.name + ' ' + (i + 1),
            originalPositionName: position.position.name,
            shift: position.shift,
          });
        }

        return workers
      });

      const positionsFlat = positionsArray.flat();

      const branchHours = await readHoursByBranch((Number(id)));
      const report = generateReportObject(positionsFlat, schedules, values);
      console.log('repormatutinoEndt: ', branchHours.matutinoEnd);
      const rep2 = generateShiftReport(schedules, positionsFlat, branchHours.matutinoStart, branchHours.matutinoEnd, branchHours.intermedioStart, branchHours.intermedioEnd, branchHours.vespertinoStart, branchHours.vespertinoEnd);
      console.log('rep2: ', rep2);
      console.log('positionsFlat: ', positionsFlat);
      setReport(rep2);
    },
  })

  return (
    <Layout loading={loading}>
      <Title
        baseTitle={`Surcursales`}
        basePath="/sucursales"
        activeTitle='Horario'
        title={`Horario de ${branch?.name}`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Horario de la sucursal
          </PanelHeader>
          <ReporteSucursalForm formik={formik} />
        </Panel>
      </div>
      {
        report && (
          <div className="card border-0 m-4 rounded">
            <Panel>
              <PanelHeader noButton={true} className="bg-azul">
                Reporte
              </PanelHeader>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      {
                        report.filter((shiftRecord) => shiftRecord.positions.length > 0).map((shiftRecord, shiftIndex) => (
                          <div className='mt-5' key={shiftIndex}>
                            <h3>{shiftRecord.shift}</h3>
                            <table className="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Posición</th>
                                  <th>Colaborador</th>
                                  <th>Horario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  shiftRecord?.positions?.map((positionRecord, positionIndex) => (
                                    <tr key={positionIndex}>
                                      <td>{positionRecord?.positionName ?? 'Sin nombre'}</td>
                                      <td>
                                        {
                                          Array.isArray(positionRecord?.occupiedBy) && positionRecord.occupiedBy.length > 0
                                            ? (
                                              <React.Fragment>
                                                {positionRecord.occupiedBy.map((schedule, schedIndex) => (
                                                  <p key={schedIndex}>{schedule?.user}</p>
                                                ))}
                                                {Array.isArray(positionRecord?.unoccupiedBy) && positionRecord.unoccupiedBy.map((schedule, schedIndex) => (
                                                  <p style={{ backgroundColor: '#FFDB0055' }} key={schedIndex}>{'⚠️ Sin asignar'}</p>
                                                ))}
                                              </React.Fragment>
                                            )
                                            : Array.isArray(positionRecord?.unoccupiedBy) && positionRecord.unoccupiedBy.map((schedule, schedIndex) => (
                                              <p style={{ backgroundColor: '#FFDB0055' }} key={schedIndex}>{'⚠️ Sin asignar'}</p>
                                            ))
                                        }
                                      </td>
                                      <td>
                                        {
                                          Array.isArray(positionRecord?.occupiedBy) && positionRecord.occupiedBy.length > 0
                                            ? (
                                              <React.Fragment>
                                                {positionRecord.occupiedBy.map((schedule, schedIndex) => (
                                                  <p key={schedIndex}>{schedule?.startTime ? `${schedule.startTime} - ${schedule.endTime}` : 'Horario no disponible'}</p>
                                                ))}
                                                {Array.isArray(positionRecord?.unoccupiedBy) && positionRecord.unoccupiedBy.map((schedule, schedIndex) => (
                                                  <p key={schedIndex}>{schedule?.startTime ? `${schedule.startTime} - ${schedule.endTime}` : 'Horario no disponible'}</p>
                                                ))}
                                              </React.Fragment>
                                            )
                                            : Array.isArray(positionRecord?.unoccupiedBy) && positionRecord.unoccupiedBy.map((schedule, schedIndex) => (
                                              <p key={schedIndex}>{schedule?.startTime ? `${schedule.startTime} - ${schedule.endTime}` : 'Horario no disponible'}</p>
                                            ))
                                        }
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        )
      }
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Aceptar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ReporteSucursal