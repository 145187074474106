export interface IVacancy {
  idPosition: number;
  idBusinessName: number;
  idBranch: number;
  isAvailable: boolean;
  isPriority?: boolean;
}

export const DefaultVacancy: IVacancy = {
  idPosition: -99,
  idBusinessName: -99,
  idBranch: -99,
  isAvailable: false,
  isPriority: false,
};
