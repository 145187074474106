import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setLoading, setError } from "../../../store/slices/ReportsSlice.ts";
import {
  getHiredPositionByBranch,
  getOverlappedPositionByBranch,
  getUnfilledPositionByBranch,
  getUnmatchedPartners,
} from "../../../services/partner.service.ts";
import { groupAndSortPositions } from "../../../utils/index.ts";
import { SortData } from "../../../models/ReportPositionsBranch.ts";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PositionReportRow from "./PositionReportRow.tsx";
import EmptyImage from "../../EmptyImage.tsx";

const ModalDetailsPosition = () => {
  const dispatch = useDispatch();
  const { modalType, branchSelected, loading } = useSelector(
    (state: RootState) => state.report
  );
  const [records, setRecords] = React.useState<SortData[]>([]);
  const fetchData = async () => {
    try {
      let response = [];
      if (modalType === "chilitos-en-plantilla") {
        response = await getHiredPositionByBranch(branchSelected);
      } else if (modalType === "sobrecarga-en-puestos") {
        response = await getOverlappedPositionByBranch(branchSelected);
      } else if (modalType === "puestos-sin-cubrir") {
        response = await getUnfilledPositionByBranch(branchSelected);
      }
      if (response) {
        const data = groupAndSortPositions(response) as SortData[];
        setRecords(data);
      }
      

      if (modalType == "chilitos-sin-coincidencia") {
        const response = await getUnmatchedPartners(branchSelected);
        const order = response.sort((a, b) => a.partner.candidate.person.firstName.localeCompare(b.partner.candidate.person.firstName));
        setRecords(order);
      }
    } catch (error) {
      console.log(error);
      dispatch(setError(error?.message || "Error al cargar la información"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    dispatch(setLoading(true));
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          {/* <TableHead>
          <TableRow>
            <TableCell width={10} />
            <TableCell align="left">Nombre del puesto</TableCell>
          </TableRow>
        </TableHead> */}
          <TableBody>
            
            {records.map((row: any, index) => (
              <PositionReportRow key={index} data={row} index={index} partner={row?.partner} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records.length === 0 && (
        <div className="py-4">
          <div className="d-flex">
            <EmptyImage className="mx-auto w-250px" />
          </div>
          <h5 className="text-secondary text-center fs-20px">
            No hay registros que mostrar
          </h5>
        </div>
      )}
    </React.Fragment>
    // <>{JSON.stringify(records)}</>
  );
};

export default ModalDetailsPosition;
