import React from "react";
import { StatsChilito } from "../../../models/StatsChilito";
import ButtonLink from "../../ButtonLink.tsx";

const TableChilitosStats = ({ records }: { records: StatsChilito[] }) => {
  return (
    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Razón social</th>
          <th style={{ textAlign: "center" }}>No Chilitos</th>
          <th style={{ textAlign: "center" }}>Credenciales generadas</th>
          <th style={{ textAlign: "center" }}>Credenciales no generadas</th>
          <th style={{ textAlign: "center" }}>Documentación</th>
        </tr>
      </thead>
      <tbody>
        {records.map((record, index) => (
          <tr key={index}>
            <td>{record.branch.id}</td>
            <td>{record.branch.name}</td>
            <td>{record.branch.businessName.name}</td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-verde d-block btn-lg fs-14px"
                style={{ margin: "0 auto" }}
              >
                {record.partners.length}
              </button>
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className={`btn btn-${
                  record.partners.filter((partner) => partner.credentials)
                    .length === record.partners.length
                    ? "azul"
                    : "rojo"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
              >
                {
                  record.partners.filter((partner) => partner.credentials)
                    .length
                }
              </button>
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className={`btn btn-${
                  record.partners.filter((partner) => !partner.credentials)
                    .length === 0
                    ? "azul"
                    : "rojo"
                } d-block btn-lg fs-14px`}
                style={{ margin: "0 auto" }}
              >
                {
                  record.partners.filter((partner) => !partner.credentials)
                    .length
                }
              </button>
            </td>

            <td style={{ textAlign: "center" }}>
              <ButtonLink
                className="btn btn-amarillo d-block btn-lg fs-14px"
                text={<i className="fa-solid fa-eye"></i>}
                to={`/reportes/documentos/${record.branch.id}`}
                type="button"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableChilitosStats;
