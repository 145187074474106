import React from "react";
import { getIn } from "formik";
import DateTime from "react-datetime";

const SelectHour = ({ ...props }) => {
  const error =
    getIn(props.formik.touched, props.field) &&
    getIn(props.formik.errors, props.field);

  return (
    <div className="row fs-13px col-md-12 mt-3">
      <label
        className="form-label col-form-label col-md-4 text-end"
        style={{ color: error ? "red" : "inherit" }}
      >
        {props.label}&nbsp;
        <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
          *
        </span>
      </label>

      <div className="row fs-13px col-md-8">
        <DateTime
          dateFormat={false}
          inputProps={{ placeholder: "Escoje una hora de entrada" }}
          onChange={(value: any) => {
            const date = value._d;
            if (isNaN(date)) {
              return;
            }
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const time = `${hours}:${minutes}`;
            console.log(time);
            props.formik.setFieldValue(props.field, date);
          }}
          value={props.formik.values[props.field]}
        />
      </div>
    </div>
  );
};

export default SelectHour;
